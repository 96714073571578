import { SubCol } from '../document'
import { Role } from './account'
import { ArticleDraft } from './article'
import { Badge } from './badge'
import { BookDraft } from './book'
import { BookmarkedUser } from './bookmark'
import { Chapter } from './chapter'
import { BadEvaluator, GoodEvaluator } from './evaluation'
import { Macro } from './macro'
import { Comment, CommentDraft, Reply, ReplyDraft } from './message'
import { Notification } from './notification'
import { OtherAccount } from './other_account'
import { Package } from './package'
import { Page } from './page'
import { PVShard } from './pv_shard'
import { Reference } from './reference'
import {
  Followee,
  Follower,
  FollowingTag,
  TagContributor,
  TagFollower,
} from './relationship'
import { Sale } from './sale'
import { TeamInvitation, TeamMember } from './team'

export const ReferenceSubcols = []

export const PackageSubcols = []

export const MacroSubcols = []

export const PVShardSubcols = []

export const ReportSubcols = []

export const ArticleDraftSubcols: [SubCol<Reference>, SubCol<Macro>] = [
  { id: 'references', children: ReferenceSubcols },
  { id: 'macros', children: MacroSubcols },
]

export const CommentDraftSubcols: [SubCol<Reference>, SubCol<Macro>] = [
  { id: 'references', children: ReferenceSubcols },
  { id: 'macros', children: MacroSubcols },
]

export const ReplyDraftSubcols: [SubCol<Reference>, SubCol<Macro>] = [
  { id: 'references', children: ReferenceSubcols },
  { id: 'macros', children: MacroSubcols },
]

export const GoodEvaluatorSubcols = []

export const BadEvaluatorSubcols = []

export const BookmarkedUserSubcols = []

export const MutedUserSubcols = []

export const MuteUserSubcols = []

export const MessageMuteUserSubcols = []

export const BadgeSubcols = []

export const ReplySubcols: [
  SubCol<Reference>,
  SubCol<Macro>,
  SubCol<GoodEvaluator>,
  SubCol<BadEvaluator>
] = [
  { id: 'references', children: ReferenceSubcols },
  { id: 'macros', children: MacroSubcols },
  { id: 'good_evaluators', children: GoodEvaluatorSubcols },
  { id: 'bad_evaluators', children: BadEvaluatorSubcols },
]

export const CommentSubcols: [
  SubCol<Reply>,
  SubCol<Reference>,
  SubCol<Macro>,
  SubCol<GoodEvaluator>,
  SubCol<BadEvaluator>
] = [
  { id: 'replies', children: ReplySubcols },
  { id: 'references', children: ReferenceSubcols },
  { id: 'macros', children: MacroSubcols },
  { id: 'good_evaluators', children: GoodEvaluatorSubcols },
  { id: 'bad_evaluators', children: BadEvaluatorSubcols },
]

export const ArticleSubcols: [
  SubCol<Comment>,
  SubCol<Reference>,
  SubCol<Macro>,
  SubCol<GoodEvaluator>,
  SubCol<BadEvaluator>,
  SubCol<BookmarkedUser>,
  SubCol<Badge>,
  SubCol<PVShard>
] = [
  { id: 'comments', children: CommentSubcols },
  { id: 'references', children: ReferenceSubcols },
  { id: 'macros', children: MacroSubcols },
  { id: 'good_evaluators', children: GoodEvaluatorSubcols },
  { id: 'bad_evaluators', children: BadEvaluatorSubcols },
  { id: 'bookmarked_users', children: BookmarkedUserSubcols },
  { id: 'badges', children: BadgeSubcols },
  { id: 'pv_shards', children: PVShardSubcols },
]

export const PageSubcols: [SubCol<Comment>] = [
  { id: 'comments', children: CommentSubcols },
]

export const ChapterSubcols: [SubCol<Page>] = [
  { id: 'pages', children: PageSubcols },
]

export const BookDraftSubcols: [
  SubCol<Page>,
  SubCol<Reference>,
  SubCol<Macro>
] = [
  { id: 'pages', children: PageSubcols },
  { id: 'references', children: ReferenceSubcols },
  { id: 'macros', children: MacroSubcols },
]

export const BookSubcols: [
  SubCol<Page>,
  SubCol<Reference>,
  SubCol<Macro>,
  SubCol<GoodEvaluator>,
  SubCol<BadEvaluator>,
  SubCol<BookmarkedUser>,
  SubCol<Badge>,
  SubCol<PVShard>
] = [
  { id: 'pages', children: PageSubcols },
  { id: 'references', children: ReferenceSubcols },
  { id: 'macros', children: MacroSubcols },
  { id: 'good_evaluators', children: GoodEvaluatorSubcols },
  { id: 'bad_evaluators', children: BadEvaluatorSubcols },
  { id: 'bookmarked_users', children: GoodEvaluatorSubcols },
  { id: 'badges', children: BadgeSubcols },
  { id: 'pv_shards', children: PVShardSubcols },
]

export const GlossarySubcols: [SubCol<Reference>, SubCol<Macro>] = [
  { id: 'references', children: ReferenceSubcols },
  { id: 'macros', children: MacroSubcols },
]

export const GlossaryDraftSubcols: [SubCol<Reference>, SubCol<Macro>] = [
  { id: 'references', children: ReferenceSubcols },
  { id: 'macros', children: MacroSubcols },
]

export const TextbookSubcols: [
  SubCol<Page>,
  SubCol<Chapter>,
  SubCol<Reference>,
  SubCol<Macro>
] = [
  { id: 'pages', children: PageSubcols },
  { id: 'chapters', children: ChapterSubcols },
  { id: 'references', children: ReferenceSubcols },
  { id: 'macros', children: MacroSubcols },
]

export const TextbookDraftSubcols: [
  SubCol<Page>,
  SubCol<Chapter>,
  SubCol<Reference>,
  SubCol<Macro>
] = [
  { id: 'pages', children: PageSubcols },
  { id: 'chapters', children: ChapterSubcols },
  { id: 'references', children: ReferenceSubcols },
  { id: 'macros', children: MacroSubcols },
]

export const TermsWithGlossaryInfoSubcols = []

export const CategorySubcols = []

export const MathdownImageSubcols = []

export const AnnouncementSubcols = []

export const SaleSubcols = []

export const PayoutApplicationSubcols = []

export const NotificationSubcols = []

export const ProfileSubcols = []

export const FollowerSubcols = []

export const FolloweeSubcols = []

export const FollowingTagSubcols = []

export const TagFollowerSubcols = []

export const TagContributorSubcols = []

export const TagSubcols: [SubCol<TagFollower>, SubCol<TagContributor>] = [
  { id: 'followers', children: TagFollowerSubcols },
  { id: 'contributors', children: TagContributorSubcols },
]

export const RoleSubcols = []

export const TeamMembersSubcols = []

export const TeamInvitationsSubcols = []

export const OtherAccountSubcols = []

export const AccountSubcols: [
  SubCol<ArticleDraft>,
  SubCol<CommentDraft>,
  SubCol<ReplyDraft>,
  SubCol<BookDraft>,
  SubCol<Macro>,
  SubCol<Package>,
  SubCol<Followee>,
  SubCol<Follower>,
  SubCol<FollowingTag>,
  SubCol<Notification>,
  SubCol<Role>,
  SubCol<Sale>,
  SubCol<OtherAccount>,
  SubCol<TeamMember>,
  SubCol<TeamInvitation>
] = [
  { id: 'article_drafts', children: ArticleDraftSubcols },
  { id: 'comment_drafts', children: CommentDraftSubcols },
  { id: 'reply_drafts', children: ReplyDraftSubcols },
  { id: 'book_drafts', children: BookDraftSubcols },
  { id: 'macros', children: MacroSubcols },
  { id: 'packages', children: PackageSubcols },
  { id: 'followees', children: FolloweeSubcols },
  { id: 'followers', children: FollowerSubcols },
  { id: 'following_tags', children: FollowingTagSubcols },
  { id: 'notifications', children: NotificationSubcols },
  { id: 'roles', children: RoleSubcols },
  { id: 'sales', children: SaleSubcols },
  { id: 'other_accounts', children: OtherAccountSubcols },
  // 以下はチームアカウントの場合のみ
  { id: 'members', children: TeamMembersSubcols },
  { id: 'invitations', children: TeamInvitationsSubcols },
]

export const InquirySubcols = []

export const IPAddressSubcols = []

export const SeriesSubcols = []
