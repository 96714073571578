import {
  useCollectionData,
  useCollectionDataOnce,
} from 'react-firebase-hooks/firestore'
import {
  Reference,
  ReferencesParentRefAssignable,
  ValidDocument,
} from '../../firebase'
import { sortedReferencesRef } from '../../models/references'

export const useReferences = <T extends ValidDocument>(
  parentRef?: ReferencesParentRefAssignable<T>,
  once = false,
  defaultReferences?: Reference[]
) => {
  const [listenedReferences, listenedLoading, listenedError] =
    useCollectionData(
      parentRef && !once ? sortedReferencesRef(parentRef) : null,
      { initialValue: defaultReferences }
    )
  const [onceReferences, onceLoading, onceError] = useCollectionDataOnce(
    parentRef && once ? sortedReferencesRef(parentRef) : null,
    { initialValue: defaultReferences }
  )
  const references = once ? onceReferences : listenedReferences
  const loading = once ? onceLoading : listenedLoading
  const error = once ? onceError : listenedError

  return { references, loading, error }
}
