import DOMPurify from 'isomorphic-dompurify'

export const sanitize = (src: string) =>
  DOMPurify.sanitize(src, {
    ADD_TAGS: ['mjx-container', 'mjx-assistive-mml', 'use'],
    ADD_ATTR: [
      'ctxtmenu_counter',
      'jax',
      'focusable',
      'focusable',
      'justify',
      'target',
    ],
  })
