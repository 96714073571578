import { collection, doc, DocRef } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document } from '../document'
import { accountRef } from './account'
import {
  FolloweeSubcols,
  FollowerSubcols,
  FollowingTagSubcols,
  TagContributorSubcols,
  TagFollowerSubcols,
} from './subcollections'
import { tagRef } from './tag'

export interface Follower extends Document {
  readonly parent_id: 'followers'
  readonly ref: DocRef<Follower>
  readonly subcollections: typeof FollowerSubcols
}

export interface Followee extends Document {
  readonly parent_id: 'followees'
  readonly ref: DocRef<Followee>
  readonly subcollections: typeof FolloweeSubcols
}

export interface FollowingTag extends Document {
  readonly parent_id: 'following_tags'
  readonly ref: DocRef<FollowingTag>
  readonly subcollections: typeof FollowingTagSubcols
}

export interface TagFollower extends Document {
  readonly parent_id: 'followers'
  readonly ref: DocRef<TagFollower>
  readonly subcollections: typeof TagFollowerSubcols
}

export interface TagContributor extends Document {
  readonly parent_id: 'contributors'
  readonly ref: DocRef<TagContributor>
  readonly subcollections: typeof TagFollowerSubcols
  count: number // ユーザが親のタグに貢献した数
}

export const followerConverter = getConverter<Follower>(FolloweeSubcols)
export const followersRef = (uid: string) =>
  collection<Follower>(accountRef(uid), 'followers').withConverter(
    followerConverter
  )
export const followerRef = (uid: string, followerUid: string) =>
  doc(followersRef(uid), followerUid)

export const followeeConverter = getConverter<Followee>(FolloweeSubcols)
export const followeesRef = (uid: string) =>
  collection<Followee>(accountRef(uid), 'followees').withConverter(
    followeeConverter
  )
export const followeeRef = (uid: string, followeeUid: string) =>
  doc(followeesRef(uid), followeeUid)

export const followingTagConverter =
  getConverter<FollowingTag>(FollowingTagSubcols)
export const followingTagsRef = (uid: string) =>
  collection<FollowingTag>(accountRef(uid), 'following_tags').withConverter(
    followingTagConverter
  )
export const followingTagRef = (uid: string, tagId: string) =>
  doc(followingTagsRef(uid), tagId)

export const tagFollowerConverter =
  getConverter<TagFollower>(TagFollowerSubcols)
export const tagFollowersRef = (tagId: string) =>
  collection<TagFollower>(tagRef(tagId), 'followers').withConverter(
    tagFollowerConverter
  )
export const tagFollowerRef = (tagId: string, uid: string) =>
  doc(tagFollowersRef(tagId), uid)

export const tagContributorConverter = getConverter<TagContributor>(
  TagContributorSubcols
)
export const tagContributorsRef = (tagId: string) =>
  collection<TagContributor>(tagRef(tagId), 'contributors').withConverter(
    tagContributorConverter
  )
export const tagContributorRef = (tagId: string, uid: string) =>
  doc(tagContributorsRef(tagId), uid)
