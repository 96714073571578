import { useState } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Draft } from '../../firebase'
import { useAuthState } from '../../firebase/hooks/useAuthState'
import { orderedDraftsRef } from '../../models/draft'

export const useDrafts = <T extends Draft>(parentId: T['parent_id']) => {
  const { user } = useAuthState()
  const [drafts] = useCollectionData(
    user ? orderedDraftsRef(parentId, user.uid) : null
  )
  const [selectedDraftId, setSelectedDraftId] = useState<string>()
  return {
    drafts,
    selectDraft: setSelectedDraftId,
    selectedDraft: drafts?.find((d) => d.id === selectedDraftId),
  }
}
