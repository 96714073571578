import { Button } from 'react-bootstrap'
import { PromiseVoid } from '../../../types'
import { SpinnerButton } from '../../spinners'

type CancelButtonProps = {
  onClick: () => void
  disabled?: boolean
}

export const CancelButton = ({
  onClick: handleClick,
  disabled,
}: CancelButtonProps) => {
  return (
    <Button onClick={handleClick} variant="secondary" disabled={disabled}>
      キャンセル
    </Button>
  )
}

type SaveButtonProps = {
  onClick: () => PromiseVoid
  disabled?: boolean
  loading?: boolean
  isUpdate: boolean
}

export const SaveButton = ({
  onClick: handleClick,
  disabled,
  loading,
  isUpdate,
}: SaveButtonProps) => {
  return (
    <SpinnerButton
      loading={loading}
      loadingText="保存中..."
      disabled={disabled}
      onClick={handleClick}
    >
      {isUpdate ? '更新' : '追加'}
    </SpinnerButton>
  )
}

type Props = {
  onClickCancelButton: () => void
  onClickSaveButton: () => PromiseVoid
  cancelButtonDisabled: boolean
  saveButtonDisabled: boolean
  saveButtonLoading: boolean
  isUpdate: boolean
}

export const ActionButtons = ({
  onClickCancelButton: handleClickCancelButton,
  onClickSaveButton: handleClickSaveButton,
  cancelButtonDisabled,
  saveButtonDisabled,
  saveButtonLoading,
  isUpdate,
}: Props) => {
  return (
    <div className="d-flex gap-4">
      <SaveButton
        onClick={handleClickSaveButton}
        disabled={saveButtonDisabled}
        loading={saveButtonLoading}
        isUpdate={isUpdate}
      />
      <CancelButton
        disabled={cancelButtonDisabled}
        onClick={handleClickCancelButton}
      />
    </div>
  )
}
