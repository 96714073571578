import { collection, db, DocRef } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document } from '../document'
import { MathdownImageSubcols } from './subcollections'

// Cloud Storage path
// "uploads/mathdown/{id}.{png/jpeg/gif}"
export interface MathdownImage extends Document {
  readonly ref: DocRef<MathdownImage>
  readonly parent_id: 'mathdown_images'
  readonly subcollections: typeof MathdownImageSubcols
  created_by: string
  filename: string
  full_path: string
  url: string
}

export const mathdownImageConverter =
  getConverter<MathdownImage>(MathdownImageSubcols)
export const mathdownImagesRef = collection<MathdownImage>(
  db,
  'mathdown_images'
).withConverter(mathdownImageConverter)
