import { useCallback } from 'react'
import useSWRInfinite from 'swr/infinite'
import { z } from 'zod'
import { ESUser } from '../../elastic_search'
import { fb } from '../../firebase'
import { UsersSearchEndpointParams } from '../../shared/firebase/functions/search_endpoint'
import { deserializeArray } from '../../utils'

type Params = z.input<typeof UsersSearchEndpointParams>
type SWRKey = {
  index: 'users'
  params: Params
  page: number
  size: number
}

const PAGE_SIZE = 20

const fetchUsers = async (key: SWRKey): Promise<ESUser[]> => {
  const { data } = await fb.call('search')(key)
  if (data.index !== 'users') throw new Error('Unexpected result.')
  return deserializeArray(data.result, {})
}

export const useESUsers = (params: Params | null, fallback?: ESUser[]) => {
  const getKey = useCallback(
    (index: number): SWRKey | null => {
      if (params === null) return null
      return { params, page: index + 1, size: PAGE_SIZE, index: 'users' }
    },
    [params]
  )
  const { data, size, setSize, isLoading, isValidating } = useSWRInfinite<
    ESUser[]
  >(getKey, fetchUsers, { fallbackData: fallback ? [fallback] : undefined })

  const isLoadingMore =
    isLoading || (size > 0 && data && typeof data[size - 1] === 'undefined')
  const isEmpty = data?.[0] !== undefined ? data[0].length === 0 : undefined
  const isReachingEnd =
    data !== undefined
      ? data.length >= 2 &&
        data[data.length - 2]?.length < PAGE_SIZE &&
        data[data.length - 1]?.length === 0
      : undefined
  const isRefreshing = isValidating && data && data.length === size

  const users: ESUser[] | undefined =
    data && data.length > 0 ? ([] as ESUser[]).concat(...data) : undefined

  const loadMore = async () => {
    await setSize(size + 1)
  }

  return {
    users,
    loadMore,
    isReachingEnd,
    isLoadingMore,
    isEmpty,
    isRefreshing,
  }
}
