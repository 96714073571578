import { useEffect, useState } from 'react'
import { Badge, Form, InputGroup } from 'react-bootstrap'
import { FaQuestionCircle } from 'react-icons/fa'
import { GeneralBookForm, MathlogArticleForm, PaperForm, WebsiteForm } from '.'
import {
  CreateParams,
  DocRef,
  Reference,
  ReferencesParentRefAssignable,
  ValidDocument,
  addDoc,
  referencesRef,
  updateDoc,
} from '../../../firebase'
import { useAuthState } from '../../../firebase/hooks'
import { Designed, PromiseVoid } from '../../../types'
import { HELP_NOTION_LINKS, logEvent } from '../../../utils'
import { usePopupMessage } from '../../messenger'

type ReferenceTypeSelectorProps = {
  value: Reference['type'] | undefined
  onChange: (referenceType: Reference['type']) => PromiseVoid
}

const ReferenceTypeSelector = ({
  value,
  onChange: handleChange,
}: ReferenceTypeSelectorProps) => (
  <div className="mb-6">
    <Form.Label>参考文献の種類</Form.Label>
    <InputGroup>
      <Form.Select
        value={value}
        onChange={(e) => handleChange(e.target.value as Reference['type'])}
      >
        <option value={undefined} selected={value === undefined}>
          選択してください
        </option>
        <option
          value={'mathlog_article'}
          selected={value === 'mathlog_article'}
        >
          Mathlogの記事
        </option>
        <option value={'general_book'} selected={value === 'general_book'}>
          書籍
        </option>
        <option value={'paper'} selected={value === 'paper'}>
          論文
        </option>
        <option value={'website'} selected={value === 'website'}>
          Webサイト
        </option>
      </Form.Select>
    </InputGroup>
  </div>
)

type Props<T extends ValidDocument> = {
  parentRef: ReferencesParentRefAssignable<T>
  reference?: Reference
  onSave: (reference: DocRef<Reference>) => PromiseVoid
  onCancel: () => PromiseVoid
  nextOrder: number
}

export type EachReferenceFormProps = {
  reference?: Reference
  onClickSaveButton: (params: CreateParams<Reference>) => PromiseVoid
  onClickCancelButton: () => PromiseVoid
  nextOrder: number
}

export const ReferenceForm = <T extends ValidDocument>({
  parentRef,
  reference,
  onSave: handleSave,
  onCancel: handleCancel,
  nextOrder,
  ...containerProps
}: Designed<Props<T>>) => {
  const { user } = useAuthState()
  const [referenceType, setReferenceType] = useState<
    Reference['type'] | undefined
  >(reference ? reference.type : undefined)

  useEffect(() => {
    setReferenceType(reference ? reference.type : undefined)
  }, [reference])

  const { setPopupMessage } = usePopupMessage()

  const saveReference = async (params: CreateParams<Reference>) => {
    const ref = reference
      ? await updateDoc(reference.ref, params)
      : await addDoc(referencesRef(parentRef), params)
    setPopupMessage(
      reference ? '参考文献を更新しました。' : '参考文献を保存しました。'
    )
    await handleSave(ref)
    setReferenceType(undefined)
  }

  const formProps: EachReferenceFormProps = {
    reference,
    nextOrder,
    onClickCancelButton: handleCancel,
    onClickSaveButton: saveReference,
  }

  return (
    <div {...containerProps}>
      <Badge className="mb-2" bg={reference ? 'success' : 'primary'}>
        {reference ? '編集モード' : '追加モード'}
      </Badge>
      <div className="mb-6">
        <ReferenceTypeSelector
          value={referenceType}
          onChange={setReferenceType}
        />
      </div>
      {referenceType && (
        <>
          {referenceType === 'mathlog_article' && (
            <MathlogArticleForm {...formProps} />
          )}
          {referenceType === 'general_book' && (
            <GeneralBookForm {...formProps} />
          )}
          {referenceType === 'paper' && <PaperForm {...formProps} />}
          {referenceType === 'website' && <WebsiteForm {...formProps} />}
        </>
      )}
      <hr />
      <div className="d-flex align-items-center small text-muted mb-6 mb-md-0">
        <FaQuestionCircle className="me-1" />
        参考文献の追加方法や使い方は
        <a
          href={HELP_NOTION_LINKS.REFERENCES_SETTING}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            logEvent('tap_reference_help', {
              uid: user?.uid,
            })
          }
        >
          こちら
        </a>
      </div>
    </div>
  )
}
