import { UserImage } from '.'
import { LinkWrapper } from '../../assets/LinkWrapper'
import { ESUser } from '../../elastic_search'
import { Profile } from '../../firebase'
import { Designed } from '../../types'

type Props = {
  size: string | number
  profile?: Profile | ESUser
  imgStyle?: React.CSSProperties
}

export const UserImageWithLink = ({
  size,
  profile,
  imgStyle,
  ...wrapperProps
}: Designed<Props>) => {
  return profile ? (
    <LinkWrapper href={`/users/${profile.id}`} passHref>
      <UserImage
        profile={profile}
        size={size}
        imgStyle={imgStyle}
        {...wrapperProps}
      />
    </LinkWrapper>
  ) : (
    <UserImage profile={profile} size={size} {...wrapperProps} />
  )
}
