import { isBlank } from '../../../../../utils'
import { rules } from '../../rules'
import type { Formula, Tokens } from '../../types'
import { FormulaeCache } from '../../utils/formulae_cache'

export const formula = (
  src: string,
  formulae: Formula[],
  cache: FormulaeCache | null
): Tokens.Formula | null => {
  const cap = rules.inline.formula.exec(src)
  if (!cap) return null
  const index = Number(cap[1])
  const hash = Number(cap[2])
  const cachedHTML = cache?.get(hash)
  const base = {
    type: 'formula' as const,
    raw: cap[0],
    hash,
    error: formulae[index].error,
  }
  if (!isBlank(cachedHTML)) {
    return {
      ...base,
      text: cachedHTML,
      cached: true,
    }
  } else {
    const alignReg = /\\Text(Left|Center|Right)/g
    const alignCap = alignReg.exec(formulae[index].text)
    const align = (alignCap ? alignCap[1].toLowerCase() : undefined) as
      | 'right'
      | 'center'
      | 'left'
      | undefined
    const replacedFormulaText = formulae[index].text.replace(alignReg, '')
    return {
      ...base,
      align,
      text: replacedFormulaText,
      cached: false,
    }
  }
}
