import { RegexEditor } from '../utils/regex_editor'
import { nolink } from './nolink'
import { reflink } from './reflink'

const _reflinkSearch = 'reflink|nolink(?!\\()'

export const reflinkSearch = new RegexEditor(_reflinkSearch, 'g')
  .replace('reflink', reflink)
  .replace('nolink', nolink)
  .getRegex()
