import { useMemo, useState } from 'react'
import { Button, Dropdown, Nav } from 'react-bootstrap'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaUserPlus } from 'react-icons/fa'
import InfiniteScroll from 'react-infinite-scroller'
import {
  Batch,
  Profile,
  TeamMember,
  TeamProfile,
  addDoc,
  deleteDoc,
  isAdminOrOwner,
  isNotCurrentUser,
  profileRef,
  teamInvitationsRef,
  teamMemberRef,
} from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { useTeamMembers } from '../../hooks/models/useTeamMembers'
import { PromiseVoid } from '../../types'
import { isBlank } from '../../utils'
import { IconWrapper } from '../IconWrapper'
import { usePopupMessage } from '../messenger'
import { LoadingSpinner } from '../spinners'
import { UserImageWithName } from '../user'

type MemberItemProps = {
  memberProfile: Profile
  teamId: string
  onChangeRole: (
    teamId: string,
    memberId: string,
    role: TeamMember['role']
  ) => PromiseVoid
}

const MemberItem = ({
  memberProfile,
  onChangeRole: handleChangeRole,
  teamId,
}: MemberItemProps) => {
  const { profile: currentUserProfile } = useAuthState()
  return (
    <div className="d-flex align-items-center gap-2">
      <div className="flex-grow-1">
        <UserImageWithName profile={memberProfile} size={'45px'} />
      </div>
      {isAdminOrOwner(currentUserProfile, teamId) && (
        <Dropdown align={'end'}>
          <Dropdown.Toggle
            variant="link"
            className="text-black fs-5 dropdown-toggle-after-none me-2"
          >
            <BsThreeDotsVertical />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={async () => {
                const batch = new Batch()
                const role =
                  memberProfile.teams[teamId] === 'contributor'
                    ? ('admin' as const)
                    : ('contributor' as const)
                batch.update(profileRef(memberProfile.id), {
                  [`teams.${teamId}`]: role,
                })
                batch.update(teamMemberRef(teamId, memberProfile.id), {
                  role,
                })
                await batch.commit()
                await handleChangeRole(teamId, memberProfile.id, role)
              }}
            >
              {memberProfile.teams[teamId] === 'contributor'
                ? '管理者に変更'
                : '投稿者に変更'}
            </Dropdown.Item>
            {isNotCurrentUser(currentUserProfile, memberProfile.id) && (
              <Dropdown.Item
                className="text-danger"
                onClick={async () => {
                  const confirm =
                    window.confirm('本当にメンバーを追放しますか。')
                  if (!confirm) return
                  await deleteDoc(teamMemberRef(teamId, memberProfile.id))
                }}
              >
                メンバーを追放
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  )
}

type SpecificRoleMembersListProps = {
  teamProfile: TeamProfile
  onClose: () => void
  role: TeamMember['role']
}

const SpecificRoleMembersList = ({
  teamProfile,
  role,
}: SpecificRoleMembersListProps) => {
  const {
    members: fetchedMembers,
    loadMore,
    isLast,
    fetching,
  } = useTeamMembers(teamProfile.id, role)
  const [removedMemberIds, setRemovedMemberIds] = useState<string[]>([])

  const members = useMemo(() => {
    return fetchedMembers.filter((m) => !removedMemberIds.includes(m.id))
  }, [fetchedMembers, removedMemberIds])

  return (
    <InfiniteScroll
      loadMore={async () => {
        if (!fetching) await loadMore()
      }}
      hasMore={!isLast}
      useWindow={false}
      loader={<LoadingSpinner />}
    >
      <div className="d-flex flex-column gap-3">
        {members.map((member) => (
          <MemberItem
            key={member.id}
            memberProfile={member}
            teamId={teamProfile.id}
            onChangeRole={(teamId, memberId) => {
              setRemovedMemberIds((prev) => [...prev, memberId])
            }}
          />
        ))}
      </div>
      {members.length === 0 && 'メンバーがいません。'}
    </InfiniteScroll>
  )
}

type Props = {
  teamProfile: TeamProfile
  onClose: () => void
}

export const TeamMembersList = ({
  teamProfile,
  onClose: handleClose,
}: Props) => {
  const [activeNav, setActiveNav] = useState<'contributors' | 'admins'>(
    'admins'
  )
  const { user, profile: currentUserProfile } = useAuthState()
  const { setPopupMessage } = usePopupMessage()
  const [issuedInvitation, setIssuedInvitation] = useState(false)

  return (
    <>
      <div className="d-flex gap-4 justify-content-between align-items-center mb-3">
        <Nav variant="pills">
          <Nav.Item onClick={() => setActiveNav('admins')}>
            <Nav.Link active={activeNav === 'admins'} eventKey="users">
              管理者
            </Nav.Link>
          </Nav.Item>
          <Nav.Item onClick={() => setActiveNav('contributors')}>
            <Nav.Link active={activeNav === 'contributors'} eventKey="tags">
              投稿者
            </Nav.Link>
          </Nav.Item>
        </Nav>
        {isAdminOrOwner(currentUserProfile, teamProfile.id) && (
          <div>
            <Button
              size="sm"
              variant="link"
              disabled={issuedInvitation}
              onClick={async () => {
                if (process.env.NEXT_PUBLIC_HOST === undefined) {
                  throw Error('missing NEXT_PUBLIC_HOST.')
                }
                if (isBlank(user)) throw Error('missing user.')
                setIssuedInvitation(true)
                const invitationRef = await addDoc(
                  teamInvitationsRef(teamProfile.id),
                  { created_by: user.uid }
                )
                await navigator.clipboard.writeText(
                  `${process.env.NEXT_PUBLIC_HOST}/users/${teamProfile.id}?invitation=${invitationRef.id}`
                )
                setPopupMessage('招待リンクをコピーしました。')

                // 連打されて大量に発行されないように1秒間ボタンを押せなくする
                setTimeout(() => setIssuedInvitation(false), 1000)
              }}
            >
              <IconWrapper suffix="招待">
                <FaUserPlus />
              </IconWrapper>
            </Button>
          </div>
        )}
      </div>
      {activeNav === 'admins' && (
        <SpecificRoleMembersList
          teamProfile={teamProfile}
          onClose={handleClose}
          role="admin"
        />
      )}
      {activeNav === 'contributors' && (
        <SpecificRoleMembersList
          teamProfile={teamProfile}
          onClose={handleClose}
          role="contributor"
        />
      )}
    </>
  )
}
