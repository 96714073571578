import { DocRef, collection, doc } from '../../../../firebase/alias'
import { isBlank } from '../../../utils'
import { getConverter } from '../converter'
import { Document } from '../document'
import { accountRef } from './account'
import { Profile } from './profile'
import { TeamInvitationsSubcols, TeamMembersSubcols } from './subcollections'

export interface TeamMember extends Document {
  readonly ref: DocRef<TeamMember>
  readonly parent_id: 'members'
  readonly subcollections: typeof TeamMembersSubcols
  role: 'admin' | 'contributor'
  invitation_id?: string
}

export interface TeamInvitation extends Document {
  readonly ref: DocRef<TeamInvitation>
  readonly parent_id: 'invitations'
  readonly subcollections: typeof TeamInvitationsSubcols
  created_by: string
}

export const teamMemberConverter = getConverter<TeamMember>(TeamMembersSubcols)
export const teamMembersRef = (teamId: string) =>
  collection(accountRef(teamId), 'members').withConverter(teamMemberConverter)
export const teamMemberRef = (teamId: string, memberId: string) =>
  doc(teamMembersRef(teamId), memberId)

export const teamInvitationConverter = getConverter<TeamInvitation>(
  TeamInvitationsSubcols
)
export const teamInvitationsRef = (teamId: string) =>
  collection(accountRef(teamId), 'invitations').withConverter(
    teamInvitationConverter
  )
export const teamInvitationRef = (teamId: string, invitationId: string) =>
  doc(teamInvitationsRef(teamId), invitationId)

export const isAdminOrOwner = (
  currentUser: Profile | null | undefined,
  teamId: string
) => {
  return (
    !isBlank(currentUser) &&
    (currentUser.teams[teamId] === 'admin' || currentUser.id === teamId)
  )
}
