import { RegexEditor } from '../utils/regex_editor'
import { box } from './box'
import { hr } from './hr'
import { table } from './table'
import { _tag } from './_tag'

const _paragraph =
  /^([^\n]+(?:\n(?!hr|heading|lheading|blockquote|fences|list|html|table|box| +\n)[^\n]+)*)/

export const paragraph = new RegexEditor(_paragraph)
  .replace('hr', hr)
  .replace('heading', ' {0,3}#{1,6} ?')
  .replace('|lheading', '') // setex headings don't interrupt commonmark paragraphs
  .replace('table', table) // interrupt paragraphs with table
  .replace('blockquote', ' {0,3}>')
  .replace('box', box)
  .replace('fences', ' {0,3}(?:`{3,}(?=[^`\\n]*\\n)|~{3,})[^\\n]*\\n')
  .replace('list', ' {0,3}(?:[*+-]|1[.)]) ') // only lists starting from 1 can interrupt
  .replace(
    'html',
    '</?(?:tag)(?: +|\\n|/?>)|<(?:script|pre|style|textarea|!--)'
  )
  .replace('tag', _tag) // pars can be interrupted by type (6) html blocks
  .getRegex()
