import { deleteField, query, where } from 'firebase/firestore'
import { mutate } from 'swr'
import {
  Account,
  Authorization,
  AuthorizationDetails,
  Batch,
  Book,
  BookDraft,
  DocRef,
  Timestamp,
  bookDraftsRef,
  bookRef,
  doc,
  getDoc,
  getDocs,
} from '../firebase'
import { canEditContent } from './content'
import { addDefaultMacos, addMacros, replaceMacros } from './macros'
import { addPages, replacePages } from './page'
import { addReferences, replaceReferences } from './references'
import { hasRole } from './role'
import { findOrCreateTagIds } from './tag'

export const createBookDraft = async (
  uid: string,
  publicationRef?: DocRef<Book>
) => {
  const batch = new Batch()
  const now = Timestamp.now()
  if (publicationRef) {
    const publicationSnapshot = await getDoc(publicationRef)
    const publication = publicationSnapshot.data()
    if (!publication) throw new Error('The publication is not found.')
    if (
      publication.created_by !== uid &&
      !(await hasRole(uid, 'mathlog_admin'))
    )
      throw new Error('The uid is invalid.')
    const ref = doc(bookDraftsRef(uid))
    batch.create(ref, {
      created_by: uid,
      title: publication.title,
      description: publication.description,
      thumbnail: publication.thumbnail,
      publication_id: publication.id,
      collaborator_uids: publication.collaborator_uids,
      editability_details: publication.editability_details,
      document_id: ref.id,
      created_by_user_at: now,
      updated_by_user_at: now,
    })
    await addPages(publicationRef, ref, batch)
    await addMacros(publicationRef, ref, batch)
    await addReferences(publicationRef, ref, batch)
    await batch.commit()
    return ref
  } else {
    const ref = doc(bookDraftsRef(uid))
    batch.create(ref, {
      created_by: uid,
      title: '',
      description: '',
      collaborator_uids: [],
      editability_details: { authorized_by_url: false, authorized_uids: [] },
      document_id: ref.id,
      created_by_user_at: now,
      updated_by_user_at: now,
    })
    await addDefaultMacos(uid, ref, batch)
    await batch.commit()
    return ref
  }
}

export type PublicationParams = {
  category_id: string
  tags: string[]
  visibility: Authorization
  limited_visibility_details?: AuthorizationDetails
  is_beginner: boolean
  collaborator_uids: string[]
}

export const createBookByDraft = async (
  draft: BookDraft,
  params: PublicationParams,
  publicationRef: DocRef<Book>
): Promise<DocRef<Book>> => {
  const uid = draft.ref.parent.parent?.id
  if (uid === undefined) {
    throw new Error('Cloud not specify user.')
  }

  const batch = new Batch()
  const now = Timestamp.now()
  await addPages(draft.ref, publicationRef, batch)
  await addMacros(draft.ref, publicationRef, batch)
  const referencesNum = await addReferences(draft.ref, publicationRef, batch)
  batch.create(publicationRef, {
    category_id: params.category_id,
    tag_ids: await findOrCreateTagIds(params.tags),
    created_by: uid,
    thumbnail: draft.thumbnail,
    title: draft.title,
    description: draft.description,
    good_count: 0,
    bad_count: 0,
    comments_count: 0,
    bookmarked_count: 0,
    reports_score: 0,
    pv: 0,
    has_references: referencesNum > 0,
    is_beginner: params.is_beginner,
    collaborator_uids: draft.collaborator_uids,
    is_collaborative: draft.is_collaborative,
    editability_details: draft.editability_details,
    visibility: params.visibility,
    limited_visibility_details: params.limited_visibility_details,
    created_by_user_at: now,
    updated_by_user_at: now,
  })
  await batch.delete(draft.ref)
  await batch.commit()
  return publicationRef
}

export const updateBookByDraft = async (
  draft: BookDraft,
  params: PublicationParams
): Promise<DocRef<Book>> => {
  const uid = draft.ref.parent.parent?.id
  if (uid === undefined) {
    throw new Error('Cloud not specify user.')
  }

  if (draft.publication_id === undefined)
    throw new Error('Publication target does not exist.')

  const batch = new Batch()
  const publicationRef = bookRef(draft.publication_id)
  await replacePages(draft.ref, publicationRef, batch)
  await replaceMacros(draft.ref, publicationRef, batch)
  const referencesNum = await replaceReferences(
    draft.ref,
    publicationRef,
    batch
  )
  const isAdmin = await hasRole(uid, 'mathlog_admin')
  batch.update(publicationRef, {
    category_id: params.category_id,
    tag_ids: await findOrCreateTagIds(params.tags),
    thumbnail: draft.thumbnail,
    title: draft.title,
    description: draft.description,
    has_references: referencesNum > 0,
    visibility: params.visibility,
    editability_details: draft.editability_details,
    limited_visibility_details: params.limited_visibility_details,
    is_beginner: params.is_beginner,
    updated_by_user_at: !isAdmin ? Timestamp.now() : undefined,
  })
  await batch.delete(draft.ref)
  await batch.commit()
  // キャッシュをクリアする
  await mutate(`books/${publicationRef.id}/pages`, undefined, false)
  return publicationRef
}

const bookDraftsSnapshotByBookId = async (userId: string, bookId: string) =>
  await getDocs(
    query(bookDraftsRef(userId), where('publication_id', '==', bookId))
  )

export const checkBookDraftExists = async (userId: string, bookId: string) => {
  const bookDraftsSnapshot = await bookDraftsSnapshotByBookId(userId, bookId)
  return !bookDraftsSnapshot.empty
}

export const getExistingBookDraftSnapshot = async (
  userId: string,
  bookId: string
) => {
  const bookDraftsSnapshot = await bookDraftsSnapshotByBookId(userId, bookId)
  const bookDrafts = bookDraftsSnapshot.docs.map((doc) => doc.data())
  return bookDrafts[0]
}

export const getExistingBookDraftID = async (
  userId: string,
  bookId: string
) => {
  const bookDraftsSnapShot = await bookDraftsSnapshotByBookId(userId, bookId)
  const bookDrafts = bookDraftsSnapShot.docs.map((doc) => doc.data())
  return bookDrafts[0].id
}

export const deleteBook = async (book: Book) => {
  const batch = new Batch()
  await batch.delete(book.ref)
  // 関連する下書きからpublication_refを消す
  const relationalDraftsSnapshot = await getDocs(
    query(
      bookDraftsRef(book.created_by),
      where('publication_id', '==', book.id)
    )
  )
  relationalDraftsSnapshot.docs.forEach((doc) =>
    batch.update(doc.ref, { publication_id: deleteField() })
  )
  await batch.commit()
}

export const canEditBook = (book: Book, account: Account) =>
  canEditContent(book, account)
