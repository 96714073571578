import { Toast, ToastContainer } from 'react-bootstrap'
import { FaSquareFull } from 'react-icons/fa'
import { DISPLAY_APP_NAME } from '../../../utils'
import { IconWrapper } from '../../IconWrapper'

import { usePopupMessage } from '../hooks/usePopupMessage'

type Props = {
  top: number
  displayTime?: number
  maxWidth?: number | 'none' // ページ全体の横幅の最大値 ページ全体で合わせる
}

export const PopupMessageToast = ({
  top,
  displayTime = 3500,
  maxWidth = 'none',
}: Props) => {
  const { popupMessage, removePopupMessage } = usePopupMessage()
  return (
    <ToastContainer
      className="flex-shrink-0 position-sticky pe-1 ms-auto zindex-toast"
      style={{ height: 0, top, maxWidth }} // ヘッダー同等の z-index を持つように指定
    >
      <div className="pt-1">
        {popupMessage && (
          <Toast onClose={removePopupMessage} show delay={displayTime} autohide>
            <Toast.Header>
              <div className="w-100">
                <IconWrapper
                  className={'text-' + popupMessage.type}
                  suffix={
                    <strong className="me-auto ms-2">{DISPLAY_APP_NAME}</strong>
                  }
                >
                  <FaSquareFull />
                </IconWrapper>
              </div>
            </Toast.Header>
            <Toast.Body>{popupMessage.text}</Toast.Body>
          </Toast>
        )}
      </div>
    </ToastContainer>
  )
}
