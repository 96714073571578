import { FirebaseError } from 'firebase/app'
import {
  AuthErrorCodes,
  User,
  fetchSignInMethodsForEmail,
  getRedirectResult,
} from 'firebase/auth'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { usePopupMessage } from '../../assets/messenger'
import { useStaticQueryData } from '../../hooks'
import { PromiseVoid } from '../../types'
import { logEvent } from '../../utils'
import { useAfterSignedInPath } from '../hooks'
import { fb } from '../instance'
import { getProviderName } from '../utils/oauth'

type Props = {
  onOAuthSignedIn?: (user: User) => PromiseVoid
  onOAuthReauthenticated?: (user: User) => PromiseVoid
}

export const useOAuthRedirectHandler = ({
  onOAuthReauthenticated: handleOAuthReauthenticated,
  onOAuthSignedIn: handleOAuthSignedIn,
}: Props = {}) => {
  const { setPopupMessage } = usePopupMessage()
  const router = useRouter()

  const [processed, setProcessed] = useState(false)

  const isOAuthSignInRedirect =
    useStaticQueryData('OAuthSignInRedirect') === 'true'
  const providerId = useStaticQueryData('providerId')

  const { afterSignedInPath } = useAfterSignedInPath()

  useEffect(() => {
    if (!isOAuthSignInRedirect) return
    getRedirectResult(fb.auth)
      .then(async (result) => {
        if (result !== null) {
          logEvent('login', {
            method: result.providerId ?? providerId ?? 'OAuth',
          })
          await router.push(afterSignedInPath ?? '/')
          setPopupMessage('ログインしました。')
          if (handleOAuthSignedIn) await handleOAuthSignedIn(result.user)
        } else {
          logEvent('failed_login', {
            message: 'not found redirect result.',
          })
          setPopupMessage('アカウントの情報を取得できませんでした。', 'danger')
        }
      })
      .catch(async (error: unknown) => {
        let message: string
        if (error instanceof FirebaseError) {
          switch (error.code) {
            case AuthErrorCodes.NEED_CONFIRMATION: {
              if (
                !error.customData ||
                typeof error.customData.email !== 'string'
              ) {
                setPopupMessage('予期せぬエラーが発生しました。', 'danger')
                break
              }
              const email = error.customData.email
              const methods = await fetchSignInMethodsForEmail(fb.auth, email)
              const providerName = getProviderName(methods[0])
              setPopupMessage(
                `あなたは既に${providerName}でアカウントを作成しています。ログインして連携をしてください。`,
                'danger'
              )
              break
            }
            case AuthErrorCodes.WEB_STORAGE_UNSUPPORTED: {
              setPopupMessage(
                'サードパーティのCookieがブロックされている可能性があります。ブラウザの設定を見直してください。シークレットモードを使っている場合は通常モードにすることで解決できる場合もあります。'
              )
              break
            }
            default:
              console.debug(error)
              setPopupMessage('予期せぬエラーが発生しました。', 'danger')
              break
          }
          message = error.code
        } else {
          message = error instanceof Error ? error.message : 'unexpected.'
        }
        logEvent('failed_login', {
          message,
          method: providerId ?? 'OAuth',
        })
      })
      .finally(() => setProcessed(true))
  }, [isOAuthSignInRedirect])

  const isOAuthReauthenticateRedirect =
    useStaticQueryData('OAuthReauthenticateRedirect') === 'true'

  useEffect(() => {
    if (!isOAuthReauthenticateRedirect) return
    getRedirectResult(fb.auth)
      .then(async (result) => {
        if (result !== null && handleOAuthReauthenticated)
          await handleOAuthReauthenticated(result.user)
        else
          setPopupMessage('アカウントの情報を取得できませんでした。', 'danger')
      })
      .catch(async (error) => {
        if (error instanceof FirebaseError)
          switch (error.code) {
            case AuthErrorCodes.NEED_CONFIRMATION: {
              const email = error.customData?.email
              if (typeof email === 'string') {
                const methods = await fetchSignInMethodsForEmail(fb.auth, email)
                const providerName = getProviderName(methods[0])
                setPopupMessage(
                  `あなたは${providerName}でアカウントを作成しています。${providerName}で認証してください。`
                )
              } else {
                setPopupMessage(
                  `あなたは既にアカウントを作成済みです。ログインしてください。。`,
                  'danger'
                )
              }
              break
            }
            case AuthErrorCodes.USER_MISMATCH: {
              setPopupMessage(
                `このアカウントはあなたの認証情報と一致しません。あなたがアカウント作成をした方法をお試しください。`,
                'danger'
              )
              break
            }
            case AuthErrorCodes.WEB_STORAGE_UNSUPPORTED: {
              setPopupMessage(
                'サードパーティのCookieがブロックされている可能性があります。ブラウザの設定を見直してください。シークレットモードを使っている場合は通常モードにすることで解決できる場合もあります。',
                'danger'
              )
              break
            }
            default:
              console.error(error)
              setPopupMessage('予期せぬエラーが発生しました。', 'danger')
              break
          }
      })
      .finally(() => setProcessed(true))
  }, [isOAuthReauthenticateRedirect])

  return {
    isOAuthSignInRedirect,
    isOAuthReauthenticateRedirect,
    isOAuthLoading:
      (isOAuthReauthenticateRedirect || isOAuthSignInRedirect) && !processed,
  }
}
