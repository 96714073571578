import { Lexer } from '../../lexer'
import { rules } from '../../rules'
import type { Tokens } from '../../types'

export const paragraph = (
  src: string,
  lexer: Lexer
): Tokens.Paragraph | null => {
  const cap = rules.block.paragraph.exec(src)
  if (!cap) return null
  const token: Tokens.Paragraph = {
    type: 'paragraph',
    raw: cap[0],
    text: cap[1].endsWith('\n') ? cap[1].slice(0, -1) : cap[1],
    tokens: [],
  }
  lexer.inline(token.text, token.tokens)
  return token
}
