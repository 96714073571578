import { RegexEditor } from '../utils/regex_editor'
import { hr } from './hr'

const _table =
  '^ *([^\\n ].*\\|.*)\\n' + // Header
  ' {0,3}(?:\\| *)?(:?-+:? *(?:\\| *:?-+:? *)*)(?:\\| *)?' + // Align
  '(?:\\n((?:(?! *\\n|hr|heading|blockquote|code|fences|list).*\\|.*(?:\\n|$))*)\\n*|$)' // Cells
// ↑3行目の正規表現でCellsは「.*」ならマッチしていたが最低でも1つ「|」がないといけないように「.*\\|.*」へ変更した

export const table = new RegexEditor(_table)
  .replace('hr', hr)
  .replace('heading', ' {0,3}#{1,6} ?')
  .replace('blockquote', ' {0,3}>')
  .replace('code', ' {4}[^\\n]')
  .replace('fences', ' {0,3}(?:`{3,}(?=[^`\\n]*\\n)|~{3,})[^\\n]*\\n')
  .replace('list', ' {0,3}(?:[*+-]|1[.)]) ') // only lists starting from 1 can interrupt
  .getRegex()
