import { useDocumentData } from 'react-firebase-hooks/firestore'
import { Serialized, deserialize, isBlank } from '../../utils'
import { Profile, profileConverter, profileRef } from '../../firebase'

export const useProfile = (
  id: string | null | undefined,
  staticProfile: Serialized<Profile> | null
) => {
  const [profile, loadingProfile] = useDocumentData(
    !isBlank(id) && id !== '' ? profileRef(id) : null,
    {
      initialValue: staticProfile
        ? deserialize(staticProfile, { ref: profileConverter })
        : undefined,
    }
  )
  return { profile, loadingProfile }
}
