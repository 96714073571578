import { useRef } from 'react'
import { Profile } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { uploadUserImage } from '../../models/uploader'
import { Designed } from '../../types'
import { UserImage } from './Image'

type Props = {
  profile: Profile
  size: number | string
}

export const UserImageUploader = ({
  profile,
  size,
  ...designProps
}: Designed<Props>) => {
  const ref = useRef<HTMLInputElement | null>(null)
  const { user } = useAuthState()
  return (
    <div {...designProps}>
      <UserImage
        profile={profile}
        size={size}
        onClick={() => {
          if (!ref.current) throw new Error('Unexpected error.')
          ref.current.click()
        }}
        className="clickable"
      />
      <input
        ref={ref}
        type="file"
        className="d-none"
        onChange={async (e) => {
          if (!e.currentTarget.files) {
            alert('ファイルを選択してください。')
            return
          }
          if (user?.uid !== profile.id) {
            alert('不正なアクセスです。')
            return
          }
          const file = e.currentTarget.files[0]
          const extension = file.name
            .split('.')
            .slice(-1)[0]
            .toLocaleLowerCase()
          e.currentTarget.value = ''
          if (!['jpeg', 'jpg', 'png'].includes(extension)) {
            alert('対応している拡張子はjpeg, jpg, pngのみです。')
            return
          }
          await uploadUserImage(file, extension, profile)
        }}
      />
    </div>
  )
}
