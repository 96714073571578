import { createHash } from '.'
import { rules } from '../../rules'
import type { Formula, HTML } from '../../types'
import { FormulaRemover } from './formula_remover'

export class HTMLRemover {
  private src = ''
  private text = ''
  private stack: string[] = []
  private formulae: Formula[]
  private rawLength = 0

  private constructor(formulae: Formula[]) {
    this.formulae = formulae
  }

  private static getTagInfo(tag: string) {
    const rule = /^<(\/)?([a-zA-Z][\w:-]*)/
    const cap = rule.exec(tag)
    if (!cap) return null
    const type = ['br', 'BR', 'hr', 'HR', 'img', 'IMG'].includes(cap[2])
      ? 'self-close'
      : cap[1] === '/'
      ? 'close'
      : 'open'
    return {
      name: cap[2],
      type,
    }
  }

  private eatTag() {
    const cap = rules.extractor.tag.exec(this.src)
    if (!cap) return false
    this.text += cap[0]
    this.src = this.src.substring(cap[0].length)
    this.rawLength += cap[0].length
    const tagInfo = HTMLRemover.getTagInfo(cap[0])
    if (!tagInfo) throw new Error()
    const { name, type } = tagInfo
    if (type === 'open') {
      this.stack.push(name)
    }
    if (type === 'close') {
      const lastTagName = this.stack.pop()
      if (lastTagName !== name) throw new Error()
    }
    return true
  }

  private eatFormula() {
    const formula = FormulaRemover.remove(this.src)
    if (!formula) return false
    this.src = this.src.substring(formula.text.length)
    this.text += `!FORMULA[${this.formulae.length}][${createHash(
      formula.text
    )}][${formula.text.split('\n').length - 1}]`
    this.rawLength += formula.text.length
    this.formulae.push(formula)
    return true
  }

  private eatText() {
    const cap = rules.extractor.text.html.exec(this.src)
    if (!cap) return false
    this.src = this.src.substring(cap[0].length)
    this.text += cap[0]
    this.rawLength += cap[0].length
    return true
  }

  private remove(src: string): HTML | null {
    this.src = src
    this.text = ''
    this.stack = []
    do {
      try {
        if (this.eatTag()) continue
        if (this.stack.length !== 0) {
          if (this.eatFormula()) continue
          if (this.eatText()) continue
        }
      } catch (error) {
        break
      }
    } while (this.stack.length !== 0 && this.src)
    return this.text !== ''
      ? {
          text: this.text,
          error: this.stack.length !== 0,
          rawLength: this.rawLength,
        }
      : null
  }

  static remove(src: string, formulae: Formula[]) {
    const remover = new HTMLRemover(formulae)
    return remover.remove(src)
  }
}
