import { User } from 'firebase/auth'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import {
  BookDraft,
  MathdownImage,
  Profile,
  Series,
  Timestamp,
  createDoc,
  doc,
  mathdownImagesRef,
  updateDoc,
} from '../firebase'
import { fb } from '../firebase/instance'

export const uploadMathdownImage = async (
  file: File,
  extension: string,
  user: User
) => {
  const firestoreRef = doc(mathdownImagesRef)
  const id = firestoreRef.id
  const filename = `${id}.${extension}`
  const fullPath = `uploads/mathdown/${filename}`
  const uploadRef = ref(fb.storage, fullPath)
  await uploadBytes(uploadRef, file)
  const url = await getDownloadURL(uploadRef)
  await createDoc<MathdownImage>(firestoreRef, {
    created_by: user.uid,
    filename,
    full_path: fullPath,
    url,
  })
  return '/' + fullPath // mathdownはfullPathで表示可能なのでURLではなくfullPathを返却する
}

export const uploadBookThumbnail = async (
  file: File,
  extension: string,
  user: User,
  bookDraft: BookDraft
) => {
  const id = bookDraft.id
  const filename = `${id}.${extension}`
  const fullPath = `uploads/book_thumbnails/${user.uid}/${filename}`
  const uploadRef = ref(fb.storage, fullPath)
  await uploadBytes(uploadRef, file)
  const url = await getDownloadURL(uploadRef)
  await updateDoc(bookDraft.ref, {
    updated_by_user_at: Timestamp.now(),
    thumbnail: {
      filename,
      full_path: fullPath,
      url,
    },
  })
  return url
}

export const uploadSeriesThumbnail = async (
  file: File,
  extension: string,
  user: User,
  series: Series
) => {
  const id = series.id
  const filename = `${id}.${extension}`
  const fullPath = `uploads/series/${user.uid}/${filename}`
  const uploadRef = ref(fb.storage, fullPath)
  await uploadBytes(uploadRef, file)
  const url = await getDownloadURL(uploadRef)
  await updateDoc(series.ref, {
    updated_by_user_at: Timestamp.now(),
    thumbnail: {
      filename,
      full_path: fullPath,
      url,
    },
  })
  return url
}

export const uploadUserImage = async (
  file: File,
  extension: string,
  profile: Profile
) => {
  const id = profile.id
  const filename = `${id}.${extension}`
  const fullPath = `uploads/profile/${profile.id}/${filename}`
  const uploadRef = ref(fb.storage, fullPath)
  await uploadBytes(uploadRef, file)
  const url = await getDownloadURL(uploadRef)
  await updateDoc(profile.ref, {
    updated_by_user_at: Timestamp.now(),
    image: {
      filename,
      full_path: fullPath,
      url,
    },
  })
  return url
}
