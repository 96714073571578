import { rules } from '../../rules'
import type { Tokens } from '../../types'

export const br = (src: string): Tokens.Br | null => {
  const cap = rules.inline.br.exec(src)
  if (!cap) return null
  return {
    type: 'br',
    raw: cap[0],
  }
}
