import { RegexEditor } from '../utils/regex_editor'
import { _email } from './_email'
import { _scheme } from './_scheme'

/*eslint no-control-regex: "off"*/
export const _autolink = /^<(scheme:[^\s\x00-\x1f<>]*|email)>/

export const autolink = new RegexEditor(_autolink)
  .replace('scheme', _scheme)
  .replace('email', _email)
  .getRegex()
