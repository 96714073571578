import { useEffect, useRef } from 'react'
import { z } from 'zod'
import { PVShardsParent, PVShardsParentRefAssignable } from '../../firebase'
import { incrementPageViewShard } from '../../models/pv_shards'

const VisitedHistoryParser = z.array(
  z.object({ path: z.string(), date: z.string() })
)

type VisitedHistory = z.infer<typeof VisitedHistoryParser>

export const usePVCounter = <T extends PVShardsParent>(
  ref: PVShardsParentRefAssignable<T> | null
) => {
  const updateTriggered = useRef(false)
  useEffect(() => {
    if (ref === null) return

    if (updateTriggered.current) return
    updateTriggered.current = true

    // F5アタックを簡易的に防ぐ用のコード
    // 直近にPVを更新したページと同じページをロードしている場合はカウントアップしない
    const resentVisitedHistory: VisitedHistory = []
    const now = new Date()

    const rawVisitedHistory = localStorage.getItem('visitedHistory')
    const visitedHistoryParseResult =
      rawVisitedHistory !== null
        ? VisitedHistoryParser.safeParse(JSON.parse(rawVisitedHistory))
        : null

    if (visitedHistoryParseResult?.success === true) {
      resentVisitedHistory.push(
        ...visitedHistoryParseResult.data.filter(
          ({ date }) =>
            new Date(date).getTime() > now.getTime() - 1000 * 10 * 60
        )
      )
    }

    const currentPath = window.location.href
    if (!resentVisitedHistory.find(({ path }) => path === currentPath)) {
      void incrementPageViewShard(ref)
    }
    resentVisitedHistory.push({
      path: currentPath,
      date: now.toUTCString(),
    })
    localStorage.setItem('visitedHistory', JSON.stringify(resentVisitedHistory))
  }, [ref])
}
