import { QueryDocumentSnapshot } from 'firebase/firestore'
import { useCallback, useRef, useState } from 'react'
import { Follower, getDoc, getDocs, Profile, profileRef } from '../../firebase'
import { sortedFollowersRef } from '../../models/follow'

const FOLLOWERS_LIMIT = 20

export const useFollowers = (uid: string) => {
  const lastFollower = useRef<QueryDocumentSnapshot<Follower> | null>(null)
  const [followers, setFollowers] = useState<Profile[]>([])
  const [fetching, setFetching] = useState(false)
  const [isLast, setIsLast] = useState(false)

  const loadMore = useCallback(async () => {
    if (fetching) return
    if (isLast) return
    setFetching(true)
    const loadedFollowersSnapshot = await getDocs(
      sortedFollowersRef(uid, lastFollower.current, FOLLOWERS_LIMIT)
    )
    const loadedFollowerDocs = loadedFollowersSnapshot.docs
    const loadedFollowers = await Promise.all(
      loadedFollowerDocs.map(async (doc) => {
        const uid = doc.id
        const profileSnapshot = await getDoc(profileRef(uid))
        const user = profileSnapshot.data()
        return user
      })
    )
    const filteredLoadedFollowers = loadedFollowers.filter(
      (user) => user !== undefined
    ) as Profile[] // undefinedを除去したのでasを使って型を強制変更
    setFollowers([...followers, ...filteredLoadedFollowers])
    if (!loadedFollowersSnapshot.empty) {
      lastFollower.current = loadedFollowerDocs.slice(-1)[0]
    }
    if (loadedFollowerDocs.length < FOLLOWERS_LIMIT) {
      setIsLast(true)
    }
    setFetching(false)
  }, [fetching, followers, isLast])

  return { loadMore, followers, isLast, fetching }
}
