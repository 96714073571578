import { LinkWrapper } from '../../../assets/LinkWrapper'
import { PaperReference } from '../../../firebase'

type Props = {
  reference: PaperReference
  link: boolean
}

export const PaperReferenceItem = ({ reference, link }: Props) => {
  const title = [
    reference.author,
    reference.title,
    reference.journal,
    reference.published_year,
    reference.page,
  ]
    .filter((item) => item)
    .join(', ')
  return (
    <LinkWrapper
      href={
        link && reference.doi !== undefined && reference.doi !== ''
          ? `https://doi.org/${reference.doi}`
          : null
      }
    >
      <div className="text-break">{title}</div>
    </LinkWrapper>
  )
}
