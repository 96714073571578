import { FirebaseError } from 'firebase/app'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { usePopupMessage } from '../../assets/messenger'
import {
  BadgesParentRefAssignable,
  ValidDocument,
  fb,
  pricesRef,
} from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import {
  STRIPE_BADGE_PRODUCTS_JPY,
  StripeBadgeProducts,
} from '../../shared/stripe'
import { useQueryData } from '../useQueryData'

export const useStripeBadgeProduct = (
  currency: 'jpy',
  defaultSelectedBadge?: keyof StripeBadgeProducts
) => {
  const { user } = useAuthState()
  const router = useRouter()
  const { setPopupMessage } = usePopupMessage()

  const badgePaymentStatus = useQueryData('badge_payment_status')
  useEffect(() => {
    if (badgePaymentStatus === 'cancel') {
      setPopupMessage('バッチの購入をキャンセルしました。', 'danger')
    } else if (badgePaymentStatus === 'success') {
      setPopupMessage('バッチを購入しました。')
    }
  }, [badgePaymentStatus])

  const [thankYouBadgePrices, thankYouBadgeLoading] = useCollectionDataOnce(
    pricesRef(STRIPE_BADGE_PRODUCTS_JPY.thank_you_badge)
  )
  const [readMoreBadgePrices, readMoreBadgeLoading] = useCollectionDataOnce(
    pricesRef(STRIPE_BADGE_PRODUCTS_JPY.read_more_badge)
  )
  const [elegantBadgePrices, elegantBadgeLoading] = useCollectionDataOnce(
    pricesRef(STRIPE_BADGE_PRODUCTS_JPY.elegant_badge)
  )

  const loading =
    thankYouBadgeLoading || readMoreBadgeLoading || elegantBadgeLoading

  const [currentBadge, selectBadge] = useState<
    keyof StripeBadgeProducts | null
  >(defaultSelectedBadge ?? null)

  const currentBadgePrices = useMemo(() => {
    switch (currentBadge) {
      case 'thank_you_badge':
        return thankYouBadgePrices
          ?.filter((p) => p.active)
          .sort((a, b) => a.unit_amount - b.unit_amount)
      case 'read_more_badge':
        return readMoreBadgePrices
          ?.filter((p) => p.active)
          .sort((a, b) => a.unit_amount - b.unit_amount)
      case 'elegant_badge':
        return elegantBadgePrices
          ?.filter((p) => p.active)
          .sort((a, b) => a.unit_amount - b.unit_amount)
      default:
        throw new Error('unreachable')
    }
  }, [currentBadge, loading])

  const [isPurchasing, setIsPurchasing] = useState(false)

  const purchase = async <T extends ValidDocument>(
    price: string,
    ref: BadgesParentRefAssignable<T>
  ) => {
    if (!user) throw new Error('Must sign in to purchase.')

    // 現在選択中のバッチのプライズから選ばれていることの確認
    if (!currentBadgePrices?.find((p) => p.id === price))
      throw new Error('price is invalid.')

    setIsPurchasing(true)
    try {
      const params = window.location.search
      const locationHrefExceptParams = window.location.href.slice(
        0,
        params.length !== 0 ? -params.length : undefined
      )
      console.log(`${locationHrefExceptParams}?badge_payment_status=success`)
      const {
        data: { url },
      } = await fb.call('createStripeCheckoutSession')({
        mode: 'payment',
        price,
        successCallbackURL: `${locationHrefExceptParams}?badge_payment_status=success`,
        cancelCallbackURL: `${locationHrefExceptParams}?badge_payment_status=cancel`,
        metadata: {
          product: currentBadge,
          badge_receiver_content_collection: ref.parent.id,
          badge_receiver_content_id: ref.id,
        },
      })
      await router.push(url)
    } catch (error) {
      if (error instanceof FirebaseError) {
        setPopupMessage(error.code, 'danger')
      } else if (error instanceof Error) {
        setPopupMessage(error.message, 'danger')
      } else {
        setPopupMessage('予期せぬエラーが発生しました。', 'danger')
      }
      setIsPurchasing(false)
    }
  }

  return {
    currentBadgePrices,
    currentBadge,
    selectBadge,
    loading,
    isPurchasing,
    purchase,
  }
}
