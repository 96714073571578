import { collection, doc, DocRef } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document } from '../document'
import { Book, BookDraft } from './book'
import { PageSubcols } from './subcollections'
import { Textbook, TextbookDraft } from './textbook'
import { WithUserTimestamp } from './user_timestamp'

export interface Page extends Document, WithUserTimestamp {
  readonly ref: DocRef<Page>
  readonly parent_id: 'pages'
  readonly subcollections: typeof PageSubcols
  title: string
  body: string
  order: number
  chapter_id?: string
  comments_count: number
}

export type PagesParent = Book | BookDraft | Textbook | TextbookDraft

export const pageConverter = getConverter<Page>(PageSubcols)
export const pagesRef = (pagesParent: DocRef<PagesParent>) =>
  collection<Page>(pagesParent, 'pages').withConverter(pageConverter)
export const pageRef = (pagesParent: DocRef<PagesParent>, id: string) =>
  doc(pagesRef(pagesParent), id)
