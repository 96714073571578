import { useTranslation } from 'next-i18next'
import { Spinner } from 'react-bootstrap'

type Props = {
  text?: string
}

export const LoadingSpinner = ({ text }: Props) => {
  const { t } = useTranslation()
  return (
    <div className="w-100 py-7 d-flex align-items-center mx-auto justify-content-center gap-4">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">{t('general.loading')}...</span>
      </Spinner>
      <div>{text ?? t('general.loading')}</div>
    </div>
  )
}
