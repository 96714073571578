import { App } from '../types'

export const IS_PROD = process.env.NEXT_PUBLIC_ENV === 'prod' ? true : false

export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME as App

export const DISPLAY_APP_NAME =
  APP_NAME === 'mathlog'
    ? 'Mathlog'
    : APP_NAME === 'mathpedia'
    ? 'Mathpedia'
    : 'Unknown'

export const MATHLOG_HOST = IS_PROD
  ? 'https://mathlog.info'
  : 'https://dev.mathlog.info'

export const MATHPEDIA_HOST = IS_PROD
  ? 'https://math.jp'
  : 'https://dev.math.jp'
