import { collection, doc, DocRef } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document, ParentRefAssignable, ValidDocument } from '../document'
import { BadgeSubcols } from './subcollections'

export const BADGE_TYPES = [
  'thank_you',
  'elegant',
  'read_more',
  'monthly_no1_good_count',
  'annual_no1_good_count',
  'annual_no2_good_count',
  'annual_no3_good_count',
] as const

export type BadgeType = typeof BADGE_TYPES[number]

export interface Badge extends Document {
  readonly ref: DocRef<Badge>
  readonly parent_id: 'badges'
  readonly subcollections: typeof BadgeSubcols
  sent_by: string | 'official'
  type: BadgeType
  payment_id?: string // stripe payment_id
}

export type BadgesParentRefAssignable<T extends ValidDocument> =
  ParentRefAssignable<Badge, T>

export const badgeConverter = getConverter<Badge>(BadgeSubcols)
export const badgesRef = <T extends ValidDocument>(
  badgesParent: BadgesParentRefAssignable<T>
) => collection<Badge>(badgesParent, 'badges').withConverter(badgeConverter)
export const badgeRef = <T extends ValidDocument>(
  badgesParent: BadgesParentRefAssignable<T>,
  id: string
) => doc(badgesRef(badgesParent), id)
