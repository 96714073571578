import { useTranslation } from 'next-i18next'
import { Button, Spinner } from 'react-bootstrap'
import { Designed } from '../../types'

type Props = {
  variant?: string
  disabled?: boolean
  loading?: boolean
  type?: 'submit' | 'button' | 'reset'
  loadingText?: string
  children: React.ReactNode
  size?: 'sm' | 'lg'
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const SpinnerButton = ({
  children,
  disabled = false,
  loading = false,
  loadingText,
  type = 'submit',
  ...otherProps
}: Designed<Props>) => {
  const { t } = useTranslation()
  return (
    <Button disabled={disabled || loading} type={type} {...otherProps}>
      {loading && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          className="me-1"
          aria-hidden="true"
        />
      )}
      {loading ? loadingText ?? t('general.loading') : children}
    </Button>
  )
}
