import { collection, doc, DocRef } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document, ParentRefAssignable, ValidDocument } from '../document'
import { Account, accountRef } from './account'
import { MacroSubcols } from './subcollections'

export interface Macro extends Document {
  readonly ref: DocRef<Macro>
  readonly parent_id: 'macros'
  readonly subcollections: typeof MacroSubcols
  command_name: string
  arguments_count: number
  formula: string
  package_id?: string
}

export type MacrosParentRefAssignable<T extends ValidDocument> =
  ParentRefAssignable<Macro, T>

export const macroConverter = getConverter<Macro>(MacroSubcols)
export const macrosRef = <T extends ValidDocument>(
  parentRef: MacrosParentRefAssignable<T>
) => collection<Macro>(parentRef, 'macros').withConverter(macroConverter)

export const macroRef = <T extends ValidDocument>(
  parentRef: MacrosParentRefAssignable<T>,
  id: string
) => doc(macrosRef(parentRef), id)
export const defaultMacrosRef = (uid: string) =>
  macrosRef<Account>(accountRef(uid))
