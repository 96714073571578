import Link from 'next/link'
import { ESUser } from '../../elastic_search'
import { Profile } from '../../firebase'
import { Designed } from '../../types'

type Props = {
  profile: Profile | ESUser
}

export const UserNameLink = ({
  profile,
  ...containerProps
}: Designed<Props>) => (
  <Link href={`/users/${profile.id}`} passHref>
    <a {...containerProps}>{profile.display_name}</a>
  </Link>
)
