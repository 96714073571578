import { PaperReference } from '../../../firebase'

type Props = {
  reference: PaperReference
}

export const PaperReferenceAccordionBody = ({ reference }: Props) => (
  <ul className="mb-4 ps-6">
    <li>参考文献の種類：論文</li>
    {reference.label !== undefined && reference.label !== '' && (
      <li>ラベル：{reference.label}</li>
    )}
    <li>著者：{reference.author}</li>
    <li>雑誌名：{reference.journal}</li>
    {reference.volume !== undefined && reference.volume !== '' && (
      <li>巻：{reference.volume}</li>
    )}
    {reference.number !== undefined && reference.number !== '' && (
      <li>号：{reference.number}</li>
    )}
    {reference.page !== undefined && reference.page !== '' && (
      <li>ページ：{reference.page}</li>
    )}
    {reference.published_year !== undefined &&
      reference.published_year !== '' && (
        <li>出版年：{reference.published_year}</li>
      )}
  </ul>
)
