import { orderBy, query } from 'firebase/firestore'
import {
  Batch,
  ChaptersParent,
  DocRef,
  chapterRef,
  chaptersRef,
  getDocs,
} from '../firebase'

export const sortedChaptersRef = (ChapterParent: DocRef<ChaptersParent>) =>
  query(chaptersRef(ChapterParent), orderBy(`order`))

export const addChapters = async (
  source: DocRef<ChaptersParent>,
  target: DocRef<ChaptersParent>,
  batch: Batch
) => {
  const sourceChaptersSnapshot = await getDocs(chaptersRef(source))
  const sourceChapters = sourceChaptersSnapshot.docs.map((doc) => doc.data())
  for (const chapter of sourceChapters)
    batch.create(chapterRef(target, chapter.id), chapter)
  return sourceChapters.length
}

export const deleteChapters = async (
  target: DocRef<ChaptersParent>,
  batch: Batch
) => {
  const targetChaptersSnapshot = await getDocs(chaptersRef(target))
  const targetChapterRefs = targetChaptersSnapshot.docs.map((doc) => doc.ref)
  for (const ref of targetChapterRefs) await batch.delete(ref)
  return targetChapterRefs.length
}

export const moveChapters = async (
  source: DocRef<ChaptersParent>,
  target: DocRef<ChaptersParent>,
  batch: Batch
) => {
  const num = await addChapters(source, target, batch)
  await deleteChapters(source, batch)
  return num
}

export const replaceChapters = async (
  source: DocRef<ChaptersParent>,
  target: DocRef<ChaptersParent>,
  batch: Batch
) => {
  await deleteChapters(target, batch)
  return await addChapters(source, target, batch)
}

export const moveAndReplaceChapters = async (
  source: DocRef<ChaptersParent>,
  target: DocRef<ChaptersParent>,
  batch: Batch
) => {
  await deleteChapters(target, batch)
  return await moveChapters(source, target, batch)
}
