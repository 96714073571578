import { collection, doc, DocRef } from '../../../../../firebase/alias'
import { getConverter } from '../../converter'
import { Document } from '../../document'
import { customerRef } from './customer'
import { PaymentSubcols } from './subcollections'

// 不完全 適宜追加
export interface Payment extends Document {
  readonly parent_id: 'payments'
  readonly subcollections: typeof PaymentSubcols
  readonly ref: DocRef<Payment>
  amount: number
  metadata: {
    product: OnetimePaymentProducts
    badge_receiver_content_collection: string
    badge_receiver_content_id: string
  }
}

export const ONETIME_PAYMENT_PRODUCTS = [
  'thank_you_badge',
  'elegant_badge',
  'read_more_badge',
] as const

export type OnetimePaymentProducts = typeof ONETIME_PAYMENT_PRODUCTS[number]

export const paymentConverter = getConverter<Payment>(PaymentSubcols)
export const paymentsRef = (uid: string) =>
  collection<Payment>(customerRef(uid), 'payments').withConverter<Payment>(
    paymentConverter
  )
export const paymentRef = (uid: string, id: string) => doc(paymentsRef(uid), id)
