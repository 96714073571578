import { DocRef, collection, db, doc } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document } from '../document'
import { accountRef } from './account'
import { AuthorizationDetails } from './authorization'
import { ContentBase } from './content'
import { ArticleDraftSubcols, ArticleSubcols } from './subcollections'
import { WithUserTimestamp } from './user_timestamp'

export interface Article extends ContentBase {
  readonly ref: DocRef<Article>
  readonly parent_id: 'articles'
  readonly subcollections: typeof ArticleSubcols
  type: 'explanation' | 'problem' | 'discussion'
  body: string
  youtube_code?: string
}

export interface ArticleDraft extends Document, WithUserTimestamp {
  readonly ref: DocRef<ArticleDraft>
  readonly parent_id: 'article_drafts'
  readonly subcollections: typeof ArticleDraftSubcols

  /**
   * idと同じ値が入るがFirestoreに保存している
   * collectionGroup検索でidによる検索ができないため、この設計で妥協している
   */
  document_id: string
  created_by: string

  title: string
  body: string
  publication_id?: string // 存在する場合は更新用の下書き, 検索用

  /** 共同編集が有効になっているか・削除予定 */
  is_collaborative?: never

  /** 実際に共同編集を行なったユーザ */
  collaborator_uids: string[]

  /** 共同編集に参加できるユーザ */
  editability_details: AuthorizationDetails
}

export const articleConverter = getConverter<Article>(ArticleSubcols)
export const articlesRef = collection<Article>(db, `articles`).withConverter(
  articleConverter
)
export const articleRef = (id: string) => doc(articlesRef, id)

export const articlesRefWithoutConverter = collection<Article>(db, `articles`)
export const articleRefWithoutConverter = (id: string) =>
  doc(articlesRefWithoutConverter, id)

export const articleDraftConverter =
  getConverter<ArticleDraft>(ArticleDraftSubcols)
export const articleDraftsRef = (uid: string) =>
  collection<ArticleDraft>(accountRef(uid), 'article_drafts').withConverter(
    articleDraftConverter
  )
export const articleDraftRef = (uid: string, id: string) =>
  doc(articleDraftsRef(uid), id)
