type AnyObject = { [key: string]: any }

export const deepMerge = <T extends AnyObject, U extends AnyObject>(
  obj1: T,
  obj2: U
): T & U => {
  const output: AnyObject = { ...obj1 }

  for (const key in obj2) {
    if (Object.prototype.hasOwnProperty.call(obj2, key)) {
      if (isObject(obj2[key])) {
        if (key in obj1 && isObject(obj1[key])) {
          output[key] = deepMerge(obj1[key], obj2[key])
        } else {
          output[key] = obj2[key]
        }
      } else {
        output[key] = obj2[key]
      }
    }
  }

  return output as T & U
}

export const isObject = (value: unknown): value is AnyObject => {
  return (
    value !== null &&
    value !== undefined &&
    typeof value === 'object' &&
    !Array.isArray(value)
  )
}
