import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useAuthState } from '../../firebase/hooks/useAuthState'

// メールの確認が済んでいるユーザがログインしている場合にメインページに遷移させる
export const useRequireNotSignedIn = () => {
  const { isLoading, isSignedIn, isEmailVerified } = useAuthState()
  const router = useRouter()

  useEffect(() => {
    if (isLoading) return
    if (isSignedIn === true && isEmailVerified === true) {
      void router.push('/')
    }
  }, [isLoading])

  return { isLoading }
}
