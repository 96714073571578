import { increment } from 'firebase/firestore'
import { random } from '..//utils'
import {
  PVShardsParent,
  PVShardsParentRefAssignable,
  PV_SHARDS_IDS,
  createDoc,
  getDoc,
  pvShardRef,
  updateDoc,
} from '../firebase'

export const incrementPageViewShard = async <T extends PVShardsParent>(
  ref: PVShardsParentRefAssignable<T>
) => {
  const id = random(PV_SHARDS_IDS)
  const shardRef = pvShardRef(ref, id)
  const shardSnapshot = await getDoc(shardRef)
  if (shardSnapshot.exists()) {
    await updateDoc(shardRef, { count: increment(1) })
  } else {
    await createDoc(shardRef, { count: 1 })
  }
}
