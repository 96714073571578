import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Accordion, Button, ButtonGroup } from 'react-bootstrap'
import { FaGripVertical, FaPencilAlt, FaTrashAlt } from 'react-icons/fa'
import { ReferenceItem } from '..'
import {
  deleteDoc,
  Reference,
  ReferencesParentRefAssignable,
  ValidDocument,
} from '../../../firebase'
import { Designed, PromiseVoid } from '../../../types'
import { IconWrapper } from '../../IconWrapper'
import { usePopupMessage } from '../../messenger'
import { MathlogArticleReferenceAccordionBody } from './Article'
import { GeneralBookReferenceAccordionBody } from './GeneralBook'
import { PaperReferenceAccordionBody } from './Paper'
import { WebsiteReferenceAccordionBody } from './Website'

type AccordionItemBodyProps = {
  reference: Reference
}

const AccordionItemBody = ({ reference }: AccordionItemBodyProps) => {
  switch (reference.type) {
    case 'mathlog_article':
      return <MathlogArticleReferenceAccordionBody reference={reference} />
    case 'general_book':
      return <GeneralBookReferenceAccordionBody reference={reference} />
    case 'paper':
      return <PaperReferenceAccordionBody reference={reference} />
    case 'website':
      return <WebsiteReferenceAccordionBody reference={reference} />
  }
}

type AccordionItemProps<T extends ValidDocument> = {
  number: number
  parentRef: ReferencesParentRefAssignable<T>
  eventKey: string
  reference: Reference
  activeKeys: string[]
  setActiveKeys: (keys: string[]) => void
  onEditButtonClick: () => PromiseVoid
  editing: boolean
}

const AccordionItem = <T extends ValidDocument>({
  number,
  parentRef,
  eventKey,
  reference,
  activeKeys,
  setActiveKeys,
  onEditButtonClick: handleEditButtonClick,
  editing,
}: AccordionItemProps<T>) => {
  const { setPopupMessage } = usePopupMessage()
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: reference.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  return (
    <Accordion.Item eventKey={eventKey} ref={setNodeRef} style={style}>
      <Accordion.Header
        onClick={(event) => {
          const activeKeyIndex = activeKeys.indexOf(eventKey)
          if (activeKeyIndex >= 0) {
            setActiveKeys([
              ...activeKeys.slice(0, activeKeyIndex),
              ...activeKeys.slice(activeKeyIndex + 1),
            ])
          } else {
            setActiveKeys([...activeKeys, eventKey])
          }
          event.stopPropagation()
        }}
      >
        <Button
          className="text-muted me-2 flex-shrink-0"
          variant="link"
          {...listeners}
          {...attributes}
        >
          <FaGripVertical />
        </Button>
        <div className="flex-shrink-0 me-2">{`[${number}]`}</div>
        <ReferenceItem
          reference={reference}
          link={false}
          amazonBookImage={false}
        />
        {editing && (
          <div className="text-success flex-shrink-0">（編集中）</div>
        )}
      </Accordion.Header>
      <Accordion.Body>
        <AccordionItemBody reference={reference} />
        <ButtonGroup>
          <Button
            type="button"
            size="sm"
            variant="success"
            onClick={async () => {
              const msg =
                '左側のフォームに入力中の内容は失われます。本当に続けますか？'
              if (window.confirm(msg)) await handleEditButtonClick()
            }}
          >
            <IconWrapper suffix="編集">
              <FaPencilAlt />
            </IconWrapper>
          </Button>
          <Button
            type="button"
            size="sm"
            variant="danger"
            onClick={async () => {
              const msg = '本当に削除しますか？'
              if (!window.confirm(msg)) return
              await deleteDoc(reference.ref)
              setPopupMessage('参考文献を削除しました。', 'danger')
            }}
          >
            <IconWrapper suffix="削除">
              <FaTrashAlt />
            </IconWrapper>
          </Button>
        </ButtonGroup>
      </Accordion.Body>
    </Accordion.Item>
  )
}

type Props<T extends ValidDocument> = {
  parentRef: ReferencesParentRefAssignable<T>
  references: Reference[]
  editingId?: string
  activeKeys: string[]
  setActiveKeys: (keys: string[]) => void
  onEditButtonClick: (reference: Reference) => PromiseVoid
  shiftReferences: (target: number, position: number) => PromiseVoid
}

export const ReferencesAccordion = <T extends ValidDocument>({
  parentRef,
  editingId,
  references,
  activeKeys,
  setActiveKeys,
  onEditButtonClick: handleEditButtonClick,
  shiftReferences,
  ...wrapperProps
}: Designed<Props<T>>) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )
  return (
    <Accordion activeKey={activeKeys} alwaysOpen {...wrapperProps}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={async (event) => {
          const { active, over } = event
          if (over === null || active.id === over.id) return
          const target = references.findIndex(
            (reference) => reference.id === `${active.id}`
          )
          const position = references.findIndex(
            (reference) => reference.id === `${over.id}`
          )
          await shiftReferences(target, position)
        }}
      >
        <SortableContext
          items={references}
          strategy={verticalListSortingStrategy}
        >
          {references.map((reference, index) => (
            <AccordionItem
              key={reference.id}
              number={index + 1}
              parentRef={parentRef}
              eventKey={reference.id}
              reference={reference}
              activeKeys={activeKeys}
              setActiveKeys={setActiveKeys}
              editing={editingId === reference.id}
              onEditButtonClick={() => handleEditButtonClick(reference)}
            />
          ))}
          {references.length === 0 && (
            <span className="text-muted">参考文献は追加されていません。</span>
          )}
        </SortableContext>
      </DndContext>
    </Accordion>
  )
}
