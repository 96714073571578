import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { Badge } from 'react-bootstrap'
import { ESCategory } from '../../elastic_search'
import { Category, Content, DocRef, updateDoc } from '../../firebase'
import { useCurrentUserRoles } from '../../hooks'
import { CategorySelector } from './Selector'

type Props = {
  category: Category | ESCategory
  contentRef?: DocRef<Content>
}

export const CategoryBadge = ({ category, contentRef }: Props) => {
  const { hasRole } = useCurrentUserRoles()
  const { i18n } = useTranslation()
  const lang = i18n.language
  return contentRef && hasRole('mathlog_admin') === true ? (
    <CategorySelector
      style={{ maxWidth: 150 }}
      size={'sm'}
      value={category.id}
      onChange={async (id) => {
        await updateDoc(contentRef, { category_id: id })
      }}
    />
  ) : (
    <Link href={`/categories/${category.id}/articles`} passHref>
      <a className="lh-0">
        <Badge bg="primary" className="cursor-pointer">
          {category.localized_name[lang]}
        </Badge>
      </a>
    </Link>
  )
}
