import { useEffect, useState } from 'react'

export const useBrowser = () => {
  const [browser, setBrowser] = useState<
    | 'edge'
    | 'opera'
    | 'samsung'
    | 'chrome'
    | 'firefox'
    | 'safari'
    | 'msie'
    | 'uc'
    | null
  >(null)
  useEffect(() => {
    const ua = window.navigator.userAgent.toLowerCase()
    if (
      ua.indexOf('edge') !== -1 ||
      ua.indexOf('edga') !== -1 ||
      ua.indexOf('edgios') !== -1
    ) {
      setBrowser('edge')
    } else if (ua.indexOf('opera') !== -1 || ua.indexOf('opr') !== -1) {
      setBrowser('opera')
    } else if (ua.indexOf('samsungbrowser') !== -1) {
      setBrowser('samsung')
    } else if (ua.indexOf('ucbrowser') !== -1) {
      setBrowser('uc')
    } else if (ua.indexOf('chrome') !== -1 || ua.indexOf('crios') !== -1) {
      setBrowser('chrome')
    } else if (ua.indexOf('firefox') !== -1 || ua.indexOf('fxios') !== -1) {
      setBrowser('firefox')
    } else if (ua.indexOf('safari') !== -1) {
      setBrowser('safari')
    } else if (ua.indexOf('msie') !== -1 || ua.indexOf('trident') !== -1) {
      setBrowser('msie')
    }
  }, [])
  return browser
}
