import { increment } from 'firebase/firestore'
import { useCallback, useState } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import {
  Batch,
  followingTagRef,
  profileRef,
  tagFollowerRef,
  tagRef,
} from '../../firebase'
import { useAuthState } from '../../firebase/hooks'

export const useFollowTag = (tagId: string) => {
  const [isSending, setIsSending] = useState(false)
  const { user: currentUser } = useAuthState()
  const ref = currentUser ? tagFollowerRef(tagId, currentUser.uid) : null
  const [followSnapshot] = useDocument(ref)
  const isFollowing = followSnapshot ? followSnapshot.exists() : undefined

  const follow = useCallback(async () => {
    if (!currentUser) throw new Error('Unexpected call.')
    if (isFollowing === true) throw new Error('Unexpected call.')
    if (isSending) return
    setIsSending(true)
    const batch = new Batch()
    batch.create(tagFollowerRef(tagId, currentUser.uid), {})
    batch.create(followingTagRef(currentUser.uid, tagId), {})
    batch.update(tagRef(tagId), {
      followers_count: increment(1),
    })
    batch.update(profileRef(currentUser.uid), {
      following_tags_count: increment(1),
    })
    await batch.commit()
    setIsSending(false)
  }, [tagId, isFollowing, currentUser, isSending])

  const unfollow = useCallback(async () => {
    if (!currentUser) throw new Error('Unexpected call.')
    if (isFollowing === false) throw new Error('Unexpected call.')
    if (isSending) return
    setIsSending(true)
    const batch = new Batch()
    await batch.delete(tagFollowerRef(tagId, currentUser.uid))
    await batch.delete(followingTagRef(currentUser.uid, tagId))
    batch.update(tagRef(tagId), {
      followers_count: increment(-1),
    })
    batch.update(profileRef(currentUser.uid), {
      following_tags_count: increment(-1),
    })
    await batch.commit()
    setIsSending(false)
  }, [tagId, isFollowing, currentUser, isSending])

  return { isFollowing, follow, unfollow }
}
