import { SubCol } from '../../document'
import { Invoice } from './invoice'
import { Payment } from './payment'
import { Price } from './price'
import { Subscription } from './subscription'

export const InvoiceSubcols = []

export const PaymentSubcols = []

export const CheckoutSessionSubcols = []

export const SubscriptionSubcols: [SubCol<Invoice>] = [
  { id: 'invoices', children: InvoiceSubcols },
]

export const PriceSubcols = []

export const ProductSubcols: [SubCol<Price>] = [
  { id: 'prices', children: PriceSubcols },
]

export const CustomerSubcols: [SubCol<Payment>, SubCol<Subscription>] = [
  { id: 'payments', children: PaymentSubcols },
  { id: 'subscriptions', children: SubscriptionSubcols },
]
