import { UserNameLink } from '.'
import { ESUser } from '../../elastic_search'
import { Profile } from '../../firebase'
import { Designed } from '../../types'
import { UserImageWithLink } from './ImageWithLink'

type Props = {
  size: string | number
  profile: Profile | ESUser
  gap?: number
}

export const UserImageWithName = ({
  size,
  profile,
  gap = 2,
  ...wrapperProps
}: Designed<Props>) => {
  return (
    <div {...wrapperProps}>
      <div className={`d-flex align-items-center gap-${gap}`}>
        <UserImageWithLink
          profile={profile}
          size={size}
          className="flex-shrink-0"
        />
        <div className="flex-grow-1 overflow-hidden">
          <UserNameLink
            profile={profile}
            className="d-block text-truncate text-black fw-bold"
          />
        </div>
      </div>
    </div>
  )
}
