import { rules } from '../../rules'
import type { Tokens } from '../../types'

export const space = (src: string): Tokens.Space | null => {
  const cap = rules.block.newline.exec(src)
  if (!cap || cap[0].length === 0) return null
  return {
    type: 'space',
    raw: cap[0],
  }
}
