import { useEffect, useRef, useState } from 'react'
import { Form, Nav } from 'react-bootstrap'
import { Macro } from '../../../../firebase'
import { Designed, PromiseVoid } from '../../../../types'
import { compileMacrosToHTML } from '../../compiler/utils'
import { useMathJaxRenderer } from '../hooks'

type MathJaxProps = {
  cache?: string
  children: React.ReactNode
  onTypeset?: (result: string) => PromiseVoid
  inline?: boolean
  macros?: Macro[]
}

export const InlineMathJax = ({
  children,
  onTypeset: handleTypeset,
  macros,
  ...otherProps
}: Omit<MathJaxProps, 'inline'>) => {
  const compiledMacros = compileMacrosToHTML(macros ?? [])
  const childrenRef = useRef<HTMLDivElement>(null)
  const childrenCopyRef = useRef<HTMLSpanElement>(null)

  const { ref, typeset } = useMathJaxRenderer<HTMLSpanElement>(
    compiledMacros,
    handleTypeset
  )

  useEffect(() => {
    if (childrenRef.current === null || childrenCopyRef.current === null) return
    childrenCopyRef.current.innerHTML = childrenRef.current.innerHTML
    typeset()
  }, [children])

  return (
    <>
      <span ref={childrenRef} className="d-none">
        {children}
      </span>
      <span ref={ref} {...otherProps}>
        {macros && (
          <span
            className="d-none"
            dangerouslySetInnerHTML={{ __html: compiledMacros }}
          />
        )}
        <span ref={childrenCopyRef} />
      </span>
    </>
  )
}

export const BlockMathJax = ({
  children,
  onTypeset: handleTypeset,
  macros,
  ...otherProps
}: Omit<MathJaxProps, 'inline'>) => {
  const compiledMacros = compileMacrosToHTML(macros ?? [])
  const childrenRef = useRef<HTMLDivElement>(null)
  const childrenCopyRef = useRef<HTMLDivElement>(null)

  const { ref, typeset } = useMathJaxRenderer<HTMLDivElement>(
    compiledMacros,
    handleTypeset
  )

  useEffect(() => {
    if (childrenRef.current === null || childrenCopyRef.current === null) return
    childrenCopyRef.current.innerHTML = childrenRef.current.innerHTML
    typeset()
  }, [children])

  return (
    <>
      <div ref={childrenRef} className="d-none">
        {children}
      </div>
      <div ref={ref} {...otherProps}>
        {macros && (
          <div
            className="d-none"
            dangerouslySetInnerHTML={{ __html: compiledMacros }}
          />
        )}
        <div ref={childrenCopyRef} />
      </div>
    </>
  )
}

export const MathJax = ({
  inline = false,
  cache,
  ...otherProps
}: Designed<MathJaxProps>) => {
  if (cache !== undefined)
    return <div dangerouslySetInnerHTML={{ __html: cache }} />
  return inline ? (
    <InlineMathJax {...otherProps} />
  ) : (
    <BlockMathJax {...otherProps} />
  )
}

type MathJaxEditorProps = {
  affix?: string
  value?: string
  variant?: string
  onChange?: (currentValue: string) => PromiseVoid
  onBlur?: (currentValue: string) => PromiseVoid
  height: number | string
  macros?: Macro[]
  isInvalid?: boolean
  placeholder?: string
  defaultValue?: string
}

export const MathJaxEditor = ({
  affix = '',
  variant = 'tabs',
  value,
  defaultValue,
  height,
  onChange: handleChange,
  onBlur: handleBlur,
  macros,
  isInvalid,
  placeholder,
  ...otherProps
}: Designed<MathJaxEditorProps>) => {
  const [displayedValue, setDisplayedValue] = useState<string>()
  const [activeNav, setActiveNav] = useState<'input' | 'output'>('input')
  const inputRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (activeNav === 'output' && value !== undefined) {
      setDisplayedValue(affix + value + affix)
    }
  }, [value, affix])

  return (
    <div {...otherProps} style={{ height }}>
      <div className="w-100 h-100 d-flex flex-column">
        <Nav variant={variant} className="mb-2">
          <Nav.Item>
            <Nav.Link
              active={activeNav === 'input'}
              onClick={() => {
                if (activeNav === 'input') return
                setActiveNav('input')
              }}
            >
              入力
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              active={activeNav === 'output'}
              onClick={() => {
                if (activeNav === 'output') return
                if (inputRef.current === null)
                  throw new Error('Unexpected error.')
                setDisplayedValue(affix + inputRef.current.value + affix)
                setActiveNav('output')
              }}
            >
              プレビュー
            </Nav.Link>
          </Nav.Item>
        </Nav>
        {activeNav === 'input' ? (
          <Form.Control
            value={value}
            defaultValue={defaultValue}
            as="textarea"
            onChange={async (e) => {
              if (handleChange) await handleChange(e.currentTarget.value)
            }}
            onBlur={async (e) => {
              if (handleBlur) await handleBlur(e.currentTarget.value)
            }}
            ref={inputRef}
            className={'w-100 flex-grow-1'}
            isInvalid={isInvalid}
            placeholder={placeholder}
          />
        ) : (
          <MathJax
            macros={macros}
            className={'w-100 flex-grow-1 overflow-auto'}
          >
            {displayedValue}
          </MathJax>
        )}
      </div>
    </div>
  )
}
