export const mangle = (text: string) => {
  let out = '',
    i,
    ch

  const l = text.length
  for (i = 0; i < l; i++) {
    ch = text.charCodeAt(i)
    if (Math.random() > 0.5) {
      ch = 'x' + ch.toString(16)
    }
    out += `&#${ch};`
  }

  return out
}
