import { ColRef } from '../../../../firebase/alias'
import { Article, ArticleDraft, articleDraftsRef } from './article'
import { Book, BookDraft, bookDraftsRef } from './book'
import { Glossary, GlossaryDraft, glossaryDraftsRef } from './glossary'
import {
  Comment,
  CommentDraft,
  Reply,
  ReplyDraft,
  commentDraftsRef,
  replyDraftsRef,
} from './message'
import { Textbook, TextbookDraft, textbookDraftsRef } from './textbook'

export type Draft =
  | ArticleDraft
  | CommentDraft
  | ReplyDraft
  | GlossaryDraft
  | BookDraft
  | TextbookDraft
export type Publication = Article | Comment | Reply | Glossary | Book | Textbook

export const draftsRef = (
  parentId: Draft['parent_id'],
  uid: string
): ColRef<Draft> => {
  switch (parentId) {
    case 'article_drafts':
      return articleDraftsRef(uid)
    case 'comment_drafts':
      return commentDraftsRef(uid)
    case 'reply_drafts':
      return replyDraftsRef(uid)
    case 'book_drafts':
      return bookDraftsRef(uid)
    case 'glossary_drafts':
      return glossaryDraftsRef
    case 'textbook_drafts':
      return textbookDraftsRef
  }
}
