export const list = (
  text: string,
  loose: boolean,
  ordered: boolean,
  start: number | '',
  roman: boolean,
  parentheses: boolean,
  brackets: boolean
) => {
  const type = ordered ? 'ol' : 'ul'
  const classNames = []
  if (loose) classNames.push('loose')
  if (roman) classNames.push('roman')
  if (parentheses) classNames.push('parentheses')
  if (brackets) classNames.push('brackets')
  return `
    <${type} start="${start}" class="${classNames.join(' ')}">
      ${text}
    </${type}>`
}
