import { collection, db, doc, DocRef } from '../../../../../firebase/alias'
import { getConverter } from '../../converter'
import { Document } from '../../document'
import { CustomerSubcols } from './subcollections'

export interface Customer extends Document {
  readonly parent_id: 'customers'
  readonly subcollections: typeof CustomerSubcols
  readonly ref: DocRef<Customer>
  email?: string
  stripeId: string
  stripeLink: string
}

export const customerConverter = getConverter<Customer>(CustomerSubcols)
export const customersRef = collection<Customer>(
  db,
  'customers'
).withConverter<Customer>(customerConverter)
export const customerRef = (uid: string) => doc(customersRef, uid)
