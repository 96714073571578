import { rules } from '../../rules'
import type { Tokens } from '../../types'
import { rtrim } from '../utils'

export const code = (src: string): Tokens.Code | null => {
  const cap = rules.block.code.exec(src)
  if (!cap) return null
  const text = cap[0].replace(/^ {1,4}/gm, '')
  return {
    type: 'code',
    raw: cap[0],
    codeBlockStyle: 'indented',
    text: rtrim(text, '\n'),
  }
}
