import * as renderer from '../../renderer/html'
import { TableAlign, Token, Tokens } from '../../types'
import { parseInlineToHTML } from './inline'

const parseCellToHTML = (
  cellTokens: Token[],
  header: boolean,
  align: TableAlign
) => {
  return renderer.tablecell(parseInlineToHTML(cellTokens), header, align)
}

const parseHeaderToHTML = (header: Tokens.TableCell[], align: TableAlign[]) => {
  let cell = ''
  for (let cellIndex = 0; cellIndex < header.length; cellIndex++) {
    cell += parseCellToHTML(header[cellIndex].tokens, true, align[cellIndex])
  }
  return renderer.tablerow(cell)
}

const parseBodyToHTML = (
  rows: Tokens.TableCell[][],
  align: ('center' | 'left' | 'right' | null)[]
) => {
  let body = ''
  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
    const row = rows[rowIndex]
    let cell = ''
    for (let cellIndex = 0; cellIndex < row.length; cellIndex++) {
      cell += parseCellToHTML(row[cellIndex].tokens, false, align[cellIndex])
    }
    body += renderer.tablerow(cell)
  }
  return body
}

export const parseTableToHTML = (token: Tokens.Table) => {
  const header = parseHeaderToHTML(token.header, token.align)
  const body = parseBodyToHTML(token.rows, token.align)
  return renderer.table(header, body)
}
