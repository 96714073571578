import { useCallback, useState } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import { Batch, Profile } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { messageMuteUserRef } from '../../shared/firebase/firestore/scheme/mute'

export const useMessageMute = (profile: Profile) => {
  const [isSending, setIsSending] = useState(false)
  const { user: currentUser } = useAuthState()
  const ref = currentUser
    ? messageMuteUserRef(currentUser.uid, profile.id)
    : null
  const [muteSnapshot] = useDocument(ref)
  const isMuted = muteSnapshot ? muteSnapshot.exists() : undefined

  const mute = useCallback(async () => {
    if (!currentUser) throw new Error('Unexpected call.')
    if (isMuted !== false) throw new Error('Unexpected call.')
    if (isSending) return
    setIsSending(true)
    const batch = new Batch()
    batch.create(messageMuteUserRef(currentUser.uid, profile.id), {})
    await batch.commit()
    setIsSending(false)
  }, [profile, currentUser, isMuted, isSending])

  const cancelMute = useCallback(async () => {
    if (!currentUser) throw new Error('Unexpected call.')
    if (isMuted !== true) throw new Error('Unexpected call.')
    if (isSending) return
    setIsSending(true)
    const batch = new Batch()
    await batch.delete(messageMuteUserRef(currentUser.uid, profile.id))
    await batch.commit()
    setIsSending(false)
  }, [profile, currentUser, isMuted, isSending])

  return { mute, cancelMute, isMuted, isLoading: isMuted === undefined }
}
