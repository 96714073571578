import { useEffect, useState } from 'react'
import { ValidDocument, updateDoc } from '../firebase'

const ORDER_INTERVAL = 4096

export interface SortableValidDocument extends ValidDocument {
  order: number
}

export const useOrderChanger = <T extends SortableValidDocument>(
  orderedArray: T[]
) => {
  // orderedArrayはFirebaseの書き込みを待つため即座に変更されない．
  // 以下の配列はドロップ時に即座に並び順が変わるようにした配列である．
  const [immediatelyOrderedArray, setImmediatelyOrderedArray] =
    useState(orderedArray)

  useEffect(() => {
    setImmediatelyOrderedArray(orderedArray)
  }, [orderedArray])

  const getOrderAt = (position: number) => {
    if (position < 0) position = orderedArray.length + position
    if (orderedArray.length === 0) return ORDER_INTERVAL
    const previousOrder = position > 0 ? orderedArray[position - 1].order : 0
    const nextOrder =
      position < orderedArray.length ? orderedArray[position].order : null
    const order =
      nextOrder === null
        ? previousOrder + ORDER_INTERVAL
        : (previousOrder + nextOrder) / 2
    return order - previousOrder <= 1 ? null : order
  }

  const getNextOrder = () => {
    const nextOrder = getOrderAt(orderedArray.length)
    if (nextOrder === null) throw Error('Unexpected error.')
    return nextOrder
  }

  const getNewOrderedArray = (target: number, position: number) => {
    if (position < 0) position = orderedArray.length + position
    if (target < 0) target = orderedArray.length + target
    if (target === position) return [...orderedArray]
    if (target < position) {
      return [
        ...orderedArray.slice(0, target),
        ...orderedArray.slice(target + 1, position + 1),
        orderedArray[target],
        ...orderedArray.slice(position + 1),
      ]
    } else {
      return [
        ...orderedArray.slice(0, position),
        orderedArray[target],
        ...orderedArray.slice(position, target),
        ...orderedArray.slice(target + 1),
      ]
    }
  }

  const reset = async (newOrderedArray?: T[]) => {
    await Promise.all(
      (newOrderedArray ?? orderedArray).map(async (e, i) =>
        updateDoc(e.ref, { order: (i + 1) * ORDER_INTERVAL })
      )
    )
  }

  const shift = async (target: number, position: number) => {
    if (position < 0) position = orderedArray.length + position
    if (target < 0) target = orderedArray.length + target
    if (target === position) return
    const newOrder = getOrderAt(target > position ? position : position + 1)
    const newOrderedArray = getNewOrderedArray(target, position)
    setImmediatelyOrderedArray(newOrderedArray)
    if (newOrder !== null) {
      await updateDoc(orderedArray[target].ref, { order: newOrder })
    } else {
      await reset(newOrderedArray)
    }
  }

  return {
    array: immediatelyOrderedArray,
    shift,
    reset,
    getOrderAt,
    nextOrder: getNextOrder(),
  }
}
