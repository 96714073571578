import { Lexer } from '../../lexer'
import { rules } from '../../rules'
import { Tokens } from '../../types'

export const tag = (src: string, lexer: Lexer): Tokens.Tag | null => {
  const cap = rules.inline.tag.exec(src)
  if (!cap) return null

  if (!lexer.state.inLink && /^<a /i.test(cap[0])) {
    lexer.state.inLink = true
  } else if (lexer.state.inLink && /^<\/a>/i.test(cap[0])) {
    lexer.state.inLink = false
  }
  if (
    !lexer.state.inRawBlock &&
    /^<(pre|code|kbd|script)(\s|>)/i.test(cap[0])
  ) {
    lexer.state.inRawBlock = true
  } else if (
    lexer.state.inRawBlock &&
    /^<\/(pre|code|kbd|script)(\s|>)/i.test(cap[0])
  ) {
    lexer.state.inRawBlock = false
  }

  return {
    type: 'tag',
    raw: cap[0],
    inLink: lexer.state.inLink,
    inRawBlock: lexer.state.inRawBlock,
    text: cap[0],
  }
}
