import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useAuthState } from '../../firebase/hooks/useAuthState'
import { useAfterSignedInPath } from './useAfterSignedInPath'

// ログインしていないユーザをログインページに遷移させる
export const useRequireSignedIn = () => {
  const { isLoading, isSignedIn, user } = useAuthState()
  const { setAfterSignedInPath } = useAfterSignedInPath()
  const router = useRouter()

  useEffect(() => {
    if (isLoading) return
    if (isSignedIn === false) {
      setAfterSignedInPath(router.asPath) // サインイン後にこのpathに遷移するようにする
      void router.push('/auth/sign_in')
    }
  }, [isLoading, isSignedIn])

  return { isLoading, user }
}
