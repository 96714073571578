import { FirebaseError } from 'firebase/app'
import {
  sendEmailVerification,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { usePopupMessage } from '../../assets/messenger'
import { LoadingSpinner, SpinnerButton } from '../../assets/spinners'
import { createDoc, otherAccountRef } from '../../shared/firebase'
import { logEvent } from '../../utils'
import { useAfterSignedInPath } from '../hooks'
import { useRequireConfirmation } from '../hooks/useRequireConfirmation'
import { useRequireNotSignedIn } from '../hooks/useRequireNotSignedIn'
import { fb } from '../instance'
import { OAuthSignInButtons } from './OAuth'

type Props = {
  /** 「ログイン」というタイトルを表示しない */
  hideTitle?: boolean

  /** SNSのログインとメールアドレスのログインをどのデバイスサイズでも横並びにしない */
  forceVertical?: boolean

  /**
   * リダイレクトの設定
   * string型の場合はpathとしてログイン後に指定されたpathに遷移
   * trueの場合は有効なafterSignedInPathが存在する場合は当該ページに、その他の場合はメインページに遷移
   * falseの場合はリダイレクトしない
   * デフォルトはtrueが指定されている
   */
  redirect?: string | boolean
}

interface SignInFields {
  email: string
  password: string
}

export const SignInForm = ({
  hideTitle = false,
  forceVertical = false,
  redirect = true,
}: Props) => {
  useRequireConfirmation()
  const { isLoading } = useRequireNotSignedIn()

  const router = useRouter()
  const { setPopupMessage } = usePopupMessage()
  const [isRevealPassword, setIsRevealPassword] = useState(false)
  const { register, handleSubmit, formState } = useForm<SignInFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {},
  })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { afterSignedInPath, setAfterSignedInPath } = useAfterSignedInPath()
  useEffect(() => {
    if (typeof redirect === 'string') setAfterSignedInPath(redirect)
    else if (redirect === false) setAfterSignedInPath(router.asPath)
  }, [redirect])

  if (isLoading) return <LoadingSpinner />
  return (
    <>
      {!hideTitle && <h1 className="h4 mb-6">ログイン</h1>}
      <div className="row g-7">
        <div className={forceVertical ? 'col-12' : 'col-lg-6'}>
          <Form
            className="d-flex flex-column gap-4"
            onSubmit={handleSubmit(async (fields) => {
              setIsSubmitting(true)
              try {
                const { user } = await signInWithEmailAndPassword(
                  fb.auth,
                  fields.email,
                  fields.password
                )
                logEvent('login', { method: 'password' })
                // マルチアカウントの検知
                if (user.emailVerified) {
                  const currentUserId = localStorage.getItem('CurrentUserID')
                  if (currentUserId !== null && currentUserId !== user.uid) {
                    await createDoc(
                      otherAccountRef(user.uid, currentUserId),
                      {}
                    )
                  }
                  localStorage.setItem('CurrentUserID', user.uid)
                  await router.push(afterSignedInPath ?? '/')
                  setPopupMessage('ログインしました。')
                } else {
                  try {
                    if (process.env.NEXT_PUBLIC_HOST === undefined)
                      throw new Error('missing NEXT_PUBLIC_HOST')
                    await sendEmailVerification(user, {
                      handleCodeInApp: true,
                      url: process.env.NEXT_PUBLIC_HOST,
                    })
                    logEvent('send_email', {
                      email: user.email,
                    })
                  } catch (error) {
                    // ここは失敗しても確認メールの再送画面に遷移するのでログの記録のみでエラー処理しない。
                    logEvent('failed_send_email', {
                      email: user.email,
                    })
                  }
                  await router.push('/auth/confirmation')
                }
              } catch (e) {
                let message: string
                if (e instanceof FirebaseError) {
                  switch (e.code) {
                    case 'auth/wrong-password':
                      setPopupMessage('パスワードが間違っています。', 'danger')
                      break
                    case 'auth/wrong-user':
                      setPopupMessage(
                        'メールアドレスが間違っています。',
                        'danger'
                      )
                      break
                    case 'auth/user-not-found':
                      setPopupMessage(
                        'このメールアドレスは登録されていません。',
                        'danger'
                      )
                      break
                    default:
                      console.error(e)
                      setPopupMessage(
                        '予期せぬエラーが発生しました。',
                        'danger'
                      )
                      break
                  }
                  message = e.code
                } else {
                  message = e instanceof Error ? e.message : 'unexpected.'
                  console.error(e)
                  setPopupMessage('予期せぬエラーが発生しました．', 'danger')
                }
                logEvent('failed_login', {
                  method: 'password',
                  message,
                })
                setIsSubmitting(false)
              }
            })}
          >
            <Form.Group>
              <Form.Control
                type="email"
                {...register('email', { required: true })}
                placeholder="メールアドレス"
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type={isRevealPassword ? 'text' : 'password'}
                {...register('password', { required: true })}
                placeholder="パスワード"
              />
            </Form.Group>
            <Form.Check
              type="checkbox"
              onClick={() => {
                setIsRevealPassword(!isRevealPassword)
              }}
              label="パスワードを表示する"
            />
            <div className="d-flex mt-6 mt-sm-2">
              <SpinnerButton
                className="w-100 py-2"
                variant="primary"
                type="submit"
                loading={isSubmitting}
                disabled={!formState.isValid}
              >
                ログイン
              </SpinnerButton>
            </div>
          </Form>
          <hr className="my-6" />
          <div className="text-muted mb-2">
            新規アカウント登録は
            <Link href="/auth/sign_up" passHref>
              <a>こちら</a>
            </Link>
          </div>
          <div className="text-muted">
            パスワードを忘れた方は
            <Link href="/auth/forgot_password" passHref>
              <a>こちら</a>
            </Link>
          </div>
        </div>
        <OAuthSignInButtons
          className={forceVertical ? 'col-12 mt-7' : 'col-lg-6 mt-7 mt-lg-0'}
        />
      </div>
    </>
  )
}
