import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroller'
import { ValidDocumentWithEvaluation } from '../../firebase'
import { useGoodEvaluators } from '../../hooks'
import { Designed } from '../../types'
import { LoadingSpinner } from '../spinners'
import { UserList } from '../user'
import { UserImageList } from '../user/ImageList'

type Props = {
  evaluable: ValidDocumentWithEvaluation
}

export const GoodEvaluatorList = ({
  evaluable,
  ...otherProps
}: Designed<Props>) => {
  const { goodEvaluators, loadMore, isLast, fetching } = useGoodEvaluators(
    evaluable.ref
  )
  const [show, setShow] = useState(false)

  useEffect(() => {
    void loadMore()
  }, [])

  return (
    <>
      <Modal onHide={() => setShow(false)} show={show} scrollable>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">高評価した人</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InfiniteScroll
            loadMore={async () => {
              if (!fetching) await loadMore()
            }}
            hasMore={!isLast}
            useWindow={false}
            loader={<LoadingSpinner />}
          >
            <UserList profiles={goodEvaluators} imageSize={'40'} />
          </InfiniteScroll>
        </Modal.Body>
      </Modal>
      <div {...otherProps}>
        <UserImageList
          className="d-flex gap-3 mb-2 flex-wrap"
          size="35"
          profiles={goodEvaluators.slice(0, 20)}
        />
        {goodEvaluators.length !== 0 ? (
          <Button
            variant="link"
            onClick={() => {
              setShow(true)
            }}
          >
            もっと見る
          </Button>
        ) : (
          <>高評価したユーザはいません</>
        )}
      </div>
    </>
  )
}
