import Hotjar from '@hotjar/browser'
import { logEvent as firebaseLogEvent } from 'firebase/analytics'
import { fb } from '../firebase'

type HotjarConfig = {
  siteId: number
  version: number
}

export const logInit = (hotjarConfig: HotjarConfig) => {
  // Google Analyticsを読み込み
  fb.analytics

  // Hotjar設定
  Hotjar.init(hotjarConfig.siteId, hotjarConfig.version)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logEvent = (name: string, param?: { [key: string]: any }) => {
  // Google Analyticsのログ追加
  firebaseLogEvent(fb.analytics, name, param)

  // hotjarのイベントを追加
  Hotjar.event(name)
}
