import { increment } from 'firebase/firestore'
import { useCallback, useState } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import {
  Batch,
  Bookmarkable,
  ValidDocument,
  bookmarkedUserRef,
} from '../../firebase'
import { useAuthState } from '../../firebase/hooks'

export const useBookmark = <T extends ValidDocument>(
  bookmarkable: Bookmarkable
) => {
  const [isSending, setIsSending] = useState(false)
  const { user: currentUser } = useAuthState()
  const ref = currentUser
    ? bookmarkedUserRef(bookmarkable.ref, currentUser.uid)
    : null
  const [bookmarkSnapshot] = useDocument(ref)
  const isBookmarked = bookmarkSnapshot ? bookmarkSnapshot.exists() : undefined

  const bookmark = useCallback(async () => {
    if (!currentUser) throw new Error('Unexpected call.')
    if (isBookmarked === true || isBookmarked === undefined)
      throw new Error('Unexpected call.')
    if (isSending) return
    setIsSending(true)
    const batch = new Batch()
    batch.create(bookmarkedUserRef(bookmarkable.ref, currentUser.uid), {})
    if (currentUser.uid !== bookmarkable.created_by) {
      batch.update(bookmarkable.ref, { bookmarked_count: increment(1) })
    }
    await batch.commit()
    setIsSending(false)
  }, [bookmarkable, currentUser, isBookmarked, isSending])

  const cancelBookmark = useCallback(async () => {
    if (!currentUser) throw new Error('Unexpected call.')
    if (isBookmarked === false || isBookmarked === undefined)
      throw new Error('Unexpected call.')
    if (isSending) return
    setIsSending(true)
    const batch = new Batch()
    await batch.delete(bookmarkedUserRef(bookmarkable.ref, currentUser.uid))
    if (currentUser.uid !== bookmarkable.created_by) {
      batch.update(bookmarkable.ref, { bookmarked_count: increment(-1) })
    }
    await batch.commit()
    setIsSending(false)
  }, [bookmarkable, currentUser, isBookmarked, isSending])

  return { bookmark, cancelBookmark, isBookmarked }
}
