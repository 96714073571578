import { MathlogArticleReference } from '../../../firebase'

type Props = {
  reference: MathlogArticleReference
}

export const MathlogArticleReferenceAccordionBody = ({ reference }: Props) => (
  <ul className="mb-4 ps-6">
    <li>参考文献の種類：Mathlogの記事</li>
    {reference.label !== undefined && <li>ラベル：{reference.label}</li>}
  </ul>
)
