import { collection, doc, DocRef } from '../../../../../firebase/alias'
import { getConverter } from '../../converter'
import { Document } from '../../document'
import { productRef } from './product'
import { PriceSubcols } from './subcollections'

// 不完全 適宜追加
export interface Price extends Document {
  readonly parent_id: 'prices'
  readonly subcollections: typeof PriceSubcols
  readonly ref: DocRef<Price>
  unit_amount: number
  currency: 'jpy'
  active: boolean
}

export const priceConverter = getConverter<Price>(PriceSubcols)
export const pricesRef = (productId: string) =>
  collection<Price>(productRef(productId), 'prices').withConverter<Price>(
    priceConverter
  )
export const priceRef = (productId: string, id: string) =>
  doc(pricesRef(productId), id)
