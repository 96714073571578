import { useTranslation } from 'next-i18next'
import { createContext } from 'react'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import {
  termsWithGlossaryInfoRef,
  TermWithGlossaryInfo,
} from '../../../firebase'
import { MathJaxLoader } from '../mathjax/config'

export const TermsContext = createContext<TermWithGlossaryInfo[] | null>(null)

export const MathdownConfig = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation()
  const [terms] = useDocumentDataOnce(
    termsWithGlossaryInfoRef(i18n.language === 'ja' ? 'ja' : 'en')
  )

  return (
    <TermsContext.Provider value={terms?.data ?? null}>
      <MathJaxLoader>{children}</MathJaxLoader>
    </TermsContext.Provider>
  )
}
