import { ESUser } from '../../elastic_search'
import { Profile } from '../../firebase'
import { Designed, PromiseVoid } from '../../types'
import { UserItem } from './Item'

type Props = {
  profiles: Profile[] | ESUser[]
  imageSize?: string
  onClick?: (profile: Profile | ESUser) => PromiseVoid
  prefix?: (props: { profile: Profile | ESUser; index: number }) => JSX.Element
  suffix?: (props: { profile: Profile | ESUser; index: number }) => JSX.Element
  isFollowButton?: boolean
}

export const UserList = ({
  profiles,
  imageSize = '55px',
  prefix: Prefix,
  suffix: Suffix,
  onClick: handleClick,
  className = 'd-flex flex-column gap-4',
  style,
  isFollowButton = true,
}: Designed<Props>) => (
  <div className={className} style={style}>
    {profiles.map((profile, index) => (
      <UserItem
        onClick={handleClick}
        profile={profile}
        className="overflow-hidden"
        key={profile.id}
        imageSize={imageSize}
        isFollowButton={isFollowButton}
        prefix={
          Prefix &&
          (({ profile }) => <Prefix profile={profile} index={index} />)
        }
        suffix={
          Suffix &&
          (({ profile }) => <Suffix profile={profile} index={index} />)
        }
      />
    ))}
    {profiles.length === 0 && (
      <div className="text-muted">ユーザはいません。</div>
    )}
  </div>
)
