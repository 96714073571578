import { QueryDocumentSnapshot } from 'firebase/firestore'
import { useCallback, useRef, useState } from 'react'
import {
  Profile,
  TeamMember,
  getDoc,
  getDocs,
  profileRef,
} from '../../firebase'
import { limitedTeamMembersByRoleRef } from '../../models/team'

const MEMBERS_LIMIT = 20

export const useTeamMembers = (
  teamProfileId: string,
  role: TeamMember['role']
) => {
  const lastMember = useRef<QueryDocumentSnapshot<TeamMember> | null>(null)
  const [members, setMembers] = useState<Profile[]>([])
  const [fetching, setFetching] = useState(false)
  const [isLast, setIsLast] = useState(false)

  const loadMore = useCallback(async () => {
    if (fetching) return
    if (isLast) return
    setFetching(true)
    const loadedMembersSnapshot = await getDocs(
      limitedTeamMembersByRoleRef(
        teamProfileId,
        role,
        lastMember.current,
        MEMBERS_LIMIT
      )
    )
    const loadedMemberDocs = loadedMembersSnapshot.docs

    const loadedMembers = await Promise.all(
      loadedMemberDocs.map(async (doc) => {
        const uid = doc.id
        const profileSnapshot = await getDoc(profileRef(uid))
        const user = profileSnapshot.data()
        return user
      })
    )
    const filteredLoadedMembers = loadedMembers.filter(
      (user) => user !== undefined
    ) as Profile[] // undefinedを除去したのでasを使って型を強制変更
    setMembers([...members, ...filteredLoadedMembers])
    if (!loadedMembersSnapshot.empty) {
      lastMember.current = loadedMemberDocs.slice(-1)[0]
    }
    if (loadedMemberDocs.length < MEMBERS_LIMIT) {
      setIsLast(true)
    }
    setFetching(false)
  }, [isLast, members, fetching])

  return { loadMore, members, isLast, fetching }
}
