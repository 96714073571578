export const _attribute =
  /\s+[a-zA-Z:_][\w.:-]*(?:\s*=\s*"[^"]*"|\s*=\s*'[^']*'|\s*=\s*[^\s"'=<>`]+)?/

export const _srcAttribute =
  /\s+src(?:\s*=\s*"([^"])*"|\s*=\s*'([^'])*'|\s*=\s*([^\s"'=<>`])+)?/

export const _hrefAttribute =
  /\s+href(?:\s*=\s*"([^"])*"|\s*=\s*'([^'])*'|\s*=\s*([^\s"'=<>`])+)?/

export const _altAttribute =
  /\s+alt(?:\s*=\s*"([^"])*"|\s*=\s*'([^'])*'|\s*=\s*([^\s"'=<>`])+)?/
