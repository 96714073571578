import { Lexer } from '../../lexer'
import { rules } from '../../rules'
import type { Tokens } from '../../types'
import { splitCells } from '../utils'

export const table = (src: string, lexer: Lexer): Tokens.Table | null => {
  const cap = rules.block.table.exec(src)
  if (!cap) return null

  const header = splitCells(cap[1]).map((c) => {
    return { text: c, tokens: [] }
  })
  const _align = cap[2].replace(/^ *|\| *$/g, '').split(/ *\| */)
  const _rows = cap[3].trim() ? cap[3].replace(/\n[ \t]*$/, '').split('\n') : []

  const raw = cap[0]
  const align: ('left' | 'right' | 'center' | null)[] = []

  let k
  for (let i = 0; i < header.length; i++) {
    if (/^ *-+: *$/.test(_align[i])) {
      align.push('right')
    } else if (/^ *:-+: *$/.test(_align[i])) {
      align.push('center')
    } else if (/^ *:-+ *$/.test(_align[i])) {
      align.push('left')
    } else {
      align.push(null)
    }
  }

  const rows: Tokens.TableCell[][] = []
  for (let i = 0; i < _rows.length; i++) {
    const row = splitCells(_rows[i], header.length).map((c) => {
      return { text: c, tokens: [] }
    })
    rows.push(row)
  }

  // parse child tokens inside headers and cells

  // header child tokens
  for (let j = 0; j < header.length; j++) {
    header[j].tokens = []
    lexer.inline(header[j].text, header[j].tokens)
  }

  // cell child tokens
  for (let j = 0; j < rows.length; j++) {
    const row = rows[j]
    for (k = 0; k < row.length; k++) {
      row[k].tokens = []
      lexer.inline(row[k].text, row[k].tokens)
    }
  }

  return {
    type: 'table',
    header,
    rows,
    align,
    raw,
  }
}
