import { Lexer } from '../../lexer'
import { rules } from '../../rules'
import { HTML, Tokens } from '../../types'

export const html = (
  src: string,
  lexer: Lexer,
  htmlList: HTML[]
): Tokens.HTML | null => {
  const cap = rules.block.html.exec(src)
  if (!cap) return null
  const index = Number(cap[1])
  return {
    type: 'html',
    raw: cap[0],
    text: htmlList[index].text,
    error: htmlList[index].error,
    tokens: lexer.inlineTokens(htmlList[index].text),
  }
}
