import { rules } from '../../rules'
import type { Tokens } from '../../types'
import { indentCodeCompensation } from '../utils'

export const fences = (src: string): Tokens.Code | null => {
  const cap = rules.block.fences.exec(src)
  if (!cap) return null
  const raw = cap[0]
  const text = indentCodeCompensation(raw, cap[3] || '')
  return {
    type: 'code',
    raw,
    lang: cap[2] ? cap[2].trim() : cap[2],
    text,
  }
}
