import Link from 'next/link'
import { Nav } from 'react-bootstrap'
import { Category } from '../../firebase'
import { useElementSize } from '../../hooks'
import { useCategories } from '../../hooks/models/useCategories'
import { Locale } from '../../types'
import { APP_NAME } from '../../utils/env'

type Props = {
  onChangeHeight?: (height: number) => void
  top: number
  locale: Locale
  categories?: Category[]
  activeCategoryId?: string
  fixed?: boolean
}

export const MOBILE_CATEGORIES_MENU_HEIGHT = 42

export const MobileCategoriesMenu = ({
  onChangeHeight,
  top,
  locale,
  categories,
  activeCategoryId,
  fixed = false,
}: Props) => {
  const app = APP_NAME
  const { menuCategories } = useCategories(categories)
  const { elementRef: ref, height } = useElementSize({
    onChangeHeight,
    defaultHeight: MOBILE_CATEGORIES_MENU_HEIGHT,
  })
  return (
    <>
      <Nav
        variant="underline"
        className={`w-100 flex-shrink-0 flex-nowrap overflow-auto scrollbar-none d-md-none shadow-sm gap-5 px-2 bg-white ${
          fixed ? 'position-fixed' : ''
        }`}
        ref={ref}
        style={{ top, height: 42 }}
      >
        {menuCategories?.map((category) => (
          <Nav.Item key={category.id} className="flex-shrink-0">
            <Link
              href={
                app === 'mathlog'
                  ? `/categories/${category.id}/articles`
                  : `/categories/${category.id}`
              }
              passHref
            >
              <Nav.Link
                active={activeCategoryId === category.id}
                className="text-body"
              >
                <span className="small">{category.localized_name[locale]}</span>
              </Nav.Link>
            </Link>
          </Nav.Item>
        ))}
      </Nav>
      {fixed && <div className="w-100 d-md-none" style={{ height }} />}
    </>
  )
}
