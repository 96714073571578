import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { usePopupMessage } from '../../assets/messenger'
import { Role, rolesRef } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'

export const useRequireRole = (roles: Role['id'][]) => {
  const router = useRouter()
  const { setPopupMessage } = usePopupMessage()
  const { user } = useAuthState()
  const [rolesData, rolesDataLoading, rolesDataError] = useCollectionDataOnce(
    user ? rolesRef(user.uid) : null
  )
  const isOk = rolesData?.some((r) => roles.includes(r.id))
  const isLoading = isOk === undefined
  useEffect(() => {
    if (isLoading && rolesDataError === undefined) return
    if (isLoading && rolesDataError) throw rolesDataError
    if (isOk === true) return
    setPopupMessage('アクセス権限がありません。', 'danger')
    void router.push('/')
  })

  return isLoading
}
