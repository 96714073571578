import Link from 'next/link'
import { FaTag } from 'react-icons/fa'
import { IconWrapper } from '../../assets/IconWrapper'
import { Tag } from '../../firebase'
import { ESTag } from '../../shared/elastic_search/scheme/tag'
import { Designed } from '../../types'
import { TagFollowButton } from './FollowButton'

type Props = {
  tag: Tag | ESTag
  showFollowButton?: boolean
}

export const BlockTagItem = ({
  tag,
  showFollowButton = true,
  ...wrapperProps
}: Designed<Props>) => (
  <div {...wrapperProps}>
    <div className="d-flex align-items-center justify-content-between gap-3">
      <Link href={`/tags/${tag.id}`} passHref>
        <IconWrapper suffix={tag.name} className="fw-bold" truncate>
          <FaTag className="flex-shrink-0" />
        </IconWrapper>
      </Link>
      {showFollowButton && <TagFollowButton tag={tag} />}
    </div>
  </div>
)
