import { UserInfo } from 'firebase/auth'

const PROVIDERS = ['google.com', 'github.com', 'twitter.com', 'password']

export type Provider = typeof PROVIDERS[number]

export const getProviderName = (providerId: string) => {
  switch (providerId) {
    case 'google.com':
      return 'Google'
    case 'github.com':
      return 'Github'
    case 'twitter.com':
      return 'Twitter'
    case 'facebook.com':
      return 'Facebook'
    case 'password':
      return 'Email'
    default:
      return providerId
  }
}

export const hasProvider = (providerData: UserInfo[], provider: Provider) => {
  return !!providerData.find((p) => p.providerId === provider)
}
