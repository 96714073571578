import { useEffect } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Category } from '../../firebase'
import { sortedCategoriesRef } from '../../models/category'
import { useCategories } from './useCategories'

export const useDynamicCategories = (defaultCategories?: Category[]) => {
  const {
    mutate,
    categories: cachedCategories,
    menuCategories: cachedMenuCategories,
    selectableCategories: cachedSelectableCategories,
  } = useCategories(defaultCategories)
  const [categories, loading, error] = useCollectionData(sortedCategoriesRef, {
    initialValue: defaultCategories,
  })

  useEffect(() => {
    // SWRのキャッシュを再検証なしで更新
    void mutate(categories, false)
  }, [categories])

  return {
    categories: categories ?? cachedCategories,
    menuCategories: cachedMenuCategories,
    selectableCategories: cachedSelectableCategories,
    loading,
    error,
  }
}
