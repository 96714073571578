export const ADSENSE_CLIENT = 'ca-pub-4318304326866018'

export const ADSENSE_SLOTS = {
  DEFAULT_RIGHT_SIDEBAR_SLOT: '7841520556',
  CONTENT_SIDEBAR_1_SLOT: '4763521952',
  CONTENT_SIDEBAR_2_SLOT: '3750974562',
  ARTICLE_BOTTOM_SLOT: '8446496531',
  BOOK_BOTTOM_SLOT: '8140811567',
  PAGE_SIDEBAR_SLOT: '6827729894',
  PAGE_BOTTOM_SLOT: '1124811226',
}
