import { ESUser } from '../../elastic_search'
import { Profile } from '../../firebase'
import { Designed, PromiseVoid } from '../../types'
import { UserFollowButton } from './FollowButton'
import { UserImageWithName } from './ImageWithName'

type Props = {
  profile: Profile | ESUser
  imageSize?: number | string
  onClick?: (profile: Profile | ESUser) => PromiseVoid
  prefix?: (props: { profile: Profile | ESUser }) => JSX.Element
  suffix?: (props: { profile: Profile | ESUser }) => JSX.Element
  isFollowButton?: boolean
}

export const UserItem = ({
  profile,
  imageSize = '55px',
  prefix: Prefix,
  suffix: Suffix,
  onClick: handleClick,
  isFollowButton = true,
  ...wrapperProps
}: Designed<Props>) => (
  <div {...wrapperProps} onClick={handleClick && (() => handleClick(profile))}>
    <div className="d-flex align-items-center gap-2">
      {Prefix && <Prefix profile={profile} />}
      <div className="flex-grow-1 overflow-hidden">
        <UserImageWithName profile={profile} size={imageSize} />
      </div>
      {isFollowButton && (
        <div className="flex-shrink-0">
          <UserFollowButton profile={profile} className="d-print-none" />
        </div>
      )}
      {Suffix && <Suffix profile={profile} />}
    </div>
  </div>
)
