export const escapeTexText = (text: string) => {
  const parser = new DOMParser()
  const parseTextDocument = parser.parseFromString(text, 'text/html')
  const rootElement = parseTextDocument.documentElement
  const elementContent = rootElement?.textContent
  return `${
    elementContent !== null && elementContent !== undefined
      ? elementContent
      : ''
  }`
    .replace(
      /(%|#|(?<!\\ref\{[^}]+)_|\$|(?<!\\ref\{[^}]+)&|(?<!\\ref){|(?<!\\ref\{[^}]+)})/g,
      '\\$1'
    )
    .replace(/~/g, '\\textasciitilde')
    .replace(/\^/g, '\\textasciicircum')
}
