import { Lexer } from '../../lexer'
import { rules } from '../../rules'
import type { Tokens } from '../../types'

export const text = (src: string, lexer: Lexer): Tokens.Text | null => {
  const cap = rules.block.text.exec(src)
  if (!cap) return null
  const token: Tokens.Text = {
    type: 'text',
    raw: cap[0],
    text: cap[0],
    tokens: [],
  }
  lexer.inline(token.text, token.tokens ?? [])
  return token
}
