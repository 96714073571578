import {
  collection,
  db,
  doc,
  DocRef,
  Timestamp,
} from '../../../../firebase/alias'
import { Locale } from '../../../types'
import { getConverter } from '../converter'
import { Document } from '../document'
import {
  GlossaryDraftSubcols,
  GlossarySubcols,
  TermsWithGlossaryInfoSubcols,
} from './subcollections'
import { WithUserTimestamp } from './user_timestamp'

export type Term = {
  display: string // ユーザに表示する用語 (漢字，英語)
  reading: string // 日本語の場合のふりがな，英語の場合は表示する用語と同じ
}

export interface Glossary extends Document, WithUserTimestamp {
  readonly ref: DocRef<Glossary>
  readonly parent_id: 'glossaries'
  readonly subcollections: typeof GlossarySubcols
  created_by: string // uid
  last_updated_by: string // uid
  terms: Term[] // 同義語があるため複数格納可能
  category_id?: string // 特定のカテゴリの用語場合はそのカテゴリを指定する
  summary: string // 用語説明の概要
  detail: string // 用語説明の詳細
}

export interface GlossaryDraft extends Document {
  readonly ref: DocRef<GlossaryDraft>
  readonly parent_id: 'glossary_drafts'
  readonly subcollections: typeof GlossaryDraftSubcols
  created_by: string // uid
  last_updated_by: string // uid
  terms: Term[] // 同義語があるため複数格納可能
  category_id?: string // 特定のカテゴリの用語場合はそのカテゴリを指定する
  summary: string
  detail: string
  publication_id?: string // 存在する場合は更新用の下書き, 検索用
  /**
   * ユーザが実際に作成した時のタイムスタンプ
   * 現状はcreated_atと同じ時間になる
   * 今後システムとして先に作成しておく場合を想定して区別する
   */
  created_by_user_at: Timestamp

  /**
   * ユーザが実際に更新した時のタイムスタンプ
   * システム的にアップデートするフィールドを更新する際には更新されない
   */
  updated_by_user_at: Timestamp
}

export interface TermWithGlossaryInfo extends Term {
  category_id?: string
  glossary_id: string
}
export interface TermsWithGlossaryInfo extends Document {
  readonly parent_id: 'terms'
  readonly id: Locale
  readonly subcollections: typeof TermsWithGlossaryInfoSubcols
  ref: DocRef<TermsWithGlossaryInfo>
  data: TermWithGlossaryInfo[]
}

export const glossaryConverter = getConverter<Glossary>(GlossarySubcols)
export const glossariesRef = collection<Glossary>(
  db,
  'glossaries'
).withConverter(glossaryConverter)
export const glossaryRef = (id: string) => doc(glossariesRef, id)

export const glossaryDraftConverter =
  getConverter<GlossaryDraft>(GlossaryDraftSubcols)
export const glossaryDraftsRef = collection<GlossaryDraft>(
  db,
  'glossary_drafts'
).withConverter(glossaryDraftConverter)
export const glossaryDraftRef = (id: string) => doc(glossaryDraftsRef, id)

export const termsWithGlossaryInfoConverter =
  getConverter<TermsWithGlossaryInfo>(TermsWithGlossaryInfoSubcols)
export const termsWithGlossaryInfoRef = (locale: Locale) =>
  doc(collection<TermsWithGlossaryInfo>(db, 'terms'), locale).withConverter(
    termsWithGlossaryInfoConverter
  )
