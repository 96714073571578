import { atom } from 'recoil'
import { compileMacrosToHTML } from '../../compiler/utils'

export const mathjaxState = atom({
  key: 'MathJax',
  default: {
    loaded: false,
    waitingTypeset: false,
    compiledMacros: compileMacrosToHTML([]),
  },
})
