import { increment } from 'firebase/firestore'
import { useCallback, useState } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import { Batch, followeeRef, followerRef, profileRef } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'

export const useFollowUser = (uid: string) => {
  const [isSending, setIsSending] = useState(false)
  const { user: currentUser } = useAuthState()
  const ref = currentUser ? followerRef(uid, currentUser.uid) : null
  const [followSnapshot] = useDocument(ref)
  const isFollowing = followSnapshot ? followSnapshot.exists() : undefined

  const follow = useCallback(async () => {
    if (!currentUser) throw new Error('Unexpected call.')
    if (isFollowing === true || isFollowing === undefined)
      throw new Error('Unexpected call.')
    if (isSending) return
    setIsSending(true)
    const batch = new Batch()
    batch.create(followerRef(uid, currentUser.uid), {})
    batch.create(followeeRef(currentUser.uid, uid), {})
    batch.update(profileRef(uid), {
      followers_count: increment(1),
    })
    batch.update(profileRef(currentUser.uid), {
      followees_count: increment(1),
    })
    await batch.commit()
    setIsSending(false)
  }, [uid, currentUser, isFollowing, isSending])

  const unfollow = useCallback(async () => {
    if (!currentUser) throw new Error('Unexpected call.')
    if (isFollowing === false || isFollowing === undefined)
      throw new Error('Unexpected call.')
    if (isSending) return
    setIsSending(true)
    const batch = new Batch()
    await batch.delete(followerRef(uid, currentUser.uid))
    await batch.delete(followeeRef(currentUser.uid, uid))
    batch.update(profileRef(uid), {
      followers_count: increment(-1),
    })
    batch.update(profileRef(currentUser.uid), {
      followees_count: increment(-1),
    })
    await batch.commit()
    setIsSending(false)
  }, [uid, currentUser, isFollowing, isSending])

  return { isFollowing, follow, unfollow }
}
