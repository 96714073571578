import { FaEye } from 'react-icons/fa'
import { IconWrapper } from '../assets/IconWrapper'
import { Designed } from '../types'

type Props = {
  value: number
}

export const PVCounter = ({ value, ...wrapperProps }: Designed<Props>) => (
  <IconWrapper suffix={value} {...wrapperProps}>
    <FaEye />
  </IconWrapper>
)
