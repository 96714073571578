import { DocRef, collection, db, doc } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document } from '../document'
import { IPAddressSubcols } from './subcollections'

export interface IPAddress extends Document {
  readonly parent_id: 'ip_addresses'
  readonly ref: DocRef<IPAddress>
  readonly subcollections: typeof IPAddressSubcols
  ip: string
  uids: string[]
  is_blocked: boolean
}

export const ipAddressConverter = getConverter<IPAddress>(IPAddressSubcols)
export const ipAddressesRef = collection<IPAddress>(
  db,
  'ip_addresses'
).withConverter(ipAddressConverter)
export const ipAddressRef = (id: string) => doc(ipAddressesRef, id)
