import { FaThumbsDown } from 'react-icons/fa'
import { Designed } from '../../types'
import { IconWrapper } from '../IconWrapper'

type Props = {
  value: number
}

export const BadCounter = ({ value, ...wrapperProps }: Designed<Props>) => (
  <IconWrapper suffix={value} {...wrapperProps}>
    <FaThumbsDown />
  </IconWrapper>
)
