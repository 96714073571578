import type { MutableRefObject } from 'react'
import { useEffect, useState } from 'react'

export type Heading = {
  type: string
  text: string
  scroll: () => void
}

export type ContentsTableData = {
  headings?: Heading[]
  activeIndex?: number
}

export const useContentsTable = (
  ref: MutableRefObject<HTMLElement | null>,
  top = 0, // topにどの程度隙間を空けるか
  dep?: unknown,
  selector = 'h2, h3'
): ContentsTableData => {
  // ref内部のHTMLを解析し，見出しの内容とその見出しにscrollする関数を生成
  const [headingElements, setHeadingElements] = useState<
    NodeListOf<Element> | undefined
  >(undefined)
  useEffect(() => {
    if (!ref.current) return
    setHeadingElements(ref.current.querySelectorAll<HTMLElement>(selector))
  }, [ref, dep])

  const [headings, setHeadings] = useState<Heading[] | undefined>(undefined)
  useEffect(() => {
    if (!headingElements) return
    const currentHeadings: Heading[] = []
    headingElements.forEach((headingElement, index) => {
      currentHeadings.push({
        scroll: () => {
          const target =
            headingElement.getBoundingClientRect().top +
            window.pageYOffset -
            top +
            1
          window.scrollTo(0, target)
          setActiveIndex(index)
        },
        text: headingElement.innerHTML,
        type: headingElement.tagName,
      })
    })
    setHeadings(currentHeadings)
  }, [headingElements, top])

  // scrollYの取得処理
  const [scrollY, setScrollY] = useState(0)
  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY)
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  // ユーザがスクロールしたときにactiveIndexを更新する処理
  const [activeIndex, setActiveIndex] = useState<number>(-1)
  useEffect(() => {
    if (!ref.current) return
    if (!headingElements) return
    let currentActiveIndex = -1
    while (currentActiveIndex + 1 < headingElements.length) {
      const element = headingElements[currentActiveIndex + 1]
      const offset =
        element.getBoundingClientRect().top + window.pageYOffset - top
      if (offset <= scrollY) currentActiveIndex++
      else break
    }
    if (currentActiveIndex + 1 === headingElements.length) {
      const offset =
        ref.current.getBoundingClientRect().bottom + window.pageYOffset - top
      if (offset < scrollY) currentActiveIndex++
    }
    setActiveIndex(currentActiveIndex)
  }, [scrollY])

  return { headings, activeIndex }
}
