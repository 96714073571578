export const box = (
  type: string | undefined,
  titleDom: string,
  text: string,
  title?: string,
  label?: string
) => {
  let result = ''
  result += `<div class="box ${type ?? 'normal'}" ${
    label !== undefined ? `data-label=${label}` : ''
  } ${title !== undefined ? `data-title=${title}` : ''} data-type=${
    type ?? 'normal'
  }>`
  result += `<div class="title">`
  if (title !== undefined) result += `<span id=${title}>`
  if (label !== undefined) result += `<span id=${label}>`
  result += titleDom
  if (label !== undefined) result += `</span>`
  if (title !== undefined) result += `</span>`
  result += '</div>'
  result += `<div class="body">${text}</div>`
  result += '</div>'
  return result
}
