import { Button } from 'react-bootstrap'
import { FaUserTag } from 'react-icons/fa'
import { Tag } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { useFollowTag } from '../../hooks'
import { ESTag } from '../../shared/elastic_search/scheme/tag'
import { Designed } from '../../types'

type Props = {
  tag: Tag | ESTag
  type?: 'text' | 'icon'
  size?: 'sm' | 'lg'
  outline?: boolean
}

export const TagFollowButton = ({
  tag,
  type = 'icon',
  outline = type === 'icon',
  ...otherProps
}: Designed<Props>) => {
  const { follow, unfollow, isFollowing } = useFollowTag(tag.id)
  const { user: currentUser } = useAuthState()
  if (!currentUser || isFollowing === undefined) return <></>
  return (
    <Button
      {...otherProps}
      variant={
        isFollowing
          ? outline
            ? 'outline-danger'
            : 'danger'
          : outline
          ? 'outline-primary'
          : 'primary'
      }
      onClick={isFollowing ? unfollow : follow}
    >
      {type === 'icon' ? (
        <FaUserTag />
      ) : isFollowing ? (
        'フォロー解除'
      ) : (
        'フォローする'
      )}
    </Button>
  )
}
