import { useEffect, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'
import { mathjaxState } from '../config'

export const useMathJaxRenderer = <T extends HTMLElement>(
  compiledMacros?: string,
  callback?: (result: string) => void
) => {
  const targetElementRef = useRef<T>(null)

  const [mathjax, setMathjax] = useRecoilState(mathjaxState)

  // 数式のプレビューが発動された回数
  const [triggeredCount, setTriggeredCount] = useState<number>(0)

  const waitingTargetRef = useRef<T>()
  const waitingMacrosRef = useRef<string>()
  const currentTypesetCountRef = useRef<number>(0) // 数式のプレビューを行った回数

  const typeset = () => {
    if (targetElementRef.current === null)
      throw new Error('The targetElementRef is not assigned to dom.')
    waitingTargetRef.current = targetElementRef.current
    waitingMacrosRef.current = compiledMacros
    setTriggeredCount((current) => current + 1)
  }

  useEffect(() => {
    if (triggeredCount <= currentTypesetCountRef.current) return // 発動した回数分処理されている時は何も実行しない

    if (waitingTargetRef.current === undefined) return
    if (waitingMacrosRef.current === undefined) return

    if (!mathjax.loaded) return // MathJaxがロードされていないときは待機
    if (mathjax.waitingTypeset) return // 他のコンポーネントで描画中のときは待機

    // 他のコンポーネントが割り込まないようにブロック
    setMathjax((current) => {
      return { ...current, waitingTypeset: true }
    })
    currentTypesetCountRef.current += 1

    // マクロが変わっている場合はマクロを再読込
    const currentWaitingCompiledMacros = waitingMacrosRef.current
    // if (currentWaitingCompiledMacros !== mathjax.compiledMacros) {
    //   try {
    //     window.MathJax.startup.getComponents()
    //   } catch (error) {
    //     console.error(error)
    //   }
    // }

    // 本来は上記のようにマクロが変わっている場合のみ実行したいが、ラベルのリセットがうまく動作しないため、毎回コンポーネントを作成し直す
    // texResetはうまく動作しなかった

    // TODO: ESLintを無効にしないように修正
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    window.MathJax.startup.getComponents()

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    window.MathJax.typesetPromise([waitingTargetRef.current]).then(() => {
      setMathjax((current) => {
        return {
          ...current,
          waitingTypeset: false,
          compiledMacros: currentWaitingCompiledMacros,
        }
      })
      if (waitingTargetRef.current) {
        const alignFormulae =
          waitingTargetRef.current.getElementsByClassName('formula-align')
        for (let i = 0; i < alignFormulae.length; i++) {
          const element = alignFormulae.item(i)
          if (!element) continue
          const align = element.getAttribute('data-align')
          if (align === null || align === '' || align === 'left') continue
          const mathjaxContainer = element
            .getElementsByClassName('MathJax')
            .item(0)
          mathjaxContainer?.setAttribute('justify', align)
        }
      }
      if (callback) callback(targetElementRef.current?.innerHTML ?? '')
    })
  }, [mathjax.loaded, mathjax.waitingTypeset, triggeredCount])

  return { typeset, ref: targetElementRef }
}
