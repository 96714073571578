import { collection, doc, DocRef } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document, ParentRefAssignable, ValidDocument } from '../document'
import { BookmarkedUserSubcols } from './subcollections'

export interface BookmarkedUser extends Document {
  readonly parent_id: 'bookmarked_users'
  readonly ref: DocRef<BookmarkedUser>
  readonly subcollections: typeof BookmarkedUserSubcols
}

export type BookmarkableRefAssignable<T extends ValidDocument> =
  ParentRefAssignable<BookmarkedUser, T>

export interface Bookmarkable extends ValidDocument {
  bookmarked_count: number
  created_by: string
  ref: BookmarkableRefAssignable<Bookmarkable>
}

export const bookmarkedUserConverter = getConverter<BookmarkedUser>(
  BookmarkedUserSubcols
)

export const bookmarkedUsersRef = <T extends ValidDocument>(
  bookmarkableRef: BookmarkableRefAssignable<T>
) =>
  collection<BookmarkedUser>(bookmarkableRef, 'bookmarked_users').withConverter(
    bookmarkedUserConverter
  )

export const bookmarkedUserRef = <T extends ValidDocument>(
  bookmarkableRef: BookmarkableRefAssignable<T>,
  uid: string
) => doc(bookmarkedUsersRef(bookmarkableRef), uid)
