import { IS_PROD } from '../../utils'
import { Features } from '../firebase'

export type StripeBadgeProducts = {
  thank_you_badge: `prod_${string}`
  read_more_badge: `prod_${string}`
  elegant_badge: `prod_${string}`
}

export const STRIPE_BADGE_PRODUCTS_JPY: StripeBadgeProducts = {
  thank_you_badge: IS_PROD ? 'prod_Nyl648P0EhYbux' : 'prod_NqUgNnOQlbs7YA',
  read_more_badge: IS_PROD ? 'prod_Nyl6xzqq4ApSC5' : 'prod_NqVxrJDl9PVHFY',
  elegant_badge: IS_PROD ? 'prod_Nyl60Vxw9W7RFR' : 'prod_NqVy7NPUcxWJnb',
}

export type PaidPlan = 'Premium'

export type Plan = PaidPlan | 'Free'

export type Interval = 'monthly' | 'yearly'

export const SUBSCRIPTION_PRODUCTS: Record<string, PaidPlan> = IS_PROD
  ? { prod_O8tKty3zJvt6Mk: 'Premium' }
  : { prod_OEXMr8ZhZ9lf8v: 'Premium' }

export const YEARLY_PREMIUM_PLAN_PRICE = IS_PROD
  ? 'price_1NMbYJKAfwtbO4R6WqMqVGQ0'
  : 'price_1NS4HKKAfwtbO4R6gXJDIltd'

export const MONTHLY_PREMIUM_PLAN_PRICE = IS_PROD
  ? 'price_1NMbYJKAfwtbO4R6lQdCNRfB'
  : 'price_1NS4HKKAfwtbO4R61HKpcOOm'

export const planToFeatures = (plan: Plan): Features => {
  switch (plan) {
    case 'Free':
      return {
        visibility_select: false,
        tex_export: false,
        ads_remove: false,
        collaborative_edit: false,
        evaluate: true,
      }

    case 'Premium':
      return {
        visibility_select: true,
        tex_export: true,
        ads_remove: true,
        collaborative_edit: true,
        evaluate: true,
      }
  }
}
