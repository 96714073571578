import { Button } from 'react-bootstrap'
import { FaUserMinus, FaUserPlus } from 'react-icons/fa'
import { ESUser } from '../../elastic_search'
import { Profile } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { useFollowUser } from '../../hooks/models/useFollowUser'
import { Designed } from '../../types'

type Props = {
  profile: Profile | ESUser
  type?: 'text' | 'icon'
  size?: 'sm' | 'lg'
  outline?: boolean
}

export const UserFollowButton = ({
  profile,
  type = 'icon',
  outline = type === 'icon',
  ...otherProps
}: Designed<Props>) => {
  const { follow, unfollow, isFollowing } = useFollowUser(profile.id)
  const { user: currentUser } = useAuthState()
  if (
    !currentUser ||
    currentUser.uid === profile.id ||
    isFollowing === undefined
  )
    return <></>
  return (
    <Button
      {...otherProps}
      variant={
        isFollowing
          ? outline
            ? 'outline-danger'
            : 'danger'
          : outline
          ? 'outline-primary'
          : 'primary'
      }
      onClick={async (e) => {
        e.stopPropagation()
        if (isFollowing) await unfollow()
        else await follow()
      }}
    >
      {type === 'icon' ? (
        isFollowing ? (
          <FaUserMinus />
        ) : (
          <FaUserPlus />
        )
      ) : isFollowing ? (
        'フォロー解除'
      ) : (
        'フォロー'
      )}
    </Button>
  )
}
