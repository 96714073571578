import { createContext, useState } from 'react'

export type PopupMessageType =
  | 'primary'
  | 'info'
  | 'success'
  | 'warning'
  | 'danger'

export type PopupMessage = {
  type: PopupMessageType
  text: string
}

export type PopupMessageState = {
  popupMessage: PopupMessage | undefined
  setPopupMessage: (message: PopupMessage | undefined) => void
}

export const PopupMessageContext = createContext<PopupMessageState>({
  popupMessage: undefined,
  setPopupMessage: () => {
    throw new Error('Unexpected call error.')
  },
})

type Props = {
  children: React.ReactNode
}

export const PopupMessageProvider = ({ children }: Props) => {
  const [popupMessage, setPopupMessage] = useState<PopupMessage>()

  return (
    <PopupMessageContext.Provider value={{ popupMessage, setPopupMessage }}>
      {children}
    </PopupMessageContext.Provider>
  )
}
