const caret = /(^|[^[])\^/g

export class RegexEditor {
  private regexStr: string
  private opt: string | undefined

  constructor(regex: RegExp | string, opt?: string) {
    this.regexStr = typeof regex !== 'string' ? regex.source : regex
    this.opt = opt
  }

  replace(name: RegExp | string, val: RegExp | string) {
    let valStr = typeof val !== 'string' ? val.source : val
    valStr = valStr.replace(caret, '$1')
    this.regexStr = this.regexStr.replace(name, valStr)
    return this
  }

  getRegex() {
    return new RegExp(this.regexStr, this.opt)
  }
}

export const edit = (regex: RegExp | string, opt?: string) => {
  const regexStr = typeof regex !== 'string' ? regex.source : regex
  opt = opt ?? ''
  const obj = {
    replace: (name: RegExp | string, val: RegExp | string) => {
      let valStr = typeof val !== 'string' ? val.source : val
      valStr = valStr.replace(caret, '$1')
      regex = regexStr.replace(name, valStr)
      return obj
    },
    getRegex: () => {
      return new RegExp(regexStr, opt)
    },
  }
  return obj
}
