import { QueryDocumentSnapshot } from 'firebase/firestore'
import { useCallback, useRef, useState } from 'react'
import { Followee, getDoc, getDocs, Profile, profileRef } from '../../firebase'
import { sortedFolloweesRef } from '../../models/follow'

const FOLLOWEES_LIMIT = 20

export const useFollowees = (uid: string) => {
  const lastFollowee = useRef<QueryDocumentSnapshot<Followee> | null>(null)
  const [followees, setFollowees] = useState<Profile[]>([])
  const [fetching, setFetching] = useState(false)
  const [isLast, setIsLast] = useState(false)

  const loadMore = useCallback(async () => {
    if (fetching) return
    if (isLast) return
    setFetching(true)
    const loadedFolloweesSnapshot = await getDocs(
      sortedFolloweesRef(uid, lastFollowee.current, FOLLOWEES_LIMIT)
    )
    const loadedFolloweeDocs = loadedFolloweesSnapshot.docs
    const loadedFollowees = await Promise.all(
      loadedFolloweeDocs.map(async (doc) => {
        const uid = doc.id
        const profileSnapshot = await getDoc(profileRef(uid))
        const user = profileSnapshot.data()
        return user
      })
    )

    const filteredLoadedFollowees = loadedFollowees.filter(
      (user) => user !== undefined
    ) as Profile[] // undefinedを除去したのでasを使って型を強制変更
    setFollowees([...followees, ...filteredLoadedFollowees])
    if (!loadedFolloweesSnapshot.empty) {
      lastFollowee.current = loadedFolloweeDocs.slice(-1)[0]
    }
    if (loadedFolloweeDocs.length < FOLLOWEES_LIMIT) {
      setIsLast(true)
    }
    setFetching(false)
  }, [isLast, followees, fetching])

  return { loadMore, followees, isLast, fetching }
}
