import { Profile } from '../../firebase'
import { Designed } from '../../types'
import { UserImageWithLink } from './ImageWithLink'

type Props = {
  size: string
  profiles: Profile[]
}

export const UserImageList = ({
  size,
  profiles,
  ...otherProps
}: Designed<Props>) => {
  return (
    <div {...otherProps}>
      {profiles.map((profile) => (
        <UserImageWithLink key={profile.id} profile={profile} size={size} />
      ))}
    </div>
  )
}
