import { useState } from 'react'
import { Nav } from 'react-bootstrap'
import { IconType } from 'react-icons'
import { FaEye, FaPlus } from 'react-icons/fa'
import { ReferenceForm, ReferencesAccordion } from '.'
import {
  Reference,
  ReferencesParentRefAssignable,
  ValidDocument,
} from '../../firebase'
import { useMedia } from '../../hooks'
import { useOrderChanger } from '../../hooks/useOrdersChanger'
import { Designed } from '../../types'
import { IconWrapper } from '../IconWrapper'

type Props<T extends ValidDocument> = {
  parentRef: ReferencesParentRefAssignable<T>
  references: Reference[]
}

export const ReferencesEditor = <T extends ValidDocument>({
  parentRef,
  references,
  ...containerProps
}: Designed<Props<T>>) => {
  const [editingReferenceId, setEditingReferenceId] = useState<string>()
  const editingReference = references.find((r) => r.id === editingReferenceId)
  const [activeKeys, setActiveKeys] = useState<string[]>([])
  const {
    array: immediatelyOrderedReferences,
    shift,
    nextOrder,
  } = useOrderChanger(references)
  const isMd = useMedia('md')

  type Mode = 'input' | 'output'
  const [mode, setMode] = useState<Mode>('input')

  type Pill = {
    modeType: Mode
    name: string
    icon: IconType
  }

  const pills: Pill[] = [
    {
      modeType: 'input',
      name: '追加',
      icon: FaPlus,
    },
    {
      modeType: 'output',
      name: '確認',
      icon: FaEye,
    },
  ]

  return (
    <div {...containerProps}>
      <div className="d-flex flex-column h-100 w-100 overflow-hidden">
        <div className="flex-shrink-0 mb-2 d-md-none">
          <Nav variant="pills" className="fs-7">
            {pills.map(({ modeType, name, icon: Icon }) => (
              <Nav.Item key={name}>
                <Nav.Link
                  active={mode === modeType}
                  onClick={() => setMode(modeType)}
                  className="px-2 py-1"
                >
                  <IconWrapper suffix={name}>
                    <Icon />
                  </IconWrapper>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </div>
        <div className="d-flex flex-grow-1 overflow-hidden">
          {(isMd || mode === 'input') && (
            <ReferenceForm
              className="flex-grow-1 h-100 overflow-auto pe-md-3"
              style={{ minWidth: '50%' }}
              parentRef={parentRef}
              reference={editingReference}
              onSave={(referenceRef) => {
                if (!activeKeys.includes(referenceRef.id))
                  setActiveKeys([...activeKeys, referenceRef.id])
                setEditingReferenceId(undefined)
              }}
              onCancel={() => setEditingReferenceId(undefined)}
              nextOrder={nextOrder}
            />
          )}
          {(isMd || mode === 'output') && (
            <div
              className="flex-grow-1 h-100 d-flex flex-column ps-md-3"
              style={{ minWidth: '50%' }}
            >
              <h2 className="flex-shrink-0 h5">追加済みの参考文献</h2>
              <ReferencesAccordion
                className="flex-grow-1 overflow-auto"
                parentRef={parentRef}
                references={immediatelyOrderedReferences}
                editingId={editingReferenceId}
                activeKeys={activeKeys}
                setActiveKeys={setActiveKeys}
                onEditButtonClick={(reference) =>
                  setEditingReferenceId(reference.id)
                }
                shiftReferences={shift}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
