import { Reference } from '../../../firebase'
import { GeneralBookReferenceItem } from './GeneralBook'
import { MathlogArticleReferenceItem } from './MathlogArticle'
import { PaperReferenceItem } from './Paper'
import { WebsiteReferenceItem } from './Website'

type Props = {
  reference: Reference
  link: boolean
  amazonBookImage: boolean
}

export const ReferenceItem = ({ reference, link, amazonBookImage }: Props) => {
  switch (reference.type) {
    case 'mathlog_article':
      return <MathlogArticleReferenceItem reference={reference} link={link} />
    case 'general_book':
      return (
        <GeneralBookReferenceItem
          reference={reference}
          link={link}
          amazonBookImage={amazonBookImage}
        />
      )
    case 'paper':
      return <PaperReferenceItem reference={reference} link={link} />
    case 'website':
      return <WebsiteReferenceItem reference={reference} link={link} />
    default:
      throw new Error('Unexpected reference type has detected.')
  }
}
