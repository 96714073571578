import { DocRef, collection, db, doc } from '../../../../firebase/alias'
import { App } from '../../../types'
import { getConverter } from '../converter'
import { Document } from '../document'
import { InquirySubcols } from './subcollections'

export const INQUIRY_TYPES = [
  'bug',
  'feedback',
  'job_offer',
  'question',
] as const

export type InquiryType = typeof INQUIRY_TYPES[number]

export interface Inquiry extends Document {
  readonly parent_id: 'inquiries'
  readonly ref: DocRef<Inquiry>
  readonly subcollections: typeof InquirySubcols
  app: App
  created_by: string
  body: string
  type: InquiryType
  is_requested_reply: boolean
}

export const getInquiryTypeDescription = (type: InquiryType): string => {
  switch (type) {
    case 'bug':
      return '不具合の報告'
    case 'feedback':
      return '意見・要望'
    case 'question':
      return '質問'
    case 'job_offer':
      return 'お仕事のご依頼'
  }
}

export const inquiryConverter = getConverter<Inquiry>(InquirySubcols)
export const inquiriesRef = collection<Inquiry>(db, 'inquiries').withConverter(
  inquiryConverter
)
export const inquiryRef = (id: string) => doc(inquiriesRef, id)
