import Link, { LinkProps } from 'next/link'
import { UrlObject } from 'url'
import { isBlank } from '../utils'

type Props = Omit<LinkProps, 'href'> & {
  href: UrlObject | string | null | undefined
  withATag?: boolean
}

export const LinkWrapper = ({
  href,
  children,
  withATag = true,
  ...otherProps
}: React.PropsWithChildren<Props>) => {
  if (isBlank(href) || href === '') return <>{children}</>
  return withATag ? (
    <Link href={href} {...otherProps}>
      <a>{children}</a>
    </Link>
  ) : (
    <Link href={href} {...otherProps}>
      {children}
    </Link>
  )
}
