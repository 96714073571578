import { collection, db, doc, DocRef } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document } from '../document'
import { BankAccount } from './account'
import { PayoutApplicationSubcols } from './subcollections'

export interface PayoutApplication extends Document {
  readonly parent_id: 'payout_applications'
  readonly ref: DocRef<PayoutApplication>
  readonly subcollections: typeof PayoutApplicationSubcols
  uid: string
  method: 'amazon' | 'bank'
  email?: string
  bank_account?: BankAccount
  has_processed: boolean
}

export const payoutApplicationConverter = getConverter<PayoutApplication>(
  PayoutApplicationSubcols
)
export const payoutApplicationsRef = collection<PayoutApplication>(
  db,
  'payout_applications'
).withConverter<PayoutApplication>(payoutApplicationConverter)
export const payoutApplicationRef = (id: string) =>
  doc(payoutApplicationsRef, id)
