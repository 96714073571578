import { useState } from 'react'
import { Button, Modal, Nav } from 'react-bootstrap'
import { IconType } from 'react-icons'
import { FaUserAlt } from 'react-icons/fa'
import InfiniteScroll from 'react-infinite-scroller'
import { IconWrapper } from '../../assets/IconWrapper'
import { BlockTagList } from '../../assets/tag/BlockList'
import { Profile } from '../../firebase'
import { useFollowees } from '../../hooks/models/useFollowees'
import { useFollowers } from '../../hooks/models/useFollowers'
import { useFollowingTags } from '../../hooks/models/useFollowingTags'
import { Designed, PromiseVoid } from '../../types'
import { LoadingSpinner } from '../spinners'
import { TeamMembersList } from '../team'
import { UserList } from './List'

type RelationShipType = 'followers' | 'followees' | 'members'

type TitleProps = {
  title: string
  icon: IconType
}

const Title = ({ title, icon: Icon }: TitleProps) => (
  <IconWrapper suffix={title} className="text-muted">
    <Icon />
  </IconWrapper>
)

type RelationshipsListProps = {
  profile: Profile
  onClose: () => void
}

const FollowersList = ({
  profile,
  onClose: handleClose,
}: RelationshipsListProps) => {
  const { followers, loadMore, isLast, fetching } = useFollowers(profile.id)
  return (
    <InfiniteScroll
      loadMore={async () => {
        if (!fetching) await loadMore()
      }}
      hasMore={!isLast}
      useWindow={false}
      loader={<LoadingSpinner />}
    >
      <UserList profiles={followers} onClick={handleClose} imageSize={'45px'} />
    </InfiniteScroll>
  )
}

const FollowingUsersList = ({
  profile,
  onClose: handleClose,
}: RelationshipsListProps) => {
  const { followees, loadMore, isLast, fetching } = useFollowees(profile.id)
  return (
    <InfiniteScroll
      loadMore={async () => {
        if (!fetching) await loadMore()
      }}
      hasMore={!isLast}
      useWindow={false}
      loader={<LoadingSpinner />}
    >
      <UserList profiles={followees} onClick={handleClose} imageSize={'45px'} />
    </InfiniteScroll>
  )
}

const FollowingTagsList = ({ profile }: RelationshipsListProps) => {
  const { followingTags, loadMore, isLast, fetching } = useFollowingTags(
    profile.id
  )
  return (
    <InfiniteScroll
      loadMore={async () => {
        if (!fetching) await loadMore()
      }}
      hasMore={!isLast}
      useWindow={false}
      loader={<LoadingSpinner />}
    >
      <BlockTagList tags={followingTags} />
    </InfiniteScroll>
  )
}

type ModalProps = {
  show: boolean
  type: RelationShipType
  onHide: () => PromiseVoid
  profile: Profile
}

const RelationshipsModal = ({
  show,
  type,
  onHide: handleClose,
  profile,
}: ModalProps) => {
  const [followeesActiveNav, setFolloweesActiveNav] = useState<
    'tags' | 'users'
  >('users')
  if (!profile.is_team && type === 'members')
    throw new Error('This profile is not for a team.')
  return (
    <Modal show={show} onHide={handleClose} scrollable>
      <Modal.Header closeButton className="py-2">
        <Modal.Title className="fs-6">
          {type === 'followees' && <Title title="Follows" icon={FaUserAlt} />}
          {type === 'followers' && <Title title="Followers" icon={FaUserAlt} />}
          {type === 'members' && <Title title="Members" icon={FaUserAlt} />}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {show && (
          <>
            {type === 'followees' && (
              <>
                <Nav variant="pills" className="mb-3">
                  <Nav.Item onClick={() => setFolloweesActiveNav('users')}>
                    <Nav.Link
                      active={followeesActiveNav === 'users'}
                      eventKey="users"
                    >
                      ユーザ
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item onClick={() => setFolloweesActiveNav('tags')}>
                    <Nav.Link
                      active={followeesActiveNav === 'tags'}
                      eventKey="tags"
                    >
                      タグ
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                {followeesActiveNav === 'users' && (
                  <FollowingUsersList profile={profile} onClose={handleClose} />
                )}
                {followeesActiveNav === 'tags' && (
                  <FollowingTagsList profile={profile} onClose={handleClose} />
                )}
              </>
            )}
            {type === 'followers' && (
              <FollowersList profile={profile} onClose={handleClose} />
            )}
            {type === 'members' && profile.is_team && (
              <TeamMembersList teamProfile={profile} onClose={handleClose} />
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

type Props = {
  profile: Profile
  type: RelationShipType
}

export const UserRelationshipsCounter = ({
  profile,
  type,
  ...wrapperProps
}: Designed<Props>) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <Button
        variant="link"
        {...wrapperProps}
        className="text-muted text-decoration-none"
        onClick={() => setShowModal(true)}
      >
        {type === 'followees' && (
          <span>
            <span>
              {profile.followees_count + profile.following_tags_count}
            </span>
            <span className="ms-1">Follow</span>
          </span>
        )}
        {type === 'followers' && (
          <span>
            <span>{profile.followers_count}</span>
            <span className="ms-1">Followers</span>
          </span>
        )}
        {type === 'members' && profile.is_team && (
          <span>
            <span>{profile.team_fields.members_count}</span>
            <span className="ms-1">Members</span>
          </span>
        )}
      </Button>
      <RelationshipsModal
        profile={profile}
        type={type}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    </>
  )
}
