import { Lexer } from '../../lexer'
import { rules } from '../../rules'
import type { Tokens } from '../../types'

export const emStrong = (
  src: string,
  maskedSrc: string,
  prevChar = '',
  lexer: Lexer
): Tokens.Em | Tokens.Strong | null => {
  let match = rules.inline.emStrong.lDelim.exec(src)
  if (!match) return null

  // _ can't be between two alphanumerics. \p{L}\p{N} includes non-english alphabet/numbers as well
  if (match[3] && prevChar.match(/[\p{L}\p{N}]/u)) return null

  const nextChar = match[1] || match[2] || ''
  // formulaが中に入ってきた場合にうまく処理ができない問題の対処
  // !FORMULAの場合も処理を行うようにする
  if (
    !nextChar ||
    (nextChar &&
      (prevChar === '' ||
        rules.inline.punctuation.exec(prevChar) ||
        /!FORMULA.*/.exec(src)))
  ) {
    const lLength = match[0].length - 1
    let rDelim,
      rLength,
      delimTotal = lLength,
      midDelimTotal = 0

    const endReg = match[0].startsWith('*')
      ? rules.inline.emStrong.rDelimAst
      : rules.inline.emStrong.rDelimUnd
    endReg.lastIndex = 0

    // Clip maskedSrc to same section of string as src (move to lexer?)
    maskedSrc = maskedSrc.slice(-1 * src.length + lLength)
    while ((match = endReg.exec(maskedSrc)) !== null) {
      rDelim =
        match[1] || match[2] || match[3] || match[4] || match[5] || match[6]

      if (!rDelim) continue // skip single * in __abc*abc__

      rLength = rDelim.length

      if (match[3] || match[4]) {
        // formulaが中に入ってきた場合にうまく処理ができない問題の対処
        // 先頭が「!FORMULA」の場合処理を続行する
        if (!/!FORMULA.*/.exec(src)) {
          // found another Left Delim
          delimTotal += rLength
          continue
        }
      } else if (match[5] || match[6]) {
        // either Left or Right Delim
        if (lLength % 3 && !((lLength + rLength) % 3)) {
          midDelimTotal += rLength
          continue // CommonMark Emphasis Rules 9-10
        }
      }

      delimTotal -= rLength

      if (delimTotal > 0) continue // Haven't found enough closing delimiters

      // Remove extra characters. *a*** -> *a*
      rLength = Math.min(rLength, rLength + delimTotal + midDelimTotal)

      // Create `em` if smallest delimiter has odd char count. *a***
      if (Math.min(lLength, rLength) % 2) {
        const text = src.slice(1, lLength + match.index + rLength)
        return {
          type: 'em',
          raw: src.slice(0, lLength + match.index + rLength + 1),
          text,
          tokens: lexer.inlineTokens(text, []),
        }
      }

      // Create 'strong' if smallest delimiter has even char count. **a***
      const text = src.slice(2, lLength + match.index + rLength - 1)
      return {
        type: 'strong',
        raw: src.slice(0, lLength + match.index + rLength + 1),
        text,
        tokens: lexer.inlineTokens(text, []),
      }
    }
  }
  return null
}
