import { deleteField, increment } from 'firebase/firestore'
import { Button } from 'react-bootstrap'
import { Batch, TeamProfile, profileRef, teamMemberRef } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { isBlank } from '../../utils'

type Props = {
  teamProfile: TeamProfile
  invitationId?: string
}

export const TeamParticipateButton = ({ teamProfile, invitationId }: Props) => {
  const { user: currentUser, profile: currentUserProfile } = useAuthState()

  // チームメンバー情報取得中はボタンを表示しない
  if (isBlank(currentUserProfile)) return <></>

  const isParticipating = currentUserProfile.teams[teamProfile.id] !== undefined

  // 招待が要求される組織にはinvitationIdがない場合に「チームに参加」ボタンは出さない
  if (
    teamProfile.team_fields.required_invitation &&
    !isParticipating &&
    isBlank(invitationId)
  )
    return <></>

  // 現在がチームアカウント本体である場合は参加ボタンは出さない
  if (!currentUser || teamProfile.id === currentUser.uid) return <></>

  return (
    <Button
      variant={isParticipating ? 'outline-danger' : 'secondary'}
      size={'sm'}
      onClick={async () => {
        if (isParticipating) {
          const batch = new Batch()
          batch.update(profileRef(teamProfile.id), {
            'team_fields.members_count': increment(-1),
          })
          await batch.delete(teamMemberRef(teamProfile.id, currentUser.uid))
          batch.update(profileRef(currentUser.uid), {
            [`teams.${teamProfile.id}`]: deleteField(),
          })
          await batch.commit()
        } else {
          const batch = new Batch()
          batch.update(profileRef(teamProfile.id), {
            'team_fields.members_count': increment(1),
          })
          batch.update(profileRef(currentUser.uid), {
            [`teams.${teamProfile.id}`]: 'contributor' as const,
          })
          batch.create(teamMemberRef(teamProfile.id, currentUser.uid), {
            role: 'contributor',
            invitation_id: invitationId,
          })
          await batch.commit()
        }
      }}
    >
      {isParticipating ? 'チームから脱退' : 'チームに参加'}
    </Button>
  )
}
