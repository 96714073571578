const escapeTest = /(?:&(?!(?:amp;|lt;|gt;|quot;|#39;))|<|>|"|')/
const escapeReplace = /(?:&(?!(?:amp;|lt;|gt;|quot;|#39;))|<|>|"|')/g
const escapeTestNoEncode = /[<>"']|&(?!#?\w+;)/
const escapeReplaceNoEncode = /[<>"']|&(?!#?\w+;)/g
const escapeReplacements: Record<string, string> = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
}

const getEscapeReplacement = (ch: string) => escapeReplacements[ch]

export const escapeSpecialCharacters = (html: string, encode = false) => {
  if (encode) {
    if (escapeTest.test(html)) {
      return html.replace(escapeReplace, getEscapeReplacement)
    }
  } else {
    if (escapeTestNoEncode.test(html)) {
      return html.replace(escapeReplaceNoEncode, getEscapeReplacement)
    }
  }
  return html
}
