import { collection, doc, DocRef } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document } from '../document'
import { ChapterSubcols } from './subcollections'
import { Textbook, TextbookDraft } from './textbook'
import { WithUserTimestamp } from './user_timestamp'

export interface Chapter extends Document, WithUserTimestamp {
  readonly ref: DocRef<Chapter>
  readonly parent_id: 'chapters'
  readonly subcollections: typeof ChapterSubcols
  title: string
  summary: string
  order: number
}

export type ChaptersParent = Textbook | TextbookDraft
export const chapterConverter = getConverter<Chapter>(ChapterSubcols)
export const chaptersRef = (chaptersParent: DocRef<ChaptersParent>) =>
  collection<Chapter>(chaptersParent, 'chapters').withConverter(
    chapterConverter
  )
export const chapterRef = (
  chaptersParent: DocRef<ChaptersParent>,
  id: string
) => doc(chaptersRef(chaptersParent), id)
