import { QueryDocumentSnapshot } from 'firebase/firestore'
import { useCallback, useRef, useState } from 'react'
import { FollowingTag, Tag, getDoc, getDocs, tagRef } from '../../firebase'
import { sortedFollowingTagsRef } from '../../models/follow'

const FOLLOWING_TAGS_LIMIT = 20

export const useFollowingTags = (uid: string) => {
  const lastFollowingTag = useRef<QueryDocumentSnapshot<FollowingTag> | null>(
    null
  )
  const [followingTags, setFollowingTags] = useState<Tag[]>([])
  const [fetching, setFetching] = useState(false)
  const [isLast, setIsLast] = useState(false)

  const loadMore = useCallback(async () => {
    if (fetching) return
    if (isLast) return
    setFetching(true)
    const loadedFollowingTagsSnapshot = await getDocs(
      sortedFollowingTagsRef(
        uid,
        lastFollowingTag.current,
        FOLLOWING_TAGS_LIMIT
      )
    )
    const loadedFollowingTagDocs = loadedFollowingTagsSnapshot.docs
    const loadedFollowingTags = await Promise.all(
      loadedFollowingTagDocs.map(async (doc) => {
        const uid = doc.id
        const tagSnapshot = await getDoc(tagRef(uid))
        const tag = tagSnapshot.data()
        return tag
      })
    )

    const filteredLoadedFollowingTags = loadedFollowingTags.filter(
      (tag) => tag !== undefined
    ) as Tag[] // undefinedを除去したのでasを使って型を強制変更
    setFollowingTags([...followingTags, ...filteredLoadedFollowingTags])
    if (!loadedFollowingTagsSnapshot.empty) {
      lastFollowingTag.current = loadedFollowingTagDocs.slice(-1)[0]
    }
    if (loadedFollowingTagDocs.length < FOLLOWING_TAGS_LIMIT) {
      setIsLast(true)
    }
    setFetching(false)
  }, [isLast, followingTags, fetching])

  return { loadMore, followingTags, isLast, fetching }
}
