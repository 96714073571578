import * as renderer from '../../renderer/html'
import { Token } from '../../types'
import { parseInlineToHTML } from './inline'
import { parseListToHTML } from './list'
import { parseTableToHTML } from './table'

export const parseBlockToHTML = (tokens: Token[], top = true): string => {
  let out = ''
  for (let tokenIndex = 0; tokenIndex < tokens.length; tokenIndex++) {
    const token = tokens[tokenIndex]
    switch (token.type) {
      case 'space': {
        continue
      }
      case 'hr': {
        out += renderer.hr()
        continue
      }
      case 'heading': {
        out += renderer.heading(
          token.depth,
          parseInlineToHTML(token.tokens),
          token.text,
          token.label
        )
        continue
      }
      case 'code': {
        out += renderer.code(token.text, token.lang)
        continue
      }
      case 'table': {
        out += parseTableToHTML(token)
        continue
      }
      case 'blockquote': {
        const body = parseBlockToHTML(token.tokens)
        out += renderer.blockquote(body)
        continue
      }
      case 'list': {
        out += parseListToHTML(token)
        continue
      }
      case 'html': {
        out += parseInlineToHTML(token.tokens)
        continue
      }
      case 'paragraph': {
        out += renderer.paragraph(parseInlineToHTML(token.tokens))
        continue
      }
      case 'box': {
        out += renderer.box(
          token.boxType,
          parseInlineToHTML(token.titleTokens),
          parseBlockToHTML(token.tokens),
          token.title,
          token.label
        )
        continue
      }
      case 'text': {
        let body = token.tokens ? parseInlineToHTML(token.tokens) : token.text
        while (tokenIndex + 1 < tokens.length) {
          const nextToken = tokens[tokenIndex + 1]
          if (nextToken.type === 'text') {
            body +=
              '\n' +
              (nextToken.tokens
                ? parseInlineToHTML(nextToken.tokens)
                : token.text)
            tokenIndex++
          } else break
        }
        out += top ? renderer.paragraph(body) : body
        continue
      }

      default: {
        const errMsg = 'Token with "' + token.type + '" type was not found.'
        console.error(errMsg)
        return ''
      }
    }
  }

  return out
}
