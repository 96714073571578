import { collection, db, doc, DocRef } from '../../../../../firebase/alias'
import { getConverter } from '../../converter'
import { Document } from '../../document'
import { ProductSubcols } from './subcollections'

// 不完全 適宜追加
export interface Product extends Document {
  readonly parent_id: 'products'
  readonly subcollections: typeof ProductSubcols
  readonly ref: DocRef<Product>
}

export const productConverter = getConverter<Product>(ProductSubcols)
export const productsRef = collection<Product>(
  db,
  'products'
).withConverter<Product>(productConverter)
export const productRef = (uid: string) => doc(productsRef, uid)
