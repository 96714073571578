import { dynamicImportJS } from 'dynamic-import-assets'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { isBlank } from '../../../../utils'
import { mathjaxState } from '../global/atom'

type Props = {
  children: React.ReactNode
}

export const MathJaxLoader = ({ children }: Props) => {
  const [mathjax, setMathjax] = useRecoilState(mathjaxState)
  const router = useRouter()

  // atomによるグローバルの状態管理が翻訳処理を行うと維持できなくなるため，
  // routerが変わったごとに以下を行う
  useEffect(() => {
    // 既に読み込み済みでグローバルの状態に登録されている場合は何もしない
    if (mathjax.loaded) return
    if (!isBlank(window.MathJax)) return

    // OPTIONをMathJaxLoaderの外に出すとstartup.getComponents()で不具合が出るので注意
    const OPTIONS = {
      loader: {
        load: [
          '[custom]/xypic.js',
          '[tex]/noerrors',
          '[tex]/physics',
          'ui/safe',
        ],
        paths: {
          custom: 'https://cdn.jsdelivr.net/gh/sonoisa/XyJax-v3@3.0.0/build/',
        },
      },
      startup: {
        typeset: false,
        pageReady: () => {
          setMathjax((current) => {
            return { ...current, loaded: true }
          })
        },
      },
      tex: {
        packages: {
          '[+]': ['xypic', 'noerrors', 'physics'],
        },
        inlineMath: [['$', '$']],
        displayMath: [['$$', '$$']],
        processEscapes: true, // use \$ to produce a literal dollar sign
        processEnvironments: true, // process \begin{xxx}...\end{xxx} outside math mode
        processRefs: true, // process \ref{...} outside of math mode
      },
      svg: {
        scale: 1, // global scaling factor for all expressions
        minScale: 0.5, // smallest scaling factor to use
        mtextInheritFont: false, // true to make mtext elements use surrounding font
        merrorInheritFont: true, // true to make merror text use surrounding font
        mathmlSpacing: false, // true for MathML spacing rules, false for TeX rules
        skipAttributes: {}, // RFDa and other attributes NOT to copy to the output
        exFactor: 0.5, // default size of ex in em units
        displayAlign: 'left', // default for indentalign when set to 'auto'
        displayIndent: '0', // default for indentshift when set to 'auto'
        fontCache: 'local', // or 'global' or 'none'
        localID: null, // ID to use for local font cache (for single equation processing)
        internalSpeechTitles: true, // insert <title> tags with speech content
        titleID: 0, // initial id number to use for aria-labeledby titles
      },
    }

    // その他の場合はロードされていないので，MathJaxをロードする
    window.MathJax = OPTIONS
    void dynamicImportJS(
      'https://cdn.jsdelivr.net/npm/mathjax@3.2.0/es5/tex-svg-full.js'
    )
  }, [router])

  return <>{children}</>
}
