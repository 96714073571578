import { memo, MutableRefObject } from 'react'
import { Macro, Reference } from '../../../../firebase'
import { Designed } from '../../../../types'
import { Design } from '../types'
import { DynamicCompiler } from './DynamicCompiler'
import { StaticCompiler } from './StaticCompiler'

export type CompilerProps = Designed<{
  mathdown: string
  _ref?: MutableRefObject<HTMLDivElement | null>
  macros?: Macro[]
  references?: Reference[]
  design?: Design
}>

type Props = { dynamic?: boolean } & CompilerProps

const MathdownImpl = ({ dynamic = false, ...editorProps }: Props) => {
  return dynamic ? (
    <DynamicCompiler {...editorProps} />
  ) : (
    <StaticCompiler {...editorProps} />
  )
}

/**
 * dynamic == true: エディタなどの変更頻度が高いものに用いる(内部でmathdownを分割コンパイルする),
 * dynamic == false: 変更頻度が低いものに用いる
 */
export const Mathdown = memo(MathdownImpl)
