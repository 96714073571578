import { Tag } from '../../firebase'
import { ESTag } from '../../shared/elastic_search/scheme/tag'
import { Designed } from '../../types'
import { BlockTagItem } from './BlockItem'

type Props = {
  tags: Tag[] | ESTag[]
  card?: boolean
}

export const BlockTagList = ({
  tags,
  card = false,
  className = 'd-flex flex-column gap-3',
  style,
}: Designed<Props>) => (
  <div className={className} style={style}>
    {tags.map((tag) => (
      <BlockTagItem
        key={tag.id}
        tag={tag}
        className={card ? 'card p-2 m-0' : ''}
      />
    ))}
    {tags.length === 0 && <div>タグはありません。</div>}
  </div>
)
