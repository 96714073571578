import { LinkWrapper } from '../../../assets/LinkWrapper'
import { GeneralBookReference } from '../../../firebase'
import { getAmazonProductImageURL } from '../../../models/amazon'

type Props = {
  reference: GeneralBookReference
  link: boolean
  amazonBookImage: boolean
}

export const GeneralBookReferenceItem = ({
  reference,
  link,
  amazonBookImage,
}: Props) => {
  const title = [
    reference.author,
    reference.title,
    reference.series,
    reference.publisher,
    reference.published_year,
    reference.page,
  ]
    .filter((item) => item)
    .join(', ')
  const href =
    link &&
    reference.asin !== undefined &&
    process.env.NEXT_PUBLIC_ASSOCIATE_TRACKING_ID !== undefined
      ? `https://www.amazon.co.jp/dp/${reference.asin}/ref=nosim?tag=${process.env.NEXT_PUBLIC_ASSOCIATE_TRACKING_ID}`
      : null
  if ((!link && !amazonBookImage) || reference.asin === undefined) {
    return <>{title}</>
  }
  return (
    <div>
      <div className="text-break">
        <LinkWrapper href={href}>{title}</LinkWrapper>
      </div>
      {amazonBookImage && href !== null && (
        <div className="mt-1">
          <LinkWrapper href={href}>
            <img
              alt={title}
              src={getAmazonProductImageURL(reference.asin)}
              style={{
                display: 'inline',
                margin: '0',
                width: '150px',
              }}
            />
          </LinkWrapper>
        </div>
      )}
    </div>
  )
}
