import { collection, doc, DocRef } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document } from '../document'
import { accountRef } from './account'
import { OtherAccountSubcols } from './subcollections'

// 公式によるミュートは対応していない
export interface OtherAccount extends Document {
  readonly parent_id: 'other_accounts'
  readonly ref: DocRef<OtherAccount>
  readonly subcollections: typeof OtherAccountSubcols
}
export const otherAccountConverter =
  getConverter<OtherAccount>(OtherAccountSubcols)

export const otherAccountsRef = (uid: string) =>
  collection<OtherAccount>(accountRef(uid), 'other_accounts').withConverter(
    otherAccountConverter
  )

export const otherAccountRef = (uid: string, otherUid: string) =>
  doc(otherAccountsRef(uid), otherUid)
