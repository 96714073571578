import { RegexEditor } from '../utils/regex_editor'

const _text =
  /^(?:(?:\\\\|[\s\S]*?[^\\])(?:\\\\)*(?=token_beginning)|[\s\S]*?$)/
const _formulaTokenBeginning = '\\$\\$?|{|}|\\\\begin|\\\\end'
const _codeTokenBeginning = '\\n {4}|\\n?`'
const _htmlTokenBeginning = '<'

export const text = {
  global: new RegexEditor(_text)
    .replace('token_beginning', /formula|code|html/)
    .replace('formula', _formulaTokenBeginning)
    .replace('code', _codeTokenBeginning)
    .replace('html', _htmlTokenBeginning)
    .getRegex(),
  formula: new RegexEditor(_text)
    .replace('token_beginning', _formulaTokenBeginning)
    .getRegex(),
  html: new RegexEditor(_text)
    .replace('token_beginning', /formula|html/)
    .replace('formula', _formulaTokenBeginning)
    .replace('html', _htmlTokenBeginning)
    .getRegex(),
}
