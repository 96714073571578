import { useMemo } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { isActiveSubscription, subscriptionsRef } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'

export const useActiveSubscription = () => {
  const { user } = useAuthState()
  const [subscriptions, loading] = useCollectionData(
    user ? subscriptionsRef(user.uid) : null
  )
  const subscription = useMemo(() => {
    if (loading && subscriptions === undefined) return undefined
    return subscriptions?.filter(isActiveSubscription)[0] ?? null
  }, [subscriptions, loading])

  return subscription
}
