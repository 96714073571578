import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useAuthState } from '../../firebase/hooks/useAuthState'

// メールの確認が済んでいた場合に最後に拒否したページに遷移させる
export const useRequireNotConfirmation = () => {
  const { isLoading, isSignedIn, isEmailVerified } = useAuthState()
  const router = useRouter()

  useEffect(() => {
    if (isLoading) return
    if (isSignedIn === true && isEmailVerified === true) {
      router.back()
    }
  }, [isLoading])

  return { isLoading }
}
