import { FaUser } from 'react-icons/fa'
import { ESUser } from '../../elastic_search'
import { Profile } from '../../firebase'
import { Designed, PromiseVoid } from '../../types'
import { isBlank } from '../../utils'

type Props = {
  profile?: Profile | ESUser
  onClick?: () => PromiseVoid
  size: number | string
  imgStyle?: React.CSSProperties
}

export const UserImage = ({
  profile,
  size,
  onClick: handleClick,
  imgStyle,
  className,
  style,
}: Designed<Props>) => {
  const profileImageURL = profile?.image?.url
  return (
    <div
      className={className}
      onClick={handleClick}
      style={{ width: size, height: size, ...style }}
    >
      {profile && !isBlank(profileImageURL) ? (
        <img
          src={profileImageURL}
          width={size}
          height={size}
          alt={profile.display_name}
          style={imgStyle}
        />
      ) : (
        <FaUser className="text-muted" style={{ width: size, height: size }} />
      )}
    </div>
  )
}
