import { useEffect, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import {
  CreateParamsWithoutFieldValue,
  PaperReference,
} from '../../../firebase'
import { remove } from '../../../utils'
import { ActionButtons } from './ActionButtons'
import { EachReferenceFormProps } from './ReferenceForm'

export const PaperForm = ({
  onClickSaveButton: handleClickSaveButton,
  onClickCancelButton: handleClickCancelButton,
  reference,
  nextOrder,
}: EachReferenceFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    register,
    getValues,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<CreateParamsWithoutFieldValue<PaperReference>>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const resetWithDefaultValues = () => reset({ type: 'paper' })

  useEffect(() => {
    if (reference && reference.type === 'paper')
      reset(remove(reference, ['id', 'parent_id', 'ref']))
    else resetWithDefaultValues()
  }, [reference])

  return (
    <Form>
      <div className="h6 text-danger">必須</div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>著者</InputGroup.Text>
          <Form.Control
            {...register('author', {
              required: true,
            })}
            isInvalid={!!errors.author}
          />
        </InputGroup>
      </div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>タイトル</InputGroup.Text>
          <Form.Control
            {...register('title', {
              required: true,
            })}
            isInvalid={!!errors.title}
          />
        </InputGroup>
      </div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>雑誌名</InputGroup.Text>
          <Form.Control
            {...register('journal', {
              required: true,
            })}
            isInvalid={!!errors.journal}
          />
        </InputGroup>
      </div>
      <div className="h6">任意</div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>ラベル</InputGroup.Text>
          <Form.Control {...register('label')} isInvalid={!!errors.label} />
        </InputGroup>
        <Form.Text>
          本文で<code>[[ラベル]]</code>
          で参考文献の番号を使えます。
        </Form.Text>
      </div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>DOI</InputGroup.Text>
          <Form.Control {...register('doi')} isInvalid={!!errors.doi} />
        </InputGroup>
      </div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>ページ</InputGroup.Text>
          <Form.Control {...register('page')} isInvalid={!!errors.page} />
        </InputGroup>
      </div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>巻</InputGroup.Text>
          <Form.Control
            type="number"
            {...register('volume')}
            isInvalid={!!errors.volume}
          />
        </InputGroup>
      </div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>号</InputGroup.Text>
          <Form.Control
            type="number"
            {...register('number')}
            isInvalid={!!errors.number}
          />
        </InputGroup>
      </div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>出版年</InputGroup.Text>
          <Form.Control
            type="number"
            {...register('published_year')}
            min={1800}
            max={new Date().getFullYear()}
            isInvalid={!!errors.published_year}
          />
        </InputGroup>
      </div>
      <ActionButtons
        onClickCancelButton={async () => {
          resetWithDefaultValues()
          await handleClickCancelButton()
        }}
        onClickSaveButton={async () => {
          setIsSubmitting(true)
          const params = getValues()
          if (!reference) params.order = nextOrder
          await handleClickSaveButton(params)
          setIsSubmitting(false)
          resetWithDefaultValues()
        }}
        saveButtonLoading={isSubmitting}
        saveButtonDisabled={!isValid}
        cancelButtonDisabled={!isDirty && !reference}
        isUpdate={!!reference}
      />
    </Form>
  )
}
