import { QueryDocumentSnapshot } from 'firebase/firestore'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import {
  EvaluableRefAssignable,
  GoodEvaluator,
  Profile,
  ValidDocumentWithEvaluation,
  getDoc,
  getDocs,
  profileRef,
} from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { sortedGoodEvaluatorsRef } from '../../models/evaluation'
import { useGood } from './useGood'

const GOOD_EVALUATORS_LIMIT = 20

export const useGoodEvaluators = <T extends ValidDocumentWithEvaluation>(
  evaluableRef: EvaluableRefAssignable<T>
) => {
  const lastGoodEvaluator = useRef<QueryDocumentSnapshot<GoodEvaluator> | null>(
    null
  )
  const [goodEvaluators, setGoodEvaluators] = useState<Profile[]>([])
  const [fetching, setFetching] = useState(false)
  const [isLast, setIsLast] = useState(false)
  const { isGood } = useGood(evaluableRef)
  const { user } = useAuthState()
  const [profile] = useDocumentDataOnce(user ? profileRef(user.uid) : null)

  useEffect(() => {
    if (!user) return
    if (
      isGood === true &&
      profile &&
      (goodEvaluators.length === 0 || goodEvaluators[0].id !== user.uid)
    ) {
      setGoodEvaluators([profile, ...goodEvaluators])
    } else if (isGood === false && goodEvaluators[0]?.id === user.uid) {
      setGoodEvaluators(goodEvaluators.slice(1))
    }
  }, [isGood, user, profile])

  const loadMore = useCallback(async () => {
    if (fetching) return
    if (isLast) return
    setFetching(true)
    const loadedGoodEvaluatorsSnapshot = await getDocs(
      sortedGoodEvaluatorsRef(
        evaluableRef,
        lastGoodEvaluator.current,
        GOOD_EVALUATORS_LIMIT
      )
    )
    const loadedGoodEvaluatorDocs = loadedGoodEvaluatorsSnapshot.docs
    const loadedGoodEvaluators = await Promise.all(
      loadedGoodEvaluatorDocs.map(async (doc) => {
        const uid = doc.id
        const profileSnapshot = await getDoc(profileRef(uid))
        const profile = profileSnapshot.data()
        return profile
      })
    )

    const filteredLoadedGoodEvaluators = loadedGoodEvaluators.filter(
      (profile) => profile !== undefined && profile.id !== user?.uid
    ) as Profile[] // undefinedを除去したのでasを使って型を強制変更
    setGoodEvaluators([...goodEvaluators, ...filteredLoadedGoodEvaluators])
    if (!loadedGoodEvaluatorsSnapshot.empty) {
      lastGoodEvaluator.current = loadedGoodEvaluatorDocs.slice(-1)[0]
    }
    if (loadedGoodEvaluatorDocs.length < GOOD_EVALUATORS_LIMIT) {
      setIsLast(true)
    }
    setFetching(false)
  }, [isLast, goodEvaluators, fetching])

  return { loadMore, goodEvaluators, isLast, fetching }
}
