import { useMedia as useOriginalMedia } from 'use-media'

const SM_MAX_WIDTH = 540
const MD_MAX_WIDTH = 768
const LG_MAX_WIDTH = 992
const XL_MAX_WIDTH = 1200
const XXL_MAX_WIDTH = 1400

// Bootstrapのブレイクポイントを使えるように拡張したuseMedia
// 標準のBootstrapのクラスで対応できる場合はなるべく使わないこと（描画されたから動くのでレイアウトがちらつく）
export const useMedia = (
  rawQuery:
    | string
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | 'xxl'
    | Record<string, string | number | boolean>,
  defaultState?: boolean
) => {
  let query = rawQuery
  switch (rawQuery) {
    case 'sm':
      query = { minWidth: SM_MAX_WIDTH }
      break
    case 'md':
      query = { minWidth: MD_MAX_WIDTH }
      break
    case 'lg':
      query = { minWidth: LG_MAX_WIDTH }
      break
    case 'xl':
      query = { minWidth: XL_MAX_WIDTH }
      break
    case 'xxl':
      query = { minWidth: XXL_MAX_WIDTH }
      break
    default:
      // defaultの場合は処理せずそのまま使う
      break
  }
  return useOriginalMedia(query, defaultState)
}
