import { MutableRefObject, useContext, useEffect, useState } from 'react'
import { Reference, TermWithGlossaryInfo } from '../../../../firebase'
import { APP_NAME, MATHPEDIA_HOST } from '../../../../utils'
import { TermsContext } from '../../config'

type Box = {
  type: string
  title: string | null
  label: string | null
  number: number
}

const boxList: Record<string, { ja: string }> = {
  conj: { ja: '予想' },
  axm: { ja: '公理' },
  def: { ja: '定義' },
  prop: { ja: '命題' },
  fml: { ja: '公式' },
  lem: { ja: '補題' },
  thm: { ja: '定理' },
  cor: { ja: '系' },
  prf: { ja: '証明' },
  ex: { ja: '例' },
  exc: { ja: '問題' },
  rem: { ja: '注意' },
}

const buildShortenedLinks = (
  elm: HTMLDivElement,
  terms: TermWithGlossaryInfo[],
  references?: Reference[]
) => {
  const boxes: Box[] = []
  const boxElements = elm.querySelectorAll('.box')
  boxElements.forEach((boxElement) => {
    const type = boxElement.getAttribute('data-type') ?? 'normal'
    const title = boxElement.getAttribute('data-title')
    const label = boxElement.getAttribute('data-label')
    boxes.push({
      type,
      title,
      label,
      number: boxes.filter((b) => b.type === type).length + 1,
    })
  })

  // 各短縮リンクを展開
  const shortenedLinkElements = elm.querySelectorAll('.shortened-link')
  shortenedLinkElements.forEach((shortenedLinkElement) => {
    const rawTitle = shortenedLinkElement.getAttribute('data-title')

    // 用語の解析
    const term = terms.find((term) => term.display === rawTitle)
    /// mathpediaの公開が終わるまでMathlogは用語を展開しない
    if (term && APP_NAME !== 'mathlog') {
      // shortenedLinkElement.setAttribute('target', `_blank`) // mathlogの時は外部リンクで開く
      shortenedLinkElement.setAttribute(
        'href',
        `${MATHPEDIA_HOST}/glossaries/${term.glossary_id}`
      )
      return
    }

    // 参考文献の解析
    if (references) {
      const referenceIndex = references.findIndex(
        (reference) => reference.label === rawTitle
      )
      if (referenceIndex !== -1) {
        const reference = references[referenceIndex]
        if (reference.label === undefined) return
        shortenedLinkElement.setAttribute('href', `#${reference.label}`)
        shortenedLinkElement.innerHTML = `[${referenceIndex + 1}]`
        return
      }
    }

    // ボックスの解析
    const box = boxes.find(
      (box) => box.title === rawTitle || box.label === rawTitle
    )
    const localizedBoxType = box ? boxList[box.type] : undefined
    if (box && localizedBoxType) {
      if (rawTitle !== null)
        shortenedLinkElement.setAttribute('href', `#${rawTitle}`)
      shortenedLinkElement.innerHTML = `${localizedBoxType.ja}${box.number}`
      return
    }

    shortenedLinkElement.setAttribute('class', 'text-black')
  })
}

export const useShortenedLinksBuilder = (
  ref: MutableRefObject<HTMLDivElement | null>,
  references?: Reference[]
) => {
  const terms = useContext(TermsContext)
  const [waitingBuild, setWaitingBuild] = useState(true)

  useEffect(() => {
    if (!terms || !waitingBuild) return
    if (!ref.current) throw new Error('ref is null.')
    setWaitingBuild(false)
    buildShortenedLinks(ref.current, terms, references)
  }, [terms, references, waitingBuild])

  const rebuild = () => setWaitingBuild(true)
  return { rebuild }
}
