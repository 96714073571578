import { Lexer } from '..'
import * as Tokenizer from '../../tokenizer/block'
import type { TexImage, Token } from '../../types'

export class BlockLexer {
  private lexer: Lexer
  private src = ''
  private tokens: Token[] = []
  private labels: Map<string, string>
  private images: Map<string, TexImage>
  constructor(
    lexer: Lexer,
    labels: Map<string, string>,
    images: Map<string, TexImage>
  ) {
    this.lexer = lexer
    this.labels = labels
    this.images = images
  }

  private get lastToken() {
    return this.tokens[this.tokens.length - 1]
  }

  lex(src: string, tokens: Token[]) {
    this.tokens = tokens
    this.src = src
    while (this.src) {
      if (this.eatSpace()) continue
      if (this.eatCode()) continue
      if (this.eatBox()) continue
      if (this.eatFences()) continue
      if (this.eatHeading()) continue
      if (this.eatHr()) continue
      if (this.eatBlockquote()) continue
      if (this.eatList()) continue
      if (this.eatList()) continue
      if (this.eatHtml()) continue
      if (this.eatDef()) continue
      if (this.eatTable()) continue
      if (this.eatLheading()) continue
      if (this.eatParagraph()) continue
      if (this.eatText()) continue
      if (this.src) {
        const errMsg = `Infinite loop on byte: ${this.src.charCodeAt(0)}`
        console.error(errMsg)
      }
    }
    this.lexer.state.top = true
    return this.tokens
  }

  private eat(token: Token | null) {
    if (token) {
      this.src = this.src.substring(token.raw.length)
      this.tokens.push(token)
    }
    return !!token
  }

  private eatSpace() {
    const token = Tokenizer.space(this.src)
    if (token) {
      this.src = this.src.substring(token.raw.length)
      if (token.raw.length === 1 && this.tokens.length > 0) {
        // if there's a single \n as a spacer, it's terminating the last line,
        // so move it there so that we don't get unecessary paragraph tags
        this.lastToken.raw += '\n'
      } else {
        this.tokens.push(token)
      }
    }
    return !!token
  }

  private eatCode() {
    const token = Tokenizer.code(this.src)
    if (token) {
      this.src = this.src.substring(token.raw.length)
      // An indented code block cannot interrupt a paragraph.
      if (
        this.lastToken !== undefined &&
        (this.lastToken.type === 'paragraph' || this.lastToken.type === 'text')
      ) {
        this.lastToken.raw += '\n' + token.raw
        this.lastToken.text += '\n' + token.text
        this.lexer.inlineQueue[this.lexer.inlineQueue.length - 1].src =
          this.lastToken.text
      } else this.tokens.push(token)
    }
    return !!token
  }

  private eatBox() {
    const token = Tokenizer.box(this.src, this.lexer, this.labels)
    return this.eat(token)
  }

  private eatFences() {
    const token = Tokenizer.fences(this.src)
    return this.eat(token)
  }

  private eatHeading() {
    const token = Tokenizer.heading(this.src, this.lexer)
    return this.eat(token)
  }

  private eatHr() {
    const token = Tokenizer.hr(this.src)
    return this.eat(token)
  }

  private eatBlockquote() {
    const token = Tokenizer.blockquote(this.src, this.lexer)
    return this.eat(token)
  }

  private eatList() {
    const token = Tokenizer.list(this.src, this.lexer)
    return this.eat(token)
  }

  private eatHtml() {
    const token = Tokenizer.html(this.src, this.lexer, this.lexer.htmlList)
    return this.eat(token)
  }

  private eatDef() {
    const token = Tokenizer.def(this.src)
    if (token) {
      this.src = this.src.substring(token.raw.length)
      if (
        this.lastToken !== undefined &&
        (this.lastToken.type === 'paragraph' || this.lastToken.type === 'text')
      ) {
        this.lastToken.raw += '\n' + token.raw
        this.lastToken.text += '\n' + token.raw
        this.lexer.inlineQueue[this.lexer.inlineQueue.length - 1].src =
          this.lastToken.text
      } else if (!this.lexer.links[token.tag]) {
        this.lexer.links[token.tag] = {
          href: token.href,
          title: token.title,
        }
      }
    }
    return !!token
  }

  private eatTable() {
    const token = Tokenizer.table(this.src, this.lexer)
    return this.eat(token)
  }

  private eatLheading() {
    const token = Tokenizer.lheading(this.src, this.lexer)
    return this.eat(token)
  }

  private eatParagraph() {
    if (!this.lexer.state.top) return false
    const token = Tokenizer.paragraph(this.src, this.lexer)
    return this.eat(token)
  }

  private eatText() {
    const token = Tokenizer.text(this.src, this.lexer)
    if (token) {
      this.src = this.src.substring(token.raw.length)
      if (this.lastToken !== undefined && this.lastToken.type === 'text') {
        this.lastToken.raw += '\n' + token.raw
        this.lastToken.text += '\n' + token.text
        this.lexer.inlineQueue.pop()
        this.lexer.inlineQueue[this.lexer.inlineQueue.length - 1].src =
          this.lastToken.text
      } else this.tokens.push(token)
    }
    return !!token
  }
}
