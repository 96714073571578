import { Lexer } from '../../lexer'
import { rules } from '../../rules'
import type { Tokens } from '../../types'
import { escapeSpecialCharacters } from '../utils'

export const text = (src: string, lexer: Lexer): Tokens.Text | null => {
  const cap = rules.inline.text.exec(src)
  if (!cap) return null
  const text = lexer.state.inRawBlock ? cap[0] : escapeSpecialCharacters(cap[0])
  return {
    type: 'text',
    raw: cap[0],
    text,
  }
}
