import { LinkWrapper } from '../../../assets/LinkWrapper'
import { WebsiteReference } from '../../../firebase'

type Props = {
  reference: WebsiteReference
  link: boolean
}

export const WebsiteReferenceItem = ({ reference, link }: Props) => {
  const accessedOnDate = reference.accessed_on.toDate()
  const accessedOnStr = `${accessedOnDate.getFullYear()}年${
    accessedOnDate.getMonth() + 1
  }月${accessedOnDate.getDate()}日`
  const title = [
    reference.author,
    reference.title,
    reference.name,
    `閲覧日 ${accessedOnStr}`,
    reference.url,
  ]
    .filter((item) => item)
    .join(', ')
  return (
    <LinkWrapper href={link ? reference.url : null}>
      <div className="text-break">{title}</div>
    </LinkWrapper>
  )
}
