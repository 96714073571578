import {
  collection,
  db,
  doc,
  DocRef,
  Timestamp,
} from '../../../../firebase/alias'
import { isBlank } from '../../../utils'
import { getConverter } from '../converter'
import { Document } from '../document'
import { Profile } from './profile'
import { SeriesSubcols } from './subcollections'
import { WithUserTimestamp } from './user_timestamp'

type Thumbnail = { url: string; filename: string; full_path: string }

export interface Series extends Document, WithUserTimestamp {
  readonly ref: DocRef<Series>
  readonly parent_id: 'series'
  readonly subcollections: typeof SeriesSubcols
  title: string
  description: string

  thumbnail?: Thumbnail // Cloud Storage path : "/uploads/book_thumbnails/{id}.{png/jpeg}"

  article_ids: string[]

  created_by: string

  addability: 'limited' | 'public'
  addable_uids: string[]
  addable_for_members: boolean

  last_added_at?: Timestamp
}

export const seriesConverter = getConverter<Series>(SeriesSubcols)

// seriesは単数系も複数形も同じなので、ColとDocを変数名に含めている
export const seriesColRef = collection<Series>(db, 'series').withConverter(
  seriesConverter
)
export const seriesDocRef = (id: string) => doc(seriesColRef, id)

export const isAddableArticlesToSeries = (
  currentUserProfile: Profile | null | undefined,
  series: Series
) => {
  if (isBlank(currentUserProfile)) return false

  // 自分自身がシリーズの所有者の場合
  if (series.created_by === currentUserProfile.id) return true

  // シリーズの所有者がチームで現在のユーザがチームの管理者の場合
  if (currentUserProfile.teams[series.created_by] === 'admin') return true

  // シリーズ公開版の場合
  if (series.addability === 'public') return true

  // チームメンバー全員に追加権限が付与されている場合
  if (
    series.addability === 'limited' &&
    series.addable_for_members &&
    currentUserProfile.teams[series.created_by] === 'contributor'
  )
    return true

  // ユーザ全員に追加権限が付与されている場合
  if (
    series.addability === 'limited' &&
    series.addable_uids.includes(currentUserProfile.id)
  )
    return true

  return false
}
