import {
  arrayUnion,
  getDoc,
  orderBy,
  query,
  updateDoc,
} from 'firebase/firestore'
import {
  Account,
  ArticleDraft,
  BookDraft,
  Draft,
  articleRef,
  bookRef,
  draftsRef,
} from '../firebase'
import { canEditArticle } from './article'
import { canEditBook } from './book'

export const orderedDraftsRef = (parentId: Draft['parent_id'], uid: string) =>
  query(draftsRef(parentId, uid), orderBy('updated_by_user_at', 'desc'))

export const canEditDraft = async (draft: Draft, account: Account) => {
  if (draft.parent_id === 'article_drafts')
    return canEditArticleDraft(draft, account)
  if (draft.parent_id === 'book_drafts') return canEditBookDraft(draft, account)
  return true
}

const canEditArticleDraft = async (draft: ArticleDraft, account: Account) => {
  if (draft.publication_id === undefined) return true
  const articleSnapshot = await getDoc(articleRef(draft.publication_id))
  const article = articleSnapshot.data()
  if (!articleSnapshot.exists() || article === undefined) return true
  return canEditArticle(article, account)
}

const canEditBookDraft = async (draft: BookDraft, account: Account) => {
  if (draft.publication_id === undefined) return true
  const bookSnapshot = await getDoc(bookRef(draft.publication_id))
  const book = bookSnapshot.data()
  if (!bookSnapshot.exists() || book === undefined) return true
  return canEditBook(book, account)
}

// 共同編集者の登録
export const addCollaborator = async (
  draft: ArticleDraft | BookDraft,
  id: string
) => {
  await updateDoc<ArticleDraft | BookDraft>(draft.ref, {
    collaborator_uids: arrayUnion(id),
  })
}
