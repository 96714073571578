import { useEffect, useState } from 'react'

export const useOS = () => {
  const [os, setOS] = useState<'windows' | 'android' | 'ios' | 'mac' | null>(
    null
  )
  useEffect(() => {
    const ua = window.navigator.userAgent.toLowerCase()
    if (ua.indexOf('windows nt') !== -1) {
      setOS('windows')
    } else if (ua.indexOf('android') !== -1) {
      setOS('android')
    } else if (ua.indexOf('iphone') !== -1 || ua.indexOf('ipad') !== -1) {
      setOS('ios')
    } else if (ua.indexOf('mac os x') !== -1) {
      setOS('mac')
    }
  }, [])
  return os
}
