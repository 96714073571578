import { useRouter } from 'next/router'
import { useMemo } from 'react'

// 同一ページでクエリが変わった場合は変わる
export const useQueryData = (paramName = 'id') => {
  const router = useRouter()
  const data = useMemo(() => {
    const data = router.query[paramName]
    return router.isReady ? (typeof data === 'string' ? data : null) : undefined
  }, [router.isReady, router.query, paramName])
  return data
}

// 同一ページでクエリが変わっても値が変わらない
export const useStaticQueryData = (paramName = 'id') => {
  const router = useRouter()
  const data = useMemo(() => {
    const data = router.query[paramName]
    return router.isReady ? (typeof data === 'string' ? data : null) : undefined
  }, [router.isReady, paramName])
  return data
}
