import { collection, doc, DocRef, Timestamp } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document, ParentRefAssignable, ValidDocument } from '../document'
import { ReferenceSubcols } from './subcollections'

export interface ReferenceBase extends Document {
  readonly ref: DocRef<Reference>
  readonly parent_id: 'references'
  readonly subcollections: typeof ReferenceSubcols
  order: number
  label?: string
}

export interface PaperReference extends ReferenceBase {
  type: 'paper'
  title: string
  author: string
  journal: string
  published_year?: string
  page?: string
  volume?: string
  number?: string
  doi?: string
}

export interface WebsiteReference extends ReferenceBase {
  type: 'website'
  title: string
  url: string
  accessed_on: Timestamp
  name?: string
  author?: string
  published_on?: Timestamp
}

export interface GeneralBookReference extends ReferenceBase {
  type: 'general_book'
  title: string
  author: string
  page?: string
  published_year?: string
  publisher?: string
  series?: string
  asin?: string
}

export interface MathlogArticleReference extends ReferenceBase {
  type: 'mathlog_article'
  article_id: string
}

export type Reference =
  | PaperReference
  | WebsiteReference
  | GeneralBookReference
  | MathlogArticleReference

export type ReferencesParentRefAssignable<T extends ValidDocument> =
  ParentRefAssignable<Reference, T>

export const referenceConverter = getConverter<Reference>(ReferenceSubcols)
export const referencesRef = <T extends ValidDocument>(
  parentRef: ReferencesParentRefAssignable<T>
) =>
  collection<Reference>(parentRef, 'references').withConverter(
    referenceConverter
  )
export const referenceRef = <T extends ValidDocument>(
  parentRef: ReferencesParentRefAssignable<T>,
  id: string
) => doc(referencesRef(parentRef), id)
