import { collection, db, doc, DocRef } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document } from '../document'
import { AnnouncementSubcols } from './subcollections'

export interface Announcement extends Document {
  readonly parent_id: 'announcements'
  readonly ref: DocRef<Announcement>
  readonly subcollections: typeof AnnouncementSubcols
}

export const announcementConverter =
  getConverter<Announcement>(AnnouncementSubcols)
export const announcementsRef = collection<Announcement>(
  db,
  'announcements'
).withConverter<Announcement>(announcementConverter)
export const announcementRef = (uid: string) => doc(announcementsRef, uid)
