import { Lexer } from '../../lexer'
import { rules } from '../../rules'
import type { Tokens } from '../../types'

export const blockquote = (
  src: string,
  lexer: Lexer
): Tokens.Blockquote | null => {
  const cap = rules.block.blockquote.exec(src)
  if (!cap) return null
  const text = cap[0].replace(/^ *> ?/gm, '')
  return {
    type: 'blockquote',
    raw: cap[0],
    tokens: lexer.blockTokens(text, []),
    text,
  }
}
