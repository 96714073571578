export const HELP_NOTION_LINKS = {
  ROOT: 'https://mathlog.notion.site/Mathlog-670fbb55bf1d4aeb89c41afa42dba068',
  SIGN_UP: 'https://mathlog.notion.site/1657f80f3e4a436297415027768804a6',
  LOGIN: 'https://mathlog.notion.site/7afc37a9a67d43bd8920896da3fe9da7',
  PROFILE_SETTING:
    'https://mathlog.notion.site/31828fd392234030881dbdfb706f8262',
  ACCOUNT_SETTING:
    'https://mathlog.notion.site/77ae38805d75498ba7ee15dc2237ebf6',
  SEARCH_CONTENT:
    'https://mathlog.notion.site/602c6297b09048ed996086e8e1a9847a',
  TIMELINE: 'https://mathlog.notion.site/3c60a485dd1f43adb3b015173136a3ae',
  RANKING: 'https://mathlog.notion.site/55edc9fbcbe947708dda37f668e3f91d',
  FOLLOW_FUNCTION:
    'https://mathlog.notion.site/a924c4cfa34e49f68f65cf62b1e855c2',
  LIKE_FUNCTION: 'https://mathlog.notion.site/c2089465a4b741319fc50e70c165d3bd',
  BOOKMARK_FUNCTION:
    'https://mathlog.notion.site/9805650b360c494a86fb195043609c82',
  COMMENT_FUNCTION:
    'https://mathlog.notion.site/789cda8eed1f41ed8e5d00dc5f654d67',
  SUPPORT_FOR_CREATORS:
    'https://mathlog.notion.site/553b8ef52dd144df945da1b656a1c4bd',
  MUTE_FUNCTION: 'https://mathlog.notion.site/51c88bc5bcef4c459864469a2b42cff8',
  CONTENT_REPORTING:
    'https://mathlog.notion.site/a76805f505764a0995de80b7a8973f8b',
  EDITOR_USAGE: 'https://mathlog.notion.site/d42d852f90884f0397b52952dbaea71e',
  ARTICLE_WRITING:
    'https://mathlog.notion.site/854bdc34e8db41529066a01208a88ded',
  BOOK_WRITING: 'https://mathlog.notion.site/6a87d86aed764dcebe4ef08735de2a81',
  REFERENCES_SETTING:
    'https://mathlog.notion.site/d1572375e06c4250bf653b778365727d',
  MACROS_SETTING:
    'https://mathlog.notion.site/f536b27df9904f228e456a05716f0ff9',
  PACKAGES_SETTING:
    'https://mathlog.notion.site/8095d474c65749139b06c3abc60086b0',
  LATEX_EXPORT:
    'https://mathlog.notion.site/LaTeX-9b121b7b27b34de6b52559fe72160252',
  COLLABORATIVE_EDIT:
    'https://mathlog.notion.site/20afadfc80154ce987053b12c85e3e80',
  VISIBILITY_SELECT:
    'https://mathlog.notion.site/2fab7f727c4248f69be66f53118d5408',
  AD_REMOVE: 'https://mathlog.notion.site/8aaf4538adc240c18b4d8e0b817c4a21',
  PAST_INQUIRES:
    'https://mathlog.notion.site/ce26467abce94264844846288747f82b?pvs=4',
}

export const TEAM_ACCOUNT_APPLICATION_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSdz2mdcG0MnW2Ol_kq-NTBWmtujbdVskZ3LRSmS1RLz4579zg/viewform?usp=sf_link'
