import { useMemo } from 'react'
import {
  useCollectionData,
  useCollectionDataOnce,
} from 'react-firebase-hooks/firestore'
import {
  Macro,
  MacrosParentRefAssignable,
  macrosRef,
  ValidDocument,
} from '../../firebase'

export const useMacros = <T extends ValidDocument>(
  parentRef?: MacrosParentRefAssignable<T>,
  once = false,
  defaultMacros?: Macro[]
) => {
  const [listenedMacros, listenedLoading, listenedError] = useCollectionData(
    parentRef && !once ? macrosRef(parentRef) : null,
    { initialValue: defaultMacros }
  )
  const [onceMacros, onceLoading, onceError] = useCollectionDataOnce(
    parentRef && once ? macrosRef(parentRef) : null,
    { initialValue: defaultMacros }
  )
  const macros = once ? onceMacros : listenedMacros
  const loading = once ? onceLoading : listenedLoading
  const error = once ? onceError : listenedError
  const sortedMacros = useMemo(() => {
    return macros
      ? macros.sort((a, b) =>
          a.command_name.toLowerCase() < b.command_name.toLowerCase() ? -1 : 1
        )
      : undefined
  }, [macros])
  return { macros: sortedMacros, loading, error }
}
