import { Lexer } from '../../lexer'
import { rules } from '../../rules'
import type { Tokens } from '../../types'

export const lheading = (src: string, lexer: Lexer): Tokens.Heading | null => {
  const cap = rules.block.lheading.exec(src)
  if (!cap) return null
  const token: Tokens.Heading = {
    type: 'heading',
    raw: cap[0],
    depth: cap[2].startsWith('=') ? 1 : 2,
    text: cap[1],
    tokens: [],
  }
  lexer.inline(token.text, token.tokens)
  return token
}
