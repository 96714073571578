import { collection, doc, DocRef } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document } from '../document'
import { accountRef } from './account'
import { PackageSubcols } from './subcollections'

export interface Package extends Document {
  readonly ref: DocRef<Package>
  readonly parent_id: 'packages'
  readonly subcollections: typeof PackageSubcols
  name: string
  is_active: boolean
}

export const packageConverter = getConverter<Package>(PackageSubcols)
export const packagesRef = (uid: string) =>
  collection<Package>(accountRef(uid), 'packages').withConverter(
    packageConverter
  )
export const packageRef = (uid: string, id: string) => doc(packagesRef(uid), id)
