import { setUserProperties } from 'firebase/analytics'
import { createContext, useContext, useEffect } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { Account, accountRef, fb } from '../firebase'
import { useAuthState } from '../firebase/hooks'

export const AccountContext = createContext<{
  account: Account | null | undefined
}>({
  account: undefined,
})

export const AccountProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { user } = useAuthState()
  const [account] = useDocumentData(user ? accountRef(user.uid) : null)
  useEffect(() => {
    const userType = account
      ? account.plan === 'Premium'
        ? account.plan_interval === 'monthly'
          ? 'monthly_premium'
          : 'yearly_premium'
        : 'free'
      : 'guest'
    setUserProperties(fb.analytics, { user_type: userType })
  }, [account])
  return (
    <AccountContext.Provider value={{ account: user ? account : user }}>
      {children}
    </AccountContext.Provider>
  )
}

export const useAccount = () => useContext(AccountContext)
