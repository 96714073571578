import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { CompilerProps } from '.'
import { useDelayedEffect } from '../../../../hooks'
import { useMathJaxRenderer } from '../..//mathjax'
import { useShortenedLinksBuilder } from '../hooks/useShortenedLinksBuilder'
import { Lexer } from '../lexer'
import { parseBlockToHTML } from '../parser/html'
import { Token } from '../types'
import {
  FormulaeCache,
  KeyGenerator,
  compileMacrosToHTML,
  sanitize,
} from '../utils'

const getNumOfNewlines = (tokenRaw: string) => {
  let num = tokenRaw.split('\n').length - 1
  let cap: RegExpExecArray | null
  const formulaReg = /!FORMULA\[\d+\]\[-?\d+\]\[(\d+)\]/g
  while ((cap = formulaReg.exec(tokenRaw))) {
    num += Number(cap[1])
  }
  const htmlReg = /!HTML\[\d+\]\[-?\d+\]\[(\d+)\]/g
  while ((cap = htmlReg.exec(tokenRaw))) {
    num += Number(cap[1])
  }
  return num
}

type BlockTokenParserProps = {
  token: Token
}

const BlockTokenParserImpl = ({ token }: BlockTokenParserProps) => {
  const numOfNewlines = getNumOfNewlines(token.raw)
  return (
    <div
      className="block-token-container"
      dangerouslySetInnerHTML={{ __html: sanitize(parseBlockToHTML([token])) }}
      data-line={numOfNewlines}
      data-token-type={token.type}
    />
  )
}

const BlockTokenParser = memo(
  BlockTokenParserImpl,
  (prev, next) => prev.token.raw === next.token.raw
)

const lex = (mathdown: string, formulaeCache: FormulaeCache) => {
  const tokens = Lexer.lex(mathdown, formulaeCache)
  return tokens
}

const CACHE_SIZE = 100000

const DynamicCompilerImpl = ({
  _ref: providedRef,
  mathdown: providedMathdown,
  className,
  style,
  macros,
  references,
  design,
}: CompilerProps) => {
  const compiledMacrosRef = useRef<HTMLDivElement>(null)

  const [mathdown, setMathdown] = useState(providedMathdown)
  useDelayedEffect(() => {
    setMathdown(providedMathdown)

    // compiledMacrosが描画されている要素は、mathdownが変化してもコンパイル済み状態のままになってしまい、
    // 次の描画時にマクロが読み込まれず、適切に反映されなくなってしまう
    // そのため、mathdownを書き換えるときにマクロが描画されている要素を強制的に書き換える
    if (compiledMacrosRef.current === null) return
    compiledMacrosRef.current.innerHTML = compiledMacros
  }, [providedMathdown])

  const [compiledMacros, setCompiledMacros] = useState(
    compileMacrosToHTML(macros ?? [])
  )
  useEffect(() => {
    formulaeCache.current.clear() // マクロが変わったら数式のキャッシュを削除
    kg.current.reset()
    setCompiledMacros(compileMacrosToHTML(macros ?? []))
  }, [macros])

  const { ref, typeset } = useMathJaxRenderer<HTMLDivElement>(
    compiledMacros,
    () => {
      // 以下数式のコンパイル結果をキャッシュしておく処理
      if (!ref.current) return
      const notCachedElements =
        ref.current.getElementsByClassName('not-cached-formula')
      const notCachedElementsNum = notCachedElements.length
      for (let i = 0; i < notCachedElementsNum; i++) {
        const e = notCachedElements.item(i)
        if (e === null) continue
        const strHash = e.getAttribute('data-hash')
        if (strHash === null) continue
        const hash = Number(strHash)
        formulaeCache.current.add(hash, e.innerHTML)
      }
      rebuild()
    }
  )

  useEffect(() => {
    if (!providedRef) return
    providedRef.current = ref.current
  }, [])

  const { rebuild } = useShortenedLinksBuilder(ref, references)

  const formulaeCache = useRef<FormulaeCache>(new FormulaeCache(CACHE_SIZE))
  const kg = useRef<KeyGenerator>(new KeyGenerator())

  const tokens = useMemo(() => {
    return lex(mathdown, formulaeCache.current)
  }, [mathdown, compiledMacros])

  useEffect(() => {
    typeset()
  }, [tokens])

  return (
    <div
      ref={ref}
      className={`${className !== undefined ? className : ''} mathdown ${
        design ? `mathdown-${design}` : ''
      }`}
      style={style}
    >
      <div
        // compiledMacrosの中でもd-noneを指定しているが、最初の要素のmarginTopを0にするために、この要素にもd-noneが必要
        className="d-none"
        ref={compiledMacrosRef}
        dangerouslySetInnerHTML={{
          __html: compiledMacros,
        }}
      />
      {tokens.map((token, index) => {
        if (index === 0) kg.current.start()
        return (
          <BlockTokenParser key={kg.current.exec(token.raw)} token={token} />
        )
      })}
    </div>
  )
}

export const DynamicCompiler = memo(DynamicCompilerImpl)
