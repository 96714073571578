type Prefix = string

type PrefixData = {
  suffix: string
  suffixIndex: number
  key: number
}

type Suffix = string

type SuffixData = {
  prefix: string
  prefixIndex: number
  key: number
}

const getPrefixAndSuffix = (src: string, maxLength: number) => {
  return [src.slice(0, maxLength), src.slice(-maxLength)]
}

const removeData = <T>(list: T[] | undefined, index?: number) => {
  if (!list || list.length === 0) return null

  if (index === undefined) {
    index = 0
    while (list[index] === undefined) {
      index++
      if (index === list.length) return null
    }
  }

  const data = list[index]
  delete list[index]
  return data
}

// 各トークンのテキストからReactの一意のKeyを生成するためのクラス
export class KeyGenerator {
  private keyPrefix = 1
  private savedPrefixMap: Record<Prefix, PrefixData[]> = {}
  private savedSuffixMap: Record<Suffix, SuffixData[]> = {}
  private prefixMap: Record<Prefix, PrefixData[]> = {}
  private suffixMap: Record<Suffix, SuffixData[]> = {}
  private currentKey = 0

  start() {
    this.savedPrefixMap = this.prefixMap
    this.savedSuffixMap = this.suffixMap
    this.prefixMap = {}
    this.suffixMap = {}
  }

  removePrefixData(prefix: Prefix, prefixIndex?: number): PrefixData | null {
    return removeData(this.savedPrefixMap[prefix], prefixIndex)
  }

  removeSuffixData(suffix: Suffix, suffixIndex?: number): SuffixData | null {
    return removeData(this.savedSuffixMap[suffix], suffixIndex)
  }

  getKeyBody(prefix: Prefix, suffix: Suffix): number {
    const prefixData = this.removePrefixData(prefix)
    if (prefixData) {
      this.removeSuffixData(prefixData.suffix, prefixData.suffixIndex)
      return prefixData.key
    }

    const suffixData = this.removeSuffixData(suffix)
    if (suffixData) {
      this.removePrefixData(suffixData.prefix, suffixData.prefixIndex)
      return suffixData.key
    }

    this.currentKey++
    return this.currentKey
  }

  save(prefix: Prefix, suffix: Suffix, key: number) {
    if (this.prefixMap[prefix] === undefined) this.prefixMap[prefix] = []
    if (this.suffixMap[suffix] === undefined) this.suffixMap[suffix] = []
    const suffixIndex = this.suffixMap[suffix].length
    const prefixIndex = this.prefixMap[prefix].length
    this.prefixMap[prefix].push({
      suffix,
      key,
      suffixIndex,
    })
    this.suffixMap[suffix].push({
      prefix,
      key,
      prefixIndex,
    })
  }

  exec(src: string): string {
    const [prefix, suffix] = getPrefixAndSuffix(src, 20)
    const keyBody = this.getKeyBody(prefix, suffix)
    this.save(prefix, suffix, keyBody)
    return `${this.keyPrefix}-${keyBody}`
  }

  reset() {
    this.savedPrefixMap = {}
    this.savedSuffixMap = {}
    this.prefixMap = {}
    this.suffixMap = {}
    this.currentKey = 0
    this.keyPrefix++
  }
}
