import { DocRef, collection, db, doc } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document } from '../document'
import { TextbookDraftSubcols, TextbookSubcols } from './subcollections'
import { WithUserTimestamp } from './user_timestamp'

export interface Textbook extends Document, WithUserTimestamp {
  readonly ref: DocRef<Textbook>
  readonly parent_id: 'textbooks'
  readonly subcollections: typeof TextbookSubcols
  created_by: string // uid
  last_updated_by: string // uid
  title: string
  description: string
  category_id: string
}

export interface TextbookDraft extends Document, WithUserTimestamp {
  readonly ref: DocRef<TextbookDraft>
  readonly parent_id: 'textbook_drafts'
  readonly subcollections: typeof TextbookDraftSubcols
  created_by: string // uid
  last_updated_by: string // uid
  title: string
  description: string
  publication_id?: string // 存在する場合は更新用の下書き, 検索用
  category_id?: string // デフォルトは未選択
}

export const textbookConverter = getConverter<Textbook>(TextbookSubcols)
export const textbooksRef = collection<Textbook>(db, `textbooks`).withConverter(
  textbookConverter
)
export const textbookRef = (id: string) => doc(textbooksRef, id)

const textbookDraftConverter = getConverter<TextbookDraft>(TextbookDraftSubcols)
export const textbookDraftsRef = collection<TextbookDraft>(
  db,
  'textbook_drafts'
).withConverter(textbookDraftConverter)
export const textbookDraftRef = (id: string) => doc(textbookDraftsRef, id)
