import { useContext } from 'react'
import {
  PopupMessageContext,
  PopupMessageType,
} from '../../messenger/context/popup_message'

/**
 * ユーザーのサインイン状態を取得するためのカスタムフック。
 * setMessageにnullが代入可能なのはnext-i18nextで使いやすくするため
 */
export const usePopupMessage = () => {
  const { popupMessage, setPopupMessage } = useContext(PopupMessageContext)
  return {
    popupMessage,
    setPopupMessage: (
      text: string | null,
      type: PopupMessageType = 'primary'
    ) => setPopupMessage({ text: text ?? '', type }),
    removePopupMessage: () => setPopupMessage(undefined),
  }
}
