import {
  collection,
  doc,
  DocRef,
  FirestoreDataConverter,
} from '../../../../firebase/alias'
import { App } from '../../../types'
import { getConverter } from '../converter'
import { Document } from '../document'
import { accountRef } from './account'
import { Badge, badgeConverter } from './badge'
import { Comment, commentConverter, Reply, replyConverter } from './message'
import { NotificationSubcols } from './subcollections'

export interface NotificationBase extends Document {
  readonly ref: DocRef<Notification>
  readonly parent_id: 'notifications'
  readonly subcollections: typeof NotificationSubcols
  app: App
  sent_by: string | 'official' // uid
  target_ref: DocRef<unknown> | null
  message: unknown
  status: 'read' | 'unread'
}

export interface CommentNotification extends NotificationBase {
  readonly ref: DocRef<CommentNotification>
  target_ref: DocRef<Comment>
  message: 'create_comment'
}

export interface ReplyNotification extends NotificationBase {
  readonly ref: DocRef<ReplyNotification>
  target_ref: DocRef<Reply>
  message: 'create_reply'
}

// export interface EvaluationNotification extends NotificationBase {
//   readonly ref: DocRef<EvaluationNotification>
//   target_ref: DocRef<GoodEvaluator>
//   message: 'create_good_evaluation'
// }

export interface BadgeNotification extends NotificationBase {
  readonly ref: DocRef<BadgeNotification>
  target_ref: DocRef<Badge>
  message: 'create_badge'
  amount?: number
}

export interface OfficialNotification extends NotificationBase {
  readonly ref: DocRef<BadgeNotification>
  target_ref: null
  sent_by: 'official'
  message: 'official_notice'
  localized_message: {
    ja: string
    en: string
  }
}

export type Notification =
  | CommentNotification
  | ReplyNotification
  // | EvaluationNotification
  | BadgeNotification
  | OfficialNotification

export type NotificationTarget = Notification['target_ref'] extends DocRef<
  infer T
>
  ? T
  : never

export const notificationConverter = getConverter<Notification>(
  NotificationSubcols,
  {
    fromFirestore: (snapshot) => {
      // target_ref に Converter を適用する
      const data = snapshot.data()
      const targetRef = data.target_ref as Notification['target_ref']
      let converter:
        | FirestoreDataConverter<Comment>
        | FirestoreDataConverter<Reply>
        // | FirestoreDataConverter<GoodEvaluator>
        | FirestoreDataConverter<Badge>
        | null = null
      switch (targetRef?.parent.id) {
        case 'replies':
          converter = replyConverter
          break
        case 'comments':
          converter = commentConverter
          break
        case 'badges':
          converter = badgeConverter
          break
        // case 'good_evaluators':
        //   converter = goodEvaluatorConverter
        //   break
        default:
          throw new Error('unreachable.')
      }
      return {
        // TODO: ESlintを無視しないように修正
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        target_ref: data.target_ref.withConverter(converter),
      }
    },
  }
)
export const notificationsRef = (uid: string) =>
  collection<Notification>(accountRef(uid), 'notifications').withConverter(
    notificationConverter
  )
export const notificationRef = (uid: string, id: string) =>
  doc(notificationsRef(uid), id)
