import { DocRef, collection, db, doc } from '../../../../firebase/alias'
import { App, Locale } from '../../../types'
import { getConverter } from '../converter'
import { Document } from '../document'
import { CategorySubcols } from './subcollections'

export interface Category extends Document {
  readonly parent_id: 'categories'
  readonly ref: DocRef<Category>
  readonly subcollections: typeof CategorySubcols
  order: number
  menu: Record<App, boolean>
  selectable: Record<App, boolean>
  localized_name: Record<Locale, string>
  localized_summary: Record<Locale, string>
}

export const categoryConverter = getConverter<Category>(CategorySubcols)
export const categoriesRef = collection<Category>(
  db,
  'categories'
).withConverter(categoryConverter)
export const categoryRef = (id: string) => doc(categoriesRef, id)
