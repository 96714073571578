import { RegexEditor } from '../utils/regex_editor'
import { def } from './def'
import { _bullet } from './_bullet'

const _list = /^( {0,3}bull) ([^\n]+?)?(?:\n|$)/

export const list = new RegexEditor(_list)
  .replace(/bull/g, _bullet)
  .replace(
    'hr',
    '\\n+(?=\\1?(?:(?:- *){3,}|(?:_ *){3,}|(?:\\* *){3,})(?:\\n+|$))'
  )
  .replace('def', '\\n+(?=' + def.source + ')')
  .getRegex()
