import { Reference } from '../../../../firebase'
import { getMathlogArticleData } from '../../../../models/article'
import { formatDateToYYYYMMDD } from '../../../../shared/utils/date'
import { escapeTexText } from './escape_tex_text'

export const compileReferencesToTex = async (references: Reference[]) => {
  let src = '\n\\begin{thebibliography}{99}\n'
  let num = 0
  for (const reference of references) {
    num++
    switch (reference.type) {
      case 'mathlog_article': {
        const { profile, article } = await getMathlogArticleData(
          reference.article_id
        )
        if (profile === undefined || article === undefined) break

        src += `\\bibitem{${num}}`
        const bibitem = []
        if (profile !== undefined)
          bibitem.push(escapeTexText(profile.display_name))
        if (article !== undefined) {
          bibitem.push(article.title)
          bibitem.push(`\\url{https://mathlog.info/articles/${article.id}}`)
          bibitem.push('閲覧日 ' + formatDateToYYYYMMDD(reference.created_at))
        } else {
          bibitem.push('削除済みの記事')
        }
        src += bibitem.join(', ')
        src += '.\n'
        break
      }
      case 'general_book': {
        src += `\\bibitem{${num}}`
        const bibitem = []
        bibitem.push(escapeTexText(reference.author))
        bibitem.push(escapeTexText(reference.title))
        if (reference.series !== undefined && reference.series !== '')
          bibitem.push(escapeTexText(reference.series))
        if (reference.publisher !== undefined && reference.publisher !== '')
          bibitem.push(escapeTexText(reference.publisher))
        if (
          reference.published_year !== undefined &&
          reference.published_year !== ''
        )
          bibitem.push(reference.published_year)
        if (reference.page !== undefined && reference.page !== '')
          bibitem.push(reference.page)
        src += bibitem.join(', ')
        src += '.\n'
        break
      }
      case 'paper': {
        src += `\\bibitem{${num}}`
        const bibitem = []
        bibitem.push(escapeTexText(reference.author))
        bibitem.push(escapeTexText(reference.title))
        bibitem.push(escapeTexText(reference.journal))
        if (reference.volume !== undefined && reference.volume !== '')
          bibitem.push(reference.volume)
        if (reference.number !== undefined && reference.number !== '')
          bibitem.push(reference.number)
        if (reference.page !== undefined && reference.page !== '')
          bibitem.push(reference.page)
        if (
          reference.published_year !== undefined &&
          reference.published_year !== ''
        )
          bibitem.push(reference.published_year)
        src += bibitem.join(', ')
        src += '.\n'
        break
      }
      case 'website': {
        src += `\\bibitem{${num}}`
        const bibitem = []
        if (reference.author !== undefined && reference.author !== '')
          bibitem.push(escapeTexText(reference.author))
        bibitem.push(escapeTexText(reference.title))
        if (reference.name !== undefined && reference.name !== '')
          bibitem.push(escapeTexText(reference.name))
        bibitem.push('\\url{' + reference.url + '}')
        bibitem.push('閲覧日 ' + formatDateToYYYYMMDD(reference.accessed_on))
        src += bibitem.join(', ')
        src += '.\n'
        break
      }
      default:
        break
    }
  }
  src += '\n\\end{thebibliography}\n'
  return src
}
