import { Designed } from '../types'

type Props = {
  children: React.ReactNode
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  space?: string | number
  truncate?: boolean
}

export const IconWrapper = ({
  children,
  prefix,
  suffix,
  className,
  style,
  truncate = false,
}: Designed<Props>) => {
  return truncate ? (
    <div
      className={`d-flex align-items-center gap-1 overflow-hidden ${
        className !== undefined ? className : ''
      }`}
      style={style}
    >
      {prefix !== undefined && <div className="text-truncate">{prefix}</div>}
      {children}
      {suffix !== undefined && <div className="text-truncate">{suffix}</div>}
    </div>
  ) : (
    <div
      className={`d-inline-flex align-items-center gap-1 ${
        className !== undefined ? className : ''
      }`}
      style={style}
    >
      {prefix !== undefined && <div>{prefix}</div>}
      {children}
      {suffix !== undefined && <div>{suffix}</div>}
    </div>
  )
}
