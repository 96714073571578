import { createContext, useContext, useState } from 'react'
import { MOBILE_CATEGORIES_MENU_HEIGHT } from 'web_core/assets/category'

export type LayoutProperty = {
  /** ヘッダーの高さ（DefaultLayoutで更新） */
  headerHeight: number
  setHeaderHeight: (headerHeight: number) => void

  /* モバイルのカテゴリメニューの高さ（DefaultLayoutで更新） */
  mobileCategoriesMenuHeight: number
  setMobileCategoriesMenuHeight: (mobileCategoriesMenuHeight: number) => void

  /** モバイルの下部メニューの高さ（DefaultLayoutで更新） */
  mobileBottomMenuHeight: number
  setMobileBottomMenuHeight: (mobileBottomMenuHeight: number) => void
}

export const HEADER_HEIGHT = 55
export const MOBILE_BOTTOM_MENU_HEIGHT = 49.05

export const LayoutPropertyContext = createContext<LayoutProperty>({
  headerHeight: HEADER_HEIGHT,
  mobileCategoriesMenuHeight: MOBILE_CATEGORIES_MENU_HEIGHT,
  mobileBottomMenuHeight: MOBILE_BOTTOM_MENU_HEIGHT,
  setHeaderHeight: () => {
    throw new Error('Unexpected.')
  },
  setMobileCategoriesMenuHeight: () => {
    throw new Error('Unexpected.')
  },
  setMobileBottomMenuHeight: () => {
    throw new Error('Unexpected.')
  },
})

type Props = {
  children: React.ReactNode
}

export const LayoutPropertyProvider = ({ children }: Props) => {
  const [headerHeight, setHeaderHeight] = useState(HEADER_HEIGHT)
  const [mobileCategoriesMenuHeight, setMobileCategoriesMenuHeight] = useState(
    MOBILE_CATEGORIES_MENU_HEIGHT
  )
  const [mobileBottomMenuHeight, setMobileBottomMenuHeight] = useState(
    MOBILE_BOTTOM_MENU_HEIGHT
  )
  return (
    <LayoutPropertyContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        mobileCategoriesMenuHeight,
        setMobileCategoriesMenuHeight,
        mobileBottomMenuHeight,
        setMobileBottomMenuHeight,
      }}
    >
      {children}
    </LayoutPropertyContext.Provider>
  )
}

export const useLayoutProperty = () => useContext(LayoutPropertyContext)
