import { useRouter } from 'next/router'
import { useState } from 'react'
import { fb } from '../../firebase'
import { logEvent } from '../../utils'

export const useStripePortal = () => {
  const router = useRouter()
  const [opening, setOpening] = useState(false)

  const openPortal = async () => {
    if (opening) return
    setOpening(true)
    const {
      data: { url },
    } = await fb.call('ext-firestore-stripe-payments-createPortalLink')({
      returnUrl: location.href,
    })

    // プランの管理画面へ移動する際のイベント収集
    logEvent('open_stripe_portal')
    await router.push(url)
  }

  return { openPortal, opening }
}
