import { resolveMathdownImagePath } from '../../utils'

export const image = (
  href: string,
  size: string,
  title: string,
  alt: string,
  text: string
) => {
  const src = href.startsWith('/') ? resolveMathdownImagePath(href) : href
  // 本来はdiv要素で囲むべきだがdiv要素はp要素の中に入れることができないためspan要素で代用する
  return `
    <span class="img-container mx-auto d-block" style="max-width: ${size}">
      <img
        src="${src}"
        title="${title}"
        alt="${alt}"
        decoding="async"
        class="w-100"
      />
      <span class="image-title d-block">${text}</span>
    </span>`
}
