import { useEffect, useRef, useState } from 'react'

/* timeout[ms]間スクロールされていない場合はstop, されている場合はstopを返却する関数 */
export const useScrollStatus = (timeout: number) => {
  const [status, setStatus] = useState<'scroll' | 'stop'>('stop')
  const timer = useRef<NodeJS.Timeout>()
  const [scrollY, setScrollY] = useState<number>(0)

  useEffect(() => {
    const updateScrollStatus = () => {
      setScrollY(window.scrollY)
      setStatus('scroll')
      if (timer.current) clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        setStatus('stop')
      }, timeout)
    }
    window.addEventListener('scroll', updateScrollStatus)
    window.addEventListener('resize', updateScrollStatus)
    window.visualViewport?.addEventListener('resize', updateScrollStatus)
    return () => {
      window.removeEventListener('scroll', updateScrollStatus)
      window.removeEventListener('resize', updateScrollStatus)
      window.visualViewport?.removeEventListener('resize', updateScrollStatus)
    }
  }, [])

  return { status, scrollY }
}
