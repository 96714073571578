import Link from 'next/link'
import { Button, Nav } from 'react-bootstrap'
import { Category } from '../../firebase'
import { useCurrentUserRoles } from '../../hooks'
import { useCategories } from '../../hooks/models/useCategories'
import { Locale } from '../../types'
import { APP_NAME } from '../../utils/env'

type Props = {
  locale: Locale
  categories?: Category[]
  activeCategoryId?: string
}

export const CategoriesMenu = ({
  locale,
  categories,
  activeCategoryId,
}: Props) => {
  const app = APP_NAME
  const { hasRole } = useCurrentUserRoles()
  const { menuCategories } = useCategories(categories)
  const isEditable =
    (app === 'mathlog' && hasRole('mathlog_admin') === true) ||
    (app === 'mathpedia' && hasRole('mathpedia_admin') === true)
  return (
    <>
      <Nav className="flex-grow-1 flex-column gap-1 flex-nowrap overflow-auto scrollbar-none">
        {menuCategories?.map((category) => (
          <Link
            key={category.id}
            href={
              app === 'mathlog'
                ? `/categories/${category.id}/articles`
                : `/categories/${category.id}`
            }
            passHref
          >
            <Nav.Link
              active={activeCategoryId === category.id}
              className="px-4 py-3 fw-bold"
            >
              {category.localized_name[locale]}
            </Nav.Link>
          </Link>
        ))}
      </Nav>
      {isEditable && (
        <div className="flex-shrink-0 d-flex justify-content-center align-items-center d-none d-md-block m-4">
          <Link href="/categories/edit" passHref>
            <Button variant="primary" className="w-100 py-2">
              カテゴリを編集
            </Button>
          </Link>
        </div>
      )}
    </>
  )
}
