import { RegexEditor } from '../utils/regex_editor'
import { _attribute } from './_attribute'
import { _comment } from './_comment'

const _tag =
  '^comment' +
  '|^</[a-zA-Z][\\w:-]*\\s*>' + // self-closing tag
  '|^<[a-zA-Z][\\w-]*(?:attribute)*?\\s*/?>' + // open tag
  '|^<\\?[\\s\\S]*?\\?>' + // processing instruction, e.g. <?php ?>
  '|^<![a-zA-Z]+\\s[\\s\\S]*?>' + // declaration, e.g. <!DOCTYPE html>
  '|^<!\\[CDATA\\[[\\s\\S]*?\\]\\]>' // CDATA section

export const tag = new RegexEditor(_tag)
  .replace('comment', _comment)
  .replace('attribute', _attribute)
  .getRegex()
