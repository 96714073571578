// 数式のレンダリング結果をキャッシュしておく用のクラス
export class FormulaeCache {
  private map: Record<number, string> = {}
  private order: number[] = []
  private maxSize: number

  constructor(maxSize: number) {
    this.maxSize = maxSize
  }

  add(hash: number, html: string) {
    if (this.order.length >= this.maxSize) {
      const removeHash = this.order.shift()
      if (removeHash !== undefined) delete this.map[removeHash]
    }
    this.order.push(hash)
    this.map[hash] = html
  }

  get(hash: number) {
    const index = this.order.findIndex((e) => e === hash)
    this.order.splice(index, 1)
    this.order.push(hash)
    return hash in this.map ? this.map[hash] : null
  }

  clear() {
    this.map = {}
    this.order = []
  }
}
