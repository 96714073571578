import { orderBy, query } from 'firebase/firestore'
import {
  Batch,
  ReferencesParentRefAssignable,
  ValidDocument,
  getDocs,
  referenceRef,
  referencesRef,
} from '../firebase'

export const addReferences = async <
  T extends ValidDocument,
  U extends ValidDocument
>(
  source: ReferencesParentRefAssignable<T>,
  target: ReferencesParentRefAssignable<U>,
  batch: Batch
) => {
  const sourceReferencesSnapshot = await getDocs(referencesRef(source))
  const sourceReferences = sourceReferencesSnapshot.docs.map((doc) =>
    doc.data()
  )
  for (const reference of sourceReferences)
    batch.create(referenceRef(target, reference.id), reference)
  return sourceReferences.length
}

export const deleteReferences = async <T extends ValidDocument>(
  target: ReferencesParentRefAssignable<T>,
  batch: Batch
) => {
  const targetReferencesSnapshot = await getDocs(referencesRef(target))
  const targetReferenceRefs = targetReferencesSnapshot.docs.map(
    (doc) => doc.ref
  )
  for (const ref of targetReferenceRefs) await batch.delete(ref)
  return targetReferenceRefs.length
}

export const moveReferences = async <
  T extends ValidDocument,
  U extends ValidDocument
>(
  source: ReferencesParentRefAssignable<T>,
  target: ReferencesParentRefAssignable<U>,
  batch: Batch
) => {
  const num = await addReferences(source, target, batch)
  await deleteReferences(source, batch)
  return num
}

export const replaceReferences = async <
  T extends ValidDocument,
  U extends ValidDocument
>(
  source: ReferencesParentRefAssignable<T>,
  target: ReferencesParentRefAssignable<U>,
  batch: Batch
) => {
  await deleteReferences(target, batch)
  return await addReferences(source, target, batch)
}

export const moveAndReplaceReferences = async <
  T extends ValidDocument,
  U extends ValidDocument
>(
  source: ReferencesParentRefAssignable<T>,
  target: ReferencesParentRefAssignable<U>,
  batch: Batch
) => {
  await deleteReferences(target, batch)
  return await moveReferences(source, target, batch)
}

export const sortedReferencesRef = <T extends ValidDocument>(
  parentRef: ReferencesParentRefAssignable<T>
) => query(referencesRef(parentRef), orderBy('order'))
