import axios from 'axios'
import { useRouter } from 'next/router'
import { Stack } from 'react-bootstrap'
import { FaFacebook, FaGithub, FaGlobe, FaTwitter } from 'react-icons/fa'
import { LinkWrapper } from '../../assets/LinkWrapper'
import { Profile } from '../../firebase'

type Props = {
  profile: Profile
  className?: string
  style?: React.CSSProperties
}

export const UserRelatedLinks = ({ profile, ...wrapperProps }: Props) => {
  const router = useRouter()
  const hasRelatedLinks =
    profile.website !== undefined ||
    profile.social_accounts['facebook.com'] !== undefined ||
    profile.social_accounts['github.com'] !== undefined ||
    profile.social_accounts['twitter.com'] !== undefined
  if (!hasRelatedLinks) return <></>
  return (
    <div {...wrapperProps}>
      <Stack direction="horizontal" gap={3} className="flex-wrap">
        <>
          {profile.social_accounts['facebook.com'] !== undefined && (
            <LinkWrapper
              href={`https://facebook.com/${profile.social_accounts['facebook.com']}`}
              passHref
            >
              <FaFacebook className="text-facebook clickable" />
            </LinkWrapper>
          )}
          {profile.website !== undefined && (
            <a href={'http://' + profile.website}>
              <FaGlobe className="text-muted" />
            </a>
          )}
          {profile.social_accounts['github.com'] !== undefined && (
            <a
              onClick={async () => {
                if (profile.social_accounts['github.com'] === undefined)
                  throw new Error('Unexpected error.')
                await axios
                  .get<{ html_url: string }>(
                    `https://api.github.com/user/${profile.social_accounts['github.com']}`
                  )
                  .then(async (res) => {
                    await router.push(res.data.html_url)
                  })
              }}
            >
              <FaGithub className="text-github clickable" />
            </a>
          )}
          {profile.social_accounts['twitter.com'] !== undefined && (
            <LinkWrapper
              href={`https://twitter.com/i/user/${profile.social_accounts['twitter.com']}`}
              passHref
            >
              <FaTwitter className="text-twitter clickable" />
            </LinkWrapper>
          )}
        </>
      </Stack>
    </div>
  )
}
