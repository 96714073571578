import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useEffect, useState } from 'react'
import { Alert, Button, Form, InputGroup, ListGroup } from 'react-bootstrap'
import { useController, useForm } from 'react-hook-form'
import { FaGripVertical } from 'react-icons/fa'
import { usePopupMessage } from '../../assets/messenger'
import {
  addDoc,
  categoriesRef,
  Category,
  CreateParamsWithoutFieldValue,
  updateDoc,
} from '../../firebase'
import { useRequireRole } from '../../hooks'
import { useDynamicCategories } from '../../hooks/models/useDynamicCategories'
import { useOrderChanger } from '../../hooks/useOrdersChanger'
import { App, Designed, PromiseVoid } from '../../types'
import { APP_NAME } from '../../utils/env'
import { MathJaxEditor } from '../mathdown/mathjax'

type CategoryItemProps = {
  category: Category
  app: App
  onClickEditButton: (category: Category) => PromiseVoid
}

const CategoryItem = ({
  category,
  app,
  onClickEditButton: handleClickEditButton,
}: CategoryItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: category.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  return (
    <ListGroup.Item ref={setNodeRef} style={style}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <Button
            className="text-muted me-2"
            variant="link"
            {...listeners}
            {...attributes}
          >
            <FaGripVertical />
          </Button>
          <div>{category.localized_name.ja || 'カテゴリ名未設定'}</div>
        </div>
        <div className="d-flex gap-2">
          {category.menu[app] ? (
            <Button
              variant="primary"
              size="sm"
              onClick={async () => {
                const menu = { ...category.menu }
                menu[app] = false
                await updateDoc(category.ref, { menu })
              }}
            >
              表示中
            </Button>
          ) : (
            <Button
              variant="danger"
              size="sm"
              onClick={async () => {
                const menu = { ...category.menu }
                menu[app] = true
                await updateDoc(category.ref, { menu })
              }}
            >
              非表示中
            </Button>
          )}
          {category.selectable[app] ? (
            <Button
              variant="primary"
              size="sm"
              onClick={async () => {
                const selectable = { ...category.selectable }
                selectable[app] = false
                await updateDoc(category.ref, { selectable })
              }}
            >
              選択可能
            </Button>
          ) : (
            <Button
              variant="danger"
              size="sm"
              onClick={async () => {
                const selectable = { ...category.selectable }
                selectable[app] = true
                await updateDoc(category.ref, { selectable })
              }}
            >
              選択不可
            </Button>
          )}
          <Button
            variant="success"
            size="sm"
            onClick={() => handleClickEditButton(category)}
          >
            編集
          </Button>
        </div>
      </div>
    </ListGroup.Item>
  )
}

type CategoriesListProps = {
  categories: Category[]
  onClickEditButton: (category: Category) => PromiseVoid
  shiftCategory: (target: number, position: number) => PromiseVoid
}

const CategoriesList = ({
  categories,
  shiftCategory,
  onClickEditButton: handleClickEditButton,
  ...designProps
}: Designed<CategoriesListProps>) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )
  const app = APP_NAME
  return (
    <ListGroup {...designProps}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={async (event: DragEndEvent) => {
          const { active, over } = event
          if (over === null || active.id === over.id) return
          const target = categories.findIndex(
            (category) => category.id === `${active.id}`
          )
          const position = categories.findIndex(
            (category) => category.id === `${over.id}`
          )
          await shiftCategory(target, position)
        }}
      >
        <SortableContext
          items={categories}
          strategy={verticalListSortingStrategy}
        >
          {categories.map((category) => (
            <CategoryItem
              category={category}
              app={app}
              onClickEditButton={handleClickEditButton}
              key={category.id}
            />
          ))}
        </SortableContext>
      </DndContext>
      {categories.length === 0 && <>該当するカテゴリはありません。</>}
    </ListGroup>
  )
}

type CategoryFormProps = {
  category: Category
  onClose?: () => PromiseVoid
}

const CategoryForm = ({ category, onClose: callback }: CategoryFormProps) => {
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<CreateParamsWithoutFieldValue<Category>>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const {
    field: { onChange: handleJaSummaryChange, value: jaSummary },
  } = useController({
    name: 'localized_summary.ja',
    control,
    rules: { required: true },
  })

  const {
    field: { onChange: handleEnSummaryChange, value: enSummary },
  } = useController({
    name: 'localized_summary.en',
    control,
    rules: { required: true },
  })

  useEffect(() => {
    reset({
      localized_name: category.localized_name,
      localized_summary: category.localized_summary,
    })
  }, [category])

  const { setPopupMessage } = usePopupMessage()

  return (
    <Form
      onSubmit={handleSubmit(async (data) => {
        await updateDoc(category.ref, data)
        if (callback) await callback()
        setPopupMessage('カテゴリの情報を更新しました。')
      })}
    >
      <div className="mb-4">
        <Form.Label>カテゴリ名</Form.Label>
        <InputGroup>
          <InputGroup.Text>日本語</InputGroup.Text>
          <Form.Control
            {...register('localized_name.ja', { required: true })}
          />
          <InputGroup.Text>英語</InputGroup.Text>
          <Form.Control
            {...register('localized_name.en', { required: true })}
          />
        </InputGroup>
      </div>
      <div className="mb-4">
        <Form.Label>カテゴリの概要 (日本語)</Form.Label>
        <MathJaxEditor
          value={jaSummary}
          onChange={handleJaSummaryChange}
          height="auto"
        />
      </div>
      <div className="mb-4">
        <Form.Label>カテゴリの概要 (英語)</Form.Label>
        <MathJaxEditor
          value={enSummary}
          onChange={handleEnSummaryChange}
          height="auto"
        />
      </div>
      <div className="d-flex gap-4">
        <Button type="button" variant="secondary" onClick={callback}>
          キャンセル
        </Button>
        <Button type="submit" disabled={!isDirty || !isValid}>
          保存
        </Button>
      </div>
    </Form>
  )
}

export const CategoriesEditor = () => {
  const { categories } = useDynamicCategories()
  useRequireRole(
    APP_NAME === 'mathlog' ? ['mathlog_admin'] : ['mathpedia_admin']
  )
  const {
    array: immediatelyOrderedCategories,
    shift: shiftCategory,
    nextOrder: nextCategoryOrder,
  } = useOrderChanger(categories ?? [])

  const addCategory = async () => {
    return await addDoc(categoriesRef, {
      order: nextCategoryOrder,
      localized_name: {
        ja: '',
        en: '',
      },
      localized_summary: {
        ja: '',
        en: '',
      },
      selectable: {
        mathlog: false,
        mathpedia: false,
      },
      menu: {
        mathlog: false,
        mathpedia: false,
      },
    })
  }
  const [editingCategoryId, setEditingCategoryId] = useState<string>()
  const editingCategory = categories?.find((c) => c.id === editingCategoryId)

  return (
    <>
      <div className="row gap-5">
        <div className="col">
          <h1 className="h4">カテゴリ一覧</h1>
          {categories && (
            <CategoriesList
              className="mb-6"
              shiftCategory={shiftCategory}
              categories={immediatelyOrderedCategories}
              onClickEditButton={(c) => setEditingCategoryId(c.id)}
            />
          )}
          {
            <Button
              variant="primary"
              onClick={async () => {
                const addedRef = await addCategory()
                setEditingCategoryId(addedRef.id)
              }}
            >
              新規追加
            </Button>
          }
        </div>
        <div className="col">
          {editingCategory && (
            <>
              <h1 className="h4">カテゴリの編集</h1>
              <Alert variant="warning" className="p-2">
                カテゴリ名と概要はすべてのアプリで共通です。十分に注意して設定してください。
              </Alert>
              <CategoryForm
                category={editingCategory}
                onClose={() => setEditingCategoryId(undefined)}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}
