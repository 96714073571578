import Link from 'next/link'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import { UserImageWithLink } from '../../../assets/user'
import {
  articleRef,
  MathlogArticleReference,
  profileRef,
} from '../../../firebase'

type Props = {
  reference: MathlogArticleReference
  link: boolean
}

export const MathlogArticleReferenceItem = ({ reference, link }: Props) => {
  const [article, loadingArticle] = useDocumentDataOnce(
    articleRef(reference.article_id)
  )
  const [profile] = useDocumentDataOnce(
    article ? profileRef(article.created_by) : null
  )
  const title = [
    profile?.display_name ?? 'Unknown',
    article?.title ?? (loadingArticle ? '取得中...' : '不明な記事'),
    'Mathlog',
    article?.created_at.toDate().toDateString(),
    `アクセス日: ${reference.updated_at.toDate().toDateString()}`,
  ]
    .filter((item) => item)
    .join(', ')
  if (!link) {
    return <>{title}</>
  }
  return (
    <div className="d-flex gap-1">
      <div className="flex-shrink-0">
        <UserImageWithLink
          size="20px"
          profile={profile}
          imgStyle={{ display: 'inline', margin: 0, width: 20, height: 20 }}
        />
      </div>
      <div className="flex-grow-1 text-break">
        <Link href={`/articles/${reference.article_id}/`} passHref>
          {title}
        </Link>
      </div>
    </div>
  )
}
