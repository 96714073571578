import { Macro, Reference, Timestamp } from '../../../../firebase'
import { formatDateToYYYYMMDD } from '../../../../shared/utils/date'
import { escapeTexText } from './escape_tex_text'
import { compileMacrosToTex } from './macros_compiler'
import { compileReferencesToTex } from './references_compiler'

type DocumentType = 'jsarticle' | 'jsbook'

export const makeCompleteTexFile = async (
  documentType: DocumentType,
  title: string,
  author: string,
  date: Date | Timestamp,
  body: string,
  macros: Macro[] = [],
  references: Reference[] = []
) => {
  const referencesTex =
    references.length > 0 ? await compileReferencesToTex(references) : ''

  return `\\documentclass[11pt,a4paper]{${documentType}}  
\\usepackage[dvipdfmx]{graphicx}
\\usepackage[dvipdfmx]{color}
\\usepackage{listings}
\\usepackage{enumerate}
\\usepackage{enumitem}
\\usepackage{ascmac}
\\usepackage{amsmath}
\\usepackage{amsthm}
\\usepackage{url}
\\usepackage{ulem}
\\usepackage{here}
\\usepackage{amscd}
\\usepackage{braket}
\\usepackage{bussproofs}
\\usepackage{cancel}
\\usepackage{cases}
\\usepackage{centernot}
\\usepackage{colortbl}
\\usepackage{empheq}
\\usepackage{extpfeil}
\\usepackage{gensymb}
\\usepackage{mathtools}
\\usepackage{mhchem}
\\usepackage{physics}
\\usepackage{textcomp}
\\usepackage{unicode}
\\usepackage[all]{xy}

\\theoremstyle{definition}
\\newtheorem{thm}{定理}[]
\\newtheorem{lem}[thm]{補題}
\\newtheorem{prop}[thm]{命題}
\\newtheorem{cor}[thm]{系}
\\newtheorem{ass}[thm]{仮定}
\\newtheorem{conj}[thm]{予想}
\\newtheorem{dfn}[thm]{定義}
\\newtheorem{rem}[thm]{注}
\\newtheorem{axm}[thm]{公理}
\\newtheorem{fml}[thm]{公式}
\\newtheorem{prf}[thm]{証明}
\\newtheorem{ex}[thm]{例}
\\newtheorem{exc}[thm]{問題}

\\setcounter{secnumdepth}{5}

\\newcommand{\\lt}{<}
\\renewcommand{\\gt}{>}
${compileMacrosToTex(macros)}

\\title{${escapeTexText(title)}}
\\author{${escapeTexText(author)}}
\\date{${formatDateToYYYYMMDD(date)}}

\\begin{document}

\\maketitle

${body}

${referencesTex}
\\end{document}
`
}
