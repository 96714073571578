import { FaThumbsUp } from 'react-icons/fa'
import { IconWrapper } from '../../assets/IconWrapper'
import { Designed } from '../../types'

type Props = {
  value: number
}

export const GoodCounter = ({ value, ...wrapperProps }: Designed<Props>) => (
  <IconWrapper suffix={value} {...wrapperProps}>
    <FaThumbsUp />
  </IconWrapper>
)
