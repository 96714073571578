import { arrayRemove, arrayUnion } from 'firebase/firestore'
import { Button, Form, Modal } from 'react-bootstrap'
import { Profile, updateDoc } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { useContentMute, useMessageMute } from '../../hooks'

type Props = {
  show: boolean
  onHide: () => void
  profile: Profile
}

export const MuteSettingModal = ({
  profile,
  show,
  onHide: handleHide,
}: Props) => {
  const {
    isMuted: isContentMuted,
    cancelMute: cancelContentMute,
    mute: contentMute,
    isLoading: isLoadingContentMute,
  } = useContentMute(profile)
  const {
    isMuted: isMessageMuted,
    cancelMute: cancelMessageMute,
    mute: messageMute,
    isLoading: isLoadingMessageMute,
  } = useMessageMute(profile)

  const {
    profile: currentUserProfile,
    loadingProfile: loadingCurrentUserProfile,
  } = useAuthState()
  const isCommentSectionHidden = currentUserProfile
    ? currentUserProfile.comments_section_setting?.hidden_uids.includes(
        profile.id
      ) ?? false
    : undefined

  return (
    <Modal show={show} onHide={handleHide}>
      <Modal.Header closeButton>ミュートの設定</Modal.Header>
      <Modal.Body className="d-flex flex-column gap-4">
        <Form.Check
          type="checkbox"
          id={'content_mute'}
          checked={isContentMuted}
          disabled={isLoadingContentMute}
          onChange={async (e) => {
            const checked = e.currentTarget.checked
            if (checked && isContentMuted === false) await contentMute()
            else if (!checked && isContentMuted === true)
              await cancelContentMute()
          }}
          label={
            <>
              コンテンツのミュート
              <br />
              <Form.Text className="small">
                このユーザが投稿した記事や本をタイムラインや検索結果でミュートします。
              </Form.Text>
            </>
          }
        />
        <Form.Check
          type="checkbox"
          id={'message_mute'}
          checked={isMessageMuted}
          disabled={isLoadingMessageMute}
          onChange={async (e) => {
            const checked = e.currentTarget.checked
            if (checked && isMessageMuted === false) await messageMute()
            else if (!checked && isMessageMuted === true)
              await cancelMessageMute()
          }}
          label={
            <>
              コメントや返信のミュート
              <br />
              <Form.Text className="small">
                このユーザが投稿したコメントや返信の通知が届かないようにします。
              </Form.Text>
            </>
          }
        />
        <Form.Check
          type="checkbox"
          id={'hide_mute'}
          checked={isCommentSectionHidden}
          disabled={loadingCurrentUserProfile}
          onChange={async (e) => {
            if (!currentUserProfile) throw new Error('unexpected.')
            const checked = e.currentTarget.checked
            if (checked && isCommentSectionHidden === false)
              await updateDoc(currentUserProfile.ref, {
                'comments_section_setting.hidden_uids': arrayUnion(profile.id),
              })
            else if (!checked && isCommentSectionHidden === true)
              await updateDoc(currentUserProfile.ref, {
                'comments_section_setting.hidden_uids': arrayRemove(profile.id),
              })
          }}
          label={
            <>
              コメント欄での非表示
              <br />
              <Form.Text className="small">
                このユーザがあなたの記事や本のページに対してつけたコメントが非表示されているコメントとして区別されます。
              </Form.Text>
            </>
          }
        />
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" onClick={handleHide}>
          決定
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
