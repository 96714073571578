import { increment } from 'firebase/firestore'
import { useCallback, useState } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import { Batch, Profile } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import {
  muteUserRef,
  mutedUserRef,
} from '../../shared/firebase/firestore/scheme/mute'

export const useContentMute = (profile: Profile) => {
  const [isSending, setIsSending] = useState(false)
  const { user: currentUser } = useAuthState()
  const ref = currentUser ? mutedUserRef(profile.id, currentUser.uid) : null
  const [muteSnapshot] = useDocument(ref)
  const isMuted = muteSnapshot ? muteSnapshot.exists() : undefined

  const mute = useCallback(async () => {
    if (!currentUser) throw new Error('Unexpected call.')
    if (isMuted !== false) throw new Error('Unexpected call.')
    if (isSending) return
    setIsSending(true)
    const batch = new Batch()
    batch.create(mutedUserRef(profile.id, currentUser.uid), {})
    batch.update(profile.ref, { muted_users_count: increment(1) })
    batch.create(muteUserRef(currentUser.uid, profile.id), {})
    await batch.commit()
    setIsSending(false)
  }, [profile, currentUser, isMuted, isSending])

  const cancelMute = useCallback(async () => {
    if (!currentUser) throw new Error('Unexpected call.')
    if (isMuted !== true) throw new Error('Unexpected call.')
    if (isSending) return
    setIsSending(true)
    const batch = new Batch()
    await batch.delete(mutedUserRef(profile.id, currentUser.uid))
    batch.update(profile.ref, { muted_users_count: increment(-1) })
    await batch.delete(muteUserRef(currentUser.uid, profile.id))
    await batch.commit()
    setIsSending(false)
  }, [profile, currentUser, isMuted, isSending])

  return { mute, cancelMute, isMuted, isLoading: isMuted === undefined }
}
