import { escapeSpecialCharacters } from '.'
import { isBlank } from '../../../../../utils'
import { Lexer } from '../../lexer'
import type { Link, Tokens } from '../../types'

export const outputLink = (
  cap: RegExpExecArray,
  link: Link,
  raw: string,
  lexer: Lexer
): Tokens.Link | Tokens.Image => {
  const href = link.href ?? ''
  const size = !isBlank(link.size) ? escapeSpecialCharacters(link.size) : ''
  const title = !isBlank(link.title) ? escapeSpecialCharacters(link.title) : ''
  const text = cap[1].replace(/\\([[\]])/g, '$1')

  if (!cap[0].startsWith('!')) {
    lexer.state.inLink = true
    const token: Tokens.Link = {
      type: 'link',
      raw,
      href,
      title,
      text,
      tokens: lexer.inlineTokens(text, []),
    }
    lexer.state.inLink = false
    return token
  } else {
    const image: Tokens.Image = {
      type: 'image',
      raw,
      href,
      size,
      text,
      title,
      tokens: [],
    }
    lexer.inline(text, image.tokens)
    return image
  }
}
