import { rules } from '../../rules'
import type { Formula, HTML } from '../../types'
import { FormulaRemover } from './formula_remover'
import { HTMLRemover } from './html_remover'

export const createHash = (src: string) => {
  return src.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0)
    return a & a
  }, 0)
}

export class Extractor {
  private src = ''
  private extractedSrc = ''
  private formulae: Formula[] = []
  private htmlList: HTML[] = []

  static extract(src: string, formulae: Formula[], htmlList: HTML[]) {
    const extractor = new Extractor()
    return extractor.extract(src, formulae, htmlList)
  }

  private extract(src: string, formulae: Formula[], htmlList: HTML[]) {
    this.src = src
    this.extractedSrc = ''
    this.formulae = formulae
    this.htmlList = htmlList

    while (this.src) {
      if (this.eatCode()) continue
      if (this.eatHtml()) continue
      if (this.eatFormula()) continue
      if (this.eatText()) continue
    }
    return this.extractedSrc
  }

  private eatHeadNewline() {
    if (this.src.startsWith('\n')) {
      this.extractedSrc += '\n'
      this.src = this.src.substring(1)
      return true
    }
    return false
  }

  private eatBlockCode() {
    const cap = rules.extractor.blockCode.exec(this.src)
    if (!cap) return false
    this.extractedSrc += cap[0]
    this.src = this.src.substring(cap[0].length)
    return true
  }

  private eatFencesCode() {
    const cap = rules.extractor.fences.exec(this.src)
    if (!cap) return false
    this.extractedSrc += cap[0]
    this.src = this.src.substring(cap[0].length)
    return true
  }

  private eatInlineCode() {
    const cap = rules.extractor.inlineCode.exec(this.src)
    if (!cap) return false
    this.extractedSrc += cap[0]
    this.src = this.src.substring(cap[0].length)
    return true
  }

  private eatCode() {
    if (this.eatHeadNewline()) {
      if (this.eatBlockCode()) return true
      if (this.eatFencesCode()) return true
    }
    return this.eatInlineCode()
  }

  private eatHtml() {
    const html = HTMLRemover.remove(this.src, this.formulae)
    if (!html) return false
    this.src = this.src.substring(html.rawLength)
    this.extractedSrc += `!HTML[${this.htmlList.length}][${createHash(
      html.text
    )}][${html.text.split('\n').length - 1}]`
    this.htmlList.push(html)
    return true
  }

  private eatFormula() {
    const formula = FormulaRemover.remove(this.src)
    if (!formula) return false
    this.src = this.src.substring(formula.text.length)
    this.extractedSrc += `!FORMULA[${this.formulae.length}][${createHash(
      formula.text
    )}][${formula.text.split('\n').length - 1}]`
    this.formulae.push(formula)
    return true
  }

  private eatText() {
    const cap = rules.extractor.text.global.exec(this.src)
    if (!cap) return false
    this.src = this.src.substring(cap[0].length)
    this.extractedSrc += cap[0]
    return true
  }
}
