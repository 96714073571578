import { Lexer } from '../../lexer'
import { rules } from '../../rules'
import type { Tokens } from '../../types'
import { rtrim } from '../utils'

export const heading = (src: string, lexer: Lexer): Tokens.Heading | null => {
  const cap = rules.block.heading.exec(src)
  if (!cap) return null
  let text = cap[2].trim()
  const label =
    cap[3] !== undefined ? cap[3].replace('[', '').replace(']', '') : undefined

  // remove trailing #s
  if (text.endsWith('#')) {
    const trimmed = rtrim(text, '#')
    if (!trimmed || trimmed.endsWith(' ')) {
      // CommonMark requires space before trailing #s
      text = trimmed.trim()
    }
  }

  const token: Tokens.Heading = {
    type: 'heading',
    raw: cap[0],
    depth: cap[1].length,
    text: text,
    label: label,
    tokens: [],
  }
  lexer.inline(token.text, token.tokens)
  return token
}
