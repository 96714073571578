const escapeHTMLRender = (formula: string) => {
  // <のすぐ後に英字が来ているとHTMLと干渉するのでスペースを入れる
  // XY-Picの矢印などに不当なスペースが入ると不具合になるので、エスケープは最小限にする。
  return formula.replace(/<(?=[a-zA-Z])/g, '< ')
}

export const formula = (
  text: string,
  hash: number,
  align: 'center' | 'left' | 'right' | undefined,
  error: boolean,
  cached: boolean
) => {
  return `<span class="formula ${align ? `formula-align` : ''} ${
    cached ? 'cached-formula' : 'not-cached-formula'
  }" data-hash="${hash}" ${align ? `data-align="${align}"` : ''}>${
    cached ? text : escapeHTMLRender(text)
  }</span>`
}
