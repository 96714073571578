import { query, where } from 'firebase/firestore'
import { payoutApplicationsRef } from '../firebase'

export const thisMonthPayoutApplicationsRef = (uid: string) => {
  const now = new Date()
  const thisMonthBeginning = new Date(now.getFullYear(), now.getMonth())
  return query(
    payoutApplicationsRef,
    where('uid', '==', uid),
    where('created_at', '>=', thisMonthBeginning)
  )
}
