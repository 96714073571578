import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { Tag } from '../../firebase'
import { tagIdsQuery } from '../../models/tag'

export const useTags = (tagIds?: string[], defaultTags?: Tag[]) => {
  const [tags, loading, error] = useCollectionDataOnce(
    tagIds && tagIds.length > 0 ? tagIdsQuery(tagIds) : null,
    { initialValue: defaultTags ?? [] }
  )
  return { tags, loading, error }
}
