import { Lexer } from '../../lexer'
import { rules } from '../../rules'
import type { Tokens } from '../../types'

export const list = (src: string, lexer: Lexer): Tokens.List | null => {
  let cap = rules.block.list.exec(src)
  if (!cap) return null

  let raw = '',
    istask: RegExpExecArray | null = null,
    ischecked = false,
    indent: number,
    i: number,
    blankLine: boolean,
    endsWithBlankLine = false,
    line: string,
    rawLine: string,
    itemContents = ''

  let bull = cap[1].trim()
  const isOrdered = bull.length > 1
  const isRoman = bull.match(/R/) !== null
  const isParentheses = bull.startsWith('(')
  const isBrackets = bull.startsWith('[')

  const list: Tokens.List = {
    type: 'list',
    raw: '',
    ordered: isOrdered,
    // start: isordered ? +bull.slice(0, -1) : "",
    start: isOrdered ? Number(/\d+/.exec(bull)) : '',
    roman: isRoman,
    parentheses: isParentheses,
    brackets: isBrackets,
    loose: false,
    items: [],
  }

  if (!isOrdered) {
    bull = `\\${bull}`
  } else if (isParentheses) {
    bull = `\\(${isRoman ? 'R' : ''}\\d{1,9}\\)`
  } else if (isBrackets) {
    bull = `\\[${isRoman ? 'R' : ''}\\d{1,9}\\]`
  } else {
    bull = `${isRoman ? 'R' : ''}\\d{1,9}\\${bull.slice(-1)}`
  }

  // Get next list item
  const itemRegex = new RegExp(`^( {0,3})${bull} ((?:[^\\n]*)?(?:\\n|$))`)

  // Check if current bullet point can start a new List Item
  while (src) {
    if (!(cap = itemRegex.exec(src))) {
      break
    }

    if (rules.block.hr.test(src)) {
      // End list if bullet was actually HR
      break
    }

    raw = cap[0] || ''
    src = src.substring(raw.length)

    indent = cap[1].length

    line = cap[2].split('\n', 1)[0]

    itemContents = line

    blankLine = false

    const nextBulletRegex = new RegExp(
      `^ {${indent}}(?:[*+-]|R?\\d{1,9}[.)](\\s|$)|\\(R?\\d{1,9}\\)(\\s|$)|\\[R?\\d{1,9}\\](\\s|$))`
    )

    // Check if following lines should be included in List Item
    while (src) {
      rawLine = src.split('\n', 1)[0]
      line = rawLine

      // End list item if found start of new bullet
      if (nextBulletRegex.test(line)) {
        break
      }

      if (rules.block.heading.test(src)) break
      if (rules.block.fences.test(src)) break
      if (rules.block.box.test(src)) break
      if (rules.block.blockquote.test(src)) break

      if (blankLine) break

      if (line.search(/[^ ]/) >= indent + 1 || !line.trim()) {
        // Dedent if possible
        itemContents += '\n' + line.slice(indent + 1)
      } else if (!blankLine) {
        // Until blank line, item doesn't need indentation
        itemContents += '\n' + line
      } else {
        // Otherwise, improper indentation ends this item
        break
      }

      if (!blankLine && !line.trim()) {
        // Check if current line is blank
        blankLine = true
      }

      raw += rawLine + '\n'
      src = src.substring(rawLine.length + 1)
    }

    if (!list.loose) {
      // If the previous item ended with a blank line, the list is loose
      if (endsWithBlankLine) {
        list.loose = true
      } else if (/\n *\n *$/.test(raw)) {
        endsWithBlankLine = true
      }
    }

    // Check for task list items
    istask = /^\[[ xX]\] /.exec(itemContents)
    if (istask) {
      ischecked = istask[0] !== '[ ] '
      itemContents = itemContents.replace(/^\[[ xX]\] +/, '') || ''
    }

    list.items.push({
      type: 'list_item',
      raw,
      task: !!istask,
      checked: ischecked,
      loose: false,
      tokens: [],
      text: itemContents,
    })

    list.raw += raw
  }

  // Do not consume newlines at end of final item. Alternatively, make itemRegex *start* with any newlines to simplify/speed up endsWithBlankLine logic
  list.items[list.items.length - 1].raw = raw.trimEnd()
  list.items[list.items.length - 1].text = itemContents.trimEnd()
  list.raw = list.raw.trimEnd()

  // Item child tokens handled here at end because we needed to have the final item to trim it first
  for (i = 0; i < list.items.length; i++) {
    lexer.state.top = false
    list.items[i].tokens = lexer.blockTokens(list.items[i].text, [])
  }

  return list
}
