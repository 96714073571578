import { Lexer } from '..'
import { rules } from '../../rules'
import * as Tokenizer from '../../tokenizer/inline'
import type { Token } from '../../types'

// copied from https://stackoverflow.com/a/5450113/806777
export const repeatString = (pattern: string, count: number) => {
  if (count < 1) {
    return ''
  }
  let result = ''
  while (count > 1) {
    if (count & 1) {
      result += pattern
    }
    count >>= 1
    pattern += pattern
  }
  return result + pattern
}

export class InlineLexer {
  private lexer: Lexer
  private src = ''
  private maskedSrc = ''
  private tokens: Token[] = []
  private keepPrevChar = false
  private prevChar = ''

  constructor(lexer: Lexer) {
    this.lexer = lexer
  }

  private get lastToken() {
    return this.tokens[this.tokens.length - 1]
  }

  lex(src: string, tokens: Token[]) {
    this.src = src
    this.tokens = tokens

    // String with links masked to avoid interference with em and strong
    this.maskedSrc = this.maskedOut(this.src)
    while (this.src) {
      if (!this.keepPrevChar) this.prevChar = ''
      this.keepPrevChar = false

      if (this.eatEscape()) continue
      if (this.eatShortenedLink()) continue
      if (this.eatTag()) continue
      if (this.eatLink()) continue
      if (this.eatReflink()) continue
      if (this.eatEmStrong()) continue
      if (this.eatCode()) continue
      if (this.eatFormula()) continue
      if (this.eatHtml()) continue
      if (this.eatBr()) continue
      if (this.eatDel()) continue
      if (this.eatAutolink()) continue
      if (this.eatUrl()) continue
      if (this.eatText()) continue
      if (this.src) {
        const errMsg = `Infinite loop on byte: ${this.src.charCodeAt(0)}`
        console.error(errMsg)
      }
    }

    return this.tokens
  }

  private maskedOut(src: string) {
    let maskedSrc = src
    // Mask out reflinks
    if (this.lexer.links !== undefined) {
      const links = Object.keys(this.lexer.links)
      if (links.length > 0) {
        while (true) {
          const match = rules.inline.reflinkSearch.exec(maskedSrc)
          if (!match) break
          if (
            links.includes(match[0].slice(match[0].lastIndexOf('[') + 1, -1))
          ) {
            maskedSrc =
              maskedSrc.slice(0, match.index) +
              '[' +
              repeatString('a', match[0].length - 2) +
              ']' +
              maskedSrc.slice(rules.inline.reflinkSearch.lastIndex)
          }
        }
      }
    }

    // Mask out other blocks
    while (true) {
      const match = rules.inline.blockSkip.exec(maskedSrc)
      if (!match) break
      maskedSrc =
        maskedSrc.slice(0, match.index) +
        '[' +
        repeatString('a', match[0].length - 2) +
        ']' +
        maskedSrc.slice(rules.inline.blockSkip.lastIndex)
    }

    // Mask out escaped em & strong delimiters
    while (true) {
      const match = rules.inline.escapedEmSt.exec(maskedSrc)
      if (!match) break
      maskedSrc =
        maskedSrc.slice(0, match.index) +
        '++' +
        maskedSrc.slice(rules.inline.escapedEmSt.lastIndex)
    }

    return maskedSrc
  }

  private eat(token: Token | null) {
    if (token) {
      this.src = this.src.substring(token.raw.length)
      this.tokens.push(token)
    }
    return !!token
  }

  private eatEscape() {
    const token = Tokenizer.escape(this.src)
    return this.eat(token)
  }

  private eatTag() {
    const token = Tokenizer.tag(this.src, this.lexer)
    return this.eat(token)
  }

  private eatShortenedLink() {
    const token = Tokenizer.shortenedLink(this.src)
    return this.eat(token)
  }

  private eatLink() {
    const token = Tokenizer.link(this.src, this.lexer)
    return this.eat(token)
  }

  // reflink, nolink
  private eatReflink() {
    const token = Tokenizer.reflink(this.src, this.lexer.links, this.lexer)
    if (token) {
      this.src = this.src.substring(token.raw.length)
      if (
        this.lastToken !== undefined &&
        token.type === 'text' &&
        this.lastToken.type === 'text'
      ) {
        this.lastToken.raw += token.raw
        this.lastToken.text += token.text
      } else this.tokens.push(token)
    }
    return !!token
  }

  private eatEmStrong() {
    const token = Tokenizer.emStrong(
      this.src,
      this.maskedSrc,
      this.prevChar,
      this.lexer
    )
    return this.eat(token)
  }

  private eatCode() {
    const token = Tokenizer.codespan(this.src)
    return this.eat(token)
  }

  private eatFormula() {
    const token = Tokenizer.formula(
      this.src,
      this.lexer.formulae,
      this.lexer.formulaeCache
    )
    return this.eat(token)
  }

  private eatHtml() {
    const token = Tokenizer.html(this.src, this.lexer, this.lexer.htmlList)
    return this.eat(token)
  }

  private eatBr() {
    const token = Tokenizer.br(this.src)
    return this.eat(token)
  }

  private eatDel() {
    const token = Tokenizer.del(this.src, this.lexer)
    return this.eat(token)
  }

  private eatAutolink() {
    const token = Tokenizer.autolink(this.src)
    return this.eat(token)
  }

  private eatUrl() {
    if (this.lexer.state.inLink) return false
    const token = Tokenizer.url(this.src)
    return this.eat(token)
  }

  private eatText() {
    const token = Tokenizer.text(this.src, this.lexer)
    if (token) {
      this.src = this.src.substring(token.raw.length)
      if (!token.raw.endsWith('_')) {
        // Track prevChar before string of ____ started
        this.prevChar = token.raw.slice(-1)
      }
      this.keepPrevChar = true
      if (this.lastToken !== undefined && this.lastToken.type === 'text') {
        this.lastToken.raw += token.raw
        this.lastToken.text += token.text
      } else this.tokens.push(token)
    }
    return !!token
  }
}
