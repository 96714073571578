import { rules } from '../../rules'
import type { Tokens } from '../../types'
import { escapeSpecialCharacters } from '../utils'

export const codespan = (src: string): Tokens.Codespan | null => {
  const cap = rules.inline.code.exec(src)
  if (!cap) return null
  let text = cap[2].replace(/\n/g, ' ')
  const hasNonSpaceChars = /[^ ]/.test(text)
  const hasSpaceCharsOnBothEnds = text.startsWith(' ') && text.endsWith(' ')
  if (hasNonSpaceChars && hasSpaceCharsOnBothEnds) {
    text = text.substring(1, text.length - 1)
  }
  text = escapeSpecialCharacters(text, true)
  return {
    type: 'codespan',
    raw: cap[0],
    text,
  }
}
