import { collection, doc, DocRef, Timestamp } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document } from '../document'
import { accountRef } from './account'
import { SaleSubcols } from './subcollections'

export interface Sale extends Document {
  readonly parent_id: 'sales'
  readonly ref: DocRef<Sale>
  readonly subcollections: typeof SaleSubcols
  message: { uid?: string; body: 'send_badge' | 'transfer_from_old_env' }
  amount: number
  payment_id?: string
  has_paid: boolean
  paid_at?: Timestamp
  payout_application_id: string | null
}

export const saleConverter = getConverter<Sale>(SaleSubcols)
export const salesRef = (uid: string) =>
  collection<Sale>(accountRef(uid), 'sales').withConverter<Sale>(saleConverter)
export const saleRef = (uid: string, id: string) => doc(salesRef(uid), id)
