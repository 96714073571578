import { isBlank } from '../../../../../utils'
import { Lexer } from '../../lexer'
import { rules } from '../../rules'
import type { Links, Tokens } from '../../types'
import { outputLink } from '../utils'

export const reflink = (
  src: string,
  links: Links,
  lexer: Lexer
): Tokens.Text | Tokens.Link | Tokens.Image | null => {
  let cap
  if (
    (cap = rules.inline.reflink.exec(src)) ||
    (cap = rules.inline.nolink.exec(src))
  ) {
    const linkText = (cap[2] || cap[1]).replace(/\s+/g, ' ')
    const link = links[linkText.toLowerCase()]
    if (isBlank(link) || isBlank(link.href)) {
      const text = cap[0].charAt(0)
      return {
        type: 'text',
        raw: text,
        text,
      }
    }
    return outputLink(cap, link, cap[0], lexer)
  } else return null
}
