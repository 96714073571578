import { isBlank } from '../../../utils'
import { Profile } from './profile'

export const isCurrentUser = (
  currentUser: undefined | null | Profile,
  profileId: string
) => !isBlank(currentUser) && currentUser.id === profileId

export const isNotCurrentUser = (
  currentUser: undefined | null | Profile,
  profileId: string
) => !isCurrentUser(currentUser, profileId)
