import { z } from 'zod'
import { SocialAccounts } from '../firestore'
import { SearchEndpointParams, SearchEndpointResult } from './search_endpoint'

/**
 * Functionのスキーマ
 *
 * Function名をキーとし、zodによる型付与でFunctionsの引数の型を定義する
 */
export const FunctionsParamsSchema = {
  updateSocialAccounts: z.object({}),
  search: SearchEndpointParams,
  fetchImageByURL: z.object({
    url: z.string(),
  }),
  createStripeCheckoutSession: z.object({
    mode: z.enum(['payment', 'subscription']),
    price: z.string(),
    successCallbackURL: z.string(),
    cancelCallbackURL: z.string(),
    metadata: z.record(z.any()).default({}),
  }),
  subscribePremiumPlan: z.object({
    payment_method_id: z.string(),
    interval: z.enum(['monthly', 'yearly']),
  }),
  changePremiumPlanPrice: z.object({
    interval: z.enum(['monthly', 'yearly']),
  }),
  getUidByEmail: z.object({
    email: z.string().email(),
  }),
  getUidByDraft: z.object({
    collection: z.enum(['article_drafts', 'book_drafts']),
    draft_id: z.string(),
  }),
  changeSeriesArticles: z.object({
    operation: z.enum(['add', 'remove']),
    article_id: z.string(),
    series_id: z.string(),
  }),
  'ext-firestore-stripe-payments-createPortalLink': z.object({
    returnUrl: z.string().url(),
    locale: z.string().optional(), // default: auto
    configuration: z.string().optional(), // ポータルの設定のID デフォルトはGUIから編集出来る設定が選択されている
  }),
}

/**
 * Functionの戻り値
 *
 * Functionの戻り値を定義する
 */
export interface Functions {
  updateSocialAccounts: {
    input: z.input<typeof FunctionsParamsSchema['updateSocialAccounts']>
    output: z.output<typeof FunctionsParamsSchema['updateSocialAccounts']>
    returns: { success: true; social_accounts: SocialAccounts }
  }
  search: {
    input: z.input<typeof FunctionsParamsSchema['search']>
    output: z.output<typeof FunctionsParamsSchema['search']>
    returns: SearchEndpointResult
  }
  fetchImageByURL: {
    input: z.input<typeof FunctionsParamsSchema['fetchImageByURL']>
    output: z.output<typeof FunctionsParamsSchema['fetchImageByURL']>
    returns: { image: string }
  }
  createStripeCheckoutSession: {
    input: z.input<typeof FunctionsParamsSchema['createStripeCheckoutSession']>
    output: z.output<
      typeof FunctionsParamsSchema['createStripeCheckoutSession']
    >
    returns: { success: true; url: string }
  }
  subscribePremiumPlan: {
    input: z.input<typeof FunctionsParamsSchema['subscribePremiumPlan']>
    output: z.output<typeof FunctionsParamsSchema['subscribePremiumPlan']>
    returns: { success: true }
  }
  changePremiumPlanPrice: {
    input: z.input<typeof FunctionsParamsSchema['changePremiumPlanPrice']>
    output: z.output<typeof FunctionsParamsSchema['changePremiumPlanPrice']>
    returns: { success: true }
  }
  getUidByEmail: {
    input: z.input<typeof FunctionsParamsSchema['getUidByEmail']>
    output: z.output<typeof FunctionsParamsSchema['getUidByEmail']>
    returns: { success: true; uid: string }
  }
  getUidByDraft: {
    input: z.input<typeof FunctionsParamsSchema['getUidByDraft']>
    output: z.output<typeof FunctionsParamsSchema['getUidByDraft']>
    returns: { success: true; uid: string | null }
  }
  changeSeriesArticles: {
    input: z.input<typeof FunctionsParamsSchema['changeSeriesArticles']>
    output: z.output<typeof FunctionsParamsSchema['changeSeriesArticles']>
    returns: { success: true }
  }
  'ext-firestore-stripe-payments-createPortalLink': {
    input: z.input<
      typeof FunctionsParamsSchema['ext-firestore-stripe-payments-createPortalLink']
    >
    output: z.output<
      typeof FunctionsParamsSchema['ext-firestore-stripe-payments-createPortalLink']
    >
    returns: {
      url: string
    }
  }
}
