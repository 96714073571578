import { rules } from '../../rules'
import type { Tokens } from '../../types'
import { escapeSpecialCharacters, mangle } from '../utils'

export const url = (src: string): Tokens.Link | null => {
  const cap = rules.inline.url.exec(src)
  if (!cap) return null

  let text, href
  if (cap[2] === '@') {
    text = escapeSpecialCharacters(mangle(cap[0]))
    href = 'mailto:' + text
  } else {
    // do extended autolink path validation
    let prevCapZero
    do {
      prevCapZero = cap[0]
      const backpedalCap = rules.inline._backpedal.exec(cap[0])
      cap[0] = backpedalCap ? backpedalCap[0] : ''
    } while (prevCapZero !== cap[0])
    text = escapeSpecialCharacters(cap[0])
    if (cap[1] === 'www.') {
      href = 'http://' + text
    } else {
      href = text
    }
  }
  return {
    type: 'link',
    raw: cap[0],
    text,
    href,
    tokens: [
      {
        type: 'text',
        raw: text,
        text,
      },
    ],
  }
}
