import { DocRef, collection, db, doc } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document } from '../document'
import { accountRef } from './account'
import { AuthorizationDetails } from './authorization'
import { ContentBase } from './content'
import { BookDraftSubcols, BookSubcols } from './subcollections'
import { WithUserTimestamp } from './user_timestamp'

type Thumbnail = { url: string; filename: string; full_path: string }

export interface Book extends ContentBase {
  readonly ref: DocRef<Book>
  readonly parent_id: 'books'
  readonly subcollections: typeof BookSubcols
  thumbnail?: Thumbnail // Cloud Storage path : "/uploads/book_thumbnails/{id}.{png/jpeg}"
  description: string
}

export interface BookDraft extends Document, WithUserTimestamp {
  readonly ref: DocRef<BookDraft>
  readonly parent_id: 'book_drafts'
  readonly subcollections: typeof BookDraftSubcols

  /**
   * idと同じ値が入るがFirestoreに保存している
   * collectionGroup検索でidによる検索ができないため、この設計で妥協している
   */
  document_id: string
  created_by: string

  title: string
  thumbnail?: Thumbnail // Cloud Storage path : "/uploads/book_draft_thumbnails/{uid}/{id}.{png/jpeg}"
  description: string
  publication_id?: string // 存在する場合は更新用の下書き, 検索用

  /** 共同編集が有効になっているか・削除予定 */
  is_collaborative?: never

  /** 実際に共同編集を行なったユーザ */
  collaborator_uids: string[]

  /** 共同編集に参加できるユーザ */
  editability_details: AuthorizationDetails
}

export const bookConverter = getConverter<Book>(BookSubcols)
export const booksRef = collection<Book>(db, `books`).withConverter(
  bookConverter
)
export const bookRef = (id: string) => doc(booksRef, id)

export const booksRefWithoutConverter = collection<Book>(db, `books`)
export const bookRefWithoutConverter = (id: string) =>
  doc(booksRefWithoutConverter, id)

export const bookDraftConverter = getConverter<BookDraft>(BookDraftSubcols)
export const bookDraftsRef = (uid: string) =>
  collection<BookDraft>(accountRef(uid), 'book_drafts').withConverter(
    bookDraftConverter
  )
export const bookDraftRef = (uid: string, id: string) =>
  doc(bookDraftsRef(uid), id)
