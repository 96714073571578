import { rules } from '../../rules'
import type { Formula } from '../../types'

export class FormulaRemover {
  private src = ''
  private text = ''
  private stack: string[] = []

  private eatEnclosure() {
    const cap = rules.extractor.formula.exec(this.src)
    if (!cap) return false
    this.src = this.src.substring(cap[0].length)
    const type = cap[1] || cap[0]
    switch (type) {
      case '$':
        this.text += cap[0]
        if (this.stack.slice(-1)[0] === '$') this.stack.pop()
        else this.stack.push('$')
        break

      case '$$':
        this.text += cap[0]
        if (this.stack.slice(-1)[0] === '$$') this.stack.pop()
        else if (this.stack.slice(-1)[0] === '$') {
          this.text = this.text.slice(0, -1)
          this.src = '$' + this.src
          this.stack.pop()
        } else this.stack.push('$$')
        break

      case '\\begin':
        this.text += cap[0]
        this.stack.push('environment')
        break

      case '\\end':
        this.text += cap[0]
        if (this.stack.pop() !== 'environment') throw new Error()
        break

      case '{':
        if (this.stack.length > 0) {
          this.stack.push('bracket')
          this.text += cap[0]
        }
        break

      case '}':
        if (this.stack.pop() === 'bracket') {
          this.text += cap[0]
        }
        break
    }
    return true
  }

  private eatText() {
    const cap = rules.extractor.text.formula.exec(this.src)
    if (!cap) return false
    this.src = this.src.substring(cap[0].length)
    this.text += cap[0]
    return true
  }

  private remove(src: string): Formula | null {
    this.src = src
    this.text = ''
    this.stack = []
    do {
      try {
        if (this.eatEnclosure()) continue
        if (this.stack.length !== 0 && this.eatText()) continue
      } catch (error) {
        break
      }
    } while (this.stack.length !== 0 && this.src)
    return this.text !== ''
      ? {
          text: this.text,
          error: this.stack.length !== 0,
        }
      : null
  }

  static remove(src: string) {
    const remover = new FormulaRemover()
    return remover.remove(src)
  }
}
