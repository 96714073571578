import { collection, doc, DocRef } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document, ParentRefAssignable, ValidDocument } from '../document'
import { PVShardSubcols } from './subcollections'

export interface PVShard extends Document {
  readonly ref: DocRef<PVShard>
  readonly parent_id: 'pv_shards'
  readonly subcollections: typeof PVShardSubcols
  count: number
}

export const PV_SHARDS_IDS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

export interface PVShardsParent extends ValidDocument {
  ref: PVShardsParentRefAssignable<PVShardsParent>
  pv: number
  created_by?: string
}

export type PVShardsParentRefAssignable<T extends PVShardsParent> =
  ParentRefAssignable<PVShard, T>

export const pvShardConverter = getConverter<PVShard>(PVShardSubcols)
export const pvShardsRef = <T extends PVShardsParent>(
  pvShardsParent: PVShardsParentRefAssignable<T>
) =>
  collection<PVShard>(pvShardsParent, 'pv_shards').withConverter(
    pvShardConverter
  )
export const pvShardRef = <T extends PVShardsParent>(
  pvShardsParent: PVShardsParentRefAssignable<T>,
  id: typeof PV_SHARDS_IDS[number]
) => doc(pvShardsRef(pvShardsParent), `${id}`)
