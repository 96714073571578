import { useEffect, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { useController, useForm } from 'react-hook-form'
import {
  CreateParamsWithoutFieldValue,
  MathlogArticleReference,
  articleRef,
  getDoc,
} from '../../../firebase'
import { remove } from '../../../utils'
import { ActionButtons } from './ActionButtons'
import { EachReferenceFormProps } from './ReferenceForm'

export const MathlogArticleForm = ({
  onClickSaveButton: handleClickSaveButton,
  onClickCancelButton: handleClickCancelButton,
  reference,
  nextOrder,
}: EachReferenceFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isNotFound, setIsNotFound] = useState(false)
  const [isGetting, setIsGetting] = useState(false)

  const {
    register,
    getValues,
    reset,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm<CreateParamsWithoutFieldValue<MathlogArticleReference>>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const {
    field: { onChange: handleArticleIdChange, value: articleId },
  } = useController({
    name: 'article_id',
    control,
    rules: { required: true },
  })

  const resetWithDefaultValues = () => reset({ type: 'mathlog_article' })

  useEffect(() => {
    if (reference && reference.type === 'mathlog_article')
      reset(remove(reference, ['id', 'parent_id', 'ref']))
    else resetWithDefaultValues()
  }, [reference])

  console.log(isValid)

  return (
    <Form>
      <div className="h6 text-danger">必須</div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>URL</InputGroup.Text>
          <InputGroup.Text>https://mathlog.info/articles/</InputGroup.Text>
          <Form.Control
            value={articleId}
            isInvalid={!!errors.article_id}
            onChange={async (e) => {
              handleArticleIdChange(e)
              setIsGetting(true)
              const articleId = e.currentTarget.value
              if (articleId !== '') {
                try {
                  const snapshot = await getDoc(articleRef(articleId))
                  setIsNotFound(!snapshot.exists())
                } catch (error) {
                  setIsNotFound(true)
                }
              } else {
                setIsNotFound(false)
              }
              setIsGetting(false)
            }}
          />
        </InputGroup>
        {isNotFound && (
          <Form.Text className="text-danger">
            その記事は存在しません。
          </Form.Text>
        )}
      </div>
      <div className="h6">任意</div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>ラベル</InputGroup.Text>
          <Form.Control {...register('label')} isInvalid={!!errors.label} />
        </InputGroup>
        <Form.Text>
          本文で<code>[[ラベル]]</code>
          で参考文献の番号を使えます。
        </Form.Text>
      </div>
      <ActionButtons
        onClickCancelButton={async () => {
          resetWithDefaultValues()
          await handleClickCancelButton()
        }}
        onClickSaveButton={async () => {
          setIsSubmitting(true)
          const params = getValues()
          if (!reference) params.order = nextOrder
          await handleClickSaveButton(params)
          setIsSubmitting(false)
          resetWithDefaultValues()
        }}
        saveButtonLoading={isSubmitting}
        saveButtonDisabled={!isValid || isNotFound || isGetting}
        cancelButtonDisabled={!isDirty && !reference}
        isUpdate={!!reference}
      />
    </Form>
  )
}
