import { useCallback } from 'react'
import useSWRInfinite from 'swr/infinite'
import { z } from 'zod'
import { ESBook } from '../../elastic_search'
import { fb } from '../../firebase'
import { BookSearchEndpointParams } from '../../shared/firebase/functions/search_endpoint'
import { deserializeArray } from '../../utils'

type Params = z.input<typeof BookSearchEndpointParams>
type SWRKey = {
  index: 'books'
  params: Params
  page: number
  size: number
}

const PAGE_SIZE = 20

const fetchBooks = async (key: SWRKey): Promise<ESBook[]> => {
  const { data } = await fb.call('search')(key)
  if (data.index !== 'books') throw new Error('Unexpected result.')
  return deserializeArray(data.result, {})
}

export const useESBooks = (
  params: Params | null,
  fallback?: ESBook[],
  pageSize = PAGE_SIZE
) => {
  const getKey = useCallback(
    (index: number): SWRKey | null => {
      if (params === null) return null
      return {
        params,
        page: index + 1,
        size: pageSize,
        index: 'books',
      }
    },
    [params]
  )
  const { data, size, setSize, isLoading, isValidating } = useSWRInfinite<
    ESBook[]
  >(getKey, fetchBooks, { fallbackData: fallback ? [fallback] : undefined })

  const isLoadingMore =
    isLoading || (size > 0 && data && typeof data[size - 1] === 'undefined')
  const isEmpty = data?.[0] !== undefined ? data[0].length === 0 : undefined
  const isReachingEnd =
    data !== undefined
      ? (data.length >= 2 &&
          data[data.length - 2].length < pageSize &&
          data[data.length - 1].length === 0) ||
        (data.length === 1 && data[0].length === 0)
      : undefined
  const isRefreshing = isValidating && data && data.length === size

  const books: ESBook[] | undefined =
    data && data.length > 0 ? ([] as ESBook[]).concat(...data) : undefined

  const loadMore = async () => {
    await setSize(size + 1)
  }

  return {
    books,
    loadMore,
    isReachingEnd,
    isLoadingMore,
    isEmpty,
    isRefreshing,
  }
}
