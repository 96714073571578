import { useEffect, useState } from 'react'
import { z } from 'zod'

export const AfterSignedInPathParser = z.object({
  path: z.string(),
  created_at: z.string(),
})

export const useAfterSignedInPath = () => {
  const [afterSignedInPathState, setAfterSignedInPathState] = useState<
    string | null
  >()

  useEffect(() => {
    const rawAfterSignedInPath = localStorage.getItem('afterSignedInPath')
    if (rawAfterSignedInPath === null) {
      setAfterSignedInPathState(null)
      return
    }
    try {
      const afterSignedInPath = AfterSignedInPathParser.parse(
        JSON.parse(rawAfterSignedInPath)
      )
      const createdAt = new Date(afterSignedInPath.created_at)
      const now = new Date()
      setAfterSignedInPathState(
        now.getTime() - createdAt.getTime() < 10 * 60 * 1000
          ? afterSignedInPath.path
          : null
      )
    } catch (error) {
      setAfterSignedInPathState(null)
    }
  }, [])

  const setAfterSignedInPath = (path: string | null) => {
    const now = new Date()
    setAfterSignedInPathState(path)
    if (path !== null)
      localStorage.setItem(
        'afterSignedInPath',
        JSON.stringify({
          path: path,
          created_at: now.toISOString(),
        })
      )
    else localStorage.removeItem('afterSignedInPath')
  }

  return { afterSignedInPath: afterSignedInPathState, setAfterSignedInPath }
}
