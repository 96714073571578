import { Modal } from 'react-bootstrap'
import { FaPencilAlt } from 'react-icons/fa'
import { IconWrapper } from '../IconWrapper'
import { Profile } from '../../firebase'
import { PromiseVoid } from '../../types'
import { UserProfileForm } from './ProfileForm'

type Props = {
  show: boolean
  onHide: () => PromiseVoid
  profile: Profile
}

export const ProfileEditorModal = ({
  show,
  onHide: handleHide,
  profile,
}: Props) => (
  <Modal show={show} onHide={handleHide} scrollable>
    <Modal.Header closeButton className="py-2">
      <Modal.Title>
        <IconWrapper suffix="プロフィールを編集" className="fs-6 text-muted">
          <FaPencilAlt />
        </IconWrapper>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {show && (
        <UserProfileForm
          profile={profile}
          onUpdate={async () => {
            await handleHide()
          }}
        />
      )}
    </Modal.Body>
  </Modal>
)
