import { deleteField } from 'firebase/firestore'
import { useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { usePopupMessage } from '../../assets/messenger'
import { SpinnerButton } from '../../assets/spinners'
import { Profile, Timestamp, updateDoc } from '../../firebase'
import { Designed, PromiseVoid } from '../../types'
import { UserImageUploader } from './ImageUploader'

type Props = {
  profile: Profile
  onUpdate?: () => PromiseVoid
}

type ProfileFormFields = {
  comment: string
  display_name: string
  website: string
}

export const UserProfileForm = ({
  profile,
  onUpdate: handleUpdate,
  ...otherProps
}: Designed<Props>) => {
  const { setPopupMessage } = usePopupMessage()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<ProfileFormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      display_name: profile.display_name,
      comment: profile.comment,
      website: profile.website,
    },
  })

  return (
    <Form
      onSubmit={handleSubmit(async (params) => {
        setIsSubmitting(true)
        const now = Timestamp.now()
        await updateDoc(profile.ref, {
          ...params,
          website: params.website !== '' ? params.website : deleteField(),
          updated_by_user_at: now,
        })
        if (handleUpdate) await handleUpdate()
        setPopupMessage('プロフィールを更新しました。')
        setIsSubmitting(false)
      })}
      {...otherProps}
    >
      <Form.Group className="mb-6">
        <Form.Label>プロフィール画像</Form.Label>
        <UserImageUploader profile={profile} size="100px" />
      </Form.Group>
      <Form.Group className="mb-6">
        <Form.Label>表示名</Form.Label>
        <Form.Control
          {...register('display_name', {
            required: true,
          })}
        />
        <Form.Control.Feedback type="invalid">
          {errors.display_name?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-6">
        <Form.Label>自己紹介文</Form.Label>
        <Form.Control as="textarea" rows={4} {...register('comment')} />
      </Form.Group>
      <Form.Group className="mb-6">
        <Form.Label>Webサイト</Form.Label>
        <InputGroup>
          <InputGroup.Text>http(s)://</InputGroup.Text>
          <Form.Control {...register('website')} />
        </InputGroup>
      </Form.Group>
      <SpinnerButton
        type="submit"
        loading={isSubmitting}
        disabled={!isDirty || !isValid}
      >
        プロフィールを更新
      </SpinnerButton>
    </Form>
  )
}
