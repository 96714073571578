import { RegexEditor } from '../utils/regex_editor'
import { _href } from './_href'
import { _label } from './_label'
import { _size } from './_size'
import { _title } from './_title'

const _link = /^!?\[(label)\]\(\s*(href)(?:\s+(size))?(?:\s+(title))?\s*\)/

export const link = new RegexEditor(_link)
  .replace('label', _label)
  .replace('href', _href)
  .replace('size', _size)
  .replace('title', _title)
  .getRegex()
