import { useEffect, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import {
  CreateParamsWithoutFieldValue,
  Timestamp,
  WebsiteReference,
} from '../../../firebase'
import { ActionButtons } from './ActionButtons'
import { EachReferenceFormProps } from './ReferenceForm'

// 日付は直接Formで扱えないので変換
type FieldProps = Omit<
  CreateParamsWithoutFieldValue<WebsiteReference>,
  'accessed_on' | 'published_on'
> & {
  accessed_on: string
  published_on?: string
}

export const WebsiteForm = ({
  onClickSaveButton: handleClickSaveButton,
  onClickCancelButton: handleClickCancelButton,
  reference,
  nextOrder,
}: EachReferenceFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    register,
    getValues,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<FieldProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const resetWithDefaultValues = () => reset({ type: 'website' })

  useEffect(() => {
    if (reference && reference.type === 'website') {
      const field: FieldProps = {
        type: reference.type,
        order: reference.order,
        title: reference.title,
        url: reference.url,
        accessed_on: reference.accessed_on.toDate().toDateString(),
        name: reference.name,
        author: reference.author,
        published_on: reference.published_on?.toDate().toDateString(),
      }
      reset(field)
    } else resetWithDefaultValues()
  }, [reference])

  return (
    <Form>
      <div className="h6 text-danger">必須</div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>タイトル</InputGroup.Text>
          <Form.Control
            {...register('title', {
              required: true,
            })}
            isInvalid={!!errors.title}
          />
        </InputGroup>
      </div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>サイトのURL</InputGroup.Text>
          <Form.Control
            {...register('url', {
              required: true,
            })}
            isInvalid={!!errors.url}
          />
        </InputGroup>
      </div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>アクセスした日</InputGroup.Text>
          <Form.Control
            type="date"
            {...register('accessed_on', {
              required: true,
            })}
          />
        </InputGroup>
      </div>
      <div className="h6">任意</div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>ラベル</InputGroup.Text>
          <Form.Control {...register('label')} isInvalid={!!errors.label} />
        </InputGroup>
        <Form.Text>
          本文で<code>[[ラベル]]</code>
          で参考文献の番号を使えます。
        </Form.Text>
      </div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>著者</InputGroup.Text>
          <Form.Control {...register('author')} isInvalid={!!errors.author} />
        </InputGroup>
      </div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>サイト名</InputGroup.Text>
          <Form.Control {...register('name')} isInvalid={!!errors.name} />
        </InputGroup>
      </div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>出版日</InputGroup.Text>
          <Form.Control type="date" {...register('published_on')} />
        </InputGroup>
      </div>
      <ActionButtons
        onClickCancelButton={async () => {
          resetWithDefaultValues()
          await handleClickCancelButton()
        }}
        onClickSaveButton={async () => {
          setIsSubmitting(true)
          const rawParams = getValues()
          if (!reference) rawParams.order = nextOrder
          const params: CreateParamsWithoutFieldValue<WebsiteReference> = {
            type: rawParams.type,
            order: rawParams.order,
            title: rawParams.title,
            url: rawParams.url,
            accessed_on: Timestamp.fromDate(new Date(rawParams.accessed_on)),
            name: rawParams.name,
            author: rawParams.author,
            published_on:
              rawParams.published_on !== undefined &&
              rawParams.published_on !== ''
                ? Timestamp.fromDate(new Date(rawParams.published_on))
                : undefined,
            label: rawParams.label,
          }
          await handleClickSaveButton(params)
          setIsSubmitting(false)
          resetWithDefaultValues()
        }}
        saveButtonLoading={isSubmitting}
        saveButtonDisabled={!isValid}
        cancelButtonDisabled={!isDirty && !reference}
        isUpdate={!!reference}
      />
    </Form>
  )
}
