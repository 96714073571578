import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaThumbsUp } from 'react-icons/fa'
import { useAccount } from '../../context/account'
import { ValidDocumentWithEvaluation } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { useGood } from '../../hooks'
import { Designed, PromiseVoid } from '../../types'
import { isBlank, logEvent } from '../../utils'
import { IconWrapper } from '../IconWrapper'
import { usePopupMessage } from '../messenger'

type Props = {
  evaluable: ValidDocumentWithEvaluation
  currentGoodCount?: number
  onChangeGoodCount?: (added: number, isDecrementBad: boolean) => PromiseVoid
  showCounter?: boolean
  size?: 'sm' | 'lg'
}

export const GoodButton = ({
  evaluable,
  currentGoodCount,
  onChangeGoodCount: handleChangeGoodCount,
  showCounter = false,
  ...otherProps
}: Designed<Props>) => {
  const { user, showSignInPopup } = useAuthState()
  const { isGood, good, cancelGood } = useGood(
    evaluable.ref,
    currentGoodCount,
    handleChangeGoodCount
  )
  const { account } = useAccount()
  const { setPopupMessage } = usePopupMessage()

  return (
    <OverlayTrigger overlay={<Tooltip id="button-tooltip">高評価</Tooltip>}>
      <Button
        {...otherProps}
        variant="link"
        onClick={async () => {
          if (user === undefined) return
          if (user === null) {
            showSignInPopup()
            return
          }
          if (user.uid === evaluable.created_by) {
            setPopupMessage('自分の記事は評価できません。', 'danger')
            return
          }
          if (isBlank(account)) return
          if (account.features.evaluate === false) {
            setPopupMessage(
              'あなたは現在評価機能を無効化されています。運営にお問合せしてください。',
              'danger'
            )
            return
          }
          if (isGood === undefined) return
          if (isGood) await cancelGood()
          else {
            await good()
            logEvent('tap_good_button', { uid: user?.uid })
          }
        }}
      >
        <IconWrapper
          className={isGood === true ? 'text-primary' : 'text-muted'}
          suffix={showCounter ? currentGoodCount : undefined}
        >
          <FaThumbsUp />
        </IconWrapper>
      </Button>
    </OverlayTrigger>
  )
}
