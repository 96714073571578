import { useState } from 'react'
import {
  Alert,
  Button,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { FaThumbsDown } from 'react-icons/fa'
import { useAccount } from '../../context/account'
import { ValidDocumentWithEvaluation } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { useBad } from '../../hooks/models/useBad'
import { Designed, PromiseVoid } from '../../types'
import { isBlank, logEvent } from '../../utils'
import { IconWrapper } from '../IconWrapper'
import { usePopupMessage } from '../messenger'

type Props = {
  evaluable: ValidDocumentWithEvaluation
  currentBadCount?: number
  onChangeBadCount?: (added: number, isDecrementGood: boolean) => PromiseVoid
  showCounter?: boolean
  size?: 'sm' | 'lg'
}

export const BadButton = ({
  evaluable,
  currentBadCount,
  onChangeBadCount: handleChangeBadCount,
  showCounter = false,
  ...otherProps
}: Designed<Props>) => {
  const { user, showSignInPopup } = useAuthState()
  const { isBad, bad, cancelBad } = useBad(
    evaluable.ref,
    currentBadCount,
    handleChangeBadCount
  )
  const { account } = useAccount()
  const { setPopupMessage } = usePopupMessage()
  const [show, setShow] = useState(false)
  const [reason, setReason] = useState('')

  return (
    <>
      <OverlayTrigger overlay={<Tooltip id="button-tooltip">低評価</Tooltip>}>
        <Button
          {...otherProps}
          variant="link"
          onClick={async () => {
            if (user === undefined) return
            if (user === null) {
              showSignInPopup()
              return
            }
            if (user.uid === evaluable.created_by) {
              setPopupMessage('自分の記事は評価できません。', 'danger')
              return
            }
            if (isBlank(account)) return
            if (account.features.evaluate === false) {
              setPopupMessage(
                'あなたは現在評価機能を無効化されています。運営にお問合せしてください。',
                'danger'
              )
              return
            }
            if (isBad === true) await cancelBad()
            else if (isBad === false) setShow(true)
          }}
        >
          <IconWrapper
            className={isBad === true ? 'text-primary' : 'text-muted'}
            suffix={showCounter ? currentBadCount : undefined}
          >
            <FaThumbsDown />
          </IconWrapper>
        </Button>
      </OverlayTrigger>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">低評価をする前に...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert className="py-2 mb-3" variant="warning">
            低評価をする前に著者に間違っているところや考慮が足りていない部分をコメントで指摘してあげませんか。
          </Alert>
          <h4 className="fs-6">低評価の理由</h4>
          <Form.Control
            value={reason}
            as="textarea"
            rows={4}
            onChange={(e) => setReason(e.currentTarget.value)}
          />
          <Form.Text className="text-muted small">
            ※ 低評価の理由は運営が確認しております。
            <br />
            ※
            運営が不当な低評価だと判断した場合は、低評価機能の利用の制限や低評価の取り消しを行うことがあります。
            <br />※ 低評価の理由は匿名で投稿者にお伝えすることがあります。
          </Form.Text>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShow(false)} variant="secondary" size="sm">
            キャンセル
          </Button>
          <Button
            onClick={async () => {
              if (reason === '') return
              if (isBad === false) {
                await bad(reason)
                logEvent('tap_bad_button', { uid: user?.uid })
              }
              setShow(false)
            }}
            size="sm"
            disabled={reason === ''}
          >
            低評価をする
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
