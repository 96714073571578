export const findClosingBracket = (str: string, b: string) => {
  if (!str.includes(b[1])) {
    return -1
  }
  const l = str.length
  let level = 0,
    i = 0
  for (; i < l; i++) {
    if (str[i] === '\\') {
      i++
    } else if (str[i] === b[0]) {
      level++
    } else if (str[i] === b[1]) {
      level--
      if (level < 0) {
        return i
      }
    }
  }
  return -1
}
