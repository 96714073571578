import { Timestamp } from '../../firebase/alias'
import { timestampToDate } from '../../utils/timestamp'

type Props = {
  timestamp: Date | Timestamp
  absolute?: boolean
}

/**
 * Timestampを整形して表示するコンポーネント
 * absolute以外は未来の日付の対応はできていない
 */
export const ShapedTimestamp = ({ timestamp, absolute = false }: Props) => {
  const now = new Date()
  const date =
    timestamp instanceof Date ? timestamp : timestampToDate(timestamp)
  if (absolute) {
    return (
      <>
        {date.getFullYear()}年{date.getMonth() + 1}月{date.getDate()}日
      </>
    )
  } else {
    let diff = Math.floor((now.getTime() - date.getTime()) / 1000)
    if (diff < 60) {
      return <>{diff}秒前</>
    }
    diff = Math.floor(diff / 60)
    if (diff < 60) {
      return <>{diff}分前</>
    }
    diff = Math.floor(diff / 60)
    if (diff < 24) {
      return <>{diff}時間前</>
    }
    diff = Math.floor(diff / 24)
    if (diff <= 31) {
      return <>{diff}日前</>
    }
    if (now.getFullYear() === date.getFullYear()) {
      return (
        <>
          {date.getMonth() + 1}月{date.getDate()}日
        </>
      )
    }
    return (
      <>
        {date.getFullYear()}年{date.getMonth() + 1}月{date.getDate()}日
      </>
    )
  }
}
