import { collection, db, doc, DocRef } from '../../../../firebase/alias'
import { Plan } from '../../../stripe'
import { getConverter } from '../converter'
import { Document } from '../document'
import { CommentsSectionSetting } from './message'
import { ProfileSubcols } from './subcollections'
import { TeamMember } from './team'
import { WithUserTimestamp } from './user_timestamp'

export type Provider =
  | 'google.com'
  | 'github.com'
  | 'twitter.com'
  | 'facebook.com'

export type SocialAccounts = Partial<Record<Provider, string>>

export type AvatarImage = {
  /** フロントからアクセス可能なURL */
  url: string | null
  /** Cloud Storage上でのファイル名 {uid}.{png/jpeg} */
  filename?: string
  /** Cloud Storage path : /uploads/{profile/team}/{uid}.{png/jpeg} */
  full_path?: string
}

export type ContentsAggregation = {
  /** 記事の合計 */
  articles_count: number

  /** 本の合計 */
  books_count: number

  /** 閲覧数の合計 */
  pv: number

  /** 高評価数の合計 */
  good_count: number

  /** 低評価数の合計 */
  bad_count: number

  /** ブックマーク数の合計 */
  bookmarked_count: number

  /** 報告のスコアの合計 */
  reports_score: number
}

export const DEFAULT_CONTENTS_AGGREGATION: ContentsAggregation = {
  articles_count: 0,
  books_count: 0,
  pv: 0,
  good_count: 0,
  bad_count: 0,
  bookmarked_count: 0,
  reports_score: 0,
}

export type MessagesAggregation = {
  /** コメントの合計 */
  comments_count: number

  /** 返信の合計 */
  replies_count: number

  /** 高評価数の合計 */
  good_count: number

  /** 低評価数の合計 */
  bad_count: number

  /** 報告のスコアの合計 */
  reports_score: number
}

export const DEFAULT_MESSAGES_AGGREGATION: MessagesAggregation = {
  comments_count: 0,
  replies_count: 0,
  good_count: 0,
  bad_count: 0,
  reports_score: 0,
}

export interface ProfileBase extends Document, WithUserTimestamp {
  readonly ref: DocRef<ProfileBase>
  readonly parent_id: 'profiles'
  readonly subcollections: typeof ProfileSubcols

  /** チームアカウントであるかどうか */
  is_team: boolean

  /** 表示名 */
  display_name: string

  /** アバター画像 */
  image?: AvatarImage

  /** 自己紹介コメント */
  comment: string

  /** Web Site */
  website?: string

  /** 連携しているSNSアカウント */
  social_accounts: SocialAccounts

  /** AmazonアフィリエイトのID */
  amazon_associate_id?: string

  /** フォロワー数 */
  followers_count: number

  /** ミュートされているユーザの合計 */
  muted_users_count: number

  /** コンテンツ（記事と本）に関する集計 */
  contents_aggregation: ContentsAggregation

  /** コメントと返信に関する集計 */
  messages_aggregation: MessagesAggregation

  /** フォローしている数 */
  followees_count: number

  /** フォローしているタグの数 */
  following_tags_count: number

  /** プラン（無料かプレミアムか） */
  plan: Plan

  /** コメント欄の設定 */
  comments_section_setting?: CommentsSectionSetting

  /** 参加しているチームのID */
  teams: Record<string, TeamMember['role']>
}

export interface UserProfile extends ProfileBase {
  is_team: false
}

export interface TeamProfile extends ProfileBase {
  is_team: true
  team_fields: {
    members_count: number
    required_invitation: boolean
  }
}

export type Profile = UserProfile | TeamProfile

export const profileConverter = getConverter<Profile>(ProfileSubcols)
export const profilesRef = collection<Profile>(db, 'profiles').withConverter(
  profileConverter
)
export const profileRef = (uid: string) => doc(profilesRef, uid)
