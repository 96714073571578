import { deleteField, query, where } from 'firebase/firestore'
import {
  Account,
  Article,
  ArticleDraft,
  Authorization,
  AuthorizationDetails,
  Batch,
  DocRef,
  Timestamp,
  articleDraftsRef,
  articleRef,
  doc,
  getDoc,
  getDocs,
  profileRef,
} from '../firebase'
import { ignorePermissionError } from '../utils'
import { canEditContent } from './content'
import { addDefaultMacos, addMacros, replaceMacros } from './macros'
import { addReferences, replaceReferences } from './references'
import { hasRole } from './role'
import { findOrCreateTagIds } from './tag'

export const createArticleDraft = async (
  uid: string,
  publicationRef?: DocRef<Article>
) => {
  const batch = new Batch()
  const now = Timestamp.now()
  if (publicationRef) {
    const publicationSnapshot = await getDoc(publicationRef)
    const publication = publicationSnapshot.data()
    if (!publication) throw new Error('The publication is not found.')
    if (
      publication.created_by !== uid &&
      !(await hasRole(uid, 'mathlog_admin'))
    )
      throw new Error('The uid is invalid.')
    const ref = doc(articleDraftsRef(uid))

    batch.create(ref, {
      created_by: uid,
      title: publication.title,
      body: publication.body,
      publication_id: publication.id,
      editability_details: publication.editability_details,
      collaborator_uids: publication.collaborator_uids,
      document_id: ref.id,
      created_by_user_at: now,
      updated_by_user_at: now,
    })
    await addMacros(publicationRef, ref, batch)
    await addReferences(publicationRef, ref, batch)
    await batch.commit()
    return ref
  } else {
    const ref = doc(articleDraftsRef(uid))
    batch.create(ref, {
      created_by: uid,
      title: '',
      body: '',
      editability_details: { authorized_by_url: false, authorized_uids: [] },
      collaborator_uids: [],
      document_id: ref.id,
      created_by_user_at: now,
      updated_by_user_at: now,
    })
    await addDefaultMacos(uid, ref, batch)
    await batch.commit()
    return ref
  }
}

export type PublicationParams = {
  category_id: string
  tags: string[]
  youtube_code?: string
  visibility: Authorization
  limited_visibility_details?: AuthorizationDetails
  collaborator_uids: string[]
  type: Article['type']
  is_beginner: boolean
}

/**
 * 下書きから記事を作成する
 * @param draft 下書き
 * @param params 作成用のパラメータ
 * @param publicationRef 作成するRef
 * @returns 更新されたRef
 */
export const createArticleByDraft = async (
  draft: ArticleDraft,
  params: PublicationParams,
  publicationRef: DocRef<Article>
): Promise<DocRef<Article>> => {
  const uid = draft.ref.parent.parent?.id
  if (uid === undefined) {
    throw new Error('Cloud not specify user.')
  }

  const batch = new Batch()
  await addMacros(draft.ref, publicationRef, batch)
  const now = Timestamp.now()
  const referencesNum = await addReferences(draft.ref, publicationRef, batch)
  batch.create(publicationRef, {
    category_id: params.category_id,
    tag_ids: await findOrCreateTagIds(params.tags),
    youtube_code: params.youtube_code,
    type: params.type,
    created_by: uid,
    title: draft.title,
    body: draft.body,
    good_count: 0,
    bad_count: 0,
    comments_count: 0,
    pv: 0,
    bookmarked_count: 0,
    reports_score: 0,
    visibility: params.visibility,
    has_references: referencesNum > 0,
    limited_visibility_details: params.limited_visibility_details,
    is_beginner: params.is_beginner,
    collaborator_uids: params.collaborator_uids,
    editability_details: draft.editability_details,
    created_by_user_at: now,
    updated_by_user_at: now,
  })
  await batch.delete(draft.ref)
  await batch.commit()
  return publicationRef
}

/**
 * draftから記事を更新する
 * @param draft 下書き
 * @param params 更新用のパラメータ
 * @returns 更新されたRef
 */
export const updateArticleByDraft = async (
  draft: ArticleDraft,
  params: PublicationParams
): Promise<DocRef<Article>> => {
  const uid = draft.ref.parent.parent?.id
  if (uid === undefined) throw new Error('Cloud not specify user.')

  if (draft.publication_id === undefined)
    throw new Error('Publication target does not exist.')

  const batch = new Batch()
  const publicationRef = articleRef(draft.publication_id)
  await replaceMacros(draft.ref, publicationRef, batch)
  const referencesNum = await replaceReferences(
    draft.ref,
    publicationRef,
    batch
  )
  const isAdmin = await hasRole(uid, 'mathlog_admin')
  batch.update(publicationRef, {
    category_id: params.category_id,
    tag_ids: await findOrCreateTagIds(params.tags),
    youtube_code: params.youtube_code,
    type: params.type,
    title: draft.title,
    body: draft.body,
    visibility: params.visibility,
    has_references: referencesNum > 0,
    limited_visibility_details: params.limited_visibility_details,
    editability_details: draft.editability_details,
    updated_by_user_at: !isAdmin ? Timestamp.now() : undefined,
    is_beginner: params.is_beginner,
    collaborator_uids: params.collaborator_uids,
  })
  await batch.delete(draft.ref)
  await batch.commit()
  return publicationRef
}

export const articleDraftsSnapshotByArticleId = async (
  userId: string,
  articleId: string
) =>
  await getDocs(
    query(articleDraftsRef(userId), where('publication_id', '==', articleId))
  )

export const checkArticleDraftExists = async (
  userId: string,
  articleId: string
) => {
  const articleDraftsSnapshot = await articleDraftsSnapshotByArticleId(
    userId,
    articleId
  )
  return !articleDraftsSnapshot.empty
}

export const getExistingArticleDraftSnapshot = async (
  userId: string,
  articleId: string
) => {
  const articleDraftsSnapShot = await articleDraftsSnapshotByArticleId(
    userId,
    articleId
  )
  const articleDrafts = articleDraftsSnapShot.docs.map((doc) => doc.data())
  return articleDrafts[0]
}

export const getExistingArticleDraftID = async (
  userId: string,
  articleId: string
) => {
  const articleDraftsSnapShot = await articleDraftsSnapshotByArticleId(
    userId,
    articleId
  )
  const articleDrafts = articleDraftsSnapShot.docs.map((doc) => doc.data())
  return articleDrafts[0].id
}

export const deleteArticle = async (article: Article) => {
  const batch = new Batch()
  await batch.delete(article.ref)
  // 関連する下書きからpublication_refを消す
  const relationalDraftsSnapshot = await getDocs(
    query(
      articleDraftsRef(article.created_by),
      where('publication_id', '==', article.id)
    )
  )
  relationalDraftsSnapshot.docs.forEach((doc) => {
    batch.update(doc.ref, { publication_id: deleteField() })
  })
  await batch.commit()
}

export const canEditArticle = (article: Article, account: Account) =>
  canEditContent(article, account)

export const getMathlogArticleData = async (id: string) => {
  try {
    const articleSnapshot = await getDoc(articleRef(id))
    const article = articleSnapshot.data()
    const profileSnapshot = article
      ? await getDoc(profileRef(article.created_by))
      : undefined
    const profile = profileSnapshot?.data()
    return { article, profile }
  } catch (error) {
    // 限定公開記事で権限がない場合スルーする
    ignorePermissionError(error)

    console.warn(error)
    return { article: undefined, profile: undefined }
  }
}
