import { resolveMathdownImagePath } from '../../utils'

export const tag = (tag: string) => {
  // html要素内にあるMathdownにアップロードされたファイルのパスを全部取得して置換する
  const reg =
    /(https:\/\/mathlog\.info)?(\/uploads\/mathdown\/\d+?\.(jpg|jpeg|gif|png))/g

  const matches = tag.matchAll(reg)
  let src = tag
  for (const match of matches) {
    const filePath = match[2]
    src = src.replace(filePath, resolveMathdownImagePath(filePath))
  }
  return src
}
