import { rules } from '../../rules'
import type { Tokens } from '../../types'

export const def = (src: string): Tokens.Def | null => {
  const cap = rules.block.def.exec(src)
  if (!cap) return null
  if (cap[3]) cap[3] = cap[3].substring(1, cap[3].length - 1)
  const tag = cap[1].toLowerCase().replace(/\s+/g, ' ')
  return {
    type: 'def',
    tag,
    raw: cap[0],
    href: cap[2],
    title: cap[3],
  }
}
