import { Lexer } from '../../lexer'
import { rules } from '../../rules'
import type { Tokens } from '../../types'

export const box = (
  src: string,
  lexer: Lexer,
  labels: Map<string, string>
): Tokens.Box | null => {
  const cap = rules.block.box.exec(src)

  if (!cap) return null

  const label =
    cap[4] !== undefined ? cap[4].replace('[', '').replace(']', '') : undefined
  if (label !== undefined && labels !== undefined) labels.set(label, cap[2])
  const token: Tokens.Box = {
    type: 'box',
    raw: cap[0],
    boxType: cap[2],
    label: label,
    title: cap[3] || undefined, // 空文字の時にundefinedにする
    titleTokens: [],
    tokens: lexer.blockTokens(cap[5], []),
  }
  lexer.inline(cap[3], token.titleTokens)
  return token
}
