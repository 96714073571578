import { RegexEditor } from '../utils/regex_editor'
import { _punctuation } from './_punctuation'

const _lDelim = /^(?:\*+(?:([punct_])|[^\s*]))|^_+(?:([punct*])|([^\s_]))/
//        (1) and (2) can only be a Right Delimiter. (3) and (4) can only be Left.  (5) and (6) can be either Left or Right.
//        () Skip orphan delim inside strong    (1) #***                (2) a***#, a***                   (3) #***a, ***a                 (4) ***#              (5) #***#                 (6) a***a
const _rDelimAst =
  /^[^_*]*?__[^_*]*?\*[^_*]*?(?=__)|[punct_](\*+)(?=[\s]|$)|[^punct*_\s](\*+)(?=[punct_\s]|$)|[punct_\s](\*+)(?=[^punct*_\s])|[\s](\*+)(?=[punct_])|[punct_](\*+)(?=[punct_])|[^punct*_\s](\*+)(?=[^punct*_\s])/
const _rDelimUnd =
  /^[^_*]*?\*\*[^_*]*?_[^_*]*?(?=\*\*)|[punct*](_+)(?=[\s]|$)|[^punct*_\s](_+)(?=[punct*\s]|$)|[punct*\s](_+)(?=[^punct*_\s])|[\s](_+)(?=[punct*])|[punct*](_+)(?=[punct*])/ // ^- Not allowed for _

export const emStrong = {
  lDelim: new RegexEditor(_lDelim).replace(/punct/g, _punctuation).getRegex(),
  rDelimAst: new RegexEditor(_rDelimAst, 'g')
    .replace(/punct/g, _punctuation)
    .getRegex(),
  rDelimUnd: new RegexEditor(_rDelimUnd, 'g')
    .replace(/punct/g, _punctuation)
    .getRegex(),
}
