import {
  collection,
  doc,
  DocRef,
  Timestamp,
} from '../../../../../firebase/alias'
import { getConverter } from '../../converter'
import { Document } from '../../document'
import { customerRef } from './customer'
import { Price } from './price'
import { Product } from './product'
import { SubscriptionSubcols } from './subcollections'

// 不完全 適宜追加
export interface Subscription extends Document {
  readonly parent_id: 'subscriptions'
  readonly subcollections: typeof SubscriptionSubcols
  readonly ref: DocRef<Subscription>

  /**
   * The status of the subscription object
   */
  status:
    | 'active'
    | 'canceled'
    | 'incomplete'
    | 'incomplete_expired'
    | 'past_due'
    | 'trialing'
    | 'unpaid'

  /**
   * Firestore reference to the product doc for this Subscription.
   */
  product: DocRef<Product>
  /**
   * Firestore reference to the price for this Subscription.
   */
  price: DocRef<Price>

  /**
   * 今回のサイクル分が終わったらサブスクが終了されるか
   */
  cancel_at_period_end: boolean

  /**
   * 今回のサイクルの終了日
   */
  current_period_end: Timestamp

  // 不完全 適宜追加
  items: {
    id: string
  }[]
}

export const subscriptionConverter =
  getConverter<Subscription>(SubscriptionSubcols)
export const subscriptionsRef = (uid: string) =>
  collection<Subscription>(
    customerRef(uid),
    'subscriptions'
  ).withConverter<Subscription>(subscriptionConverter)
export const subscriptionRef = (uid: string, id: string) =>
  doc(subscriptionsRef(uid), id)

export const isActiveSubscription = (subscription: Subscription) => {
  return (
    subscription.status !== 'incomplete' && // Initial payment attempt fails
    subscription.status !== 'incomplete_expired' && //  The first invoice is not paid within 23 hours
    subscription.status !== 'canceled'
  )
}
