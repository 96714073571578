import { RefObject, useEffect, useRef } from 'react'

export const useIPhoneSupportedFixedTop = <T extends HTMLElement>(
  ref: RefObject<T>,
  top: number
) => {
  const defaultViewportHeightRef = useRef<number>()
  const topRef = useRef<number>(top)

  useEffect(() => {
    topRef.current = top
  }, [top])

  useEffect(() => {
    if (ref.current === null) return
    const element = ref.current
    element.style.position = 'fixed'
    element.style.top = `${topRef.current}px`
    defaultViewportHeightRef.current = window.visualViewport?.height

    const updateElementTop = () => {
      // 現在がIPhoneであるかどうかを検出
      // useIsIPhoneにも同様のコードが存在
      const isIPhone =
        /iPhone/.test(navigator.userAgent) && window.MSStream === undefined

      // 現在キーボードを開いているかを検出（完全ではない）
      // useKeyboardStatusにも同様のコードが存在
      const isKeyboardOpened =
        defaultViewportHeightRef.current !== undefined &&
        window.visualViewport !== null &&
        defaultViewportHeightRef.current > window.visualViewport.height

      element.style.top = `${
        isIPhone && isKeyboardOpened
          ? topRef.current + window.scrollY
          : topRef.current
      }px`
      element.style.position =
        isIPhone && isKeyboardOpened ? 'absolute' : 'fixed'
    }
    window.addEventListener('scroll', updateElementTop)
    window.addEventListener('resize', updateElementTop)
    window.visualViewport?.addEventListener('resize', updateElementTop)
    return () => {
      window.removeEventListener('scroll', updateElementTop)
      window.removeEventListener('resize', updateElementTop)
      window.visualViewport?.addEventListener('resize', updateElementTop)
    }
  }, [])
}
