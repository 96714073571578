import { rules } from '../../rules'
import type { Tokens } from '../../types'
import { escapeSpecialCharacters, mangle } from '../utils'

export const autolink = (src: string): Tokens.Link | null => {
  const cap = rules.inline.autolink.exec(src)
  if (!cap) return null
  let text, href
  if (cap[2] === '@') {
    text = escapeSpecialCharacters(mangle(cap[1]))
    href = 'mailto:' + text
  } else {
    text = escapeSpecialCharacters(cap[1])
    href = text
  }

  return {
    type: 'link',
    raw: cap[0],
    text,
    href,
    tokens: [
      {
        type: 'text',
        raw: text,
        text,
      },
    ],
  }
}
