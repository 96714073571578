import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useAuthState } from '../../firebase/hooks'
import { thisMonthPayoutApplicationsRef } from '../../models/payout_application'
import { sortedSalesRef } from '../../models/sale'

export const useSales = () => {
  const now = new Date()
  const thisMonthBeginning = new Date(now.getFullYear(), now.getMonth())

  const { user } = useAuthState()

  const [sales, loading] = useCollectionData(
    user ? sortedSalesRef(user.uid) : null
  )

  const payoutApplyableSales = sales?.filter(
    (sale) => sale.created_at.toDate() < thisMonthBeginning && !sale.has_paid
  )

  const totalPayoutAmount = payoutApplyableSales?.reduce((prev, sale) => {
    return prev + sale.amount
  }, 0)

  const [thisMonthPayoutApplications, loadingThisMonthPayoutApplications] =
    useCollectionData(user ? thisMonthPayoutApplicationsRef(user.uid) : null)
  const thisMonthPayoutApplication = thisMonthPayoutApplications?.[0]

  const payoutApplyable =
    !loadingThisMonthPayoutApplications && totalPayoutAmount !== undefined
      ? now.getDate() <= 20 &&
        totalPayoutAmount >= 500 &&
        !thisMonthPayoutApplication
      : undefined // 500以上の金額を20日まで申請可能

  return {
    sales,
    thisMonthPayoutApplication,
    totalPayoutAmount,
    payoutApplyable,
    payoutApplyableSales,
    loading,
  }
}
