import { orderBy, query } from 'firebase/firestore'
import {
  Batch,
  DocRef,
  PagesParent,
  getDocs,
  pageRef,
  pagesRef,
} from '../firebase'

export const sortedPagesRef = (pagesParent: DocRef<PagesParent>) =>
  query(pagesRef(pagesParent), orderBy(`order`))

export const addPages = async (
  source: DocRef<PagesParent>,
  target: DocRef<PagesParent>,
  batch: Batch
) => {
  const sourcePagesSnapshot = await getDocs(pagesRef(source))
  const sourcePages = sourcePagesSnapshot.docs.map((doc) => doc.data())
  for (const page of sourcePages) batch.create(pageRef(target, page.id), page)
  return sourcePages.length
}

export const deletePages = async (
  target: DocRef<PagesParent>,
  batch: Batch
) => {
  const targetPagesSnapshot = await getDocs(pagesRef(target))
  const targetPageRefs = targetPagesSnapshot.docs.map((doc) => doc.ref)
  for (const ref of targetPageRefs) await batch.delete(ref)
  return targetPageRefs.length
}

export const movePages = async (
  source: DocRef<PagesParent>,
  target: DocRef<PagesParent>,
  batch: Batch
) => {
  const num = await addPages(source, target, batch)
  await deletePages(source, batch)
  return num
}

export const replacePages = async (
  source: DocRef<PagesParent>,
  target: DocRef<PagesParent>,
  batch: Batch
) => {
  await deletePages(target, batch)
  return await addPages(source, target, batch)
}

export const moveAndReplacePages = async (
  source: DocRef<PagesParent>,
  target: DocRef<PagesParent>,
  batch: Batch
) => {
  await deletePages(target, batch)
  return await movePages(source, target, batch)
}
