import { Modal } from 'react-bootstrap'
import { useAuthState } from '../hooks'
import { SignInForm } from './SignInForm'

type Props = {
  show: boolean
  onHide: () => void

  /**
   * リダイレクトの設定
   * string型の場合はpathとしてログイン後に指定されたpathに遷移
   * trueの場合は有効なafterSignedInPathが存在する場合は当該ページに、その他の場合はメインページに遷移
   * falseの場合はリダイレクトしない
   * デフォルトはfalseが指定されている
   */
  redirect?: string | boolean
}

export const SignInPopup = ({
  show,
  onHide: handleHide,
  redirect = false,
}: Props) => {
  const { user } = useAuthState()
  if (user) return <></>
  return (
    <Modal show={show} onHide={handleHide}>
      <Modal.Header closeButton>
        <Modal.Title>ログイン</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SignInForm hideTitle forceVertical redirect={redirect} />
      </Modal.Body>
    </Modal>
  )
}
