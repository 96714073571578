import * as renderer from '../../renderer/html'
import { Tokens } from '../../types'
import { parseBlockToHTML } from './block'

const parseListItemToHTML = (item: Tokens.ListItem, loose: boolean) => {
  let body = ''
  if (item.task) {
    const checkbox = renderer.checkbox(item.checked)
    if (loose) {
      if (item.tokens.length > 0 && item.tokens[0].type === 'paragraph') {
        item.tokens[0].text = checkbox + ' ' + item.tokens[0].text
        if (
          item.tokens[0].tokens !== undefined &&
          item.tokens[0].tokens.length > 0 &&
          item.tokens[0].tokens[0].type === 'text'
        ) {
          item.tokens[0].tokens[0].text =
            checkbox + ' ' + item.tokens[0].tokens[0].text
        }
      } else {
        item.tokens.unshift({
          type: 'text',
          text: checkbox,
          raw: checkbox,
        })
      }
    } else {
      body += checkbox
    }
  }
  body += parseBlockToHTML(item.tokens, loose)
  return renderer.listitem(body)
}

export const parseListToHTML = (token: Tokens.List) => {
  let body = ''
  for (const item of token.items) {
    body += parseListItemToHTML(item, token.loose)
  }
  return renderer.list(
    body,
    token.loose,
    token.ordered,
    token.start,
    token.roman,
    token.parentheses,
    token.brackets
  )
}
