import useSWR from 'swr'
import { Category, getDocs } from '../../firebase'
import { sortedCategoriesRef } from '../../models/category'
import { APP_NAME } from '../../utils/env'

const getCategories = async () => {
  const snapshot = await getDocs(sortedCategoriesRef)
  return snapshot.docs.map((doc) => doc.data())
}

export const useCategories = (defaultCategories?: Category[]) => {
  // SWRによってカテゴリのデータをキャッシュする
  const {
    data: categories,
    isLoading,
    error,
    mutate,
  } = useSWR<Category[], Error>('/categories', getCategories, {
    // categoriesはmutate関数による再検証のみを行う
    // これによりカテゴリが同じユーザに対して繰り返し取得されることを防ぐ
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    fallbackData: defaultCategories,
  })
  const menuCategories = categories?.filter((c) => c.menu[APP_NAME])
  const selectableCategories = categories?.filter((c) => c.selectable[APP_NAME])

  return {
    mutate,
    categories,
    menuCategories,
    selectableCategories,
    loading: isLoading,
    error,
  }
}
