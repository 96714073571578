import { rules } from '../../rules'
import type { Tokens } from '../../types'
import { escapeSpecialCharacters } from '../utils'

export const escape = (src: string): Tokens.Escape | null => {
  const cap = rules.inline.escape.exec(src)
  if (!cap) return null
  return {
    type: 'escape',
    raw: cap[0],
    text: escapeSpecialCharacters(cap[1]),
  }
}
