import { useEffect, useMemo, useRef, useState } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { articleDraftRef, fb } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'

export const useArticleDraft = (id: string | null | undefined) => {
  const { user } = useAuthState()

  const [ownerId, setOwnerId] = useState<string | null>()

  useEffect(() => {
    if (id === undefined) return
    if (id === null) {
      setOwnerId(null)
      return
    }
    void fb
      .call('getUidByDraft')({
        collection: 'article_drafts',
        draft_id: id,
      })
      .then((res) => setOwnerId(res.data.uid))
  }, [id])

  const ref =
    typeof ownerId === 'string' && typeof id === 'string' && user
      ? articleDraftRef(ownerId, id)
      : null
  const [articleDraft, loadingArticleDraft, error] = useDocumentData(ref)
  const notStartedFetching = useRef(true)
  const waiting = useMemo(() => {
    // refが有効になって最初のloadが終わるタイミングまでtrue
    if (ref === null) return true
    else if (notStartedFetching.current && !loadingArticleDraft) return true
    else if (notStartedFetching.current && loadingArticleDraft) {
      notStartedFetching.current = false
      return true
    } else if (!notStartedFetching.current && loadingArticleDraft) {
      return true
    } else {
      return false
    }
  }, [loadingArticleDraft, ref])
  const notFound =
    (!waiting && articleDraft === undefined) || ownerId === null || id === null

  return {
    waiting,
    articleDraft,
    error,
    notFound,
  }
}
