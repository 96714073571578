import { FaBook } from 'react-icons/fa'
import { IconWrapper } from '../../assets/IconWrapper'
import { Content } from '../../firebase'
import { ESContent } from '../../shared/elastic_search/scheme/content'
import { Designed } from '../../types'

type Props = {
  referable: Content | ESContent
  color?: string
}

export const ReferenceBadge = ({
  referable,
  color = 'muted',
  className = '',
  style,
}: Designed<Props>) => {
  if (!referable.has_references) return <></>
  return (
    <IconWrapper
      suffix="文献あり"
      className={`text-${color} ${className}`}
      style={style}
    >
      <FaBook />
    </IconWrapper>
  )
}
