import { rules } from '../../rules'
import type { Tokens } from '../../types'

export const hr = (src: string): Tokens.Hr | null => {
  const cap = rules.block.hr.exec(src)
  if (!cap) return null
  return {
    type: 'hr',
    raw: cap[0],
  }
}
