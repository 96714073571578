import { getDoc, increment } from 'firebase/firestore'
import { useCallback, useState } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import {
  Batch,
  EvaluableRefAssignable,
  ValidDocumentWithEvaluation,
  badEvaluatorRef,
  goodEvaluatorRef,
} from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { PromiseVoid } from '../../types'

export const useBad = <T extends ValidDocumentWithEvaluation>(
  ref: EvaluableRefAssignable<T>,
  currentBadCount?: number,
  handleChangeBadCount?: (
    added: number,
    isDecrementGood: boolean
  ) => PromiseVoid
) => {
  const [isSending, setIsSending] = useState(false)
  const { user: currentUser } = useAuthState()
  const [badSnapshot] = useDocument(
    currentUser ? badEvaluatorRef(ref, currentUser.uid) : null
  )

  const isBad = badSnapshot ? badSnapshot.exists() : undefined

  const bad = useCallback(
    async (reason?: string) => {
      if (!currentUser) throw new Error('Unexpected call.')
      if (isBad === true) throw new Error('Unexpected call.')
      if (isSending) return
      setIsSending(true)
      const batch = new Batch()

      // goodを押していた場合、取り消す
      const isGood = (
        await getDoc(goodEvaluatorRef(ref, currentUser.uid))
      ).exists()
      if (isGood === true) {
        await batch.delete(goodEvaluatorRef(ref, currentUser.uid))
        batch.update<ValidDocumentWithEvaluation>(ref, {
          good_count: increment(-1),
        })
      }
      batch.create(badEvaluatorRef(ref, currentUser.uid), { reason })
      batch.update<ValidDocumentWithEvaluation>(ref, {
        bad_count: increment(1),
      })
      await batch.commit()
      if (currentBadCount !== undefined && handleChangeBadCount)
        await handleChangeBadCount(1, isGood)
      setIsSending(false)
    },
    [ref, isBad, currentUser, isSending]
  )

  const cancelBad = useCallback(async () => {
    if (!currentUser) throw new Error('Unexpected call.')
    if (isBad === false) throw new Error('Unexpected call.')
    if (isSending) return
    setIsSending(true)
    const batch = new Batch()
    await batch.delete(badEvaluatorRef(ref, currentUser.uid))
    batch.update<ValidDocumentWithEvaluation>(ref, { bad_count: increment(-1) })
    await batch.commit()
    if (currentBadCount !== undefined && handleChangeBadCount)
      await handleChangeBadCount(-1, false)
    setIsSending(false)
  }, [ref, isBad, currentUser, isSending])

  return { isBad, bad, cancelBad }
}
