export const splitCells = (tableRow: string, count?: number) => {
  // ensure that every cell-delimiting pipe has a space
  // before it to distinguish it from an escaped pipe
  const row = tableRow.replace(/\|/g, (match, offset: number, str: string) => {
      let escaped = false,
        curr = offset
      while (--curr >= 0 && str[curr] === '\\') escaped = !escaped
      if (escaped) {
        // odd number of slashes means | is escaped
        // so we leave it alone
        return '|'
      } else {
        // add space before unescaped |
        return ' |'
      }
    }),
    cells = row.split(/ \|/)
  let i = 0

  // First/last cell in a row cannot be empty if it has no leading/trailing pipe
  if (!cells[0].trim()) {
    cells.shift()
  }
  if (cells.length > 0 && !cells[cells.length - 1].trim()) {
    cells.pop()
  }

  if (count !== undefined && cells.length > count) {
    cells.splice(count)
  } else {
    while (count !== undefined && cells.length < count) cells.push('')
  }

  for (; i < cells.length; i++) {
    // leading or trailing whitespace is ignored per the gfm spec
    cells[i] = cells[i].trim().replace(/\\\|/g, '|')
  }
  return cells
}
