import { useEffect, useRef, useState } from 'react'

/**
 * スマホでキーボードを開いているか、閉じているかを返却する関数（完全な実装ではない）
 * useIPhoneSupportedFixedTopにも同様のコードが存在
 */
export const useMobileKeyboardStatus = () => {
  const [status, setStatus] = useState<'open' | 'close'>('close')
  const defaultViewportHeightRef = useRef<number>()

  useEffect(() => {
    defaultViewportHeightRef.current = window.visualViewport?.height

    const updateKeyboardStatus = () => {
      // 現在キーボードを開いているかを検出（完全ではない）
      const isKeyboardOpened =
        defaultViewportHeightRef.current !== undefined &&
        window.visualViewport !== null &&
        defaultViewportHeightRef.current > window.visualViewport.height
      setStatus(isKeyboardOpened ? 'open' : 'close')
    }

    if (window.visualViewport !== null)
      window.visualViewport.addEventListener('resize', updateKeyboardStatus)
    return () => {
      if (window.visualViewport !== null)
        window.visualViewport.removeEventListener(
          'resize',
          updateKeyboardStatus
        )
    }
  }, [])

  return status
}
