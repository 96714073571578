import * as renderer from '../../renderer/html'
import { Token } from '../../types'

/**
 * Parse Inline Tokens
 */
export const parseInlineToHTML = (tokens: Token[]): string => {
  let out = ''
  for (const token of tokens) {
    switch (token.type) {
      case 'escape': {
        out += renderer.text(token.text)
        break
      }
      case 'tag': {
        out += renderer.tag(token.text)
        break
      }
      case 'link': {
        out += renderer.link(
          token.href,
          token.title,
          parseInlineToHTML(token.tokens)
        )
        break
      }
      case 'image': {
        out += renderer.image(
          token.href,
          token.size,
          token.title,
          token.text, // TODO 数式のみの展開
          parseInlineToHTML(token.tokens)
        )
        break
      }
      case 'strong': {
        out += renderer.strong(parseInlineToHTML(token.tokens))
        break
      }
      case 'em': {
        out += renderer.em(parseInlineToHTML(token.tokens))
        break
      }
      case 'codespan': {
        out += renderer.codespan(token.text)
        break
      }
      case 'br': {
        out += renderer.br()
        break
      }
      case 'del': {
        out += renderer.del(parseInlineToHTML(token.tokens))
        break
      }
      case 'formula': {
        out += renderer.formula(
          token.text,
          token.hash,
          token.align,
          token.error,
          token.cached
        )
        break
      }
      case 'shortened_link': {
        out += renderer.shortenedLink(token.title)
        break
      }
      case 'html': {
        out += parseInlineToHTML(token.tokens)
        continue
      }
      case 'text': {
        out += renderer.text(token.text)
        break
      }
      default: {
        const errMsg = 'Token with "' + token.type + '" type was not found.'
        console.error(errMsg)
        return ''
      }
    }
  }
  return out
}
