import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useAuthState } from '../../firebase/hooks/useAuthState'

// メールの確認が済んでいないログインユーザを確認ページに移動させる
export const useRequireConfirmation = (pending = false) => {
  const { isLoading, isSignedIn, isEmailVerified } = useAuthState()
  const router = useRouter()

  useEffect(() => {
    if (isLoading) return
    if (pending) return
    if (isSignedIn === true && isEmailVerified === false)
      void router.push('/auth/confirmation')
  }, [isLoading, pending])

  return { isLoading }
}
