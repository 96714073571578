import { useEffect, useMemo, useRef, useState } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { bookDraftRef, fb, pageRef } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { isBlank } from '../../utils'

export const useBookDraft = (
  id: string | null | undefined,
  pageId?: string | null | undefined
) => {
  const { user } = useAuthState()

  const [ownerId, setOwnerId] = useState<string | null>()

  useEffect(() => {
    if (id === undefined) return
    if (id === null) {
      setOwnerId(null)
      return
    }
    void fb
      .call('getUidByDraft')({
        collection: 'book_drafts',
        draft_id: id,
      })
      .then((res) => setOwnerId(res.data.uid))
  }, [id])

  const _bookDraftRef =
    !isBlank(id) && id !== '' && typeof ownerId === 'string' && user
      ? bookDraftRef(ownerId, id)
      : null
  const [bookDraft, bookDraftLoading, bookDraftError] =
    useDocumentData(_bookDraftRef)
  const notStartedFetchingBookDraft = useRef(true)
  const waitingBookDraft = useMemo(() => {
    //  _bookDraftRefが有効になって最初のloadが終わるタイミングまでtrue
    if (_bookDraftRef === null) return true
    else if (notStartedFetchingBookDraft.current && !bookDraftLoading)
      return true
    else if (notStartedFetchingBookDraft.current && bookDraftLoading) {
      notStartedFetchingBookDraft.current = false
      return true
    } else if (!notStartedFetchingBookDraft.current && bookDraftLoading) {
      return true
    } else {
      return false
    }
  }, [bookDraftLoading, _bookDraftRef])
  const notFoundBookDraft =
    (!waitingBookDraft && bookDraft === undefined) ||
    ownerId === null ||
    id === null

  const _pageRef =
    _bookDraftRef !== null && !isBlank(pageId) && pageId !== ''
      ? pageRef(_bookDraftRef, pageId)
      : null
  const [page, pageLoading, pageError] = useDocumentData(_pageRef)
  const notStartedFetchingPage = useRef(true)
  const waitingPage = useMemo(() => {
    //  _pageRefが有効になって最初のloadが終わるタイミングまでtrue
    if (_pageRef === null) return true
    else if (notStartedFetchingPage.current && !pageLoading) return true
    else if (notStartedFetchingPage.current && pageLoading) {
      notStartedFetchingPage.current = false
      return true
    } else if (!notStartedFetchingPage.current && pageLoading) {
      return true
    } else {
      return false
    }
  }, [pageLoading, _pageRef])
  const notFoundPage = (!waitingPage && page === undefined) || pageId === null

  return {
    bookDraft,
    bookDraftError,
    waitingBookDraft,
    notFoundBookDraft,
    page,
    pageError,
    waitingPage,
    notFoundPage,
  }
}
