import { RegexEditor } from '../utils/regex_editor'
import { _label } from './_label'
import { _title } from './_title'

const _def =
  /^ {0,3}\[(label)\]: *(?:\n *)?<?([^\s>]+)>?(?:(?: +(?:\n *)?| *\n *)(title))? *(?:\n+|$)/

export const def = new RegexEditor(_def)
  .replace('label', _label)
  .replace('title', _title)
  .getRegex()
