import { useEffect, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import {
  CreateParamsWithoutFieldValue,
  GeneralBookReference,
} from '../../../firebase'
import { getAmazonProductImageURL } from '../../../models/amazon'
import { remove } from '../../../utils'
import { ActionButtons } from './ActionButtons'
import { EachReferenceFormProps } from './ReferenceForm'

export const GeneralBookForm = ({
  onClickSaveButton: handleClickSaveButton,
  onClickCancelButton: handleClickCancelButton,
  reference,
  nextOrder,
}: EachReferenceFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    setValue,
    register,
    getValues,
    reset,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<CreateParamsWithoutFieldValue<GeneralBookReference>>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const asin = watch('asin')

  const resetWithDefaultValues = () => reset({ type: 'general_book' })

  useEffect(() => {
    if (reference && reference.type === 'general_book')
      reset(remove(reference, ['id', 'parent_id', 'ref']))
    else resetWithDefaultValues()
  }, [reference])

  return (
    <Form>
      <div className="h6 text-danger">必須</div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>著者名</InputGroup.Text>
          <Form.Control
            {...register('author', {
              required: true,
            })}
            isInvalid={!!errors.author}
          />
        </InputGroup>
      </div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>タイトル</InputGroup.Text>
          <Form.Control
            {...register('title', {
              required: true,
            })}
            isInvalid={!!errors.title}
          />
        </InputGroup>
      </div>
      <div className="h6">任意</div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>ラベル</InputGroup.Text>
          <Form.Control {...register('label')} isInvalid={!!errors.label} />
        </InputGroup>
        <Form.Text>
          本文で<code>[[ラベル]]</code>
          で参考文献の番号を使えます。
        </Form.Text>
      </div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>シリーズ</InputGroup.Text>
          <Form.Control {...register('series')} isInvalid={!!errors.series} />
        </InputGroup>
      </div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>ページ</InputGroup.Text>
          <Form.Control {...register('page')} isInvalid={!!errors.page} />
        </InputGroup>
      </div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>出版社</InputGroup.Text>
          <Form.Control
            {...register('publisher')}
            isInvalid={!!errors.publisher}
          />
        </InputGroup>
      </div>
      <div className="mb-6">
        <InputGroup>
          <InputGroup.Text>出版年</InputGroup.Text>
          <Form.Control
            type="number"
            min={1800}
            max={new Date().getFullYear()}
            {...register('published_year')}
            isInvalid={!!errors.published_year}
          />
        </InputGroup>
      </div>
      <div className="mb-6 d-flex gap-6 align-items-center w-100">
        <div className="flex-grow-1">
          <InputGroup>
            <InputGroup.Text>ASIN</InputGroup.Text>
            <Form.Control {...register('asin')} isInvalid={!!errors.asin} />
          </InputGroup>
          <Form.Text className="text-muted">
            *ASINはAmazonの商品を識別するための10桁の番号
          </Form.Text>
          <p className="mt-4 small mb-0">
            ASINが不明の場合は商品ページのURLから取得できます。
          </p>
          <InputGroup>
            <InputGroup.Text>URL</InputGroup.Text>
            <Form.Control
              onChange={(e) => {
                const cap = /[^0-9A-Z]([0-9A-Z]{10})([^0-9A-Z]|$)/.exec(
                  e.currentTarget.value
                )
                setValue('asin', cap ? cap[1] : undefined)
              }}
            />
          </InputGroup>
        </div>
        <div style={{ width: 100 }} className="flex-shrink-0">
          {asin !== undefined && asin !== '' ? (
            <img
              src={getAmazonProductImageURL(asin)}
              alt="Amazonの商品の画像"
              className="w-100"
            />
          ) : (
            <div className="text-muted small">
              商品が選択されると画像が表示されます。
            </div>
          )}
        </div>
      </div>
      <ActionButtons
        onClickCancelButton={async () => {
          resetWithDefaultValues()
          await handleClickCancelButton()
        }}
        onClickSaveButton={async () => {
          setIsSubmitting(true)
          const params = getValues()
          if (!reference) params.order = nextOrder
          await handleClickSaveButton(params)
          setIsSubmitting(false)
          resetWithDefaultValues()
        }}
        saveButtonLoading={isSubmitting}
        saveButtonDisabled={!isValid}
        cancelButtonDisabled={!isDirty && !reference}
        isUpdate={!!reference}
      />
    </Form>
  )
}
