export const heading = (
  level: number,
  text: string,
  title: string,
  label?: string
) => {
  const anchorText =
    label !== undefined
      ? `<h${
          level + 1
        }><span id="${label}"><span id="${title}">${text}</span></span></h${
          level + 1
        }>`
      : `<h${level + 1}><span id="${title}">${text}</span></h${level + 1}>`
  return level < 6 ? anchorText : text
}
