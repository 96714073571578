import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { TeamProfile, getDoc, teamInvitationRef } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { useQueryData } from '../../hooks'
import { isBlank } from '../../utils'
import { UserImage, UserNameLink } from '../user'
import { TeamParticipateButton } from './ParticipateButton'

type Props = {
  teamProfile: TeamProfile
}

export const TeamInvitationModal = ({ teamProfile }: Props) => {
  const router = useRouter()
  const invitationId = useQueryData('invitation')
  const [showParticipationModal, setShowParticipationModal] = useState(false)
  const { profile } = useAuthState()

  useEffect(() => {
    if (isBlank(profile)) return

    if (
      isBlank(invitationId) ||
      teamProfile === undefined ||
      !teamProfile.is_team ||
      profile.teams[teamProfile.id] !== undefined
    )
      return

    void getDoc(teamInvitationRef(teamProfile.id, invitationId)).then(
      (snapshot) => {
        const data = snapshot.data()
        if (data === undefined) {
          void router.replace(`/users/${teamProfile.id}`)
        } else {
          setShowParticipationModal(true)
        }
      }
    )
  }, [invitationId, teamProfile])

  return (
    <Modal
      onHide={() => setShowParticipationModal(false)}
      show={showParticipationModal}
    >
      <Modal.Header closeButton>招待の承諾</Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center gap-4">
        <UserImage profile={teamProfile} size={60} />
        <UserNameLink profile={teamProfile} className="text-black fw-bold" />
        {!isBlank(invitationId) && (
          <TeamParticipateButton
            teamProfile={teamProfile}
            invitationId={invitationId}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}
