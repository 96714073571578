import { collection, doc, DocRef } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document } from '../document'
import { accountRef } from './account'
import {
  MessageMuteUserSubcols,
  MutedUserSubcols,
  MuteUserSubcols,
} from './subcollections'

// idにofficialを入れた場合は公式によるミュート
export interface MutedUser extends Document {
  readonly parent_id: 'muted_users'
  readonly ref: DocRef<MutedUser>
  readonly subcollections: typeof MutedUserSubcols
}
export const mutedUserConverter = getConverter<MutedUser>(MutedUserSubcols)

export const mutedUsersRef = (uid: string) =>
  collection<MutedUser>(accountRef(uid), 'muted_users').withConverter(
    mutedUserConverter
  )

export const mutedUserRef = (targetUid: string, uid: string) =>
  doc(mutedUsersRef(targetUid), uid)

// 公式によるミュートは対応していない
export interface MuteUser extends Document {
  readonly parent_id: 'mute_users'
  readonly ref: DocRef<MuteUser>
  readonly subcollections: typeof MuteUserSubcols
}
export const muteUserConverter = getConverter<MuteUser>(MuteUserSubcols)

export const muteUsersRef = (uid: string) =>
  collection<MuteUser>(accountRef(uid), 'mute_users').withConverter(
    muteUserConverter
  )

export const muteUserRef = (uid: string, targetUid: string) =>
  doc(muteUsersRef(uid), targetUid)

export interface MessageMuteUser extends Document {
  readonly parent_id: 'message_mute_users'
  readonly ref: DocRef<MuteUser>
  readonly subcollections: typeof MuteUserSubcols
}
export const messageMuteUserConverter = getConverter<MessageMuteUser>(
  MessageMuteUserSubcols
)

export const messageMuteUsersRef = (uid: string) =>
  collection<MessageMuteUser>(
    accountRef(uid),
    'message_mute_users'
  ).withConverter(muteUserConverter)

export const messageMuteUserRef = (uid: string, targetUid: string) =>
  doc(messageMuteUsersRef(uid), targetUid)
