import { useDocumentData } from 'react-firebase-hooks/firestore'
import { Tag, tagConverter, tagRef } from '../../firebase'
import { deserialize, isBlank, Serialized } from '../../utils'

export const useTag = (
  id: string | null | undefined,
  staticTag: Serialized<Tag> | null
) => {
  const [tag, loadingTag] = useDocumentData(
    !isBlank(id) && id !== '' ? tagRef(id) : null,
    {
      initialValue: staticTag
        ? deserialize(staticTag, { ref: tagConverter })
        : undefined,
    }
  )
  return { tag, loadingTag }
}
