import {
  accountRef,
  Batch,
  getDocs,
  macroRef,
  MacrosParentRefAssignable,
  macrosRef,
  packagesRef,
  ValidDocument,
} from '../firebase'

export const addMacros = async <
  T extends ValidDocument,
  U extends ValidDocument
>(
  source: MacrosParentRefAssignable<T>,
  target: MacrosParentRefAssignable<U>,
  batch: Batch
) => {
  const sourceMacrosSnapshot = await getDocs(macrosRef(source))
  const sourceMacros = sourceMacrosSnapshot.docs.map((doc) => doc.data())
  for (const macro of sourceMacros)
    batch.create(macroRef(target, macro.id), macro)
  return sourceMacros.length
}

export const deleteMacros = async <T extends ValidDocument>(
  target: MacrosParentRefAssignable<T>,
  batch: Batch
) => {
  const targetMacrosSnapshot = await getDocs(macrosRef(target))
  const targetMacroRefs = targetMacrosSnapshot.docs.map((doc) => doc.ref)
  for (const ref of targetMacroRefs) await batch.delete(ref)
  return targetMacroRefs.length
}

export const moveMacros = async <
  T extends ValidDocument,
  U extends ValidDocument
>(
  source: MacrosParentRefAssignable<T>,
  target: MacrosParentRefAssignable<U>,
  batch: Batch
) => {
  const num = await addMacros(source, target, batch)
  await deleteMacros(source, batch)
  return num
}

export const addDefaultMacos = async <T extends ValidDocument>(
  uid: string,
  target: MacrosParentRefAssignable<T>,
  batch: Batch
) => {
  const packagesSnapshot = await getDocs(packagesRef(uid))
  const packages = packagesSnapshot.docs.map((doc) => doc.data())
  const defaultMacroCandidatesSnapshot = await getDocs(
    macrosRef(accountRef(uid))
  )
  const defaultMacroCandidates = defaultMacroCandidatesSnapshot.docs.map(
    (doc) => doc.data()
  )
  const defaultMacros = defaultMacroCandidates.filter((macro) => {
    const pkg = packages.find((p) => p.id === macro.package_id)
    return macro.package_id === undefined || pkg?.is_active // パッケージが指定されていないか、パッケージがアクティブの場合
  })
  for (const macro of defaultMacros) {
    const { package_id, ...macroExtractPackageId } = macro
    batch.create(macroRef(target, macro.id), macroExtractPackageId)
  }
  return defaultMacros.length
}

export const replaceMacros = async <
  T extends ValidDocument,
  U extends ValidDocument
>(
  source: MacrosParentRefAssignable<T>,
  target: MacrosParentRefAssignable<U>,
  batch: Batch
) => {
  await deleteMacros(target, batch)
  return await addMacros(source, target, batch)
}

export const moveAndReplaceMacros = async <
  T extends ValidDocument,
  U extends ValidDocument
>(
  source: MacrosParentRefAssignable<T>,
  target: MacrosParentRefAssignable<U>,
  batch: Batch
) => {
  await deleteMacros(target, batch)
  return await moveMacros(source, target, batch)
}
