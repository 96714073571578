import { Lexer } from '../../lexer'
import { rules } from '../../rules'
import type { Tokens } from '../../types'
import { findClosingBracket, outputLink, rtrim } from '../utils'

export const link = (
  src: string,
  lexer: Lexer
): Tokens.Link | Tokens.Image | null => {
  const cap = rules.inline.link.exec(src)
  if (!cap) return null

  const trimmedUrl = cap[2].trim()
  if (trimmedUrl.startsWith('<')) {
    // commonmark requires matching angle brackets
    if (!trimmedUrl.endsWith('>')) {
      return null
    }

    // ending angle bracket cannot be escaped
    const rtrimSlash = rtrim(trimmedUrl.slice(0, -1), '\\')
    if ((trimmedUrl.length - rtrimSlash.length) % 2 === 0) {
      return null
    }
  } else {
    // find closing parenthesis
    const lastParenIndex = findClosingBracket(cap[2], '()')
    if (lastParenIndex > -1) {
      const start = cap[0].startsWith('!') ? 5 : 4
      const linkLen = start + cap[1].length + lastParenIndex
      cap[2] = cap[2].substring(0, lastParenIndex)
      cap[0] = cap[0].substring(0, linkLen).trim()
      cap[4] = ''
    }
  }
  let href = cap[2]
  const size = cap[3] ? `${cap[3].slice(1)}px` : '85%'
  const title = cap[4] ? cap[4].slice(1, -1) : ''

  href = href.trim()
  if (href.startsWith('<')) {
    href = href.slice(1, -1)
  }
  return outputLink(
    cap,
    {
      href: href ? href.replace(rules.inline._escapes, '$1') : href,
      size: size ? size.replace(rules.inline._escapes, '$1') : size,
      title: title ? title.replace(rules.inline._escapes, '$1') : title,
    },
    cap[0],
    lexer
  )
}
