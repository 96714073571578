import { limit, orderBy, query, where } from 'firebase/firestore'
import { mutate } from 'swr'
import {
  Batch,
  DocRef,
  Textbook,
  TextbookDraft,
  Timestamp,
  doc,
  getDoc,
  textbookDraftsRef,
  textbookRef,
  textbooksRef,
} from '../firebase'
import { addChapters, replaceChapters } from './chapter'
import { addDefaultMacos, addMacros, replaceMacros } from './macros'
import { addPages, replacePages } from './page'
import { addReferences } from './references'

export const recentTextbooksRef = (size: number) =>
  query(textbooksRef, orderBy('created_at', 'desc'), limit(size))

export const categoryTextbooksRef = (categoryId: string) =>
  query(textbooksRef, where('category_id', '==', categoryId))

export const createTextbookDraft = async (
  uid: string,
  publicationRef?: DocRef<Textbook>
) => {
  const batch = new Batch()
  const now = Timestamp.now()
  if (publicationRef) {
    const publicationSnapshot = await getDoc(publicationRef)
    const publication = publicationSnapshot.data()
    if (!publication) throw new Error('The publication is not found.')
    const ref = batch.add(textbookDraftsRef, {
      ...publication,
      publication_id: publication.id,
      created_by_user_at: now,
      updated_by_user_at: now,
    })
    await addPages(publicationRef, ref, batch)
    await addChapters(publicationRef, ref, batch)
    await addMacros(publicationRef, ref, batch)
    await addReferences(publicationRef, ref, batch)
    await batch.commit()
    return ref
  } else {
    const ref = batch.add(textbookDraftsRef, {
      created_by: uid,
      last_updated_by: uid,
      description: '',
      title: '',
      created_by_user_at: now,
      updated_by_user_at: now,
    })
    await addDefaultMacos(uid, ref, batch)
    await batch.commit()
    return ref
  }
}

export const publishTextbook = async (draft: TextbookDraft) => {
  if (draft.category_id === undefined)
    throw new Error('category_id is undefined.')
  const now = Timestamp.now()
  if (draft.publication_id !== undefined) {
    const batch = new Batch()
    const publicationRef = textbookRef(draft.publication_id)
    await replacePages(draft.ref, publicationRef, batch)
    await replaceChapters(draft.ref, publicationRef, batch)
    await replaceMacros(draft.ref, publicationRef, batch)
    await addReferences(draft.ref, publicationRef, batch)
    batch.update(publicationRef, {
      last_updated_by: draft.last_updated_by,
      category_id: draft.category_id,
      title: draft.title,
      description: draft.description,
      updated_by_user_at: now,
    })
    await batch.delete(draft.ref)
    await batch.commit()
    // キャッシュをクリアする
    await mutate(`textbooks/${publicationRef.id}/pages`, undefined, false)
    await mutate(`textbooks/${publicationRef.id}/chapters`, undefined, false)
    return publicationRef
  } else {
    const batch = new Batch()
    const publicationRef = doc(textbooksRef)
    await addPages(draft.ref, publicationRef, batch)
    await addChapters(draft.ref, publicationRef, batch)
    await addMacros(draft.ref, publicationRef, batch)
    await addReferences(draft.ref, publicationRef, batch)
    batch.create(publicationRef, {
      last_updated_by: draft.last_updated_by,
      category_id: draft.category_id,
      created_by: draft.created_by,
      title: draft.title,
      description: draft.description,
      created_by_user_at: now,
      updated_by_user_at: now,
    })
    await batch.delete(draft.ref)
    await batch.commit()
    return publicationRef
  }
}
