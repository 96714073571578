import { _label as _blockLabel } from '../block/_label'
import { RegexEditor } from '../utils/regex_editor'
import { _label } from './_label'

const _reflink = /^!?\[(label)\]\[(ref)\]/

export const reflink = new RegexEditor(_reflink)
  .replace('label', _label)
  .replace('ref', _blockLabel)
  .getRegex()
