export const createUrlWithQueryParams = (
  path: string,
  queryParams: Record<string, string | number | boolean | undefined>
) => {
  const host = process.env.NEXT_PUBLIC_HOST ?? 'http://localhost:3000'
  const url = new URL(host + path)
  const params = new URLSearchParams()

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value !== undefined) params.append(key, String(value))
  })
  url.search = params.toString()
  return url.toString()
}
