import { Stripe, loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'

export const useStripeClient = () => {
  const [client, setClient] = useState<Stripe | null>()
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_STRIPE_KEY === undefined) return
    void loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY).then(setClient)
  }, [])
  return client
}
