import hljs from 'highlight.js'

const highlight = (code: string, lang: string) => {
  const language = hljs.getLanguage(lang) ? lang : 'plaintext'
  return hljs.highlight(code, { language }).value
}

export const code = (code: string, infostring = '') => {
  const cap = infostring.match(/\S*/)
  const lang = cap ? cap[0] : ''
  const className = `hljs language-${lang}`
  const out = highlight(code, lang)

  return `
    <pre>
      <code class="${className}">${out}</code>
    </pre>`
}
