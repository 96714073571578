import { Macro } from '../../../../firebase'

export const compileMacrosToHTML = (macros: Macro[], id?: string) => {
  let str = `<div class="d-none" ${
    id !== undefined ? `id="macros-${id}"` : ''
  }>$$`
  for (const macro of macros) {
    str += `\\newcommand{${macro.command_name}}[${macro.arguments_count}]{${macro.formula}}\n`
  }
  str += '$$</div>\n'
  return str
}

export const compileMacrosToTex = (macros: Macro[]) => {
  let str = ''
  for (const macro of macros) {
    str += `\\def\\${macro.command_name}`
    for (let i = 0; i < macro.arguments_count; i++) {
      str += `#${i + 1}`
    }
    str += `{${macro.formula}}\n`
  }
  return str
}
