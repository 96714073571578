import { collection, db, doc, DocRef } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document } from '../document'
import { Article } from './article'
import { Book } from './book'
import { Comment, Reply } from './message'
import { ReportSubcols } from './subcollections'

export const REPORT_TYPES = [
  'copyright',
  'privacy',
  'spam',
  'violent',
  'hate_speech',
  'illegal',
  'sexual',
  'incorrect_category',
] as const

export type ReportType = typeof REPORT_TYPES[number]

export const getReportTypeDescription = (type: ReportType): string => {
  switch (type) {
    case 'incorrect_category':
      return 'カテゴリが異なる内容'
    case 'copyright':
      return '著作権を侵害する内容'
    case 'privacy':
      return 'プライバシーを侵害する内容'
    case 'violent':
      return '悪意ある誹謗中傷や暴力的な内容'
    case 'hate_speech':
      return '差別的な発言やヘイトスピーチを含む内容'
    case 'spam':
      return 'スパムや商業目的の内容'
    case 'sexual':
      return '性的な内容'
    case 'illegal':
      return '違法または規約違反の内容'
  }
}

export type Reportable = Article | Book | Comment | Reply

export interface Report extends Document {
  readonly parent_id: 'reports'
  readonly ref: DocRef<Report>
  readonly subcollections: typeof ReportSubcols
  /** 通報対象のコンテンツのref(Converterは適用されていない) */
  target_ref: DocRef<Reportable>
  type: ReportType
  body?: string
  target_uid: string
  created_by: string
}

export const reportConverter = getConverter<Report>(ReportSubcols)

export const reportsRef = collection<Report>(db, 'reports').withConverter(
  reportConverter
)

export const reportRef = (uid: string) => doc(reportsRef, uid)
