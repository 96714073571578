import {
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  startAfter,
} from 'firebase/firestore'
import {
  EvaluableRefAssignable,
  GoodEvaluator,
  goodEvaluatorsRef,
  ValidDocumentWithEvaluation,
} from '../firebase'

export const sortedGoodEvaluatorsRef = <T extends ValidDocumentWithEvaluation>(
  evaluableRef: EvaluableRefAssignable<T>,
  after: QueryDocumentSnapshot<GoodEvaluator> | null,
  per: number
) =>
  after
    ? query(
        goodEvaluatorsRef(evaluableRef),
        orderBy('created_at', 'desc'),
        startAfter(after),
        limit(per)
      )
    : query(
        goodEvaluatorsRef(evaluableRef),
        orderBy('created_at', 'desc'),
        limit(per)
      )
