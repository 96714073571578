import {
  QueryDocumentSnapshot,
  limit,
  query,
  startAfter,
  where,
} from 'firebase/firestore'
import { TeamMember, teamMembersRef } from '../firebase'

export const limitedTeamMembersByRoleRef = (
  teamProfileId: string,
  role: TeamMember['role'],
  after: QueryDocumentSnapshot<TeamMember> | null,
  per: number
) =>
  after
    ? query(
        teamMembersRef(teamProfileId),
        where('role', '==', role),
        startAfter(after),
        limit(per)
      )
    : query(
        teamMembersRef(teamProfileId),
        where('role', '==', role),
        limit(per)
      )
