import { documentId, limit, orderBy, query, where } from 'firebase/firestore'
import { addDoc, getDocs, tagsRef } from '../firebase'

export const findOrCreateTagIds = async (tagNames: string[]) => {
  const tagIds = await Promise.all(
    tagNames.map(async (tagName) => {
      const q = query(tagsRef, where('name', '==', tagName))
      const snapshot = await getDocs(q)
      if (snapshot.empty) {
        const newTagRef = await addDoc(tagsRef, {
          name: tagName,
          followers_count: 0,
          contributors_count: 0,
        })
        return newTagRef.id
      } else {
        return snapshot.docs[0].id
      }
    })
  )
  return tagIds
}

export const getTagNames = async (tagIds: string[]) => {
  const q = query(tagsRef, where(documentId(), 'in', tagIds))
  const snapshot = await getDocs(q)
  const tagNames = snapshot.docs.map((doc) => doc.data().name)
  return tagNames.filter((e, i) => tagNames.indexOf(e) === i) // 重複を削除
}

export const tagIdsQuery = (tagIds: string[]) =>
  query(tagsRef, where(documentId(), 'in', tagIds))

export const contributorsCountSortedTagsRef = (size: number) =>
  query(tagsRef, orderBy('contributors_count', 'desc'), limit(size))
