import {
  collection,
  doc,
  DocRef,
  FirestoreDataConverter,
} from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document } from '../document'
import { accountRef } from './account'
import { Article, articleConverter } from './article'
import { Page, pageConverter } from './page'
import {
  CommentDraftSubcols,
  CommentSubcols,
  ReplyDraftSubcols,
  ReplySubcols,
} from './subcollections'
import { WithUserTimestamp } from './user_timestamp'

export type Commentable = Article | Page

export type Message = Comment | Reply

export type MessageDraft = CommentDraft | ReplyDraft

export type MessageType = 'impression' | 'question' | 'indication'

export type CommentsSectionSetting = {
  hidden_uids: string[]
}

export interface Comment extends Document, WithUserTimestamp {
  readonly ref: DocRef<Comment>
  readonly parent_id: 'comments'
  readonly subcollections: typeof CommentSubcols
  type: MessageType
  body: string
  created_by: string // uid
  good_count: number
  bad_count: number
  reports_score: number
  replies_count: number
  is_hidden?: boolean
}

export interface CommentDraft extends Document, WithUserTimestamp {
  readonly ref: DocRef<CommentDraft>
  readonly parent_id: 'comment_drafts'
  readonly subcollections: typeof CommentDraftSubcols
  type: MessageType
  body: string
  commentable_ref: DocRef<Commentable>
  publication_ref?: DocRef<Comment> // 存在する場合は更新用の下書き
}

export interface Reply extends Document, WithUserTimestamp {
  readonly ref: DocRef<Reply>
  readonly parent_id: 'replies'
  readonly subcollections: typeof ReplySubcols
  created_by: string // uid
  type: MessageType
  body: string
  reports_score: number
  good_count: number
  bad_count: number
  is_hidden?: boolean
}

export interface ReplyDraft extends Document, WithUserTimestamp {
  readonly ref: DocRef<ReplyDraft>
  readonly parent_id: 'reply_drafts'
  readonly subcollections: typeof ReplyDraftSubcols
  type: MessageType
  comment_ref: DocRef<Comment>
  publication_ref?: DocRef<Reply> // 存在する場合は更新用の下書き
  body: string
}

// コメント
export const commentConverter = getConverter<Comment>(CommentSubcols)
export const commentsRef = (commentableRef: DocRef<Commentable>) =>
  collection<Comment>(commentableRef, `comments`).withConverter(
    commentConverter
  )

export const commentsRefWithoutConverter = (
  commentableRef: DocRef<Commentable>
) => collection<Comment>(commentableRef, `comments`)

export const commentRef = (commentableRef: DocRef<Commentable>, id: string) =>
  doc(commentsRef(commentableRef), id)

export const commentRefWithoutConverter = (
  commentableRef: DocRef<Commentable>,
  id: string
) => doc(commentsRefWithoutConverter(commentableRef), id)

export const commentDraftConverter = getConverter<CommentDraft>(
  CommentDraftSubcols,
  {
    fromFirestore: (snapshot) => {
      // commentable_ref に Converter を適用する
      const data = snapshot.data()
      const commentableRef =
        data.commentable_ref as CommentDraft['commentable_ref']
      let converter: FirestoreDataConverter<Commentable> | null = null
      switch (commentableRef.parent.id) {
        case 'articles':
          converter = articleConverter
          break
        case 'pages':
          converter = pageConverter
      }
      return {
        // TODO: ESlintを無視しないように修正
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        commentable_ref: data.commentable_ref.withConverter(converter),
      }
    },
  }
)
export const commentDraftsRef = (uid: string) =>
  collection<CommentDraft>(accountRef(uid), `comment_drafts`).withConverter(
    commentDraftConverter
  )

export const commentDraftRef = (uid: string, id: string) =>
  doc(commentDraftsRef(uid), id)

// 返信
export const replyDraftConverter = getConverter<ReplyDraft>(ReplyDraftSubcols, {
  fromFirestore: (snapshot) => {
    // comment_ref に Converter を適用する
    const data = snapshot.data()
    const commentRef = data.comment_ref as DocRef<Comment>
    return {
      comment_ref: commentRef.withConverter(commentConverter),
    }
  },
})

export const replyConverter = getConverter<Reply>(ReplySubcols)
export const repliesRef = (commentRef: DocRef<Comment>) =>
  collection<Reply>(commentRef, `replies`).withConverter(replyConverter)

export const repliesRefWithoutConverter = (commentRef: DocRef<Comment>) =>
  collection<Reply>(commentRef, `replies`)

export const replyRef = (commentRef: DocRef<Comment>, id: string) =>
  doc(repliesRef(commentRef), id)

export const replyRefWithoutConverter = (
  commentRef: DocRef<Comment>,
  id: string
) => doc(repliesRefWithoutConverter(commentRef), id)

export const replyDraftsRef = (uid: string) =>
  collection<ReplyDraft>(accountRef(uid), `reply_drafts`).withConverter(
    replyDraftConverter
  )

export const replyDraftRef = (uid: string, id: string) =>
  doc(replyDraftsRef(uid), id)
