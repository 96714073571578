import { useEffect, useRef, useState } from 'react'

type Props = {
  onChangeHeight?: (height: number) => void
  onChangeWidth?: (width: number) => void
  defaultHeight?: number
  defaultWidth?: number
}

export const useElementSize = ({
  onChangeHeight: handleChangeHeight,
  onChangeWidth: handleChangeWidth,
  defaultHeight = 0,
  defaultWidth = 0,
}: Props = {}) => {
  const [height, setHeight] = useState(defaultHeight)
  const [width, setWidth] = useState(defaultWidth)
  const elementRef = useRef<HTMLDivElement>(null)

  // 要素の高さを取得し、state を更新する関数
  const updateSize = () => {
    const newHeight =
      elementRef.current?.getBoundingClientRect().height ?? defaultHeight
    const newWidth =
      elementRef.current?.getBoundingClientRect().width ?? defaultWidth
    setHeight(newHeight)
    if (handleChangeHeight) handleChangeHeight(newHeight)
    setWidth(newWidth)
    if (handleChangeWidth) handleChangeWidth(newWidth)
  }

  useEffect(() => {
    // コンポーネントがマウントされた直後に高さを取得
    updateSize()

    // サイズ変更時に高さを再取得
    window.addEventListener('resize', updateSize)

    return () => {
      // コンポーネントのアンマウント時にイベントリスナーを削除
      window.removeEventListener('resize', updateSize)
    }
  }, [])

  return { height, width, elementRef }
}
