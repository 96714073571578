import { setUserId } from 'firebase/analytics'
import { User } from 'firebase/auth'
import { createContext, useEffect, useState } from 'react'
import { useAuthState as useFirebaseHookAuthState } from 'react-firebase-hooks/auth'
import { useProfile } from '../../hooks'
import {
  Profile,
  createDoc,
  getDoc,
  otherAccountRef,
} from '../../shared/firebase'
import { SignInPopup } from '../components/SignInPopup'
import { useOAuthRedirectHandler } from '../hooks'
import { fb } from '../instance'

export type AuthState = {
  showSignInPopup: (redirect?: string | boolean) => void
  completeReauthenticate: () => void
  user: User | null | undefined
  isSignedIn: boolean | undefined
  isEmailVerified: boolean | undefined
  isLoading: boolean
  reauthenticated: boolean
  isOAuthReauthenticateRedirect: boolean
  isOAuthSignInRedirect: boolean
  profile: Profile | null | undefined
  loadingProfile: boolean
}

export const AuthContext = createContext<AuthState>({
  showSignInPopup: () => {
    throw new Error('Unexpected call error.')
  },
  completeReauthenticate: () => {
    throw new Error('Unexpected call error.')
  },
  user: undefined,
  isSignedIn: undefined,
  isEmailVerified: undefined,
  isLoading: true,
  reauthenticated: false,
  isOAuthReauthenticateRedirect: false,
  isOAuthSignInRedirect: false,
  profile: undefined,
  loadingProfile: true,
})

type Props = {
  children: React.ReactNode
}

export const AuthProvider = ({ children }: Props) => {
  const [show, setShow] = useState(false)
  const [redirect, setRedirect] = useState<string | boolean>(false)
  const [reauthenticated, setReauthenticated] = useState(false)

  const {
    isOAuthReauthenticateRedirect,
    isOAuthSignInRedirect,
    isOAuthLoading,
  } = useOAuthRedirectHandler({
    onOAuthSignedIn: async (user) => {
      // マルチアカウントの検知
      const currentUserId = localStorage.getItem('CurrentUserID')
      if (currentUserId !== null && currentUserId !== user.uid) {
        const otherAccount = await getDoc(
          otherAccountRef(user.uid, currentUserId)
        )
        if (!otherAccount.exists())
          await createDoc(otherAccountRef(user.uid, currentUserId), {})
      }
      localStorage.setItem('CurrentUserID', user.uid)
    },
    onOAuthReauthenticated: () => {
      setReauthenticated(true)
    },
  })
  const [user, isUserLoading, error] = useFirebaseHookAuthState(fb.auth)
  const { profile, loadingProfile } = useProfile(user?.uid, null)

  useEffect(() => {
    if (!user) return
    setUserId(fb.analytics, user.uid)
  }, [user])

  // 認証に関する処理の途中の場合はtrue
  // useFirebaseHookAuthStateがLoading中であるか
  // OAuthのリダイレクトを捌いている最中はtrue
  const isLoading = isUserLoading || isOAuthLoading

  // ログイン済みか
  // ログイン情報の取得中の時はundefined
  const isSignedIn = user ? true : !isLoading ? false : undefined

  // メールアドレスを確認しているか
  const isEmailVerified = user
    ? user.emailVerified
    : !isLoading
    ? false
    : undefined

  useEffect(() => {
    if (error !== undefined) console.error(error)
  }, [error])

  return (
    <>
      <AuthContext.Provider
        value={{
          showSignInPopup: (redirect: string | boolean = false) => {
            setShow(true)
            setRedirect(redirect)
          },
          completeReauthenticate: () => {
            setReauthenticated(true)
          },
          user: !isUserLoading ? user : undefined,
          isLoading,
          isSignedIn,
          isEmailVerified,
          reauthenticated,
          isOAuthReauthenticateRedirect,
          isOAuthSignInRedirect,
          profile,
          loadingProfile,
        }}
      >
        <SignInPopup
          show={show}
          onHide={() => setShow(false)}
          redirect={redirect}
        />
        {children}
      </AuthContext.Provider>
    </>
  )
}
