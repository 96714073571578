import { useEffect, useState } from 'react'
import { PromiseVoid } from '../types'

type AutoSaveStatus = 'successful' | 'waiting' | 'failed'

export const useAutoExecution = (
  func: (() => PromiseVoid) | undefined,
  interval: number,
  successfulTime: number,
  isInterrupted = false
) => {
  const [status, setStatus] = useState<AutoSaveStatus>('waiting')

  const execute = async () => {
    console.log('test')
    if (isInterrupted) return
    if (!func) return
    try {
      await func()
      setStatus('successful')
    } catch (error) {
      setStatus('failed')
    }
  }

  useEffect(() => {
    const timer = setInterval(execute, interval)
    return () => clearInterval(timer)
  }, [])

  const [successfulTimer, setSuccessfulTimer] = useState<null | NodeJS.Timer>(
    null
  )
  const clearSuccessfulTimer = () => {
    if (successfulTimer) {
      clearTimeout(successfulTimer)
      setSuccessfulTimer(null)
    }
  }

  useEffect(() => {
    clearSuccessfulTimer()
    const timer = setTimeout(() => {
      setStatus('waiting')
    }, successfulTime)
    setSuccessfulTimer(timer)
    return clearSuccessfulTimer
  }, [status])

  return status
}
