import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { Role, rolesRef } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'

export const useCurrentUserRoles = () => {
  const { user } = useAuthState()
  const [roles] = useCollectionDataOnce(user ? rolesRef(user.uid) : null)
  const isLoading = roles === undefined
  const hasRole = (role: Role['id']) => {
    if (!user || roles === undefined) return false
    else return !!roles.find((r) => r.id === role)
  }
  return { isLoading, roles, hasRole }
}
