import { Lexer } from '../../lexer'
import { rules } from '../../rules'
import type { Tokens } from '../../types'

export const del = (src: string, lexer: Lexer): Tokens.Del | null => {
  const cap = rules.inline.del.exec(src)
  if (!cap) return null
  return {
    type: 'del',
    raw: cap[0],
    text: cap[2],
    tokens: lexer.inlineTokens(cap[2], []),
  }
}
