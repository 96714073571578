import { getDoc, increment } from 'firebase/firestore'
import { useCallback, useState } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import {
  Batch,
  EvaluableRefAssignable,
  ValidDocumentWithEvaluation,
  badEvaluatorRef,
  goodEvaluatorRef,
} from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { PromiseVoid } from '../../types'

export const useGood = <T extends ValidDocumentWithEvaluation>(
  ref: EvaluableRefAssignable<T>,
  currentGoodCount?: number,
  handleChangeGoodCount?: (
    added: number,
    isDecrementBad: boolean
  ) => PromiseVoid
) => {
  const [isSending, setIsSending] = useState(false)
  const { user: currentUser } = useAuthState()
  const [goodSnapshot] = useDocument(
    currentUser ? goodEvaluatorRef(ref, currentUser.uid) : null
  )
  const isGood = goodSnapshot ? goodSnapshot.exists() : undefined

  const good = useCallback(async () => {
    if (!currentUser) throw new Error('Unexpected call.')
    if (isGood === true) throw new Error('Unexpected call.')
    if (isSending) return
    setIsSending(true)
    const batch = new Batch()

    // badを押していた場合、取り消す
    const isBad = (await getDoc(badEvaluatorRef(ref, currentUser.uid))).exists()
    if (isBad === true) {
      await batch.delete(badEvaluatorRef(ref, currentUser.uid))
      batch.update<ValidDocumentWithEvaluation>(ref, {
        bad_count: increment(-1),
      })
    }

    batch.create(goodEvaluatorRef(ref, currentUser.uid), {})
    batch.update<ValidDocumentWithEvaluation>(ref, { good_count: increment(1) })
    await batch.commit()

    if (currentGoodCount !== undefined && handleChangeGoodCount)
      await handleChangeGoodCount(1, isBad)
    setIsSending(false)
  }, [ref, isGood, currentUser, isSending])

  const cancelGood = useCallback(async () => {
    if (!currentUser) throw new Error('Unexpected call.')
    if (isGood === false) throw new Error('Unexpected call.')
    if (isSending) return
    setIsSending(true)
    const batch = new Batch()
    await batch.delete(goodEvaluatorRef(ref, currentUser.uid))
    batch.update<ValidDocumentWithEvaluation>(ref, {
      good_count: increment(-1),
    })
    await batch.commit()
    if (currentGoodCount !== undefined && handleChangeGoodCount)
      await handleChangeGoodCount(-1, false)
    setIsSending(false)
  }, [ref, isGood, currentUser, isSending])

  return { isGood, good, cancelGood }
}
