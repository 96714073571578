import {
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  startAfter,
} from 'firebase/firestore'
import {
  Followee,
  followeesRef,
  Follower,
  followersRef,
  FollowingTag,
  followingTagsRef,
  TagContributor,
  tagContributorsRef,
  TagFollower,
  tagFollowersRef,
} from '../firebase'

export const sortedFollowersRef = (
  uid: string,
  after: QueryDocumentSnapshot<Follower> | null,
  per: number
) =>
  after
    ? query(
        followersRef(uid),
        orderBy('created_at', 'desc'),
        startAfter(after),
        limit(per)
      )
    : query(followersRef(uid), orderBy('created_at', 'desc'), limit(per))

export const sortedFolloweesRef = (
  uid: string,
  after: QueryDocumentSnapshot<Followee> | null,
  per: number
) =>
  after
    ? query(
        followeesRef(uid),
        orderBy('created_at', 'desc'),
        startAfter(after),
        limit(per)
      )
    : query(followeesRef(uid), orderBy('created_at', 'desc'), limit(per))

export const sortedFollowingTagsRef = (
  uid: string,
  after: QueryDocumentSnapshot<FollowingTag> | null,
  per: number
) =>
  after
    ? query(
        followingTagsRef(uid),
        orderBy('created_at', 'desc'),
        startAfter(after),
        limit(per)
      )
    : query(followingTagsRef(uid), orderBy('created_at', 'desc'), limit(per))

export const sortedTagFollowersRef = (
  tagId: string,
  after: QueryDocumentSnapshot<TagFollower> | null,
  per: number
) =>
  after
    ? query(
        tagFollowersRef(tagId),
        orderBy('created_at', 'desc'),
        startAfter(after),
        limit(per)
      )
    : query(tagFollowersRef(tagId), orderBy('created_at', 'desc'), limit(per))

export const sortedTagContributorsRef = (
  tagId: string,
  after: QueryDocumentSnapshot<TagContributor> | null,
  per: number
) =>
  after
    ? query(
        tagContributorsRef(tagId),
        orderBy('count', 'desc'),
        startAfter(after),
        limit(per)
      )
    : query(tagContributorsRef(tagId), orderBy('count', 'desc'), limit(per))
