// 編集権限があるかをチェックする

import { Account, Content } from '../firebase'

// 限定公開をしているが現在Freeプランの場合は編集できない
export const canEditContent = (content: Content, account: Account) => {
  if (account?.features.visibility_select === true) return true
  if (content.visibility === 'public') return true
  return false
}
