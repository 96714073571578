import { rules } from '../../rules'
import type { Tokens } from '../../types'

export const shortenedLink = (src: string): Tokens.ShortenedLink | null => {
  const cap = rules.inline.shortenedLink.exec(src)
  if (!cap) return null
  return {
    type: 'shortened_link',
    raw: cap[0],
    title: cap[1],
  }
}
