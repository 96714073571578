import { useEffect, useState } from 'react'
import { useAuthState } from './useAuthState'

export const useAuthToken = () => {
  const { user } = useAuthState()
  const [token, setToken] = useState<string>()
  useEffect(() => {
    if (!user) return
    user
      .getIdToken()
      .then(setToken)
      .catch((e) => console.error(e))
  }, [user])

  return { token, user }
}
