export const tablecell = (
  text: string,
  header: boolean,
  align: 'center' | 'left' | 'right' | null
) => {
  const type = header ? 'th' : 'td'
  return `<${type} ${
    align !== null ? `align="${align}"` : ''
  }>${text}</${type}>`
}
