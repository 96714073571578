import { collection, db, doc, DocRef } from '../../../../firebase/alias'
import { Interval, Plan } from '../../../stripe'
import { getConverter } from '../converter'
import { Document } from '../document'
import { AccountSubcols, RoleSubcols } from './subcollections'

export interface Role extends Document {
  readonly id: 'mathlog_admin' | 'mathpedia_admin'
  readonly parent_id: 'roles'
  readonly subcollections: typeof RoleSubcols
  readonly ref: DocRef<Role>
}

export type BankAccount = {
  financial_institution: string // 金融機関
  branch: string // 支店
  deposit_type: string // 預金種別
  number: string // 口座番号
  name: string // 名義人
}

export type Features = {
  /**
   * 投稿範囲を選択できるかどうか
   */
  visibility_select: boolean
  /**
   * TeXエクスポートができるか
   */
  tex_export: boolean
  /**
   * 広告を削除できるか
   */
  ads_remove: boolean
  /**
   * 共同編集できるか
   */
  collaborative_edit: boolean
  /**
   * 評価できるかどうか
   * 重複アカウントの場合にfalseに変更する
   */
  evaluate: boolean
}

export interface Account extends Document {
  readonly parent_id: 'accounts'
  readonly ref: DocRef<Account>
  readonly subcollections: typeof AccountSubcols

  /** チームアカウントであるかどうか */
  is_team: boolean

  bank_account?: BankAccount
  plan: Plan
  plan_interval: Interval | null
  has_tried_premium_plan: boolean
  features: Features
}

export const accountConverter = getConverter<Account>(AccountSubcols)
export const accountsRef = collection<Account>(
  db,
  'accounts'
).withConverter<Account>(accountConverter)
export const accountRef = (uid: string) => doc(accountsRef, uid)

export const roleConverter = getConverter<Role>(RoleSubcols)
export const rolesRef = (uid: string) =>
  collection<Role>(accountRef(uid), 'roles').withConverter(roleConverter)
export const roleRef = (uid: string, role: Role['id']) =>
  doc(rolesRef(uid), role)
