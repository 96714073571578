import type { Formula, HTML, Links, TexImage, Token } from '../types'
import { FormulaeCache } from '../utils/formulae_cache'
import { BlockLexer } from './block'
import { Extractor } from './extractor'
import { InlineLexer } from './inline'

export class Lexer {
  private tokens: Token[] = []
  public inlineQueue: { src: string; tokens: Token[] }[] = []
  public links: Links = {}
  public formulae: Formula[] = []
  public htmlList: HTML[] = []
  public labels: Map<string, string> = new Map()
  public images: Map<string, TexImage> = new Map()

  public state = {
    inLink: false,
    inRawBlock: false,
    top: true,
  }
  public formulaeCache: FormulaeCache | null = null

  /**
   * Static Lex Method
   */
  static lex(
    src: string,
    formulaeCache: FormulaeCache | null = null,
    labels: Map<string, string> = new Map(),
    images: Map<string, TexImage> = new Map()
  ) {
    const lexer = new Lexer()
    return lexer.lex(src, formulaeCache, labels, images)
  }

  lex(
    src: string,
    formulaeCache: FormulaeCache | null = null,
    labels: Map<string, string> = new Map(),
    images: Map<string, TexImage> = new Map()
  ) {
    //boxのラベル
    this.labels = labels

    // 画像のURLなど
    this.images = images

    // 数式のキャッシュ
    this.formulaeCache = formulaeCache

    // replace newline command
    src = src.replace(/\r\n|\r/g, '\n').replace(/\t/g, '    ')

    // extract formulae
    src = Extractor.extract(src, this.formulae, this.htmlList)

    // block level lex
    this.blockTokens(src, this.tokens)

    // inline level lex
    while (true) {
      const next = this.inlineQueue.shift()
      if (!next) break
      this.inlineTokens(next.src, next.tokens)
    }

    return this.tokens
  }

  blockTokens(src: string, tokens: Token[]) {
    const blockLexer = new BlockLexer(this, this.labels, this.images)
    return blockLexer.lex(src, tokens)
  }

  inline(src: string, tokens: Token[]) {
    this.inlineQueue.push({ src, tokens })
  }

  inlineTokens(src: string, tokens: Token[] = []) {
    const inlineLexer = new InlineLexer(this)
    return inlineLexer.lex(src, tokens)
  }
}
