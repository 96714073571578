import { Reference } from '../../firebase'
import { Designed } from '../../types'
import { ReferenceItem } from './Item'

type Props = {
  references: Reference[]
  amazonBookImage: boolean
  link: boolean
}

export const ReferencesList = ({
  references,
  amazonBookImage,
  link,
  ...otherProps
}: Designed<Props>) => {
  return (
    <div {...otherProps}>
      {references.map((reference, index) => (
        <div
          className="d-flex gap-1 align-items-baseline"
          key={reference.id}
          id={reference.label}
        >
          <div>{`[${index + 1}]`}</div>
          <ReferenceItem
            reference={reference}
            amazonBookImage={amazonBookImage}
            link={link}
          />
        </div>
      ))}
    </div>
  )
}
