import { WebsiteReference } from '../../../firebase'
import { ShapedTimestamp } from '../../timestamp/ShapedTimestamp'

type Props = {
  reference: WebsiteReference
}

export const WebsiteReferenceAccordionBody = ({ reference }: Props) => (
  <ul className="mb-4 ps-6">
    <li>参考文献の種類：Webサイト</li>
    {reference.label !== undefined && reference.label !== '' && (
      <li>ラベル：{reference.label}</li>
    )}
    <li>サイトのURL：{reference.url}</li>
    <li>
      <>
        アクセス日：
        <ShapedTimestamp timestamp={reference.accessed_on} absolute />
      </>
    </li>
    {reference.author !== undefined && reference.author !== '' && (
      <li>著者：{reference.author}</li>
    )}
    {reference.name !== undefined && reference.name !== '' && (
      <li>サイト名：{reference.name}</li>
    )}
    {reference.published_on !== undefined && (
      <li>
        出版日：
        <ShapedTimestamp timestamp={reference.published_on} absolute />
      </li>
    )}
  </ul>
)
