import { useEffect, useRef, useState } from 'react'

export const useDelayedEffect = (
  effect: React.EffectCallback,
  deps?: React.DependencyList,
  delay = 50
) => {
  const [waiting, setWaiting] = useState(false)
  const timer = useRef<number>()

  useEffect(() => {
    window.clearTimeout(timer.current)
    setWaiting(true)
    timer.current = window.setTimeout(() => {
      setWaiting(false)
    }, delay)
  }, deps)

  useEffect(() => {
    if (!waiting) effect()
  }, [waiting])
}
