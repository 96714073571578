import { GeneralBookReference } from '../../../firebase'

type Props = {
  reference: GeneralBookReference
}

export const GeneralBookReferenceAccordionBody = ({ reference }: Props) => (
  <ul className="mb-4 ps-6">
    <li>参考文献の種類：書籍</li>
    {reference.label !== undefined && reference.label !== '' && (
      <li>ラベル：{reference.label}</li>
    )}
    <li>著者：{reference.author}</li>
    {reference.series !== undefined && reference.series !== '' && (
      <li>シリーズ：{reference.series}</li>
    )}
    {reference.page !== undefined && reference.page !== '' && (
      <li>ページ：{reference.page}</li>
    )}
    {reference.publisher !== undefined && reference.publisher !== '' && (
      <li>出版社：{reference.publisher}</li>
    )}
    {reference.published_year !== undefined &&
      reference.published_year !== '' && (
        <li>出版年：{reference.published_year}</li>
      )}
  </ul>
)
