import { collection, doc, DocRef } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document, ParentRefAssignable, ValidDocument } from '../document'
import { BadEvaluatorSubcols, GoodEvaluatorSubcols } from './subcollections'

export interface GoodEvaluator extends Document {
  readonly parent_id: 'good_evaluators'
  readonly ref: DocRef<GoodEvaluator>
  readonly subcollections: typeof GoodEvaluatorSubcols
}

export interface BadEvaluator extends Document {
  readonly parent_id: 'bad_evaluators'
  readonly ref: DocRef<BadEvaluator>
  readonly subcollections: typeof BadEvaluatorSubcols
  reason?: string
}

export interface ValidDocumentWithEvaluation extends ValidDocument {
  good_count: number
  bad_count: number
  created_by: string
  ref: EvaluableRefAssignable<ValidDocumentWithEvaluation>
}

export type EvaluableRefAssignable<T extends ValidDocumentWithEvaluation> =
  | ParentRefAssignable<GoodEvaluator, T>
  | ParentRefAssignable<BadEvaluator, T>

export const goodEvaluatorConverter =
  getConverter<GoodEvaluator>(GoodEvaluatorSubcols)

export const goodEvaluatorsRef = <T extends ValidDocumentWithEvaluation>(
  evaluableRef: EvaluableRefAssignable<T>
) =>
  collection<GoodEvaluator>(evaluableRef, 'good_evaluators').withConverter(
    goodEvaluatorConverter
  )

export const goodEvaluatorRef = <T extends ValidDocumentWithEvaluation>(
  evaluableRef: EvaluableRefAssignable<T>,
  uid: string
) => doc(goodEvaluatorsRef(evaluableRef), uid)

export const badEvaluatorConverter =
  getConverter<BadEvaluator>(BadEvaluatorSubcols)

export const badEvaluatorsRef = <T extends ValidDocumentWithEvaluation>(
  evaluableRef: EvaluableRefAssignable<T>
) =>
  collection<BadEvaluator>(evaluableRef, 'bad_evaluators').withConverter(
    badEvaluatorConverter
  )

export const badEvaluatorRef = <T extends ValidDocumentWithEvaluation>(
  evaluableRef: EvaluableRefAssignable<T>,
  uid: string
) => doc(badEvaluatorsRef(evaluableRef), uid)
