import {
  AuthProvider,
  FacebookAuthProvider,
  GithubAuthProvider,
  GoogleAuthProvider,
  linkWithRedirect,
  reauthenticateWithRedirect,
  signInWithRedirect,
  TwitterAuthProvider,
  unlink,
  User,
} from 'firebase/auth'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { FaFacebook, FaGithub, FaGoogle, FaTwitter } from 'react-icons/fa'
import { Provider } from '..'
import { usePopupMessage } from '../../assets/messenger'
import { Designed, PromiseVoid } from '../../types'
import { logEvent } from '../../utils'
import { useAfterSignedInPath } from '../hooks'
import { fb } from '../instance'
import { hasProvider } from '../utils/oauth'

const googleProvider = new GoogleAuthProvider()
const twitterProvider = new TwitterAuthProvider()
const facebookProvider = new FacebookAuthProvider()
const githubProvider = new GithubAuthProvider()

type OAuthButtonsProps = {
  onClick: (provider: AuthProvider) => PromiseVoid
  showProviders?: string[] // 表示するプロバイダーの一覧
  size?: 'sm' | 'lg'
  disabled?: Record<Provider, boolean>
} & (
  | {
      label: string // [provider_name]を変数として利用可
      labels?: undefined
    }
  | {
      label?: undefined
      labels: Record<Provider, string>
    }
)

export const OAuthButtons = ({
  label,
  labels,
  onClick: callback,
  showProviders,
  disabled,
  ...otherProps
}: Designed<OAuthButtonsProps>) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleClick = async (provider: AuthProvider) => {
    setIsSubmitting(true)
    await callback(provider)
    setIsSubmitting(false)
  }
  return (
    <div {...otherProps}>
      <div className="d-flex flex-column justify-content-between gap-4">
        {(!showProviders || showProviders.includes('google.com')) && (
          <button
            disabled={isSubmitting || disabled?.['google.com']}
            className="btn btn-google btn-social"
            onClick={() => handleClick(googleProvider)}
          >
            <FaGoogle className="btn-social-icon" />
            {labels
              ? labels['google.com']
              : label.replace('[provider_name]', 'Google')}
          </button>
        )}
        {(!showProviders || showProviders.includes('twitter.com')) && (
          <button
            disabled={isSubmitting || disabled?.['twitter.com']}
            className="btn btn-twitter btn-social"
            onClick={() => handleClick(twitterProvider)}
          >
            <FaTwitter className="btn-social-icon" />
            {labels
              ? labels['twitter.com']
              : label.replace('[provider_name]', 'Twitter')}
          </button>
        )}
        {(!showProviders || showProviders.includes('facebook.com')) && (
          <button
            disabled={isSubmitting || disabled?.['facebook.com']}
            className="btn btn-facebook btn-social"
            onClick={() => handleClick(facebookProvider)}
          >
            <FaFacebook className="btn-social-icon" />
            {labels
              ? labels['facebook.com']
              : label.replace('[provider_name]', 'Facebook')}
          </button>
        )}
        {(!showProviders || showProviders.includes('github.com')) && (
          <button
            disabled={isSubmitting || disabled?.['github.com']}
            className="btn btn-github btn-social"
            onClick={() => handleClick(githubProvider)}
          >
            <FaGithub className="btn-social-icon" />
            {labels
              ? labels['github.com']
              : label.replace('[provider_name]', 'Github')}
          </button>
        )}
      </div>
    </div>
  )
}

type SignInButtonsProps = {
  /** 新規登録用であるか */
  register?: boolean

  /**
   * リダイレクトの設定
   * string型の場合はpathとしてログイン後に指定されたpathに遷移
   * trueの場合は有効なafterSignedInPathが存在する場合は当該ページに、その他の場合はメインページに遷移
   * falseの場合はリダイレクトしない
   * デフォルトはtrueが指定されている
   */
  redirect?: string | boolean
}

export const OAuthSignInButtons = ({
  register = false,
  redirect = true,
  ...otherProps
}: Designed<SignInButtonsProps>) => {
  const router = useRouter()

  const { setAfterSignedInPath } = useAfterSignedInPath()
  useEffect(() => {
    if (typeof redirect === 'string') setAfterSignedInPath(redirect)
    else if (redirect === false) setAfterSignedInPath(router.asPath)
  }, [redirect])

  // ローカルホストの場合は`localhost:3000`をauthDomainに設定出来ないため、`firebaseapp.com`に依存している。
  // そのため、ローカルホストでクロスサイトのプロテクションが掛かっているブラウザ（Safariなど）での認証を行う場合のみ、`signInWithRedirect`が使えない。
  const signInWithProvider = async (provider: AuthProvider) => {
    await router.replace(
      `${router.asPath.split('?')[0]}?OAuthSignInRedirect=true&providerId=${
        provider.providerId
      }`
    )
    logEvent(register ? 'tap_oauth_sign_up' : 'tap_oauth_login', {
      method: provider.providerId,
    })
    await signInWithRedirect(fb.auth, provider)
  }

  return (
    <OAuthButtons
      onClick={signInWithProvider}
      label={register ? '[provider_name]で登録' : '[provider_name]でログイン'}
      {...otherProps}
    />
  )
}

type OAuthReauthenticateButtonsProps = {
  user: User
}

export const OAuthReauthenticateButtons = ({
  user,
  ...otherProps
}: Designed<OAuthReauthenticateButtonsProps>) => {
  const router = useRouter()

  // ローカルホストの場合は`localhost:3000`をauthDomainに設定出来ないため、`firebaseapp.com`に依存している。
  // そのため、ローカルホストでクロスサイトのプロテクションが掛かっているブラウザ（Safariなど）での認証を行う場合のみ、`reauthenticateWithRedirect`が使えないので動作しない。
  const reauthenticateWithProvider = async (provider: AuthProvider) => {
    await router.replace('?OAuthReauthenticateRedirect=true')
    await reauthenticateWithRedirect(user, provider)
  }

  const showProviders = user.providerData.map((p) => p.providerId)
  return (
    <OAuthButtons
      onClick={reauthenticateWithProvider}
      label={'[provider_name]で認証'}
      showProviders={showProviders}
      {...otherProps}
    />
  )
}

type AssociateSocialAccountButtonsProps = {
  user: User
}

export const AssociateSocialAccountButtons = ({
  user,
  ...otherProps
}: Designed<AssociateSocialAccountButtonsProps>) => {
  const router = useRouter()
  const { setPopupMessage } = usePopupMessage()

  const [providerData, setProviderData] = useState(user.providerData)

  const labels = useMemo<Record<Provider, string>>(() => {
    return {
      'google.com': hasProvider(providerData, 'google.com')
        ? 'Googleと連携を解除'
        : 'Googleと連携',
      'twitter.com': hasProvider(providerData, 'twitter.com')
        ? 'Twitterと連携を解除'
        : 'Twitterと連携',
      'facebook.com': hasProvider(providerData, 'facebook.com')
        ? 'Facebookと連携を解除'
        : 'Facebookと連携',
      'github.com': hasProvider(providerData, 'github.com')
        ? 'GitHubと連携を解除'
        : 'GitHubと連携',
    }
  }, [providerData])

  useEffect(() => {
    void fb.call('updateSocialAccounts')({})
  }, [providerData])

  const associateOrCancelAssociate = async (provider: AuthProvider) => {
    if (hasProvider(providerData, provider.providerId)) {
      try {
        if (providerData.length === 1) {
          setPopupMessage(
            '認証手段は1つ以上必要なため、連携を解除することはできません。パスワードを設定するか他のアカウントを連携してください。',
            'danger'
          )
          return
        }
        await unlink(user, provider.providerId)
        setProviderData((current) =>
          current.filter((p) => p.providerId !== provider.providerId)
        ) // AuthStateが自動で変わらないので手動で更新
      } catch (error) {
        setPopupMessage(
          'アカウントの連携解除に失敗しました。ページを再読み込みしてもう一度お試しください。',
          'danger'
        )
      }
    } else {
      await router.replace('?OAuthLinkRedirect=true')
      await linkWithRedirect(user, provider)
    }
  }

  return (
    <OAuthButtons
      labels={labels}
      onClick={associateOrCancelAssociate}
      {...otherProps}
    />
  )
}
