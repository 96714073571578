import { Form } from 'react-bootstrap'
import { useCategories } from '../../hooks'
import { Designed, PromiseVoid } from '../../types'

type Props = {
  value: string | undefined
  size?: 'sm' | 'lg'
  onChange: (currentValue: string) => PromiseVoid
  isInvalid?: boolean
  undefinedLabel?: string
}

export const CategorySelector = ({
  value,
  size,
  onChange: handleChange,
  isInvalid,
  undefinedLabel = 'カテゴリ未設定',
  ...otherProps
}: Designed<Props>) => {
  const { selectableCategories } = useCategories()
  return (
    <Form.Select
      {...otherProps}
      size={size}
      defaultValue={undefined}
      onChange={(e) => handleChange(e.currentTarget.value)}
      isInvalid={isInvalid}
    >
      <option hidden>{undefinedLabel}</option>
      {selectableCategories?.map((category) => (
        <option
          key={category.id}
          value={category.id}
          selected={value === category.id}
        >
          {category.localized_name.ja}
        </option>
      ))}
    </Form.Select>
  )
}
