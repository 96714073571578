import { useState } from 'react'
import { Dropdown, Stack } from 'react-bootstrap'
import { BsThreeDotsVertical } from 'react-icons/bs'
import {
  MuteSettingModal,
  ProfileEditorModal,
  UserFollowButton,
  UserImageWithLink,
  UserNameLink,
  UserRelatedLinks,
  UserRelationshipsCounter,
} from '.'
import { Profile } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { Designed } from '../../types'
import { PVCounter } from '../PVCounter'
import { GoodCounter } from '../evaluation'
import { usePopupMessage } from '../messenger'
import { TeamParticipateButton } from '../team/ParticipateButton'

type Props = {
  profile: Profile
  profileCommentTruncate?: boolean
}

export const UserInfo = ({
  profile,
  profileCommentTruncate = true,
  ...wrapperProps
}: Designed<Props>) => {
  const { user: currentUser } = useAuthState()
  const { setPopupMessage } = usePopupMessage()
  const [showEditorModal, setShowEditModal] = useState(false)
  const [showMuteSettingModal, setShowMuteSettingModal] = useState(false)

  return (
    <>
      <ProfileEditorModal
        profile={profile}
        show={showEditorModal}
        onHide={() => setShowEditModal(false)}
      />
      <MuteSettingModal
        profile={profile}
        show={showMuteSettingModal}
        onHide={() => setShowMuteSettingModal(false)}
      />
      <div {...wrapperProps}>
        <Stack gap={4} className="border rounded p-4">
          <div className={`d-flex align-items-center gap-3`}>
            <div>
              <UserImageWithLink
                profile={profile}
                size="55"
                className="flex-shrink-0"
              />
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <UserNameLink
                profile={profile}
                className="d-block text-truncate text-black fw-bold"
              />
              <div className="d-flex gap-2">
                <GoodCounter
                  value={profile.contents_aggregation.good_count}
                  className="text-muted small"
                />
                <PVCounter
                  value={profile.contents_aggregation.pv}
                  className="text-muted small"
                />
              </div>
            </div>
            <Dropdown align={'end'} style={{ zIndex: 1035 }}>
              <Dropdown.Toggle
                variant="link"
                className="text-muted fs-6 dropdown-toggle-after-none me-2"
              >
                <BsThreeDotsVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {currentUser && currentUser.uid === profile.id && (
                  <Dropdown.Item
                    onClick={() => {
                      setShowEditModal(true)
                      console.log('test')
                    }}
                    className="text-success"
                  >
                    編集
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  className="text-black"
                  onClick={async () => {
                    await navigator.clipboard.writeText(`${profile.id}`)
                    setPopupMessage('ユーザIDをコピーしました。')
                  }}
                >
                  ユーザ ID をコピー
                </Dropdown.Item>
                {currentUser && currentUser.uid !== profile.id && (
                  <Dropdown.Item
                    className="text-danger"
                    onClick={() => {
                      setShowMuteSettingModal(true)
                    }}
                  >
                    ミュートの設定
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {profile.comment && (
            <div
              className={`small mt-1 text-break ${
                profileCommentTruncate ? 'text-truncate-5' : ''
              }`}
            >
              {profile.comment}
            </div>
          )}
          <UserRelatedLinks profile={profile} className="fs-5 lh-1" />
          <Stack direction="horizontal" gap={3} className="mw-100">
            <UserRelationshipsCounter
              profile={profile}
              type="followers"
              className="text-muted text-decoration-none"
            />
            <UserRelationshipsCounter
              profile={profile}
              type="followees"
              className="text-muted text-decoration-none"
            />
            {profile.is_team && (
              <UserRelationshipsCounter
                profile={profile}
                type="members"
                className="text-muted text-decoration-none"
              />
            )}
          </Stack>
          <UserFollowButton
            profile={profile}
            type="text"
            size="sm"
            className="w-100"
          />
          {profile.is_team && <TeamParticipateButton teamProfile={profile} />}
        </Stack>
      </div>
    </>
  )
}
