import { z } from 'zod'
import {
  ESArticle,
  ESBook,
  ESGlossary,
  ESTextbook,
  ESUser,
} from '../../elastic_search'
import { Serialized } from '../../utils'

export const ArticleSearchEndpointParams = z.union([
  z.object({
    locale: z.string(),
    type: z.enum(['trend']),
    category_id: z.string().optional(),
  }),
  z.object({
    locale: z.string(),
    type: z.enum(['recommendation']),
    seed: z.number(),
    category_id: z.string().optional(),
  }),
  z.object({
    type: z.enum(['ids']),
    ids: z.array(z.string()),
  }),
  z.object({
    type: z.enum(['ranking']),
    locale: z.string(),
    category_id: z.string().optional(),
    year: z.number().min(2019).optional(),
    month: z.number().min(1).max(12).step(1).optional(),
  }),
  z.object({
    locale: z.string(),
    category_id: z.string().optional(),
    type: z.enum(['timeline']),
    mode: z.enum(['all', 'followee', 'recommend']),
  }),
  z.object({
    category_id: z.string().optional(),
    type: z.enum(['bookmarks']),
  }),
  z.object({
    type: z.enum(['query']),
    apply_recommendation: z.boolean().optional().default(true),
    locale: z.string().optional(),
    keyword: z.string().optional(),
    tag_ids: z.array(z.string()).optional(),
    uids: z.array(z.string()).optional(),
    good_evaluator_uids: z.array(z.string()).optional(),
    category_ids: z.array(z.string()).optional(),
    order: z.enum(['created_at asc', 'created_at desc', 'score desc']),
  }),
])

export const BookSearchEndpointParams = z.union([
  z.object({
    locale: z.string(),
    type: z.enum(['trend']),
    category_id: z.string().optional(),
  }),
  z.object({
    type: z.enum(['ranking']),
    locale: z.string(),
    category_id: z.string().optional(),
    year: z.number().min(2019).optional(),
    month: z.number().min(1).max(12).step(1).optional(),
  }),
  z.object({
    locale: z.string(),
    type: z.enum(['timeline']),
    category_id: z.string().optional(),
    mode: z.enum(['all', 'followee', 'recommend']),
  }),
  z.object({
    type: z.enum(['bookmarks']),
    category_id: z.string().optional(),
  }),
  z.object({
    type: z.enum(['query']),
    apply_recommendation: z.boolean().default(true),
    locale: z.string().optional(),
    keyword: z.string().optional(),
    tag_ids: z.array(z.string()).optional(),
    uids: z.array(z.string()).optional(),
    good_evaluator_uids: z.array(z.string()).optional(),
    category_ids: z.array(z.string()).optional(),
    order: z.enum(['created_at asc', 'created_at desc', 'score desc']),
  }),
])

export const GlossariesSearchEndpointParams = z.object({
  type: z.enum(['query']),
  keyword: z.string(),
})

export const TextbooksSearchEndpointParams = z.object({
  type: z.enum(['query']),
  keyword: z.string(),
})

export const UsersSearchEndpointParams = z.union([
  z.object({
    type: z.enum(['query']),
    locale: z.string(),
    keyword: z.string(),
    order: z
      .enum(['created_at asc', 'created_at desc', 'followers_count desc'])
      .optional()
      .default('created_at desc'),
  }),
  z.object({
    type: z.enum(['ids']),
    ids: z.array(z.string()),
  }),
])

export const SearchEndpointParams = z.intersection(
  z.union([
    z.object({
      index: z.enum(['articles']),
      params: ArticleSearchEndpointParams,
    }),
    z.object({
      index: z.enum(['books']),
      params: BookSearchEndpointParams,
    }),
    z.object({
      index: z.enum(['glossaries']),
      params: GlossariesSearchEndpointParams,
    }),
    z.object({
      index: z.enum(['textbooks']),
      params: TextbooksSearchEndpointParams,
    }),
    z.object({
      index: z.enum(['users']),
      params: UsersSearchEndpointParams,
    }),
  ]),
  z.object({
    page: z.number().min(1).optional().default(1),
    size: z.number().min(1).max(30).optional().default(20),
  })
)

export type SearchEndpointResult =
  | { success: true; index: 'articles'; result: Serialized<ESArticle>[] }
  | { success: true; index: 'books'; result: Serialized<ESBook>[] }
  | { success: true; index: 'glossaries'; result: Serialized<ESGlossary>[] }
  | { success: true; index: 'textbooks'; result: Serialized<ESTextbook>[] }
  | { success: true; index: 'users'; result: Serialized<ESUser>[] }
