import { memo, useEffect, useMemo } from 'react'
import { CompilerProps } from '.'
import { useMathJaxRenderer } from '../../mathjax'
import { useShortenedLinksBuilder } from '../hooks/useShortenedLinksBuilder'
import { Lexer } from '../lexer'
import { parseBlockToHTML } from '../parser/html'
import { compileMacrosToHTML, sanitize } from '../utils'

const compile = (mathdown: string, compiledMacros: string) => {
  const tokens = Lexer.lex(mathdown)
  const parsedSrc = parseBlockToHTML(tokens)
  const sanitizedSrc = sanitize(compiledMacros + parsedSrc)
  return sanitizedSrc
}

const CompilerImpl = ({
  _ref: providedRef,
  mathdown,
  className,
  style,
  macros,
  references,
  design,
}: CompilerProps) => {
  const compiledMacros = useMemo<string>(() => {
    return compileMacrosToHTML(macros ?? [])
  }, [macros])

  const compiledMathdown = useMemo<string>(
    () => compile(mathdown, compiledMacros),
    [mathdown, compiledMacros]
  )

  const { ref, typeset } = useMathJaxRenderer<HTMLDivElement>(compiledMacros)

  useEffect(() => {
    typeset()
  }, [compiledMathdown])

  useShortenedLinksBuilder(ref, references)

  useEffect(() => {
    if (!providedRef) return
    providedRef.current = ref.current
  }, [])

  return (
    <div
      ref={ref}
      dangerouslySetInnerHTML={{ __html: compiledMathdown }}
      className={`${className !== undefined ? className : ''} ${
        design ? `mathdown-${design}` : ''
      }`}
      style={style}
    />
  )
}

export const StaticCompiler = memo(CompilerImpl)
