import { collection, db, doc, DocRef } from '../../../../firebase/alias'
import { getConverter } from '../converter'
import { Document } from '../document'
import { TagSubcols } from './subcollections'

export interface Tag extends Document {
  readonly ref: DocRef<Tag>
  readonly parent_id: 'tags'
  readonly subcollections: typeof TagSubcols
  followers_count: number
  contributors_count: number
  name: string
}

export const tagConverter = getConverter<Tag>(TagSubcols)
export const tagsRef = collection<Tag>(db, 'tags').withConverter(tagConverter)
export const tagRef = (tagId: string) => doc(tagsRef, tagId)
