export const replaceUnicode = (text: string) => {
  const patterns: { unSupportText: string; replacement: string }[] = [
    { unSupportText: '①', replacement: '1' },
    { unSupportText: '②', replacement: '2' },
    { unSupportText: '③', replacement: '3' },
    { unSupportText: '④', replacement: '4' },
    { unSupportText: '⑤', replacement: '5' },
    { unSupportText: '⑥', replacement: '6' },
    { unSupportText: '⑦', replacement: '7' },
    { unSupportText: '⑧', replacement: '8' },
    { unSupportText: '⑨', replacement: '9' },
    { unSupportText: '⑩', replacement: '10' },
    { unSupportText: '｡', replacement: '。' },
    { unSupportText: 'ｱ', replacement: 'ア' },
    { unSupportText: 'ｲ', replacement: 'イ' },
    { unSupportText: 'ｳ', replacement: 'ウ' },
    { unSupportText: 'ｴ', replacement: 'エ' },
    { unSupportText: 'ｵ', replacement: 'オ' },
    { unSupportText: 'ｶ', replacement: 'カ' },
    { unSupportText: 'ｷ', replacement: 'キ' },
    { unSupportText: 'ｸ', replacement: 'ク' },
    { unSupportText: 'ｹ', replacement: 'ケ' },
    { unSupportText: 'ｺ', replacement: 'コ' },
    { unSupportText: 'ｻ', replacement: 'サ' },
    { unSupportText: 'ｼ', replacement: 'シ' },
    { unSupportText: 'ｽ', replacement: 'ス' },
    { unSupportText: 'ｾ', replacement: 'セ' },
    { unSupportText: 'ｿ', replacement: 'ソ' },
    { unSupportText: 'ﾀ', replacement: 'タ' },
    { unSupportText: 'ﾁ', replacement: 'チ' },
    { unSupportText: 'ﾂ', replacement: 'ツ' },
    { unSupportText: 'ﾃ', replacement: 'テ' },
    { unSupportText: 'ﾄ', replacement: 'ト' },
    { unSupportText: 'ﾅ', replacement: 'ナ' },
    { unSupportText: 'ﾆ', replacement: 'ニ' },
    { unSupportText: 'ﾇ', replacement: 'ヌ' },
    { unSupportText: 'ﾈ', replacement: 'ネ' },
    { unSupportText: 'ﾉ', replacement: 'ノ' },
    { unSupportText: 'ﾊ', replacement: 'ハ' },
    { unSupportText: 'ﾋ', replacement: 'ヒ' },
    { unSupportText: 'ﾌ', replacement: 'フ' },
    { unSupportText: 'ﾍ', replacement: 'ヘ' },
    { unSupportText: 'ﾎ', replacement: 'ホ' },
    { unSupportText: 'ﾏ', replacement: 'マ' },
    { unSupportText: 'ﾐ', replacement: 'ミ' },
    { unSupportText: 'ﾑ', replacement: 'ム' },
    { unSupportText: 'ﾒ', replacement: 'メ' },
    { unSupportText: 'ﾓ', replacement: 'モ' },
    { unSupportText: 'ﾔ', replacement: 'ヤ' },
    { unSupportText: 'ﾕ', replacement: 'ユ' },
    { unSupportText: 'ﾖ', replacement: 'ヨ' },
    { unSupportText: 'ﾗ', replacement: 'ラ' },
    { unSupportText: 'ﾘ', replacement: 'リ' },
    { unSupportText: 'ﾙ', replacement: 'ル' },
    { unSupportText: 'ﾚ', replacement: 'レ' },
    { unSupportText: 'ﾛ', replacement: 'ロ' },
    { unSupportText: 'ﾜ', replacement: 'ワ' },
    { unSupportText: 'ｦ', replacement: 'ヲ' },
    { unSupportText: 'ﾝ', replacement: 'ン' },
    { unSupportText: 'ｶﾞ', replacement: 'ガ' },
    { unSupportText: 'ｷﾞ', replacement: 'ギ' },
    { unSupportText: 'ｸﾞ', replacement: 'グ' },
    { unSupportText: 'ｹﾞ', replacement: 'ゲ' },
    { unSupportText: 'ｺﾞ', replacement: 'ゴ' },
    { unSupportText: 'ｻﾞ', replacement: 'ザ' },
    { unSupportText: 'ｼﾞ', replacement: 'ジ' },
    { unSupportText: 'ｽﾞ', replacement: 'ズ' },
    { unSupportText: 'ｾﾞ', replacement: 'ゼ' },
    { unSupportText: 'ｿﾞ', replacement: 'ゾ' },
    { unSupportText: 'ﾀﾞ', replacement: 'ダ' },
    { unSupportText: 'ﾁﾞ', replacement: 'ヂ' },
    { unSupportText: 'ﾂﾞ', replacement: 'ヅ' },
    { unSupportText: 'ﾃﾞ', replacement: 'デ' },
    { unSupportText: 'ﾄﾞ', replacement: 'ド' },
    { unSupportText: 'ﾊﾞ', replacement: 'バ' },
    { unSupportText: 'ﾋﾞ', replacement: 'ビ' },
    { unSupportText: 'ﾌﾞ', replacement: 'ブ' },
    { unSupportText: 'ﾍﾞ', replacement: 'ベ' },
    { unSupportText: 'ﾎﾞ', replacement: 'ボ' },
    { unSupportText: 'ﾊﾟ', replacement: 'パ' },
    { unSupportText: 'ﾋﾟ', replacement: 'ピ' },
    { unSupportText: 'ﾌﾟ', replacement: 'プ' },
    { unSupportText: 'ﾍﾟ', replacement: 'ペ' },
    { unSupportText: 'ﾎﾟ', replacement: 'ポ' },
    { unSupportText: 'ｧ', replacement: 'ァ' },
    { unSupportText: 'ｨ', replacement: 'ィ' },
    { unSupportText: 'ｩ', replacement: 'ゥ' },
    { unSupportText: 'ｪ', replacement: 'ェ' },
    { unSupportText: 'ｫ', replacement: 'ォ' },
    { unSupportText: 'ｯ', replacement: 'ッ' },
    { unSupportText: 'ｬ', replacement: 'ャ' },
    { unSupportText: 'ｭ', replacement: 'ュ' },
    { unSupportText: 'ｮ', replacement: 'ョ' },
    { unSupportText: '', replacement: '' },
    { unSupportText: '', replacement: '' },
    { unSupportText: '', replacement: '' },
  ]
  patterns.forEach((pattern) => {
    text = text.replace(pattern.unSupportText, pattern.replacement)
  })
  return text
}
